import { FC, useContext } from 'react';
import styled from 'styled-components';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';

import AppContext from 'contexts/AppContext';
import { ConversationCardProps } from 'api/lesson.utils';
import DataContext from 'contexts/DataContext';

interface StyledCardProps {
  darkmode?: string | undefined;
  selected?: boolean;
}

const ConversationHeading = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const LatestMessage = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const SessionDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const StyledCard = styled(Card)<StyledCardProps>`
  ${({ darkmode, selected }) =>
    selected
      ? 'border: 1px solid rgba(51, 153, 255);'
      : darkmode === 'true'
      ? 'border: 1px solid #FFFFFF;'
      : 'border: 1px solid#121212;'}
  box-shadow: none;
  min-height: 100px;
  margin: 0 16px;
`;

const ConversationListItem: FC<ConversationCardProps> = ({
  activity,
  handleCardClick,
  icon,
  id,
  latestMessage,
  name,
  nextSession,
  requestStatus,
}) => {
  const { isDarkMode } = useContext(AppContext);
  const { selectedRequest } = useContext(DataContext);

  return (
    <StyledCard
      darkmode={isDarkMode ? isDarkMode.toString() : ''}
      selected={selectedRequest ? id === selectedRequest.id : false}
      onClick={handleCardClick}
    >
      <CardActionArea
        disabled={selectedRequest ? id === selectedRequest.id : false}
        sx={{ minHeight: 100 }}
      >
        <CardContent>
          {icon}
          <div>
            <ConversationHeading>
              <Typography gutterBottom component="div">
                {name}
              </Typography>
              <Typography gutterBottom component="div" color="text.secondary">
                &bull; {activity}
              </Typography>
            </ConversationHeading>
            <LatestMessage gutterBottom variant="body2">
              <em>{latestMessage}</em>
            </LatestMessage>
            <SessionDetails>
              <Typography variant="body2" color="text.secondary">
                {requestStatus}
              </Typography>
              {nextSession && (
                <Typography variant="body2" color="text.secondary">
                  &bull; {nextSession}
                </Typography>
              )}
            </SessionDetails>
          </div>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default ConversationListItem;
