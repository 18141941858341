import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { TravelOptions } from 'api/organization.utils';

interface TravelSelectorProps {
  currentSelection?: TravelOptions;
  onChange: (travelOptions: TravelOptions) => void;
}

const TravelSelector = ({
  currentSelection = {
    toInstructor: true,
    toStudent: false,
    toThirdParty: false,
  },
  onChange,
}: TravelSelectorProps) => {
  const { toInstructor, toStudent, toThirdParty } = currentSelection;

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTravelOptions = {
      ...currentSelection,
      [event.target.name]: event.target.checked,
    };
    onChange(newTravelOptions);
  };

  return (
    <FormControl fullWidth>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={toInstructor}
              onChange={handleLocationChange}
              name="toInstructor"
            />
          }
          label="Students will have to travel to my location"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={toStudent}
              onChange={handleLocationChange}
              name="toStudent"
            />
          }
          label="I will travel to the student's location"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={toThirdParty}
              onChange={handleLocationChange}
              name="toThirdParty"
            />
          }
          label="I will travel to a third party location discussed with the student"
        />
      </FormGroup>
    </FormControl>
  );
};

export default TravelSelector;
