import { Card } from '@mui/material';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomAlert, CustomButton, InputFields } from 'components/shared';
import UserContext from 'contexts/UserContext';

import { StandardContainer } from 'assets/styles/shared';
import { auth } from 'api/firebaseConfig';

const FeedbackContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 90vh;
  overflow-y: auto;
  width: 540px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 100%;
`;

const StyledStandardContainer = styled(StandardContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ConfirmEmailLogIn: FC = () => {
  const { currentUser } = useContext(UserContext);

  const navigate = useNavigate();

  const { TextInput } = InputFields;

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [hasValidated, setHasValidated] = useState(false);

  const storedEmail = window.localStorage.getItem('emailForSignIn');
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const validationTimeoutRef = useRef<number | null>(null);

  const redirectUrl = useMemo(() => {
    return localStorage.getItem('path') || '/dashboard';
  }, []);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);

    setEmailError(null);
    setHasValidated(false);

    if (validationTimeoutRef.current) {
      clearTimeout(validationTimeoutRef.current);
    }

    validationTimeoutRef.current = window.setTimeout(() => {
      if (value && !emailPattern.test(value)) {
        setEmailError('Invalid email format');
      }
      setHasValidated(true);
    }, 1000);
  };

  const handleEmailLinkConfirmation = async (email: string) => {
    try {
      await signInWithEmailLink(auth, email, window.location.href);
      window.localStorage.removeItem('emailForSignIn');
      navigate(redirectUrl);
    } catch (error) {
      // TODO: Display this error to the user in a friendly manner.
      console.error('Error during email confirmation:', error);
    }
  };

  useEffect(() => {
    if (!storedEmail) return;

    if (isSignInWithEmailLink(auth, window.location.href)) {
      handleEmailLinkConfirmation(storedEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <StyledStandardContainer>
      <FeedbackContainer>
        {!storedEmail ? (
          <StyledCard>
            <h1>Confirm Email Address</h1>
            <TextInput
              fullWidth
              error={emailError ? 'error' : ''}
              helperText={
                emailError ? emailError : 'Please enter your email address'
              }
              label="Enter Email"
              onChange={handleEmailChange}
              value={email}
            />
            <CustomButton
              buttonText={'Confirm'}
              disabled={!hasValidated || !!emailError || !email}
              onClick={() => handleEmailLinkConfirmation(email)}
            />
          </StyledCard>
        ) : (
          <CustomAlert
            type="info"
            title="Confirming Email Address...  Please wait."
          />
        )}
      </FeedbackContainer>
    </StyledStandardContainer>
  );
};

export default ConfirmEmailLogIn;
