import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material';
import { RequestStatus } from 'api/lesson.utils';
import { CustomDivider } from 'components/shared';
import DataContext from 'contexts/DataContext';
import { FC, useContext } from 'react';
import styled from 'styled-components';

interface LessonManagerNavProps {
  changeActivity: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeStatus: (event: SelectChangeEvent<string>) => void;
  selectedActivities: Record<string, boolean>;
  status: string;
}

const StyledCard = styled(Card)`
  height: 100%;
  width: 200px;
`;

const TruncatedLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
`;

const LessonManagerNav: FC<LessonManagerNavProps> = ({
  changeActivity,
  changeStatus,
  selectedActivities,
  status,
}) => {
  const { userActivities } = useContext(DataContext);

  return (
    <StyledCard>
      <CardContent>
        <CardHeader title="Filters" />
        <FormControl fullWidth>
          <FormLabel id="status-radio-group-label">Status</FormLabel>
          <RadioGroup
            aria-labelledby="status-radio-group-label"
            name="status-radio-group"
            value={status}
            onChange={changeStatus}
          >
            <FormControlLabel
              value={RequestStatus.NEW}
              control={<Radio />}
              label="New"
            />
            <FormControlLabel
              value={RequestStatus.ACTIVE}
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value={RequestStatus.COMPLETED}
              control={<Radio />}
              label="Completed"
            />
            <FormControlLabel
              value={RequestStatus.CANCELED}
              control={<Radio />}
              label="Canceled"
            />
            <FormControlLabel
              value={RequestStatus.DECLINED}
              control={<Radio />}
              label="Declined"
            />
          </RadioGroup>
        </FormControl>
        <CustomDivider margin="large" />
        <FormControl fullWidth>
          <FormLabel id="activity-radio-group-label">Activity</FormLabel>
          {userActivities &&
            userActivities.map(activity => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedActivities[activity.activity.activity] || false
                    }
                    onChange={changeActivity}
                  />
                }
                key={activity.activity.activityId}
                label={
                  <TruncatedLabel>{activity.activity.activity}</TruncatedLabel>
                }
                value={activity.activity.activity}
              />
            ))}
        </FormControl>
      </CardContent>
    </StyledCard>
  );
};

export default LessonManagerNav;
