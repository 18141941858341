import { Button, Typography } from '@mui/material';
import { useContext } from 'react';

import { CustomDivider, Logo } from 'components/shared';
import AppContext from 'contexts/AppContext';

import {
  Bullet,
  CopyrightAndNotices,
  FooterContainer,
  FooterLinks,
  LeftSide,
  LinkList,
  LinkSection,
  LogoSection,
} from './styles';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  font-weight: 400;
  font-size: 1rem;
  text-transform: none;
  width: fit-content;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

const Footer = () => {
  const { isDarkMode } = useContext(AppContext);
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();

  const ResourcesLinkList = [
    {
      name: 'Need support',
      path: '/support',
    },
  ];

  const CompanyLinkList = [
    {
      name: 'About Instrekt',
      path: '/about',
    },
    {
      name: 'Become an Instructor',
      path: '#',
    },
  ];

  const CopyrightNotice = () => {
    return (
      <CopyrightAndNotices>
        <LeftSide>
          <Typography>
            &copy; {currentYear} Instrekt, All Rights Reserved
          </Typography>
          <Bullet>&bull;</Bullet>
          <StyledButton onClick={() => navigate('/privacy')} variant="text">
            Privacy
          </StyledButton>
          <Bullet>&bull;</Bullet>
          <StyledButton onClick={() => navigate('/terms')} variant="text">
            Terms and Conditions
          </StyledButton>
        </LeftSide>
      </CopyrightAndNotices>
    );
  };

  return (
    <FooterContainer darkMode={isDarkMode}>
      <FooterLinks>
        <LinkSection>
          <Typography variant="h6">Resources</Typography>
          <LinkList>
            {ResourcesLinkList.map(({ name, path }, index) => (
              <StyledButton
                key={index}
                onClick={() => navigate(path)}
                variant="text"
              >
                {name}
              </StyledButton>
            ))}
          </LinkList>
        </LinkSection>
        <LinkSection>
          <Typography variant="h6">Company</Typography>
          <LinkList>
            {CompanyLinkList.map(({ name, path }, index) => (
              <StyledButton
                key={index}
                onClick={() => navigate(path)}
                variant="text"
              >
                {name}
              </StyledButton>
            ))}
          </LinkList>
        </LinkSection>
        <LogoSection>
          <Logo />
        </LogoSection>
      </FooterLinks>
      <CustomDivider />
      <CopyrightNotice />
    </FooterContainer>
  );
};

export default Footer;
