import { ArrowBack } from '@mui/icons-material';
import { Autocomplete, Card, Tab, Tabs, TextField } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FamilyData } from 'api/family.utils';
import { StudentData } from 'api/student.utils';
import { CustomButton } from 'components/shared';
import DataContext from 'contexts/DataContext';
import FamilyInfo from './FamilyInfo';
import LessonDefaultInfo from './LessonDefaultInfo';
import StudentInfo from './StudentInfo';
import LessonLog from './LessonLog';

interface StudentDetailsProps {
  family: FamilyData;
  handleBackToStudents: () => void;
  student: StudentData;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  tab: string;
}

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem 0;
  width: 100%;
  max-width: 800px;
`;

const StudentDetailsContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: 800px;
`;

const SearchContainer = styled.div`
  gap: 16px;
  width: 300px;
`;

const StyledCard = styled(Card)`
  margin: 0 auto;
  overflow: scroll;
  padding: 1rem;
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 1rem;
  width: fit-content;
`;

const StudentDetails: FC<StudentDetailsProps> = ({
  family,
  handleBackToStudents,
  student,
}) => {
  const navigate = useNavigate();
  const { userLessons, userStudents } = useContext(DataContext);

  const [tab, setTab] = useState('overview');

  const studentOptions = userStudents.map(student => {
    return {
      id: student.id,
      label: `${student.student.lastName}, ${student.student.firstName}`,
    };
  });

  const tabList = [
    { index: 'overview', title: 'Overview' },
    { index: 'defaults', title: 'Lesson Defaults' },
    { index: 'family', title: 'Family Contact' },
    { index: 'lessons', title: 'Lesson Log' },
  ];

  const studentLessons = userLessons.filter(lesson => {
    return lesson.lesson.studentId === student.id;
  });

  const backToOverview = () => {
    setTab('overview');
  };

  const handleStudentChange = (
    event: any,
    value: { id: string; label: string } | null
  ) => {
    if (value) {
      navigate(`/students/details?s=${value.id}`);
      backToOverview();
    }
  };

  const handleViewFamily = (id: string) => {
    navigate(`/families/details?f=${id}`);
  };

  const handleViewLessonLog = (id: string) => {
    navigate(`/lessons/log?s=${id}`);
  };

  const TabPanel = ({ children, index, tab }: TabPanelProps) => {
    return (
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tabpanel-${index}`}
      >
        {tab === index && children}
      </div>
    );
  };

  return (
    <StyledCard>
      <ActionBar>
        <CustomButton
          buttonText={'Go back to students'}
          onClick={handleBackToStudents}
          startIcon={<ArrowBack />}
          variant="text"
        />
        <SearchContainer>
          <Autocomplete
            fullWidth={false}
            id="student-search"
            renderInput={params => (
              <TextField {...params} label="Switch to student" />
            )}
            onChange={handleStudentChange}
            options={studentOptions}
            value={null}
          />
        </SearchContainer>
      </ActionBar>
      <StyledTabs
        onChange={(e, value) => setTab(value)}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabList.map(tab => (
          <Tab key={tab.index} label={tab.title} value={tab.index} />
        ))}
      </StyledTabs>
      <StudentDetailsContainer variant="outlined">
        <TabPanel tab={tab} index={'overview'}>
          <StudentInfo
            handleViewLessonLog={() =>
              handleViewLessonLog(student.student.familyId)
            }
            student={student}
          />
        </TabPanel>
        <TabPanel tab={tab} index={'defaults'}>
          <LessonDefaultInfo student={student} />
        </TabPanel>
        <TabPanel tab={tab} index={'family'}>
          <FamilyInfo
            backToOverview={backToOverview}
            family={family}
            handleViewFamily={() => handleViewFamily(student.student.familyId)}
            student={student}
          />
        </TabPanel>
        <TabPanel tab={tab} index={'lessons'}>
          <LessonLog lessons={studentLessons} />
        </TabPanel>
      </StudentDetailsContainer>
    </StyledCard>
  );
};

export default StudentDetails;
