import { Timestamp } from 'firebase/firestore';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { RequestStatus } from 'api/lesson.utils';
import { CustomToggleButtonGroup, Layouts } from 'components/shared';
import DataContext, { initialRequestData } from 'contexts/DataContext';
import TrackListItem from './TrackListItem';

const TrackList = () => {
  const { incomingRequests, setSelectedTrack } = useContext(DataContext);

  const { CardListLayout } = Layouts;

  const [params] = useSearchParams();
  const m = params.get('m');

  const [selectedFilter, setSelectedFilter] = useState(RequestStatus.NEW);

  const sortedRequests = incomingRequests
    .filter(({ request }) => request.requestStatus === selectedFilter)
    .sort((a, b) => {
      const aDate = a.request.timestamp as Timestamp;
      const bDate = b.request.timestamp as Timestamp;

      if (!aDate || !bDate) return 0;

      return bDate.toMillis() - aDate.toMillis();
    });

  const filterItems = [
    {
      name: 'New',
      value: RequestStatus.NEW,
    },
    {
      name: 'Sent',
      value: RequestStatus.SENT,
    },
  ];

  const handleToggleButtonChange = (
    event: MouseEvent<HTMLElement>,
    newFilter: RequestStatus
  ) => {
    if (newFilter === null) return;

    setSelectedFilter(newFilter);
  };

  const handleOpenRequest = (id: string) => {
    const requestToOpen =
      sortedRequests.find(request => request.id === id) || initialRequestData;
    setSelectedTrack(requestToOpen);
  };

  const filters = (
    <CustomToggleButtonGroup
      exclusive
      onChange={handleToggleButtonChange}
      buttons={filterItems}
      value={selectedFilter}
    />
  );

  const cardList =
    sortedRequests &&
    sortedRequests.map(({ id, request }) => {
      return (
        <TrackListItem
          key={id}
          handleClick={() => handleOpenRequest(id)}
          id={id}
          request={request}
        />
      );
    });

  useEffect(() => {
    const requestToManage = m
      ? sortedRequests.find(request => request.id === m)
      : sortedRequests[0];

    setSelectedTrack(requestToManage || null);
  }, [sortedRequests, m, setSelectedTrack]);

  return (
    <CardListLayout
      alert={cardList.length === 0}
      alertData={{
        message: `Once you a client sends you a request for a proposal
        and you accept it, the request will appear in this list.`,
        severity: 'info',
        title: 'Nothing to see here',
      }}
      filters={filters}
      cardList={cardList}
    />
  );
};

export default TrackList;
