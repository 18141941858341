import { Step, StepLabel, Stepper } from '@mui/material';
import { FC } from 'react';

import { CustomButton } from 'components/shared';

import { StepNavButtons } from './styles';

interface NewTrackProps {
  activeStep: number;
  backStep: () => void;
  renderStepContent: () => JSX.Element;
  steps: {
    label: string;
    stepAction: () => void;
    disabled: boolean;
  }[];
}

const NewTrack: FC<NewTrackProps> = ({
  activeStep,
  backStep,
  renderStepContent,
  steps,
}) => {
  return (
    <>
      <StepNavButtons>
        <CustomButton
          buttonText="Back"
          disabled={activeStep === 0}
          onClick={backStep}
          variant="text"
        />
        <CustomButton
          buttonText={activeStep === 2 ? 'Send Proposal to Client' : 'Next'}
          color={activeStep === 2 ? 'success' : undefined}
          disabled={steps[activeStep].disabled}
          onClick={steps[activeStep].stepAction}
          variant={activeStep === 2 ? 'contained' : 'outlined'}
        />
      </StepNavButtons>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(({ label }) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContent()}
    </>
  );
};

export default NewTrack;
