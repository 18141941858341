import { Card, Drawer, IconButton, Typography } from '@mui/material';
import { RequestData, RequestStatus } from 'api/lesson.utils';
import { CustomButton, CustomDivider } from 'components/shared';
import { FC } from 'react';
import styled from 'styled-components';
import ActivityDisplay from '../Request/ActivityInfo';
import StudentsDisplay from '../Request/StudentInfo';
import RequestDisplay from '../Request/RequestInfo';
import { Close } from '@mui/icons-material';
import { DrawerHeader } from 'components/Header/styles';

interface RequestDrawerProps {
  accept?: () => void;
  decline?: () => void;
  onClose: () => void;
  open: boolean;
  requestData: RequestData;
}

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

const LessonProposalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const LessonProposalDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
`;

const RightButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  padding: 16px;
  margin-bottom: 16px;
  overflow: scroll;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RequestDrawer: FC<RequestDrawerProps> = ({
  accept,
  decline,
  onClose,
  open,
  requestData,
}) => {
  const { request } = requestData;
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <StyledCard>
        <LessonProposalContainer>
          <StyledDrawerHeader>
            <Typography variant="h5">
              Request Details for {request.requester.name}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </StyledDrawerHeader>
          <CustomDivider margin="small" />
          <LessonProposalDetails>
            {request.requestStatus === RequestStatus.NEW && (
              <ButtonContainer>
                <RightButtons>
                  <CustomButton
                    buttonText={'Create Proposal'}
                    color={'success'}
                    onClick={accept}
                    variant="outlined"
                  />
                  <CustomButton
                    buttonText={'Decline Request'}
                    color={'error'}
                    onClick={decline}
                    variant="outlined"
                  />
                </RightButtons>
              </ButtonContainer>
            )}
            <ActivityDisplay requestData={requestData} />
            <StudentsDisplay studentData={request.student} variant="outlined" />
            <RequestDisplay requestData={requestData} />
          </LessonProposalDetails>
        </LessonProposalContainer>
      </StyledCard>
    </Drawer>
  );
};

export default RequestDrawer;
