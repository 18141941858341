import { Button, SxProps, Theme } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

interface CustomButtonProps {
  padding?: { x: number; y: number };
  buttonText?: string | JSX.Element;
  color?: 'error' | 'secondary' | 'success';
  disabled?: boolean;
  endIcon?: JSX.Element;
  href?: string;
  htmlFor?: string;
  id?: string;
  onClick?: () => void;
  size?: 'large' | 'medium' | 'small';
  startIcon?: JSX.Element;
  sx?: SxProps<Theme>;
  type?: 'button' | 'submit' | 'reset';
  value?: string;
  variant?: 'contained' | 'outlined' | 'text';
}

interface StyleProps {
  padding: { x: number; y: number };
}

const StyledButton = styled(Button)<StyleProps>`
  padding: ${({ padding }) => padding.y}px ${({ padding }) => padding.x}px;
`;

const CustomButton: FC<CustomButtonProps> = ({
  padding = { x: 48, y: 16 },
  buttonText,
  color,
  disabled = false,
  endIcon,
  href,
  htmlFor,
  id,
  onClick,
  size,
  startIcon,
  sx,
  type = 'button',
  value,
  variant = 'contained',
}) => {
  return (
    <StyledButton
      padding={padding}
      color={color}
      disabled={disabled}
      endIcon={endIcon}
      href={href}
      id={id}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      sx={sx}
      type={type}
      value={value}
      variant={variant}
    >
      {htmlFor ? <label htmlFor={htmlFor}>{buttonText}</label> : buttonText}
    </StyledButton>
  );
};

export default CustomButton;
