import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import styled from 'styled-components';

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
`;

const DateTimeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 8px 0 8px 16px;
`;

const Day = styled(Typography)``;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0 8px 16px;
`;

const DisplayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MainDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledCardHeader = styled(CardHeader)`
  color: #58abff;
`;

const Time = styled(Typography)``;

export {
  CardDetails,
  DateTimeContainer,
  Day,
  DetailsContainer,
  DisplayContainer,
  FlexRowContainer,
  InformationContainer,
  MainDisplayContainer,
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
  Time,
};
