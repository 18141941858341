import {
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import React, { useCallback, useContext, useState } from 'react';
import UserContext from 'contexts/UserContext';
import { Flag } from '@mui/icons-material';
import { MessageData } from 'api/lesson.utils';

const StyledCard = styled(({ myMessage, ...otherProps }) => (
  <Card {...otherProps} />
))`
  ${({ myMessage }) => !myMessage && 'border: 1px solid rgba(51, 153, 255);'}
  margin: ${({ myMessage }) =>
    myMessage ? '8px 24px 8px 10%' : '8px 10% 8px 24px'};
  max-width: 100%;
`;

const StyledCardContent = styled(CardContent)`
  padding: 16px !important;
`;

const MessageBlobHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NameDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Message = ({ id, message }: MessageData) => {
  const { currentUser } = useContext(UserContext);
  const { displayName, text, senderId, timestamp } = message;

  // TODO: build out comment reporting functionality
  const [reportAbuse, setReportAbuse] = useState(false);

  const time = timestamp
    ?.toDate()
    .toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

  const handleMouseEnter = useCallback(() => setReportAbuse(true), []);
  const handleMouseLeave = useCallback(() => setReportAbuse(false), []);

  return (
    <StyledCard
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      variant="outlined"
      myMessage={currentUser && currentUser.id === senderId}
    >
      <StyledCardContent>
        <MessageBlobHeader>
          <NameDateContainer>
            <Typography fontWeight={1000}>
              {currentUser && currentUser.id === senderId ? 'Me' : displayName}
            </Typography>
            <Typography color={'typography.secondary'}>{time}</Typography>
          </NameDateContainer>
          {reportAbuse && currentUser && currentUser.id !== senderId && (
            <Tooltip title={'Report Comment'}>
              <IconButton
                onClick={() => console.log(`reported comment ${id}`)}
                size="small"
                sx={{ p: 0 }}
              >
                <Flag />
              </IconButton>
            </Tooltip>
          )}
        </MessageBlobHeader>
        <Typography>{text}</Typography>
      </StyledCardContent>
    </StyledCard>
  );
};

export default React.memo(Message);
