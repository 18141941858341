import { Check, Close, ContentCopy, QrCode } from '@mui/icons-material';
import { Alert, InputAdornment, Modal, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { getImageURL } from 'api/user.utils';
import {
  getSlugByName,
  saveOrganizationDocument,
  TravelOptions,
} from 'api/organization.utils';
import {
  CustomButton,
  CustomDivider,
  InputFields,
  TooltipIconButton,
} from 'components/shared';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';
import Offering from './Offering';
import SettingsView from '../SettingsView';
import TravelSelector from './TravelSelector';

import {
  QrCodeContentContainer,
  SaveAndPreviewButtonContainer,
  SectionContainer,
  StyledCircularProgress,
  StyledQRCodeCanvas,
  StyledStandardContainer,
  StyledTypography,
  UrlContainer,
  UrlIconButtonContainer,
  UrlNameAndButtonContainer,
} from './styles';

const OrganizationInfo = () => {
  const { currentUser } = useContext(UserContext);
  const { setUserOrganization, userActivities, userOrganization } =
    useContext(DataContext);

  const [updatedUserOrganization, setUpdatedUserOrganization] =
    useState(userOrganization);
  const [imageData, setImageData] = useState({
    hero: '',
  });
  const [isDirty, setIsDirty] = useState(false);
  const [isSlugAvailable, setIsSlugAvailable] = useState<
    'available' | 'checking' | 'empty' | 'not available'
  >('empty');
  const [originalSlug, setOriginalSlug] = useState(
    userOrganization.organization.slug
  );
  const [showQRCode, setShowQRCode] = useState(false);
  const [slugError, setSlugError] = useState('');
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  const { ImageField, TextArea, TextInput } = InputFields;

  const { organization } = updatedUserOrganization;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      `https://instrekt.com/i/${organization.slug}`
    );
  };

  const updateOrganizationData = (
    key: string,
    value:
      | string
      | string[]
      | { [x: string]: boolean | string }
      | TravelOptions,
    dirty?: boolean
  ) => {
    setUpdatedUserOrganization(prevData => ({
      ...prevData,
      organization: { ...prevData.organization, [key]: value },
    }));
    dirty && setIsDirty(dirty);
  };

  const checkSlugAvailability = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value } = event.target;
    updateOrganizationData(id, value);
    setIsSlugAvailable('checking');
    setSlugError('');

    const newSlug = value
      .replace(/[^a-zA-Z0-9\-_\s]/g, '') // This line currently removes characters that are not letters, numbers, or spaces.
      .replace(/\s+/g, '-') // Converts spaces to dashes.
      .replace(/^-+|-+$/g, '') // Removes leading and trailing dashes.
      .trim()
      .toLowerCase();

    const existing = newSlug === originalSlug;

    if (timerId) {
      clearTimeout(timerId);
    }

    if (value.length === 0) {
      setIsSlugAvailable('empty');
      return;
    }

    if (value) {
      const newTimerId = setTimeout(() => {
        if (existing) {
          updateOrganizationData('slug', newSlug);
          setIsSlugAvailable('available');
          return;
        }
        if (newSlug.length >= 5) {
          getSlugByName(newSlug).then(res => {
            updateOrganizationData('slug', newSlug);
            setIsSlugAvailable(res.slug ? 'not available' : 'available');
            setSlugError(
              res.slug ? 'This organization name is already taken' : ''
            );
            !res.slug && setIsDirty(true);
          });
        } else {
          setIsSlugAvailable('not available');
          setSlugError('Your organization name must be at least 5 characters');
        }
      }, 1000);

      setTimerId(newTimerId);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    updateOrganizationData(id, value, true);
  };

  const handleImageChange = (imagePath: string) => {
    updateOrganizationData('heroImage', imagePath, true);
  };

  const handleImageUrl = (url: string) => {
    setImageData(prevData => ({ ...prevData, hero: url }));
  };

  const handleLocationChange = (travelData: TravelOptions) => {
    updateOrganizationData('travelOptions', travelData, true);
  };

  const handleSaveOrgData = () => {
    if (!currentUser) return;

    saveOrganizationDocument(
      currentUser,
      {
        ...updatedUserOrganization,
        organization: {
          ...organization,
          ownerId: currentUser.id,
          ownerName: currentUser.user.firstName,
        },
      },
      setUserOrganization
    );
    setIsDirty(false);
  };

  const handlePublishClick = () => {
    if (!currentUser) return;

    saveOrganizationDocument(
      currentUser,
      {
        ...updatedUserOrganization,
        organization: {
          ...organization,
          active: !organization.active,
        },
      },
      setUserOrganization
    );
    setIsDirty(false);
  };

  const EndAdornment = () => {
    switch (isSlugAvailable) {
      case 'available':
        return <Check color="success" />;
      case 'checking':
        return <StyledCircularProgress />;
      case 'empty':
        return <></>;
      case 'not available':
        return <Close color="error" />;
    }
  };

  const isFormValid = () => {
    // does the organization have a valid slug and name?
    if (!organization.slug || !organization.orgName) return false;

    // does the organization have a location and travel option selected?
    if (!organization.travelOptions) return false;

    //does the userActivities array have at least one activity that is active?
    if (!userActivities.some(activity => activity.activity.active))
      return false;

    //does the organization have main text, sub text, and button text, hero image, bio, and philosophy?
    if (
      !organization.heroMainText ||
      !organization.heroSubText ||
      !organization.buttonText ||
      !organization.heroImage ||
      !organization.bio ||
      !organization.philosophy
    )
      return false;

    return true;
  };

  useEffect(() => {
    if (organization.slug) {
      setIsSlugAvailable('available');
      setOriginalSlug(organization.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (organization.heroImage) {
      getImageURL(organization.heroImage, url => {
        isMounted && handleImageUrl(url);
      });
    }

    return () => {
      isMounted = false;
    };
  }, [organization.heroImage]);

  return (
    <SettingsView
      rightContent={
        <StyledStandardContainer>
          <SectionContainer>
            <CustomDivider margin="large" text="Organization Information" />
            <Alert severity="warning">
              Changing your URL Path can cause broken links for your clients.
              Please be sure to update any links you have shared if you change
              your organization name as your landing page URL will also change.
            </Alert>
            <TextInput
              id="orgName"
              label="Organization Name"
              onChange={handleInputChange}
              value={organization.orgName}
            />
            <TextInput
              id="slug"
              adornmentProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {<EndAdornment />}
                  </InputAdornment>
                ),
              }}
              label="URL Path"
              onChange={checkSlugAvailability}
              value={organization.slug}
              helperText={slugError}
              error={slugError}
            />
            <UrlContainer>
              <Typography>Landing Page URL</Typography>
              <UrlNameAndButtonContainer>
                {organization.slug && isSlugAvailable !== 'not available' ? (
                  <>
                    <StyledTypography>
                      https://instrekt.com/i/{organization.slug}
                    </StyledTypography>
                    <UrlIconButtonContainer>
                      <TooltipIconButton
                        action={handleCopyToClipboard}
                        icon={<ContentCopy />}
                        tooltipText="Copy to clipboard"
                      />
                      <TooltipIconButton
                        action={() => setShowQRCode(prev => !prev)}
                        icon={<QrCode />}
                        tooltipText="Create QR Code"
                      />
                    </UrlIconButtonContainer>
                  </>
                ) : (
                  <StyledTypography>
                    Please enter a valid and available name for your
                    organization.
                  </StyledTypography>
                )}
              </UrlNameAndButtonContainer>
              <Modal
                open={showQRCode}
                onClose={() => setShowQRCode(prev => !prev)}
                aria-labelledby="landing-page-qr-code"
                aria-describedby="landing-page-qr-code"
              >
                <QrCodeContentContainer>
                  <StyledQRCodeCanvas
                    value={`https://instrekt.com/i/${organization.slug}`}
                    size={200}
                    includeMargin
                  />
                </QrCodeContentContainer>
              </Modal>
            </UrlContainer>
          </SectionContainer>
          <SectionContainer>
            <CustomDivider margin="large" text="Rate Sheet" />
            <Offering activities={userActivities} />
          </SectionContainer>
          <SectionContainer>
            <CustomDivider margin="large" text="Location and Travel Options" />
            <TravelSelector
              currentSelection={organization.travelOptions}
              onChange={handleLocationChange}
            />
          </SectionContainer>
          <SectionContainer>
            <CustomDivider margin="large" text="Hero Section" />
            <TextInput
              id="heroMainText"
              label="Main Text"
              inputProps={{ maxLength: 40 }}
              onChange={handleInputChange}
              value={organization.heroMainText}
            />
            <TextInput
              id="heroSubText"
              label="Sub Text"
              inputProps={{ maxLength: 90 }}
              onChange={handleInputChange}
              value={organization.heroSubText}
            />
            <TextInput
              id="buttonText"
              label="CTA Button Text"
              inputProps={{ maxLength: 40 }}
              onChange={handleInputChange}
              value={organization.buttonText}
            />
            <ImageField
              currentImageUrl={imageData.hero}
              height={400}
              imageName="hero"
              storageRefLocation={`/lps/${currentUser?.id}`}
              handleImageChange={handleImageChange}
              width={595}
            />
          </SectionContainer>
          <SectionContainer>
            <CustomDivider margin="large" text="About" />
            <TextArea
              id="bio"
              inputProps={{ maxLength: 510 }}
              label="Say a little bit about yourself"
              onChange={handleInputChange}
              rows={4}
              value={organization.bio}
            />
            <TextArea
              id="philosophy"
              inputProps={{ maxLength: 510 }}
              label="What is your teaching philosophy"
              onChange={handleInputChange}
              rows={4}
              value={organization.philosophy}
            />
          </SectionContainer>
          <CustomDivider margin="large" />
          <SaveAndPreviewButtonContainer>
            <CustomButton
              buttonText={organization.active ? 'Unpublish' : 'Publish'}
              disabled={!isFormValid()}
              onClick={handlePublishClick}
            />
            <CustomButton
              buttonText={'Save'}
              disabled={!isDirty}
              onClick={handleSaveOrgData}
            />
          </SaveAndPreviewButtonContainer>
        </StyledStandardContainer>
      }
    />
  );
};

export default OrganizationInfo;
