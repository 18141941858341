import { Alert, Typography } from '@mui/material';
import { CustomButton, InputFields } from 'components/shared';
import ActivityRequestDialog from 'components/shared/ActivityRequestDialog';
import DataContext from 'contexts/DataContext';
import SignupContext from 'contexts/SignupContext';
import { useContext, useState } from 'react';

const ActivityAndDescription = () => {
  const { activityList } = useContext(DataContext);
  const { applicationData, updateActivityData } = useContext(SignupContext);

  const { AutocompleteDropdown, TextArea } = InputFields;

  const [showActivityRequest, setShowActivityRequest] = useState(false);

  const handleActivityChange = (event: any, newValue: string | null) => {
    const matchingActivity = activityList.find(({ name }) => name === newValue);
    if (!matchingActivity) return;

    updateActivityData('activity', newValue || '');
    updateActivityData('activityId', matchingActivity.id);
  };

  return (
    <>
      <Typography variant="h3">Activity to instruct</Typography>
      <Alert severity="info" variant="outlined">
        Pick an activity from the list below. If you don't see your activity,
        click the link below the dropdown to request it added to the system. If
        you instruct multiple activities, you can add more after you finish the
        initial instructor setup process.
      </Alert>
      <AutocompleteDropdown
        onChange={handleActivityChange}
        options={activityList.map(item => item.name)}
        value={applicationData.application.activity.activity || null}
      />
      <CustomButton
        onClick={() => setShowActivityRequest(prev => !prev)}
        buttonText={"Don't see your activity? Request it here"}
        variant="text"
      />
      <TextArea
        fullWidth
        label="Information about this activity specific to your instruction or experience"
        id="details"
        onChange={e => updateActivityData(e.target.id, e.target.value)}
        rows={5}
        value={applicationData.application.activity.details}
      />
      <ActivityRequestDialog
        open={showActivityRequest}
        toggleDialog={() => setShowActivityRequest(prev => !prev)}
      />
    </>
  );
};

export default ActivityAndDescription;
