import { Alert, Typography } from '@mui/material';
import { InputFields } from 'components/shared';
import SignupContext from 'contexts/SignupContext';
import { useContext } from 'react';

const Availability = () => {
  const { applicationData, updateActivityData } = useContext(SignupContext);
  const { TimeOfDayField } = InputFields;

  return (
    <>
      <Typography variant="h3">Your availability</Typography>
      <Alert severity="info" variant="outlined">
        In the table below, select the time ranges you are available to work.
        This will allow your prospective students to indicate their preferred
        times to take lessons. Since it is your schedule, your students cannot
        book lessons directly and instead you will send a proposal based on the
        information they provide.
      </Alert>
      <TimeOfDayField
        handleTODClick={(event, timeDay) => {
          timeDay.length && updateActivityData('timeOfDay', timeDay);
        }}
        tOD={applicationData.application.activity.timeOfDay}
      />
    </>
  );
};

export default Availability;
