import { ArrowBack } from '@mui/icons-material';
import { Autocomplete, Card, Tab, Tabs, TextField } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FamilyData } from 'api/family.utils';
import { StudentData } from 'api/student.utils';
import { CustomButton } from 'components/shared';
import DataContext from 'contexts/DataContext';
import FamilyInfo from './FamilyInfo';

interface FamilyDetailsProps {
  family: FamilyData;
  handleBackToFamilies: () => void;
  students?: StudentData[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  tab: string;
}

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem 0;
  width: 100%;
  max-width: 800px;
`;

const FamilyDetailsContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: 800px;
`;

const SearchContainer = styled.div`
  gap: 16px;
  width: 300px;
`;

const StyledCard = styled(Card)`
  margin: 0 auto;
  overflow: scroll;
  padding: 1rem;
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 1rem;
  width: fit-content;
`;

const FamilyDetails: FC<FamilyDetailsProps> = ({
  family,
  handleBackToFamilies,
  students,
}) => {
  const navigate = useNavigate();
  const { userFamilies } = useContext(DataContext);

  const [tab, setTab] = useState('overview');

  const familyOptions = userFamilies.map(family => {
    return {
      id: family.id,
      label: `${family.family.familyName} - (${
        family.family.firstName + ' ' + family.family.lastName
      })`,
    };
  });

  const tabList = [
    { index: 'overview', title: 'Overview' },
    { index: 'students', title: 'Students' },
    { index: 'lessons', title: 'Lessons' },
  ];

  const backToOverview = () => {
    setTab('overview');
  };

  const handleFamilyChange = (
    event: any,
    value: { id: string; label: string } | null
  ) => {
    if (value) {
      navigate(`/families/details?f=${value.id}`);
      backToOverview();
    }
  };

  const TabPanel = ({ children, index, tab }: TabPanelProps) => {
    return (
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tabpanel-${index}`}
      >
        {tab === index && children}
      </div>
    );
  };

  return (
    <StyledCard>
      <ActionBar>
        <CustomButton
          buttonText={'Go back to families'}
          onClick={handleBackToFamilies}
          startIcon={<ArrowBack />}
          variant="text"
        />
        <SearchContainer>
          <Autocomplete
            fullWidth={false}
            id="family-search"
            renderInput={params => (
              <TextField {...params} label="Select a Family" />
            )}
            onChange={handleFamilyChange}
            options={familyOptions}
            value={null}
          />
        </SearchContainer>
      </ActionBar>
      <StyledTabs
        onChange={(e, value) => setTab(value)}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabList.map(tab => (
          <Tab key={tab.index} label={tab.title} value={tab.index} />
        ))}
      </StyledTabs>
      <FamilyDetailsContainer variant="outlined">
        <TabPanel tab={tab} index={'overview'}>
          <FamilyInfo family={family} />
        </TabPanel>
        <TabPanel tab={tab} index={'students'}></TabPanel>
        <TabPanel tab={tab} index={'lessons'}></TabPanel>
      </FamilyDetailsContainer>
    </StyledCard>
  );
};

export default FamilyDetails;
