import { ChangeEvent, FC, useContext, useState } from 'react';
import styled from 'styled-components';

import { Family } from 'api/family.utils';
import { Student } from 'api/student.utils';
import { InputFields } from 'components/shared';
import { PlaceType } from 'components/shared/InputFields/AddressSearch';
import DataContext from 'contexts/DataContext';

interface FamilyDetailsProps {
  family: Family;
  familyType: 'new' | 'existing';
  handleAddressChange: (address: PlaceType | null) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  student: Student;
  selectFamily: (familyId: string) => void;
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const FamilyDetails: FC<FamilyDetailsProps> = ({
  family,
  familyType,
  handleAddressChange,
  onChange,
  selectFamily,
  student,
}) => {
  const { AddressSearch, DropDown, TextInput } = InputFields;

  const { userFamilies } = useContext(DataContext);

  const [error, setError] = useState<string>('');

  const handleFamilyChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'email') {
      setError('');
    }
    onChange(event);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!family.email) return;

    if (!emailRegex.test(family.email)) {
      setError('Invalid email address');
    }
  };

  const familyOptions = userFamilies.map(family => ({
    name: `${family.family.familyName} (${family.family.lastName}, ${family.family.firstName})`,
    value: family.id,
  }));

  return familyType === 'existing' ? (
    <DropDown
      options={familyOptions}
      onChange={event => selectFamily(event.target.value)}
      value={student.familyId}
      label="Select Existing Family"
    />
  ) : (
    <>
      <InputContainer>
        <TextInput
          fullWidth
          id="familyName"
          label="Family Name"
          onChange={handleFamilyChange}
          value={family.familyName}
        />
      </InputContainer>
      <InputContainer>
        <TextInput
          fullWidth
          id="firstName"
          label="Primary Contact First Name"
          onChange={handleFamilyChange}
          value={family.firstName}
        />
        <TextInput
          fullWidth
          id="lastName"
          label="Primary Contact Last Name"
          onChange={handleFamilyChange}
          value={family.lastName}
        />
      </InputContainer>
      <InputContainer>
        <TextInput
          error={error}
          fullWidth
          helperText={error || ''}
          id="email"
          label="Primary Contact Email"
          onBlur={validateEmail}
          onChange={handleFamilyChange}
          value={family.email}
        />
        <TextInput
          fullWidth
          id="phone"
          label="Primary Contact Phone"
          onChange={handleFamilyChange}
          value={family.phone}
        />
      </InputContainer>
      <AddressSearch handleAddressChange={handleAddressChange} />
    </>
  );
};

export default FamilyDetails;
