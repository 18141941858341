import { Application, ApplicationData } from 'contexts/SignupContext';
import { auth, db } from './firebaseConfig';
import { addDoc, collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { updateUserProfileDocument } from './user.utils';
import { RoleType, UserData } from 'contexts/UserContext';
import { saveOrganizationDocument } from './organization.utils';
import { saveActivityDocument } from './activity.utils';

export const completeApplication = async (
  applicationData: ApplicationData,
  currentUser: UserData
) => {
  const userId = auth.currentUser?.uid;

  if (!userId) return;

  try {
    saveOrganizationDocument(currentUser, {
      id: '',
      organization: applicationData.application.organization,
    })
      .then(docRef => {
        docRef &&
          saveActivityDocument(docRef, {
            id: '',
            activity: applicationData.application.activity,
          });
      })
      .then(() => {
        updateUserProfileDocument(userId, {
          ...applicationData.application.instructor,
          role: RoleType.INSTRUCTOR,
        });
      });
  } catch (error) {
    console.error('Error finalizing application:', error);
  }
};

export const getUserApplication = async (
  currentUser: UserData,
  setApplicationData: (applicationData: ApplicationData) => void
) => {
  const userId = auth.currentUser?.uid;

  if (!userId && userId !== currentUser.id) return;

  const userApplicationCollectionRef = collection(
    db,
    'users',
    userId,
    'application'
  );

  try {
    const querySnapshot = await getDocs(userApplicationCollectionRef);

    if (!querySnapshot.empty) {
      const docSnapshot = querySnapshot.docs[0];
      setApplicationData({
        id: docSnapshot.id,
        application: docSnapshot.data() as Application,
      });
    } else {
      console.log('No application found for the user.');
    }
  } catch (error) {
    console.error('Error fetching user application:', error);
  }
};

export const saveApplicationDocument = async (
  applicationData: ApplicationData
) => {
  const userId = auth.currentUser?.uid;

  if (!userId) return;

  const { id, application } = applicationData;

  try {
    if (id) {
      await updateExistingApplication(id, application, userId);
    } else {
      await addNewApplication(application, userId);
    }
  } catch (error) {
    console.error('Error saving application:', error);
  }
};

const updateExistingApplication = async (
  id: string,
  application: Application,
  userId: string
) => {
  await setDoc(doc(db, 'users', userId, 'application', id), application, {
    merge: true,
  });
  return id;
};

const addNewApplication = async (application: Application, userId: string) => {
  await addDoc(collection(db, 'users', userId, 'application'), application);
  await updateUserProfileDocument(userId, { role: RoleType.APPLICANT });
};
