import { Alert, AlertTitle } from '@mui/material';

import { StandardContainer } from 'assets/styles/shared';
import styled from 'styled-components';

const StyledStandardContainer = styled(StandardContainer)`
  margin: 48px 96px;
`;

const MissingOrganzation = () => {
  return (
    <StyledStandardContainer>
      <Alert severity="warning" variant="outlined">
        <AlertTitle>Set up your organization to continue</AlertTitle>
        You must set up your organization before you can create activities.
      </Alert>
    </StyledStandardContainer>
  );
};

export default MissingOrganzation;
