import { Check, Close, Phone } from '@mui/icons-material';
import { Alert, IconButton, TextField } from '@mui/material';
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  linkWithCredential,
  signInWithPhoneNumber,
  unlink,
} from 'firebase/auth';
import MuiPhoneNumber from 'material-ui-phone-number';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { updateUserProfileDocument } from 'api/user.utils';
import { CustomButton } from 'components/shared';
import { PhoneVerificationProps } from './types';
import { auth } from 'api/firebaseConfig';

const ButtonContainer = styled.div`
  margin-top: 16px;
`;

const PhoneFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PhoneVerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const PhoneVerification: FC<PhoneVerificationProps> = ({
  currentNumber,
  setExpandedIndex,
}) => {
  const { currentUser } = auth;
  const [isAuthenticatingByPhone, setIsAuthenticatingByPhone] =
    useState<boolean>(false);
  const [message, setMessage] = useState<JSX.Element | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    currentNumber ? currentNumber : ''
  );
  const [verificationCode, setVerificationCodeCode] = useState('');
  const [verificationId, setVerificationId] = useState('');

  const handleContinueWithPhone = async () => {
    const appVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        // callback: () => {},
        'expired-callback': () => {
          setMessage(
            <Alert
              severity="error"
              action={
                <IconButton onClick={() => setMessage(null)}>
                  <Close />
                </IconButton>
              }
            >
              There was an error, please try again later
            </Alert>
          );
        },
      },
      auth
    );
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(confirmationResult => {
        setVerificationId(confirmationResult.verificationId);
        setIsAuthenticatingByPhone(true);
        setMessage(
          <Alert
            severity="info"
            action={
              <IconButton onClick={() => setMessage(null)}>
                <Close />
              </IconButton>
            }
          >
            A verification code was sent to the number provided. Check your
            messages and enter the code below.
          </Alert>
        );
      })
      .catch(error => {
        setMessage(
          <Alert
            severity="error"
            action={
              <IconButton onClick={() => setMessage(null)}>
                <Close />
              </IconButton>
            }
          >
            {error.message}
          </Alert>
        );
      });
  };

  const handleSubmitVerificationCode = async () => {
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );

    if (currentUser) {
      linkWithCredential(currentUser, credential)
        .then(() => {
          updateUserProfileDocument(currentUser.uid, { phone: phoneNumber });
        })
        .then(() => setExpandedIndex && setExpandedIndex(null))
        .catch(error => {
          setMessage(
            <Alert
              severity="error"
              action={
                <IconButton onClick={() => setMessage(null)}>
                  <Close />
                </IconButton>
              }
            >
              {error.message}
            </Alert>
          );
          setIsAuthenticatingByPhone(false);
        });
    }
  };

  const handleConfirmationCodeChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const { value } = event.target;
    setVerificationCodeCode(value);
  };

  const handlePhoneChange = (
    phoneNumber:
      | string
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (typeof phoneNumber === 'string') {
      setPhoneNumber(phoneNumber);
    }
    return;
  };

  const handleUnlinkPhone = async () => {
    if (currentUser) {
      unlink(currentUser, 'phone')
        .then(() => updateUserProfileDocument(currentUser.uid, { phone: '' }))
        .catch(error => {
          setMessage(
            <Alert
              severity="error"
              action={
                <IconButton onClick={() => setMessage(null)}>
                  <Close />
                </IconButton>
              }
            >
              {error.message}
            </Alert>
          );
        });
    }
  };

  return (
    <PhoneVerificationContainer>
      {message}
      {!isAuthenticatingByPhone ? (
        <PhoneFieldsContainer>
          <MuiPhoneNumber
            countryCodeEditable={false}
            defaultCountry="us"
            disableDropdown
            fullWidth
            onlyCountries={['us']}
            onChange={handlePhoneChange}
            placeholder={'7048675309'}
            value={phoneNumber}
            variant="outlined"
            disabled={!!currentNumber}
          />
          <ButtonContainer>
            {!currentNumber ? (
              <CustomButton
                buttonText="Verify Phone Number"
                id="phone-verifier"
                onClick={handleContinueWithPhone}
                startIcon={<Phone />}
                value="Submit Form"
                variant="contained"
              />
            ) : (
              <CustomButton
                buttonText="Unlink Phone Number"
                id="phone-verifier"
                onClick={handleUnlinkPhone}
                startIcon={<Phone />}
                value="Unlink current phone"
                variant="contained"
              />
            )}
          </ButtonContainer>
        </PhoneFieldsContainer>
      ) : (
        <PhoneFieldsContainer>
          <TextField
            fullWidth
            name="code"
            type="text"
            value={verificationCode}
            onChange={handleConfirmationCodeChange}
            label="Enter the code"
            required
          />
          <ButtonContainer>
            <CustomButton
              buttonText="Send Code"
              onClick={handleSubmitVerificationCode}
              startIcon={<Check />}
              value="Send Code"
            />
          </ButtonContainer>
        </PhoneFieldsContainer>
      )}
      <div id="recaptcha-container"></div>
    </PhoneVerificationContainer>
  );
};

export default PhoneVerification;
