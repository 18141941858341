import { useEffect } from 'react';

interface TitleComponentProps {
  title?: string;
  children: any;
}

const Title = ({ title, children }: TitleComponentProps) => {
  useEffect(() => {
    document.title = title ? `Instrekt - ${title}` : 'Instrekt';
  }, [title]);

  return children;
};

export default Title;
