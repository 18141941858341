import { FC, useContext } from 'react';
import styled from 'styled-components';
import AppContext from 'contexts/AppContext';

interface MainLayoutProps {
  content: JSX.Element;
  drawer?: JSX.Element;
  drawerButton?: JSX.Element | null;
  headerText: string;
  navigation?: JSX.Element | null;
  useDrawer?: boolean;
}

interface MainContainerProps {
  darkMode?: boolean;
}

const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding-bottom: 1px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 100%;
  margin: 0 16px 16px;
  overflow: hidden;
`;

const ContentNavigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 1px;
`;

const Header = styled.div`
  font-size: 32px;
  margin: 24px 48px;
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 24px;
`;

const MainContainer = styled.div<MainContainerProps>`
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  left: 240px;
  ${({ darkMode }) =>
    darkMode
      ? 'background-color: rgba(18, 18, 18, 0.96);'
      : 'background-color: rgba(0, 0, 0, 0.04);'}
`;

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const MainLayout: FC<MainLayoutProps> = ({
  content,
  drawer,
  drawerButton,
  headerText,
  navigation,
  useDrawer = false,
}) => {
  const { isDarkMode } = useContext(AppContext);

  return (
    <MainContainer darkMode={isDarkMode}>
      <MainContentContainer>
        <HeaderContainer>
          <Header>{headerText}</Header>
          {useDrawer && <HeaderButtons>{drawerButton}</HeaderButtons>}
        </HeaderContainer>
        <ContentContainer>
          {navigation && <ContentNavigation>{navigation}</ContentNavigation>}
          <Content>{content}</Content>
        </ContentContainer>
        {useDrawer && drawer}
      </MainContentContainer>
    </MainContainer>
  );
};

export default MainLayout;
