import { Divider, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';
import styled from 'styled-components';

interface CustomDividerProps {
  divVariant?: 'inset' | 'fullWidth' | 'middle';
  flexItem?: boolean;
  margin?: 'large' | 'small' | 'none';
  orientation?: 'vertical' | 'horizontal';
  text?: string | null;
  textVariant?: 'inherit' | Variant;
}

interface DividerProps {
  margin?: string;
}

const StyledDivider = styled(Divider)<DividerProps>`
  ${({ margin }) => {
    const getMarginStyle = (marginType: string | undefined) => {
      switch (marginType) {
        case 'large':
          return 'margin: 32px 0 16px';
        case 'small':
          return 'margin: 16px 0';
        case 'none':
          return 'margin: 0';
        default:
          return '';
      }
    };

    return getMarginStyle(margin);
  }};
  ::before,
  ::after {
    position: unset;
  }
`;

const CustomDivider: FC<CustomDividerProps> = ({
  divVariant,
  margin,
  orientation,
  text,
  textVariant = 'h5',
}) => {
  return (
    <StyledDivider
      flexItem
      margin={margin}
      orientation={orientation}
      variant={divVariant}
    >
      {text && (
        <Typography variant={textVariant ? textVariant : 'inherit'}>
          {text}
        </Typography>
      )}
    </StyledDivider>
  );
};

export default CustomDivider;
