import { Alert, Typography } from '@mui/material';
import { CustomButton } from 'components/shared';

const PaymentInfo = () => {
  return (
    <>
      <Typography variant="h3">Setup payout information</Typography>
      <Alert severity="info" variant="outlined">
        This will allow you to receive payments for your classes. You will need
        to set up a Stripe account to receive payouts. Clicking the button below
        will take you to the Stripe website to set up your account and complete
        the onboarding process.
      </Alert>
      <CustomButton
        buttonText={'Setup Payout with Stripe'}
        onClick={() => {
          window.open('https://stripe.com', '_blank');
        }}
        variant="contained"
      />
    </>
  );
};

export default PaymentInfo;
