import { Alert, AlertTitle } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

interface CustomAlertProps {
  body?: string;
  title?: string;
  type?: 'error' | 'info' | 'success' | 'warning';
  onClose?: () => void;
}

const StyledAlert = styled(Alert)`
  height: fit-content;
  margin: 16px;
  padding: 16px;
  width: 100%;
`;

const CustomAlert: FC<CustomAlertProps> = ({
  body,
  title,
  type = 'info',
  onClose,
}) => {
  return onClose ? (
    <StyledAlert severity={type} onClose={onClose}>
      <AlertTitle>{title}</AlertTitle>
      {body}
    </StyledAlert>
  ) : (
    <StyledAlert severity={type}>
      <AlertTitle>{title}</AlertTitle>
      {body}
    </StyledAlert>
  );
};

export default CustomAlert;
