import { MoreVert } from '@mui/icons-material';
import {
  Card,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { RequestData, RequestStatus } from 'api/lesson.utils';
import { useNavigate } from 'react-router-dom';

interface ILessonTable {
  handleRowClick: (id: string) => void;
  requestData: RequestData[];
  toggleDeclineDialog: () => void;
}

const StyledCard = styled(Card)`
  width: 100%;
`;

const StyledTableCell = styled(TableCell)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LessonTable: FC<ILessonTable> = ({
  handleRowClick,
  requestData,
  toggleDeclineDialog,
}) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOptionClick = (option: string, id: string) => () => {
    handleMenuClose();
    switch (option) {
      case 'View Request':
        handleRowClick(id);
        break;
      case 'Chat with Client':
        navigate(`/messages?m=${id}`);
        break;
      case 'Create Proposal':
        navigate(`/lessons/new?r=${id}`);
        break;
      case 'Decline':
        toggleDeclineDialog();
        break;
      case 'Edit':
        // Handle edit
        break;
      case 'Delete':
        // Handle delete
        break;
      case 'Mark as Complete':
        // Handle mark as complete
        break;
      case 'Mark as Active':
        // Handle mark as active
        break;
      default:
        break;
    }
  };

  const generateMenuOptions = (requestStatus: string) => {
    if (RequestStatus.NEW) {
      return ['View Request', 'Chat with Client', 'Create Proposal', 'Decline'];
    } else if (RequestStatus.ACTIVE) {
      return ['Edit', 'Delete', 'Mark as Complete'];
    } else if (RequestStatus.COMPLETED) {
      return ['Edit', 'Delete', 'Mark as Active'];
    } else {
      return [];
    }
  };

  const getStatusColor = (
    status: string
  ):
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning' => {
    switch (status) {
      case RequestStatus.ACTIVE:
        return 'primary';
      case RequestStatus.ARCHIVED:
        return 'secondary';
      case RequestStatus.CANCELED:
        return 'error';
      case RequestStatus.COMPLETED:
        return 'success';
      case RequestStatus.DECLINED:
        return 'warning';
      case RequestStatus.NEW:
        return 'primary';
      default:
        return 'default';
    }
  };

  return (
    <StyledCard>
      <TableContainer component={Paper}>
        <Table aria-label="new requests table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: '25%' }}>Date</StyledTableCell>
              <StyledTableCell style={{ width: '15%' }}>
                Requester
              </StyledTableCell>
              <StyledTableCell style={{ width: '12%' }}>Type</StyledTableCell>
              <StyledTableCell style={{ width: '15%' }}>
                Activity
              </StyledTableCell>
              <StyledTableCell style={{ width: '15%' }}>
                Student
              </StyledTableCell>
              <StyledTableCell style={{ width: '10%' }}>Status</StyledTableCell>
              <TableCell style={{ width: '8%' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestData.map(({ id, request }) => (
              <TableRow key={id}>
                <TableCell>
                  {request.timestamp?.toDate().toLocaleDateString(undefined, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </TableCell>
                <TableCell>{request.requester.name}</TableCell>
                <TableCell>{request.lessonType}</TableCell>
                <TableCell>{request.activity.name}</TableCell>
                <TableCell>
                  {request.student &&
                    request.student.student.firstName +
                      ` (${request.student.student.age})`}
                </TableCell>
                <TableCell>
                  <Chip
                    color={getStatusColor(request.requestStatus)}
                    label={request.requestStatus}
                    variant={
                      request.requestStatus === RequestStatus.NEW
                        ? 'outlined'
                        : 'filled'
                    }
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Actions">
                    <IconButton
                      aria-controls="menu"
                      aria-haspopup="true"
                      onClick={handleMenuOpen}
                    >
                      <MoreVert />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    {generateMenuOptions(request.requestStatus).map(
                      (option, index) => (
                        <MenuItem
                          key={index}
                          onClick={handleMenuOptionClick(option, id)}
                        >
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                </TableCell>
              </TableRow>
            )) || (
              <TableRow>
                <TableCell colSpan={6}>No requests found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={requestData.length}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      />
    </StyledCard>
  );
};

export default LessonTable;
