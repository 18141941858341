import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAvailableOrganizationActivities } from 'api/activity.utils';
import { getImageURL } from 'api/user.utils';
import {
  OrganizationData,
  getOrganizationBySlug,
} from 'api/organization.utils';
import {
  AboutMe,
  FavoriteInstructor,
  HowItWorks,
  Offering,
} from 'components/LandingPage';
import { Hero } from 'components/shared';
import DataContext, { ActivityData } from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';
import { Contact, ContactType, saveContact } from 'api/contact.utils';

export interface ImageData {
  [x: string]: string | undefined;
}

const LandingPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const { userContacts, updateUserContacts } = useContext(DataContext);

  const [availableActivities, setAvailableActivities] = useState<
    ActivityData[]
  >([]);
  const [diasableFavoriteButton, setDisableFavoriteButton] =
    useState<boolean>(false);
  const [favoriteInstructor, setFavoriteInstructor] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<ImageData>({});
  const [organizationData, setOrganizationData] = useState<OrganizationData>();

  const { id, organization } = organizationData || {};

  const instructorSlug = useMemo(() => {
    return window.location.pathname.split('/')[2];
  }, []);

  const handleFavoriteInstructor = () => {
    if (
      !organizationData ||
      !organizationData.organization.ownerId ||
      !organizationData.organization.ownerName
    )
      return;

    const contact: Contact = {
      contactId: organizationData.organization.ownerId,
      contactName: organizationData.organization.ownerName,
      contactType: ContactType.INSTRUCTOR,
      orgName: organizationData?.organization.orgName,
      slug: organizationData?.organization.slug,
    };
    saveContact(currentUser, contact).then(res => {
      if (res.success) {
        updateUserContacts(contact);
        setFavoriteInstructor(prev => !prev);
        setDisableFavoriteButton(true);
        setTimeout(() => {
          setDisableFavoriteButton(false);
        }, 5000);
      }
    });
  };

  const handleImageUrl = (url: string) => {
    setImageUrl({ hero: url });
  };

  useEffect(() => {
    if (!instructorSlug) return;

    getOrganizationBySlug(setOrganizationData, instructorSlug);
  }, [instructorSlug]);

  useEffect(() => {
    if (!id) return;

    getAvailableOrganizationActivities(id, () => setAvailableActivities);
  }, [id]);

  useEffect(() => {
    if (!organization) return;

    if (organization.heroImage) {
      console.log('get image url query ran');
      getImageURL(organization.heroImage, handleImageUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    if (!currentUser || !userContacts) return;

    const savedContact = userContacts.find(
      contact => contact.contact.slug === instructorSlug
    );
    if (savedContact) {
      setFavoriteInstructor(true);
    }
  }, [currentUser, instructorSlug, userContacts]);

  return (
    <>
      {currentUser && (
        <FavoriteInstructor
          disabled={diasableFavoriteButton}
          favorite={favoriteInstructor}
          handleClick={handleFavoriteInstructor}
        />
      )}
      <Hero
        image={imageUrl.hero}
        callToAction={organization?.buttonText || 'Click to request lessons'}
        handleClick={() => navigate(`/i/${instructorSlug}/request`)}
        subText={organization?.heroSubText}
        title={organization?.heroMainText}
      />
      <Offering offeredActivities={availableActivities} />
      <AboutMe
        details={organization?.bio}
        instructorId={organization?.ownerId}
        instructorName={organization?.ownerName}
      />
      <HowItWorks
        handleClick={() => navigate(`/i/${instructorSlug}/request`)}
      />
    </>
  );
};

export default LandingPage;
