import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import { db } from './firebaseConfig';
import { UserData } from 'contexts/UserContext';

export interface Contact {
  contactId?: string;
  contactName?: string;
  contactType: ContactType;
  orgName?: string;
  slug?: string;
}

export interface ContactData {
  id: string;
  contact: Contact;
}

export enum ContactType {
  INSTRUCTOR = 'Instructor',
  CLIENT = 'Client',
}

type SaveContactResponse = {
  success: boolean;
  message: string;
};

export const getAllContacts = async (
  currentUser: UserData | null,
  setContacts: (contactData: ContactData[]) => void
) => {
  if (!currentUser || !currentUser.id) return;

  const { id } = currentUser;

  console.log('contacts query ran after mount');

  const userContactsCollection = collection(db, 'users', id, 'contacts');

  const fetchContacts = async () => {
    const snapshot = await getDocs(query(userContactsCollection));
    setContacts(
      snapshot.docs.map(doc => ({
        id: doc.id,
        contact: doc.data() as Contact,
      }))
    );
  };

  fetchContacts();
};

export const getContactByContactId = async (
  contactId: string,
  currentUser: UserData | null
) => {
  if (!currentUser || !contactId) return;

  let results: string = '';

  const querySnapshot = await getDocs(
    query(
      collection(db, 'users', currentUser.id, 'contacts'),
      where('contactId', '==', contactId)
    )
  );
  querySnapshot.forEach(doc => {
    results = doc.id;
  });
  return results;
};

export const saveContact = async (
  currentUser: UserData | null,
  contact: Contact
): Promise<SaveContactResponse> => {
  if (!currentUser) return { success: false, message: 'No current user.' };

  const { contactId, contactName, contactType, orgName, slug } = contact;

  if (!contactId || !contactName || !contactType)
    return { success: false, message: 'Missing required contact data.' };
  if (contactType === ContactType.INSTRUCTOR && (!orgName || !slug))
    return {
      success: false,
      message: 'Missing organization name or slug for instructor.',
    };

  try {
    const contactDocId = await getContactByContactId(contactId, currentUser);

    if (contactDocId) {
      const contactRef = doc(
        db,
        'users',
        currentUser.id,
        'contacts',
        contactDocId
      );
      await deleteDoc(contactRef);
    } else {
      const contactsCollectionRef = collection(
        db,
        'users',
        currentUser.id,
        'contacts'
      );
      await setDoc(doc(contactsCollectionRef), { ...contact });
    }
    return { success: true, message: 'Contact saved successfully.' };
  } catch (error) {
    console.error('Error saving contact:', error);
    return {
      success: false,
      message: `Error saving contact: ${(error as Error).message}`,
    };
  }
};
