import {
  Archive,
  Edit,
  Info,
  SettingsBackupRestore,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Card,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import AddStudentDialog from './AddStudentDialog';
import {
  initialStudentData,
  saveStudentDocument,
  Student,
} from 'api/student.utils';
import {
  AddNewButton,
  CustomDivider,
  CustomSwitch,
  TooltipIconButton,
} from 'components/shared';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

import { StandardContainer } from 'assets/styles/shared';

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const StyledCard = styled(Card)`
  padding: 8px 32px;
`;

const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const StyledStandardContainer = styled(StandardContainer)`
  margin: 48px 96px;
  max-width: 595px;
`;

const StudentInfo = () => {
  const { currentUser } = useContext(UserContext);
  const { setUserStudents, userStudents } = useContext(DataContext);

  const navigate = useNavigate();

  const [isDirty, setIsDirty] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showStudentForm, setShowStudentForm] = useState<string | null>(null);
  const [studentDataToUpdate, setStudentDataToUpdate] =
    useState<Student>(initialStudentData);

  const filteredStudents = userStudents.filter(
    student => student.student.status === 'active'
  );

  const archivedStudents = userStudents.filter(
    student => student.student.status === 'inactive'
  );

  const updateNewStudentData = (key: string, value: string) => {
    setStudentDataToUpdate(prevData => ({
      ...prevData,
      [key]: value,
    }));
    setIsDirty(true);
  };

  const handleAddOrUpdateStudent = () => {
    if (!currentUser) return;

    let studentId = showStudentForm === 'new' ? '' : showStudentForm;

    saveStudentDocument(currentUser, studentDataToUpdate, studentId).then(
      res => {
        const { docId, success } = res;
        if (!success) return;

        if (docId) {
          const existingStudentIndex = userStudents.findIndex(
            s => s.id === docId
          );

          if (existingStudentIndex > -1) {
            const updatedStudents = [...userStudents];
            updatedStudents[existingStudentIndex] = {
              id: docId,
              student: studentDataToUpdate,
            };
            setUserStudents(updatedStudents);
          } else {
            setUserStudents([
              ...userStudents,
              {
                id: docId,
                student: { ...studentDataToUpdate, ownerId: currentUser.id },
              },
            ]);
          }
        }
      }
    );
  };

  // const handleAddOrUpdateStudent = async () => {
  //   if (!currentUser) return;
  //   try {
  //     let returnedStudentId: string | undefined = '';

  //     if (showStudentForm === 'new') {
  //       returnedStudentId = await saveStudentDocument(
  //         currentUser,
  //         studentDataToUpdate,
  //         null
  //       );
  //     } else {
  //       await saveStudentDocument(
  //         currentUser,
  //         studentDataToUpdate,
  //         showStudentForm
  //       );
  //     }

  //     const studentId =
  //       returnedStudentId ||
  //       (showStudentForm !== 'new' && showStudentForm) ||
  //       '';

  //     const existingStudentIndex = userStudents.findIndex(
  //       s => s.id === studentId
  //     );

  //     if (existingStudentIndex > -1) {
  //       const updatedStudents = [...userStudents];
  //       updatedStudents[existingStudentIndex] = {
  //         id: studentId,
  //         student: { ...studentDataToUpdate, ownerId: currentUser?.id },
  //       };
  //       setUserStudents(updatedStudents);
  //     } else {
  //       setUserStudents([
  //         ...userStudents,
  //         {
  //           id: studentId,
  //           student: { ...studentDataToUpdate, ownerId: currentUser?.id },
  //         },
  //       ]);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleArchive = async (id: string) => {
    console.log('Archive student with id: ', id);
  };

  const handleCancel = () => {
    setStudentDataToUpdate(initialStudentData);
    setShowStudentForm(null);
  };

  const handleEdit = (id: string) => {
    const studentToEdit = userStudents.find(student => student.id === id);
    if (studentToEdit) {
      setStudentDataToUpdate(studentToEdit.student);
      setShowStudentForm(id);
    }
  };

  const handleRestore = async (id: string) => {
    console.log('Restore student with id: ', id);
  };

  const handleNavigateToStudentDetails = (id: string) => {
    navigate(`/students/${id}`);
  };

  useEffect(() => {
    if (showStudentForm === 'new') {
      setStudentDataToUpdate(initialStudentData);
    }
  }, [showStudentForm]);

  return (
    <StyledStandardContainer>
      <CustomSwitch
        checked={showArchived}
        id="show-archived-students"
        mainLabel="Show archived students"
        onChange={() => setShowArchived(prev => !prev)}
      />
      <StyledList>
        {filteredStudents.length ? (
          filteredStudents.map(({ student, id }) => {
            return (
              <StyledCard key={id} variant="outlined">
                <ListItem
                  secondaryAction={
                    <ActionContainer>
                      <TooltipIconButton
                        action={() => handleNavigateToStudentDetails(id)}
                        icon={<Info />}
                        tooltipText="Student details"
                      />
                      <CustomDivider orientation="vertical" />
                      <TooltipIconButton
                        action={() => handleEdit(id)}
                        icon={<Edit />}
                        tooltipText="Edit Student Info"
                      />
                      <TooltipIconButton
                        action={() => handleArchive(id)}
                        icon={<Archive />}
                        tooltipText="Archive Student"
                      />
                    </ActionContainer>
                  }
                >
                  <ListItemText
                    primary={student.firstName}
                    secondary={`age: ${student.age}`}
                  />
                </ListItem>
              </StyledCard>
            );
          })
        ) : (
          <Alert severity="info" variant="outlined">
            <AlertTitle>You do not have any active students.</AlertTitle>
            Let's start by adding information about your student or you can
            restore a previously archived student. The ability to merge existing
            students is coming soon!
          </Alert>
        )}
      </StyledList>
      {showArchived && (
        <StyledList>
          <CustomDivider text={'Archived Students'} />
          {archivedStudents.length ? (
            archivedStudents.map(({ student, id }) => (
              <StyledCard key={id} variant="outlined">
                <ListItem
                  secondaryAction={
                    <ActionContainer>
                      <TooltipIconButton
                        action={() => handleRestore(id)}
                        icon={<SettingsBackupRestore />}
                        tooltipText="Restore Student"
                      />
                    </ActionContainer>
                  }
                >
                  <ListItemText
                    primary={student.firstName}
                    secondary={`age: ${student.age}`}
                  />
                </ListItem>
              </StyledCard>
            ))
          ) : (
            <Alert severity="info" variant="outlined">
              <AlertTitle>You do not have any archived students.</AlertTitle>
              Once you archive a student, you can restore them here.
            </Alert>
          )}
        </StyledList>
      )}
      <AddNewButton onClick={() => setShowStudentForm('new')} />
      <AddStudentDialog
        cancel={handleCancel}
        isDirty={isDirty}
        onClose={handleCancel}
        open={!!showStudentForm}
        save={handleAddOrUpdateStudent}
        student={studentDataToUpdate}
        updateStudent={updateNewStudentData}
      />
    </StyledStandardContainer>
  );
};

export default StudentInfo;
