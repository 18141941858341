import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import styled from 'styled-components';

import { BillingType, Student } from 'api/student.utils';
import { InputFields } from 'components/shared';
import { ActivityData } from 'contexts/DataContext';

interface LessonDefaultsProps {
  activities: ActivityData[];
  dropDownChange: (event: SelectChangeEvent) => void;
  inputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  student: Student;
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const LessonDefaults: FC<LessonDefaultsProps> = ({
  activities,
  dropDownChange,
  inputChange,
  student,
}) => {
  const { DropDown, TextInput } = InputFields;

  const activityOptions = activities
    .filter(activity => activity.activity.active)
    .map(activity => ({
      name: activity.activity.activity,
      value: activity.id,
    }));

  const EndAdornment = () => {
    switch (student.defaultBillingType) {
      case BillingType.HOURLY:
        return <Typography>per hour</Typography>;
      case BillingType.LESSON:
        return <Typography>per lesson</Typography>;
      case BillingType.MONTHLY:
        return <Typography>per month</Typography>;
      default:
        return null;
    }
  };

  const billingTypeOptions = [
    {
      label: 'No billing plan.',
      value: BillingType.NONE,
    },
    { label: 'Pays an hourly rate', value: BillingType.HOURLY },
    {
      label: 'Pays monthly for a set number of lessons',
      value: BillingType.MONTHLY,
    },
    { label: 'Pays an specific amount per lesson', value: BillingType.LESSON },
  ];

  const defaultLessonLengthOptions = [
    { name: '30 minutes', value: '30' },
    { name: '45 minutes', value: '45' },
    { name: '60 minutes', value: '60' },
  ];

  return (
    <>
      <InputContainer>
        <DropDown
          id="defaultActivity"
          label="Default Activity"
          options={activityOptions}
          onChange={dropDownChange}
          placeholder="Select an activity"
          value={student.defaultActivity || ''}
        />
        <DropDown
          label="Default Lesson Length"
          id="defaultLessonLength"
          options={defaultLessonLengthOptions}
          onChange={dropDownChange}
          value={student.defaultLessonLength}
        />
      </InputContainer>
      <FormControl component="fieldset">
        <FormLabel component="legend">Default Billing Type</FormLabel>
        <RadioGroup
          aria-label="defaultBillingType"
          name="defaultBillingType"
          value={student.defaultBillingType}
          onChange={inputChange}
        >
          {billingTypeOptions.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio id="defaultBillingType" />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <InputContainer>
        {student.defaultBillingType !== BillingType.NONE && (
          <TextInput
            fullWidth
            id="defaultLessonRate"
            label="Default Lesson Rate"
            onChange={inputChange}
            value={student.defaultLessonRate}
            adornmentProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {<EndAdornment />}
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">$ </InputAdornment>
              ),
            }}
          />
        )}
      </InputContainer>
    </>
  );
};

export default LessonDefaults;
