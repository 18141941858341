import { CancelOutlined, Chat, Preview } from '@mui/icons-material';
import { Card, CardActions, CardHeader, Chip, Divider } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import DataContext from 'contexts/DataContext';
import { TooltipIconButton } from 'components/shared';
import ReviewRequest from './ReviewRequest';
import { RequestData } from 'api/lesson.utils';

interface RequestsProps {
  toggleCancelDialog: (id: string) => void;
}

const LeftActions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
`;

const RightActions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const RequestsPanel = styled(Card)`
  flex-grow: 1;
  flex-basis: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  padding-top: 16px;
  width: 100%;
  height: 100%;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  margin: 0 16px 16px;
  width: 375px;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: flex-end;
`;

const StyledDivider = styled(Divider)`
  margin-left: 8px;
`;

const Requests: FC<RequestsProps> = ({ toggleCancelDialog }) => {
  const { outgoingRequests } = useContext(DataContext);

  const navigate = useNavigate();

  const [requestToReview, setRequestToReview] = useState<RequestData | null>(
    null
  );

  const handleGoBack = () => {
    setRequestToReview(null);
  };

  const handleReviewRequest = (id: string) => {
    setRequestToReview(
      outgoingRequests.find(request => request.id === id) || null
    );
  };

  return (
    <RequestsPanel>
      {!requestToReview ? (
        outgoingRequests.map(req => {
          const { request, id } = req;
          return (
            <StyledCard key={id} variant="outlined">
              <CardHeader
                title={`${request.activity.name}`}
                subheader={`with ${request.instructor.name} at ${request.instructor.orgName}`}
              />
              <StyledCardActions disableSpacing>
                <LeftActions>
                  <Chip label={request.requestStatus} color="primary" />
                  <StyledDivider orientation="vertical" flexItem />
                  <TooltipIconButton
                    tooltipText="Review Request"
                    icon={<Preview />}
                    action={() => handleReviewRequest(id)}
                  />
                  <TooltipIconButton
                    tooltipText="Chat with Instructor"
                    icon={<Chat />}
                    action={() => navigate(`/messages?m=${id}`)}
                  />
                </LeftActions>
                <RightActions>
                  <TooltipIconButton
                    tooltipText="Cancel Request"
                    icon={<CancelOutlined />}
                    action={() => toggleCancelDialog(id)}
                  />
                </RightActions>
              </StyledCardActions>
            </StyledCard>
          );
        })
      ) : (
        <ReviewRequest
          handleAcceptRequest={() => {}}
          handleCancelRequest={() => toggleCancelDialog(requestToReview.id)}
          handleDeclineRequest={() => {}}
          handleGoBack={handleGoBack}
          requestToReview={requestToReview}
        />
      )}
    </RequestsPanel>
  );
};

export default Requests;
