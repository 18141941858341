import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { auth, db } from './firebaseConfig';
import { UserData } from 'contexts/UserContext';

export const initialFamilyData: Family = {
  address: '',
  email: '',
  familyName: '',
  firstName: '',
  lastName: '',
  notes: '',
  ownerId: '',
  phone: '',
  placeId: '',
};

export interface Family {
  address: string;
  email: string;
  familyName: string;
  firstName: string;
  lastName: string;
  notes: string;
  ownerId: string;
  phone: string;
  placeId: string;
}

export interface FamilyData {
  id: string;
  family: Family;
}

export const getUserFamilies = async (
  setFamilies: (familyData: FamilyData[]) => void
) => {
  const userId = auth.currentUser?.uid;

  if (!userId) return;

  console.log('family query ran after mount');

  const studentsSnapshot = await getDocs(
    query(collection(db, 'families'), where('ownerId', '==', userId))
  );

  const families: FamilyData[] = [];

  studentsSnapshot.forEach(doc => {
    const familyData = doc.data() as Family;
    families.push({
      id: doc.id,
      family: familyData,
    });
  });

  setFamilies(families);
};

export const saveFamilyDocument = async (
  currentUser: UserData | null,
  familyData: Family,
  familyId?: string
): Promise<{ success: boolean; docId?: string }> => {
  const authId = auth.currentUser?.uid;
  if (!currentUser || !authId) return { success: false };

  if (authId !== currentUser.id) return { success: false };

  try {
    let docId;
    if (familyId) {
      await setDoc(
        doc(db, 'families', familyId),
        {
          ...familyData,
          ownerId: familyData.ownerId || authId,
        },
        {
          merge: true,
        }
      );
      docId = familyId;
    } else {
      const newFamilyId = await addDoc(collection(db, 'families'), {
        ...familyData,
        ownerId: familyData.ownerId || authId,
      });
      docId = newFamilyId.id;
    }
    return { success: true, docId };
  } catch (error) {
    return { success: false };
  }
};
