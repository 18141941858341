import { useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MenuText } from 'components/Header/styles';
import styled from 'styled-components';
import CustomDivider from './CustomDivider';

interface MenuItems {
  icon?: JSX.Element;
  onClick: () => void;
  text: string;
}

interface ITooltip {
  title: string;
  ariaControls: string;
}

interface FloatMenuProps {
  divider?: boolean;
  marginValue?: number;
  menuIcon: JSX.Element;
  postDividerItems?: MenuItems[];
  preDividerItems: MenuItems[];
  tooltip: ITooltip;
}

const StyledIconButton = styled(IconButton)<{ margin: number }>`
  height: 40px;
  margin-top: ${p => p.margin}px;
  width: 40px;
  &:hover {
    border-radius: 100%;
  }
`;

const FloatMenu = ({
  divider,
  marginValue = 16,
  menuIcon,
  postDividerItems,
  preDividerItems,
  tooltip,
}: FloatMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      open ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
    },
    [open]
  );

  return (
    <>
      <Tooltip title={tooltip.title}>
        <StyledIconButton
          onClick={handleClick}
          margin={marginValue}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? tooltip.ariaControls : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {menuIcon}
        </StyledIconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClick}
        onClick={handleClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              bgcolor: 'background.default',
              backgroundImage:
                'linear-gradient(rgba(255,255,255,0.05),rgba(255,255,255,0.05))',
              content: '""',
              display: 'block',
              height: 10,
              position: 'absolute',
              right: 14,
              top: 0,
              transform: 'translateY(-50%) rotate(45deg)',
              width: 10,
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {preDividerItems &&
          preDividerItems.map(({ icon, onClick, text }) => {
            return (
              <MenuItem key={text} onClick={onClick}>
                {icon}
                <MenuText>{text}</MenuText>
              </MenuItem>
            );
          })}
        {divider && <CustomDivider />}
        {postDividerItems &&
          postDividerItems.map(({ icon, onClick, text }) => {
            return (
              <MenuItem key={text} onClick={onClick}>
                {icon}
                <MenuText>{text}</MenuText>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default FloatMenu;
