import { Check, Close, Delete } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';
import { TooltipIconButton } from 'components/shared';
import { Rate } from 'contexts/DataContext';
import { FC, useState } from 'react';
import styled from 'styled-components';

interface RateCardProps {
  handleDelete: (id: string) => void;
  rate: Rate;
}

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  gap: 8px;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:last-child {
    padding-bottom: 16px;
  }
`;

const RateCard: FC<RateCardProps> = ({ handleDelete, rate }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleConfirmDelete = () => {
    setConfirmDelete(prev => !prev);
  };

  const handleDeleteCompletion = () => {
    handleDelete(id);
    setConfirmDelete(prev => !prev);
  };

  const { adjustedCost, cost, duration, id } = rate;
  return confirmDelete ? (
    <Card variant="outlined">
      <StyledCardContent>
        <DetailsContainer>
          <Typography variant="h5">Confirm Delete?</Typography>
        </DetailsContainer>
        <ActionContainer>
          <TooltipIconButton
            action={handleDeleteCompletion}
            icon={<Check />}
            tooltipText="Delete"
          />
          <TooltipIconButton
            action={handleConfirmDelete}
            icon={<Close />}
            tooltipText="Cancel"
          />
        </ActionContainer>
      </StyledCardContent>
    </Card>
  ) : (
    <Card variant="outlined">
      <StyledCardContent>
        <DetailsContainer>
          <Typography variant="h5">
            {duration} minute lesson for ${adjustedCost}
          </Typography>
          <Typography variant="body2">
            You will receive <strong>${cost}</strong> and Instrekt will receive
            $<strong>{adjustedCost - cost}</strong>.
          </Typography>
        </DetailsContainer>
        <ActionContainer>
          <TooltipIconButton
            action={handleConfirmDelete}
            icon={<Delete />}
            tooltipText="Delete"
          />
        </ActionContainer>
      </StyledCardContent>
    </Card>
  );
};

export default RateCard;
