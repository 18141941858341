import {
  DeleteOutline,
  MailOutline,
  MoreVert,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  Chip,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { FC, useContext, useState } from 'react';
import styled from 'styled-components';

import { FamilyData } from 'api/family.utils';
import {
  StudentData,
  StudentStatus,
  calculateAge,
  deleteStudent,
} from 'api/student.utils';
import UserContext from 'contexts/UserContext';

interface StudentTableBodyProps {
  emptyRows: number;
  families: FamilyData[];
  students: StudentData[];
  handleCopyEmail: (email: string) => void;
  handleStatusChange: (option: StudentStatus, id: string) => void;
  handleViewFamily: (id: string) => void;
  handleViewStudent: (id: string) => void;
}

const EmailCell = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const MenuText = styled(Typography)`
  margin-left: 8px;
`;

const StudentCell = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const StyledChip = styled(Chip)`
  margin-left: 10px;
  width: fit-content;
`;

const StudentTableBody: FC<StudentTableBodyProps> = ({
  emptyRows,
  families,
  students,
  handleCopyEmail,
  handleViewFamily,
  handleViewStudent,
  handleStatusChange,
}) => {
  const { currentUser } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(
    null
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedStudentId(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedStudentId(id);
  };

  const handleMenuOptionClick = (option: string) => () => {
    handleMenuClose();
    if (!selectedStudentId) return;
    switch (option) {
      case 'View':
        handleViewStudent(selectedStudentId);
        break;
      case 'Delete':
        handleDelete(selectedStudentId);
        break;
      case StudentStatus.ACTIVE:
      case StudentStatus.LEAD:
      case StudentStatus.INACTIVE:
        handleStatusChange(option, selectedStudentId);
        break;
      default:
        break;
    }
  };

  const handleDelete = (id: string) => {
    deleteStudent(currentUser, id);
  };

  const generateMenuOptions = () => [
    {
      name: 'View',
      icon: <VisibilityOutlined />,
      isSelectable: true,
    },
    { item: <Divider />, isSelectable: false },
    {
      item: <Typography>Update status to:</Typography>,
      isSelectable: false,
    },
    {
      item: <Chip label="Active" color="primary" />,
      isSelectable: true,
      name: 'active',
    },
    {
      item: <Chip label="Lead" color="secondary" />,
      isSelectable: true,
      name: 'lead',
    },
    {
      item: <Chip label="Inactive" color="default" />,
      isSelectable: true,
      name: 'inactive',
    },
    { item: <Divider />, isSelectable: false },
    { name: 'Delete', icon: <DeleteOutline />, isSelectable: true },
  ];

  const studentCell = (
    status: 'active' | 'inactive' | 'lead',
    firstName: string,
    lastName: string,
    id: string
  ) => {
    return (
      <StudentCell>
        <Link
          component="button"
          onClick={() => handleViewStudent(id)}
        >{`${lastName}, ${firstName}`}</Link>
        <StyledChip
          label={status.charAt(0).toUpperCase() + status.slice(1)}
          color={
            status === 'active'
              ? 'primary'
              : status === 'inactive'
              ? 'default'
              : 'secondary'
          }
          variant="outlined"
        />
      </StudentCell>
    );
  };

  const familyCell = (
    familyId: string,
    families: FamilyData[]
  ): string | undefined => {
    const family = families.find(family => family.id === familyId);
    return family?.family.familyName || '-';
  };

  const studentAge = (dob: Dayjs | null) => {
    return calculateAge(dob);
  };

  return (
    <>
      <TableBody>
        {students.map(student => (
          <TableRow key={student.id}>
            <TableCell>
              {studentCell(
                student.student.status,
                student.student.firstName,
                student.student.lastName,
                student.id
              )}
            </TableCell>
            <TableCell>
              {student.student.email ? (
                <EmailCell
                  onClick={() => handleCopyEmail(student.student.email)}
                >
                  <MailOutline fontSize="small" />
                  {student.student.email}
                </EmailCell>
              ) : (
                <Typography>-</Typography>
              )}
            </TableCell>
            <TableCell>
              {familyCell(student.student.familyId, families)}
            </TableCell>
            <TableCell>
              {studentAge(student.student.dob) || <Typography>-</Typography>}
            </TableCell>
            <TableCell>
              <Tooltip title="Actions">
                <IconButton
                  aria-controls="menu"
                  aria-haspopup="true"
                  onClick={event => handleMenuOpen(event, student.id)}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {generateMenuOptions().map((option, index) => (
                  <MenuItem
                    key={index}
                    onClick={
                      option.isSelectable && option.name
                        ? handleMenuOptionClick(option.name)
                        : undefined
                    }
                    disabled={!option.isSelectable}
                  >
                    {option.icon}
                    {!option.item && <MenuText>{option.name}</MenuText>}
                    {option.item}
                  </MenuItem>
                ))}
              </Menu>
            </TableCell>
          </TableRow>
        ))}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: 53 * emptyRows,
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

export default StudentTableBody;
