import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';

interface LabeledCheckboxProps {
  checked: boolean;
  disabled?: boolean;
  label?: string;
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end';
  mainLabel?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const LabeledCheckbox: FC<LabeledCheckboxProps> = ({
  checked = false,
  disabled = false,
  label,
  labelPlacement = 'end',
  mainLabel,
  onChange,
}) => {
  return (
    <FormControl component="fieldset" disabled={disabled}>
      {mainLabel && <FormLabel component="legend">{mainLabel}</FormLabel>}
      <FormGroup aria-label="position">
        <FormControlLabel
          value={checked}
          control={<Checkbox checked={checked} onChange={onChange} />}
          label={label}
          labelPlacement={labelPlacement}
        />
      </FormGroup>
    </FormControl>
  );
};

export default LabeledCheckbox;
