import { IconButton, Tooltip } from '@mui/material';
import styled from 'styled-components';

interface TooltipIconButtonProps {
  action: () => void;
  disabled?: boolean;
  icon: JSX.Element;
  size?: 'small' | 'medium' | 'large';
  tooltipText: string;
}

const StyledIconButton = styled(IconButton)`
  height: 40px;
  width: 40px;
  &:hover {
    border-radius: 100%;
  }
`;

const TooltipIconButton = ({
  action,
  disabled = false,
  icon,
  size = 'small',
  tooltipText,
}: TooltipIconButtonProps) => {
  return disabled ? (
    <StyledIconButton disabled={disabled} size={size}>
      {icon}
    </StyledIconButton>
  ) : (
    <Tooltip title={tooltipText}>
      <StyledIconButton onClick={action} size={size}>
        {icon}
      </StyledIconButton>
    </Tooltip>
  );
};

export default TooltipIconButton;
