import { CardActions } from '@mui/material';

import { Frequency, RequestData } from 'api/lesson.utils';
import { CustomButton } from 'components/shared';
import { initialRequestData } from 'contexts/DataContext';
import LessonList from './LessonList';
import LocationInfo from './LocationInfo';
import GeneralInfo from './GeneralInfo';
import useTracks from 'hooks/useTracks';

import {
  CardDetails,
  DisplayContainer,
  MainDisplayContainer,
  StyledCard,
  StyledCardHeader,
} from './styles';

interface ProposalInfoProps {
  handleAccept: () => void;
  handleDecline: () => void;
  requestData: RequestData;
}

const Proposal = ({
  handleAccept,
  handleDecline,
  requestData,
}: ProposalInfoProps) => {
  const { request } = requestData;

  const { lessons, generateTotalLessonCost } = useTracks(
    requestData || initialRequestData
  );

  return (
    <MainDisplayContainer>
      <DisplayContainer>
        <StyledCard variant="outlined">
          <StyledCardHeader
            title={`${
              Frequency[request.frequency as unknown as keyof typeof Frequency]
            } ${request.lessonType} ${request.activity.name} Lessons`}
          />
          <CardDetails>
            <GeneralInfo requestData={requestData} />
            <LessonList lessonData={lessons} />
            <LocationInfo requestData={requestData} />
          </CardDetails>
        </StyledCard>
        <StyledCard variant="outlined">
          <StyledCardHeader
            title={`Total Lesson Cost: $${generateTotalLessonCost}`}
            subheader={`${lessons.length} lessons at $${request.lessonRate} per lesson`}
          />
          <CardActions>
            <CustomButton
              onClick={handleAccept}
              buttonText="Accept Proposal"
              color="success"
              variant="outlined"
            />
            <CustomButton
              onClick={handleDecline}
              buttonText="Decline Proposal"
              color="error"
              variant="outlined"
            />
          </CardActions>
        </StyledCard>
      </DisplayContainer>
    </MainDisplayContainer>
  );
};

export default Proposal;
