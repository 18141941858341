import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { FC } from 'react';
import styled from 'styled-components';

import { Student } from 'api/student.utils';

interface TableHeadCell {
  id: keyof Student;
  label: string;
  disableSort?: boolean;
  width: string;
}

interface StudentTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Student
  ) => void;
  order: Order;
  orderBy: string;
}

type Order = 'asc' | 'desc';

const StyledTableCell = styled(TableCell)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StudentTableHead: FC<StudentTableHeadProps> = ({
  onRequestSort,
  order,
  orderBy,
}) => {
  const createSortHandler =
    (property: keyof Student) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const tableHeadCells: TableHeadCell[] = [
    {
      id: 'lastName',
      label: 'Student Name',
      width: '30%',
    },
    {
      id: 'email',
      label: 'Student Contact',
      width: '25%',
    },
    {
      id: 'familyId',
      label: 'Family',
      width: '25%',
    },
    {
      id: 'age',
      label: 'Age',
      disableSort: true,
      width: '15%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {tableHeadCells.map(cell => (
          <StyledTableCell
            key={cell.id}
            sortDirection={orderBy === cell.id ? order : false}
            style={{ width: cell.width }}
          >
            {cell.disableSort ? (
              <span>{cell.label}</span>
            ) : (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                {cell.label}
                {orderBy === cell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </StyledTableCell>
        ))}
        <TableCell style={{ width: '5%' }}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default StudentTableHead;
