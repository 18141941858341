import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';

import { CustomButton } from 'components/shared';

interface ArchiveActivityDialogProps {
  onClose: () => void;
  open: boolean;
}

const ArchiveActivityDialog: FC<ArchiveActivityDialogProps> = ({
  onClose,
  open,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="archive-activity-dialog-title"
      aria-describedby="archive-activity-dialog-description"
    >
      <DialogTitle id="archive-activity-dialog-title">
        Archive Activity Disabled
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="archive-activity-dialog-description">
          You need to have at least one active activity. Please activate another
          activity before archiving this one.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={onClose}
          buttonText="Understood"
          variant="text"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveActivityDialog;
