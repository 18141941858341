import { Link, Typography } from '@mui/material';
import { CustomButton, CustomDivider } from 'components/shared';
import { FC } from 'react';
import styled from 'styled-components';

interface TermsProps {
  acknowledgement?: () => void;
}

const AcknowledgementContainer = styled.div`
  margin: 24px auto;
  width: fit-content;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin: 24px 0;
`;

const MainContainer = styled.div`
  padding: 0 48px;
  margin: 24px auto;
  max-width: 1200px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EffectiveDate = styled(Typography)`
  font-size: 16px;
  margin: 0 auto 24px;
`;

const PageHeader = styled(Typography)`
  font-size: 32px;
  font-weight: 500;
  margin: 24px auto 0;
  color: #58abff;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SectionHeading = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IntroductionContainer = styled.div`
  margin: 24px 0;
`;

const ClosingContainer = styled(Typography)`
  margin: 48px 0;
  font-size: 24px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 32px;
`;

const Terms: FC<TermsProps> = ({ acknowledgement }) => {
  const sectionContent = [
    {
      title: 'Use of Services',
      content: [
        {
          li: 'a',
          text: `You may use our Services only if you are 18 years or older and capable
          of forming a binding contract with Instrekt.`,
        },
        {
          li: 'b',
          text: `You agree to provide accurate, complete, and up-to-date information in 
        your account and to update it promptly if there are any changes.`,
        },
        {
          li: 'c',
          text: `You are responsible for maintaining the security of your account and 
        password. Instrekt cannot and will not be liable for any loss or damage 
        from your failure to comply with this security obligation.`,
        },
      ],
    },
    {
      title: 'Fees and Payments',
      content: [
        {
          li: 'a',
          text: `When an instructor teaches a lesson and makes money from that lesson, a percentage of that lesson cost is the fee that goes to the usage of the Instrekt platform.`,
        },
        {
          li: 'b',
          text: `Instrekt charges a fee for each lesson booked and paid for through our platform. The fee is based on the price of the lesson and will be clearly displayed to you before you finalize the transaction.`,
        },
        {
          li: 'c',
          text: `All fees are non-refundable.`,
        },
      ],
    },
    {
      title: 'Prohibited Conduct',
      content: [
        {
          li: 'a',
          text: `You agree not to use our Services for any illegal or unauthorized purpose.`,
        },
        {
          li: 'b',
          text: `You agree not to engage in any activity that interferes with or disrupts our Services.`,
        },
        {
          li: 'c',
          text: `You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of our Services.`,
        },
      ],
    },
    {
      title: 'Intellectual Property',
      content: [
        {
          li: 'a',
          text: `All content included on our Services, such as text, graphics, logos, images, videos, and software, is the property of Instrekt or its licensors and is protected by copyright, trademark, and other laws.`,
        },
        {
          li: 'b',
          text: `You may not use any of our trademarks or logos without our prior written consent.`,
        },
      ],
    },
    {
      title: 'Disclaimers and Limitation of Liability',
      content: [
        {
          li: 'a',
          text: `Our Services are provided "as is" and without warranty of any kind.`,
        },
        {
          li: 'b',
          text: `Instrekt shall not be liable for any damages whatsoever arising out of or in connection with the use of our Services.`,
        },
        {
          li: 'c',
          text: `Instrekt is not responsible for the conduct of any user of our Services.`,
        },
      ],
    },
    {
      title: 'Termination',
      content: [
        {
          li: 'a',
          text: `Instrekt reserves the right to terminate or suspend your access to our Services at any time, for any reason, without notice.`,
        },
        {
          li: 'b',
          text: `You may terminate your account at any time by contacting us at support@instrekt.com.`,
        },
      ],
    },
    {
      title: `Indemnification`,
      content: [
        {
          li: 'a',
          text: `You agree to indemnify, defend, and hold harmless Instrekt, its affiliates, and their respective officers, directors, employees, agents, and licensors from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your use of our Services.`,
        },
      ],
    },
    {
      title: 'Governing Law and Dispute Resolution',
      content: [
        {
          li: 'a',
          text: `These Terms shall be governed by and construed in accordance with the laws of the State of California.`,
        },
        {
          li: 'b',
          text: `Any dispute arising out of or relating to these Terms or our Services shall be resolved in the state or federal courts located in Los Angeles, California.`,
        },
      ],
    },
    {
      title: 'Change to Terms',
      content: [
        {
          li: 'a',
          text: `Instrekt may modify these Terms at any time, without notice.`,
        },
        {
          li: 'b',
          text: `By continuing to use our Services after we post any changes to these Terms, you agree to be bound by the modified Terms.`,
        },
      ],
    },
    {
      title: 'Entire Agreement',
      content: [
        {
          li: 'a',
          text: `These Terms constitute the entire agreement between you and Instrekt and govern your use of our Services.`,
        },
        {
          li: 'b',
          text: `If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.`,
        },
      ],
    },
  ];
  return (
    <MainContainer>
      <HeaderContainer>
        <PageHeader>Terms and Conditions</PageHeader>
        <EffectiveDate>Effective date: January 1, 2024</EffectiveDate>
      </HeaderContainer>
      <CustomDivider />
      <IntroductionContainer>
        <Typography variant="body1">
          Welcome to Instrekt. These Terms of Use ("Terms") govern your access
          to and use of our platform and services (collectively, the
          "Services"). By accessing or using our Services, you agree to be bound
          by these Terms.
        </Typography>
      </IntroductionContainer>
      <CustomDivider />
      <ContentContainer>
        {sectionContent.map(section => {
          const { title, content } = section;
          return (
            <SectionContainer key={title}>
              <SectionHeading>{title}</SectionHeading>
              <SectionBody>
                {content.map((block, index) => (
                  <ListContainer key={index}>
                    <Typography variant="body1">{block.li})</Typography>
                    <Typography variant="body1">{block.text}</Typography>
                  </ListContainer>
                ))}
              </SectionBody>
            </SectionContainer>
          );
        })}
      </ContentContainer>
      <CustomDivider />
      <ClosingContainer>
        At Instrekt, we are committed to providing the best service we can to
        our users. If you have any questions or concerns about our Terms and
        Conditions, please contact us at{' '}
        <Link
          href="mailto:support@instrekt.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@instrekt.com
        </Link>
        .
      </ClosingContainer>
      <AcknowledgementContainer>
        {acknowledgement && (
          <CustomButton
            buttonText="Got it"
            onClick={acknowledgement}
            variant="contained"
          />
        )}
      </AcknowledgementContainer>
    </MainContainer>
  );
};

export default Terms;
