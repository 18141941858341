import { Box } from '@mui/material';
import SettingsView from '../SettingsView';

import { StyledStandardContainer } from './styles';

const Preferences = () => {
  return (
    <SettingsView
      rightContent={
        <StyledStandardContainer>
          <Box>Notifications</Box>
          <Box>Referrals</Box>
          <Box>Request for Personal Data</Box>
        </StyledStandardContainer>
      }
    />
  );
};

export default Preferences;
