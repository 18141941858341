import { Instructor, Organization } from 'contexts/SignupContext';

export const validateAcknowledgePolicies = (data: Instructor) => {
  const { acknowledgement } = data;
  return !!(
    acknowledgement &&
    acknowledgement.privacy &&
    acknowledgement.terms
  );
};

export const validateLandingPageDetails = (data: Organization) => {
  const { bio, buttonText, heroImage, heroMainText, heroSubText, philosophy } =
    data;
  return !!(
    bio &&
    buttonText &&
    heroImage &&
    heroMainText &&
    heroSubText &&
    philosophy
  );
};

export const validatePersonalInfo = (data: Instructor) => {
  const { firstName, lastName, email } = data;
  return !!(firstName && lastName && email);
};

export const validateTravelOptions = (data: Organization) => {
  const { travelOptions } = data;
  return !!(
    travelOptions &&
    (!!travelOptions.toInstructor ||
      !!travelOptions.toStudent ||
      !!travelOptions.toThirdParty)
  );
};
