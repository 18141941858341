import { Check, Close } from '@mui/icons-material';
import { Alert, InputAdornment, Typography } from '@mui/material';
import { getSlugByName } from 'api/organization.utils';
import { StyledCircularProgress } from 'components/Settings/Organization/styles';
import { InputFields } from 'components/shared';
import SignupContext from 'contexts/SignupContext';
import { useContext, useEffect, useState } from 'react';

const OrganizationInfo = () => {
  const { applicationData, updateOrganizationData } = useContext(SignupContext);
  const { TextInput } = InputFields;

  const [isSlugAvailable, setIsSlugAvailable] = useState<
    'available' | 'checking' | 'empty' | 'not available'
  >('empty');
  const [originalSlug, setOriginalSlug] = useState(
    applicationData.application.organization.slug
  );
  const [slugError, setSlugError] = useState('');
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  const EndAdornment = () => {
    switch (isSlugAvailable) {
      case 'available':
        return <Check color="success" />;
      case 'checking':
        return <StyledCircularProgress />;
      case 'empty':
        return <></>;
      case 'not available':
        return <Close color="error" />;
    }
  };

  const checkSlugAvailability = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value } = event.target;
    updateOrganizationData(id, value);
    setIsSlugAvailable('checking');
    setSlugError('');

    const newSlug = value
      .replace(/[^a-zA-Z0-9\-_\s]/g, '') // This line currently removes characters that are not letters, numbers, or spaces.
      .replace(/\s+/g, '-') // Converts spaces to dashes.
      .replace(/^-+|-+$/g, '') // Removes leading and trailing dashes.
      .trim()
      .toLowerCase();

    const existing = newSlug === originalSlug;

    if (timerId) {
      clearTimeout(timerId);
    }

    if (value.length === 0) {
      setIsSlugAvailable('empty');
      return;
    }

    if (newSlug) {
      const newTimerId = setTimeout(() => {
        if (existing) {
          updateOrganizationData('slug', newSlug);
          setIsSlugAvailable('available');
          return;
        }

        if (newSlug.length >= 5) {
          getSlugByName(newSlug).then(res => {
            updateOrganizationData('slug', newSlug);
            setIsSlugAvailable(res.slug ? 'not available' : 'available');
            setSlugError(res.slug ? 'This name is already taken' : '');
          });
        } else {
          setIsSlugAvailable('not available');
          setSlugError('Your name must be at least 5 characters');
        }
      }, 1000);

      setTimerId(newTimerId);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    updateOrganizationData(id, value);
  };

  useEffect(() => {
    if (applicationData.application.organization.slug) {
      const stillAvailable = checkSlugAvailability({
        target: {
          id: 'slug',
          value: applicationData.application.organization.slug,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      if (stillAvailable === undefined) return;

      if (stillAvailable) {
        setIsSlugAvailable('available');
        setOriginalSlug(applicationData.application.organization.slug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h3">Create Organization Name</Typography>
      <Alert severity="info" variant="outlined">
        This is the name of your organization. It needs to be a unique name
        since it will be used to create your unique URL for your landing page
        and request intake form.
      </Alert>
      <TextInput
        id="orgName"
        label="Organization Name"
        onChange={handleInputChange}
        value={applicationData.application.organization.orgName}
      />
      <TextInput
        id="slug"
        adornmentProps={{
          endAdornment: (
            <InputAdornment position="end">{<EndAdornment />}</InputAdornment>
          ),
        }}
        label="URL Path"
        onChange={checkSlugAvailability}
        value={applicationData.application.organization.slug}
        helperText={slugError}
        error={slugError}
      />
    </>
  );
};

export default OrganizationInfo;
