import { createContext, Dispatch, FC, useState } from 'react';

interface StudentInfo {
  age: string;
  id: string;
  name: string;
}

export enum RoleType {
  ADMIN = 'admin',
  INSTRUCTOR = 'instructor',
  APPLICANT = 'applicant',
  STUDENT = 'student',
}

interface User {
  acknowledgement: {
    terms: boolean;
    privacy: boolean;
  };
  address: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  googleAuthenticated: boolean;
  imagePath: string;
  instructor: boolean;
  lastName: string;
  orgId: string;
  phone: string;
  placeId: string;
  role: RoleType;
  studentInfo: StudentInfo[];
}

export interface UserData {
  id: string;
  user: User;
}

interface IUserContext {
  avatarUrl: string | null;
  clearError: () => void;
  currentUser: UserData | null;
  error: string | null;
  isDarkMode?: boolean;
  setAvatarUrl: Dispatch<any>;
  setError: Dispatch<any>;
  setCurrentUser: Dispatch<any>;
  toggleDarkMode: () => void;
}

const defaultUser: UserData = {
  id: '',
  user: {
    acknowledgement: {
      terms: false,
      privacy: false,
    },
    address: '',
    email: '',
    emailVerified: false,
    firstName: '',
    googleAuthenticated: false,
    imagePath: '',
    instructor: false,
    lastName: '',
    orgId: '',
    phone: '',
    placeId: '',
    role: RoleType.STUDENT,
    studentInfo: [],
  },
};

const UserContext = createContext<IUserContext>({
  avatarUrl: '',
  clearError: () => {},
  currentUser: null,
  error: null,
  isDarkMode: false,
  setAvatarUrl: () => {},
  setError: () => {},
  setCurrentUser: () => {},
  toggleDarkMode: () => {},
});

export default UserContext;

export const UserProvider: FC = ({ children }) => {
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [currentUser, setCurrentUser] = useState<UserData | null>(
    localStorage.getItem('soft-nav-marker') === '0' ? defaultUser : null
  );
  const [error, setError] = useState<string | null>(null);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    localStorage.getItem('darkMode') === 'false' ? false : true
  );

  const clearError = () => {
    setError(null);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(currentMode => !currentMode);
    localStorage.setItem('darkMode', JSON.stringify(!isDarkMode));
  };

  const value = {
    avatarUrl: avatarUrl,
    clearError: clearError,
    currentUser: currentUser,
    error: error,
    isDarkMode: isDarkMode,
    setAvatarUrl: setAvatarUrl,
    setError: setError,
    setCurrentUser: setCurrentUser,
    toggleDarkMode: toggleDarkMode,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
