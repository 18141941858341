import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Elements } from '@stripe/react-stripe-js';
import { onSnapshot } from 'firebase/firestore';
import { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getUserActivities } from 'api/activity.utils';
import { getAllContacts } from 'api/contact.utils';
import { auth, stripe } from 'api/firebaseConfig';
import {
  getAllLessonsForUser,
  useSubscribeToIncomingRequests,
  useSubscribeToOutgoingRequests,
} from 'api/lesson.utils';
import { getOrganizationByOrgId } from 'api/organization.utils';
import { getUserStudents } from 'api/student.utils';
import { createUserProfileDocument, getImageURL } from 'api/user.utils';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Navigation from 'components/Header/Navigation';
import AppContext from 'contexts/AppContext';
import DataContext from 'contexts/DataContext';
import UserContext, { RoleType } from 'contexts/UserContext';
import RenderRoutes from 'routes/routes';

import { GlobalStyles } from 'assets/styles/global';
import { MainContentArea } from 'assets/styles/shared';
import { getUserFamilies } from 'api/family.utils';

export const App = () => {
  const { isDarkMode } = useContext(AppContext);
  const { currentUser, setAvatarUrl, setCurrentUser } = useContext(UserContext);
  const {
    setIncomingRequests,
    setOutgoingRequests,
    setUserActivities,
    setUserContacts,
    setUserFamilies,
    setUserLessons,
    setUserOrganization,
    setUserStudents,
  } = useContext(DataContext);

  const location = useLocation();

  const path = location.pathname;

  const displayPanelMenu = useMemo(() => {
    const pathsToHidePanelMenu = [
      '/',
      '/about',
      '/confirm',
      '/instructors',
      '/instructor-signup',
      '/login',
      '/privacy',
      '/terms',
    ];
    const isOrgRequestPath = /^\/i\/[^/]+\/request$/.test(path);

    return !(
      pathsToHidePanelMenu.includes(path) ||
      (path.startsWith('/i/') && !isOrgRequestPath)
    );
  }, [path]);

  const displayHeaderAndFooter = useMemo(() => {
    const pathsToDisplayHeaderAndFooter = [
      '/',
      '/about',
      '/instructors',
      '/privacy',
      '/terms',
    ];
    const isOrgRequestPath = /^\/i\/[^/]+\/request$/.test(path);

    return (
      pathsToDisplayHeaderAndFooter.includes(path) ||
      (path.startsWith('/i/') && !isOrgRequestPath)
    );
  }, [path]);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      // TODO: set primary and secondary color pallete
      // primary: {
      //   contrastText: '#FFF',
      //   dark: '#000',
      //   light: '#fff',
      //   main: '#5badff',
      // },
      // secondary: {
      //   light: '#ff7961',
      //   main: '#f44336',
      //   dark: '#ba000d',
      //   contrastText: '#000',
      // },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? '#121212' : '#FFFFFF',
            color: isDarkMode ? '#FFFFFF' : '#121212',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? '#121212' : '#FFFFFF',
            color: isDarkMode ? '#FFFFFF' : '#121212',
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
          },
        },
      },
    },
  });

  useSubscribeToIncomingRequests(currentUser, setIncomingRequests);
  useSubscribeToOutgoingRequests(currentUser, setOutgoingRequests);

  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (!userAuth) {
        setCurrentUser(null);
        localStorage.removeItem('soft-nav-marker');
      } else {
        const userRef = await createUserProfileDocument(userAuth);
        if (userRef) {
          onSnapshot(userRef, snapShot => {
            setCurrentUser({
              id: snapShot.id,
              user: snapShot.data(),
            });
            localStorage.setItem('soft-nav-marker', '0');
          });
        }
      }
    });

    return () => {
      unsubscribeFromAuth();
    };
  }, [setCurrentUser]);

  useEffect(() => {
    if (!currentUser) return;
    const { id, user } = currentUser;

    if (!user) return;

    if (id && user.imagePath) {
      getImageURL(user.imagePath, setAvatarUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id, currentUser?.user]);

  useEffect(() => {
    if (!currentUser) return;
    const { user } = currentUser;

    if (!user) return;

    if (user.role === RoleType.INSTRUCTOR && user.orgId) {
      getUserActivities(user.orgId, setUserActivities);
      getOrganizationByOrgId(user.orgId, setUserOrganization);
    }

    getUserFamilies(setUserFamilies);
    getUserStudents(setUserStudents);
    getAllContacts(currentUser, setUserContacts);
    getAllLessonsForUser(setUserLessons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  useEffect(() => {
    if (path === '/login') return;
    localStorage.setItem('path', path);
  }, [path]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalStyles darkMode={isDarkMode} />
        <Elements stripe={stripe}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {displayHeaderAndFooter && <Header />}
            {displayPanelMenu && <Navigation />}
            <MainContentArea
              displayHeader={displayHeaderAndFooter}
              displayPanelMenu={displayPanelMenu}
            >
              <RenderRoutes />
            </MainContentArea>
            {displayHeaderAndFooter && <Footer />}
          </Box>
        </Elements>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
