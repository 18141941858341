import { Dayjs, isDayjs } from 'dayjs';
import {
  addDoc,
  collection,
  doc,
  DocumentReference,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { PlaceType } from 'components/shared/InputFields/LocationSearch';
import { UserData } from 'contexts/UserContext';
import { auth, db } from './firebaseConfig';
import { OrganizationData } from './organization.utils';
import { StudentData } from './student.utils';
import {
  convertDayjsToFirestoreTimestamp,
  convertFirestoreTimestampToDayjs,
  getServerTimestamp,
} from './user.utils';

export enum Frequency {
  ONCE = 'One Time Only',
  DAILY = 'Daily',
  SEMIWEEKLY = 'Twice Weekly',
  WEEKLY = 'Weekly',
  BIWEEKLY = 'Twice Monthly',
  MONTHLY = 'Monthly',
}

export enum LessonTypes {
  PRIVATE = 'Private',
  SEMIPRIVATE = 'Semi-Private',
  DROPIN = 'Drop-In',
}

export enum Travel {
  toInstructor = 'toInstructor',
  toStudent = 'toStudent',
  toThirdParty = 'toThirdParty',
}

export enum RequestStatus {
  ACTIVE = 'Active',
  ARCHIVED = 'Archived',
  CANCELED = 'Canceled',
  COMPLETED = 'Completed',
  DECLINED = 'Declined',
  NEW = 'New',
  SENT = 'Proposal Sent',
}

interface Message {
  displayName: string;
  text: string;
  receiverId: string;
  senderId: string;
  timestamp: Timestamp | null;
}

export interface MessageData {
  id: string;
  message: Message;
}

interface IActivity {
  name: string;
  id: string;
}

export interface Request {
  activity: IActivity;
  discount: {
    amount: number | null;
    type: 'fixed' | 'percentage';
  };
  haveLocation: boolean;
  instructor: {
    id: string;
    name: string;
    orgName: string;
  };
  latestMessage: string | null;
  lessonCount: number;
  lessonDuration: string;
  lessonLocation: PlaceType | null;
  lessonRate: number;
  lessonType: LessonTypes;
  frequency: Frequency;
  nextSession: Timestamp | null;
  note: string;
  originalTimeStamp: Timestamp | null;
  preferredTOD: string[];
  proposalFeedback: string;
  requester: {
    id: string;
    name: string;
  };
  requestStatus: RequestStatus;
  sameTime: boolean;
  startASAP: boolean;
  startDate: Dayjs | null;
  student: StudentData;
  timestamp: Timestamp | null;
  travel: Travel;

  cancelReason: string;
  declineReason: string;
}

export interface RequestData {
  id: string;
  request: Request;
}

export interface ConversationCardProps {
  activity: string;
  handleCardClick: () => void;
  icon?: JSX.Element;
  id: string;
  latestMessage: string | null;
  name: string;
  nextSession: Timestamp | null;
  requestStatus: string;
}

export enum LessonStatus {
  CANCELED = 'Canceled',
  COMPLETED = 'Completed',
  NOSHOW = 'No Show',
  SCHEDULED = 'Scheduled',
  RESCHEDULED = 'Rescheduled',
}

export interface LessonData {
  id: string;
  lesson: Lesson;
}

export interface Lesson {
  activity: IActivity;
  completed: boolean;
  date: Dayjs | null;
  familyId: string;
  familyNotes?: string;
  instructorId: string;
  instructorNotes?: string;
  requestId: string;
  rescheduleId?: string;
  status: LessonStatus;
  studentId: string;
}

export const useSubscribeToOutgoingRequests = (
  currentUser: UserData | null,
  setRequestData: Dispatch<SetStateAction<RequestData[]>>
) => {
  useEffect(() => {
    if (!currentUser || !currentUser.id) return;
    console.log('Outgoing requests sub ran after currentUser is set');

    const unsub = onSnapshot(
      query(
        collection(db, 'requests'),
        where('requester.id', '==', currentUser.id)
      ),
      snapshot => {
        setRequestData(
          snapshot.docs.map(doc => ({
            id: doc.id,
            request: doc.data() as Request,
          }))
        );
      }
    );
    return unsub;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
};

export const useSubscribeToIncomingRequests = (
  currentUser: UserData | null,
  setRequestData: Dispatch<SetStateAction<RequestData[]>>
) => {
  useEffect(() => {
    if (!currentUser || !currentUser.id) return;
    console.log('Incoming requests sub ran after currentUser is set');

    const unsub = onSnapshot(
      query(
        collection(db, 'requests'),
        where('instructor.id', '==', currentUser.id)
      ),
      snapshot => {
        setRequestData(
          snapshot.docs.map(doc => ({
            id: doc.id,
            request: doc.data() as Request,
          }))
        );
      }
    );
    return unsub;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
};

export const useSubscribeToMessages = (
  currentUser: UserData | null,
  requestId: string,
  setMessages: Dispatch<SetStateAction<MessageData[]>>
) => {
  useEffect(() => {
    if (!currentUser || !currentUser.id || !requestId) return;

    console.log('message sub ran');
    const unsub = onSnapshot(
      query(collection(db, 'requests', requestId, 'messages')),
      snapshot => {
        const fetchedMessages = snapshot.docs.map(doc => ({
          id: doc.id,
          message: doc.data() as Message,
        }));

        fetchedMessages.sort((a, b) => {
          const aTimestamp = a.message.timestamp;
          const bTimestamp = b.message.timestamp;

          if (!aTimestamp || !bTimestamp) return 0;
          return aTimestamp.toMillis() - bTimestamp.toMillis();
        });

        setMessages(fetchedMessages);
      }
    );
    return unsub;
  }, [currentUser, requestId, setMessages]);
};

export const saveInitialRequestDocument = async (
  organizationData: OrganizationData,
  requestData: Request,
  currentUser: UserData | null
): Promise<string | Error> => {
  if (!requestData || !currentUser || !organizationData) {
    return new Error('Invalid input parameters');
  }

  const { id, user } = currentUser;

  if (organizationData.organization.ownerId === id)
    return new Error('You cannot request yourself');

  if (organizationData.organization.ownerId && id) {
    const requestObject = {
      ...requestData,
      requester: {
        id: id,
        name: user.firstName + ' ' + user.lastName,
      },
      instructor: {
        id: organizationData.organization.ownerId,
        name: organizationData.organization.ownerName,
        orgName: organizationData.organization.orgName,
      },
      originalRequestTime: getServerTimestamp(),
      timestamp: getServerTimestamp(),
    };

    try {
      const requestDocRef: DocumentReference = await addDoc(
        collection(db, 'requests'),
        requestObject
      );

      return requestDocRef.id;
    } catch (error) {
      console.log(error);
      return new Error('Failed to save request document');
    }
  }

  return new Error('Missing required data');
};

export const updateRequestDocument = async (
  requestId: string,
  updatedRequestData: Partial<Request>
) => {
  if (!requestId || typeof requestId !== 'string') {
    return new Error('Invalid request ID');
  }

  if (!updatedRequestData || typeof updatedRequestData !== 'object') {
    return new Error('Invalid request data');
  }

  try {
    const requestDocRef = doc(db, 'requests', requestId);
    await setDoc(requestDocRef, updatedRequestData, { merge: true });
    return 'Request updated successfully';
  } catch (error) {
    console.error('Error updating request:', error);
    return new Error('Failed to update request document');
  }
};

export const sendMessage = async (
  currentUser: UserData | null,
  text: string,
  requestId: string,
  receiverId: string
) => {
  if (!currentUser) return;

  const { id, user } = currentUser;

  if (!text || !receiverId || !requestId) {
    return new Error('An error occurred while sending message');
  }

  const messageData = {
    displayName: user.firstName,
    text: text,
    receiverId: receiverId,
    senderId: id,
    timestamp: getServerTimestamp(),
  };
  try {
    await addDoc(
      collection(db, 'requests', requestId, 'messages'),
      messageData
    );

    await setDoc(
      doc(db, 'requests', requestId),
      {
        latestMessage: text,
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
  }
};

export const addLessonsToRequest = async (
  requestId: string,
  lessons: LessonData[],
  requestData: Partial<Request>,
  setResponse: (response: string | Error) => void
) => {
  if (!requestId || requestId.trim() === '') {
    setResponse(new Error('Invalid request ID'));
    return;
  }

  if (!lessons || !Array.isArray(lessons)) {
    setResponse(new Error('Invalid lesson data'));
    return;
  }

  try {
    await updateRequestDocument(requestId, requestData);

    const batch = writeBatch(db);

    lessons.forEach(lesson => {
      if (!lesson.lesson.date) {
        throw new Error('Invalid lesson date and/or time');
      }

      let firestoreDate;

      if (isDayjs(lesson.lesson.date)) {
        firestoreDate = convertDayjsToFirestoreTimestamp(lesson.lesson.date);
      } else {
        // Make sure this is already a Firestore Timestamp if not using Dayjs
        firestoreDate = lesson.lesson.date;
      }

      const lessonData = {
        ...lesson.lesson,
        date: firestoreDate,
      };

      const lessonRef = lesson.id
        ? doc(db, `lessons`, lesson.id)
        : doc(db, `lessons`);

      batch.set(lessonRef, lessonData);
    });

    await batch.commit();
    setResponse('Proposal updated successfully');
  } catch (error) {
    setResponse(
      new Error('Failed to update proposal: ' + (error as Error).message)
    );
  }
};

export const getLessonsFromRequest = (
  requestId: string,
  setLessons: (lessons: LessonData[]) => void,
  setPreviousLessons?: (lessons: LessonData[]) => void
) => {
  if (!requestId) {
    return new Error('Invalid request ID');
  }

  const fetchLessons = async () => {
    try {
      const lessonsSnapshot = await getDocs(
        query(collection(db, 'lessons'), where('requestId', '==', requestId))
      );

      setLessons(
        lessonsSnapshot.docs.map(doc => {
          const lessonData = doc.data() as Lesson;
          return {
            id: doc.id,
            lesson: {
              ...lessonData,
              date: convertFirestoreTimestampToDayjs(
                lessonData.date as unknown as Timestamp
              ),
            },
          };
        })
      );
      setPreviousLessons &&
        setPreviousLessons(
          lessonsSnapshot.docs.map(doc => {
            const lessonData = doc.data() as Lesson;
            return {
              id: doc.id,
              lesson: {
                ...lessonData,
                date: convertFirestoreTimestampToDayjs(
                  lessonData.date as unknown as Timestamp
                ),
              },
            };
          })
        );
    } catch (error) {
      console.error('Error fetching lessons:', error);
    }
  };

  fetchLessons();
};

export const getAllLessonsForUser = async (
  setAllLessons: (lessons: LessonData[]) => void
) => {
  const userId = auth.currentUser?.uid;

  if (!userId) {
    return new Error('Invalid user ID');
  }
  console.log('Fetching all lessons for user:', userId);

  // get all requests where the user is the clientId
  const lessonsSnapshot = await getDocs(
    query(collection(db, 'lessons'), where('clientId', '==', userId))
  );

  const lessons: LessonData[] = [];

  lessonsSnapshot.forEach(doc => {
    const lessonData = doc.data() as Lesson;
    lessons.push({
      id: doc.id,
      lesson: {
        ...lessonData,
        date: convertFirestoreTimestampToDayjs(
          lessonData.date as unknown as Timestamp
        ),
      },
    });
  });

  setAllLessons(lessons);
};

export const saveLessonDocument = async (
  currentUser: UserData | null,
  lessonData: Lesson,
  lessonId?: string
): Promise<{ success: boolean; docId?: string }> => {
  const authId = auth.currentUser?.uid;

  if (!currentUser || !authId) return { success: false };

  if (authId !== currentUser.id) return { success: false };

  try {
    let docId;
    let firestoreDate: Timestamp | null;

    if (isDayjs(lessonData.date)) {
      firestoreDate = convertDayjsToFirestoreTimestamp(lessonData.date);
    } else {
      firestoreDate = lessonData.date;
    }

    if (lessonId) {
      await setDoc(
        doc(db, 'lessons', lessonId),
        {
          ...lessonData,
          date: firestoreDate,
          instructorId: lessonData.instructorId || authId,
          status: lessonData.status || 'scheduled',
        },
        {
          merge: true,
        }
      );
      docId = lessonId;
    } else {
      const newLessonId = await addDoc(collection(db, 'lessons'), {
        ...lessonData,
        date: firestoreDate,
        instructorId: lessonData.instructorId || authId,
        status: lessonData.status || 'scheduled',
      });
      docId = newLessonId.id;
    }
    return { success: true, docId };
  } catch (error) {
    return { success: false };
  }
};
