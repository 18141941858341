import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Skeleton,
} from '@mui/material';
import { RemoveCircle, Task, Web } from '@mui/icons-material';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getImageURL } from 'api/user.utils';
import { TooltipIconButton } from 'components/shared';
import { Contact } from 'api/contact.utils';

interface ContactRecordProps {
  contact: Contact;
  handleRemoveContact: () => void;
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 250px;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
`;

const TruncatedCardHeader = styled(CardHeader)`
  .MuiCardHeader-content {
    overflow: hidden;
  }

  .MuiCardHeader-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiCardHeader-subheader {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ContactRecord: FC<ContactRecordProps> = ({
  contact,
  handleRemoveContact,
}) => {
  const navigate = useNavigate();

  const { contactId, contactName, orgName, slug } = contact;

  const [contactImage, setContactImage] = useState<string>('');

  const handleNavigation = (nav: string) => {
    if (nav === 'landingPage') return navigate(`/i/${slug}`);
    if (nav === 'requestLessons') return navigate(`/i/${slug}/request`);
  };

  useEffect(() => {
    if (contactId) {
      getImageURL(
        `/avatars/eG3xKiS9oRaasv77EQ2r6Z8HPT93/avatar.jpg`,
        setContactImage
      );
    }
  }, [contactId]);

  return (
    <StyledCard variant="outlined">
      <TruncatedCardHeader title={contactName} subheader={orgName} />
      {contactImage ? (
        <CardMedia
          component="img"
          image={contactImage}
          sx={{ height: 300, width: 250 }}
          title="profile picture"
        />
      ) : (
        <Skeleton
          animation="wave"
          height={300}
          variant="rectangular"
          width={250}
        />
      )}
      <StyledCardActions>
        <div>
          <TooltipIconButton
            action={() => handleNavigation('landingPage')}
            icon={<Web />}
            tooltipText={'Go to Landing Page'}
          />
          <TooltipIconButton
            action={() => handleNavigation('requestLessons')}
            icon={<Task />}
            tooltipText={'Request Lessons'}
          />
        </div>
        <div>
          <TooltipIconButton
            action={handleRemoveContact}
            icon={<RemoveCircle />}
            tooltipText={'Remove from My Instructors'}
          />
        </div>
      </StyledCardActions>
    </StyledCard>
  );
};

export default ContactRecord;
