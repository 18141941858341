import { Link, Typography } from '@mui/material';
import { CustomButton, CustomDivider } from 'components/shared';
import { FC } from 'react';
import styled from 'styled-components';

interface PrivacyProps {
  acknowledgement?: () => void;
}

const AcknowledgementContainer = styled.div`
  margin: 24px auto;
  width: fit-content;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin: 24px 0;
`;

const MainContainer = styled.div`
  padding: 0 48px;
  margin: 24px auto;
  max-width: 1200px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EffectiveDate = styled(Typography)`
  font-size: 16px;
  margin: 0 auto 24px;
`;

const PageHeader = styled(Typography)`
  font-size: 32px;
  font-weight: 500;
  margin: 24px auto 0;
  color: #58abff;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SectionHeading = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
`;

const SectionBody = styled(Typography)`
  font-size: 16px;
`;

const IntroductionContainer = styled.div`
  margin: 24px 0;
`;

const ClosingContainer = styled(Typography)`
  margin: 48px 0;
  font-size: 24px;
`;

const Privacy: FC<PrivacyProps> = ({ acknowledgement }) => {
  const sectionContent = [
    {
      title: 'Information We Collect',
      content: `We collect personal information such as name, email address, 
        phone number, and payment information from our users. We also collect
        non-personal information such as device information and website usage 
        statistics. This information is collected to provide our services and 
        improve the user experience.`,
    },
    {
      title: 'Use of Information',
      content: `We use the personal information we collect to operate our platform 
      and provide services to our users. We may also use this information for 
      marketing purposes, such as sending promotional emails and newsletters. 
      Non-personal information may be used for analytical and research purposes.`,
    },
    {
      title: 'Sharing of Information',
      content: `We do not sell or rent personal information to third parties. 
      However, we may share information with our service providers and partners 
      to provide our services and improve the user experience. We may also share 
      information if required by law or to protect our legal rights.`,
    },
    {
      title: 'Data Retention',
      content: `We retain personal information for as long as necessary to provide 
      our services and comply with legal obligations. Non-personal information may 
      be retained for analytical and research purposes.`,
    },
    {
      title: 'Security',
      content: `We take reasonable measures to protect personal information from 
      unauthorized access, use, or disclosure. However, no method of transmission 
      or storage is completely secure, and we cannot guarantee the absolute security 
      of personal information.`,
    },
    {
      title: 'Cookies',
      content: `We use cookies and similar technologies to enhance the user experience 
      and provide personalized content. Users can control the use of cookies through 
      their browser settings.`,
    },
    {
      title: `Children's Privacy`,
      content: `Our platform is not intended for use by children under the age of 13. 
      We do not knowingly collect personal information from children under 13.`,
    },
    {
      title: 'Changes to this Policy',
      content: `We may update this Privacy Policy from time to time. Users will be 
      notified of any significant changes via email or notification on our platform.`,
    },
  ];
  return (
    <MainContainer>
      <HeaderContainer>
        <PageHeader>Privacy Policy</PageHeader>
        <EffectiveDate>Effective date: January 1, 2024</EffectiveDate>
      </HeaderContainer>
      <CustomDivider />
      <IntroductionContainer>
        <Typography variant="body1">
          At Instrekt, we take privacy seriously. We are committed to protecting
          the personal information of our users, including individual
          instructors anpxd businesses who use our platform to operate their
          teaching-based businesses. This Privacy Policy outlines our practices
          regarding the collection, use, and disclosure of personal information,
          and how we strive to maintain the security and confidentiality of such
          information. By using our platform, you consent to our collection and
          use of your personal information as described in this policy.
        </Typography>
      </IntroductionContainer>
      <CustomDivider />
      <ContentContainer>
        {sectionContent.map(section => {
          const { title, content } = section;
          return (
            <SectionContainer key={title}>
              <SectionHeading>{title}</SectionHeading>
              <SectionBody variant="body1">{content}</SectionBody>
            </SectionContainer>
          );
        })}
      </ContentContainer>
      <CustomDivider />
      <ClosingContainer>
        At Instrekt, we are committed to protecting the privacy of our users. If
        you have any questions or concerns about our Privacy Policy, please
        contact us at{' '}
        <Link
          href="mailto:support@instrekt.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@instrekt.com
        </Link>
        .
      </ClosingContainer>
      <AcknowledgementContainer>
        {acknowledgement && (
          <CustomButton
            buttonText="Got it"
            onClick={acknowledgement}
            variant="contained"
          />
        )}
      </AcknowledgementContainer>
    </MainContainer>
  );
};

export default Privacy;
