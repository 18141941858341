import { Nightlight, WbSunny, WbTwilight } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { BlueLabel } from 'assets/styles/shared';
import styled from 'styled-components';

interface TimeOfDayProps {
  disabled?: boolean;
  disabledTOD?: string[];
  infoAlert?: string;
  label?: string;
  tOD?: string[];

  handleTODClick?: (
    event: React.MouseEvent<HTMLElement>,
    timeDay: string[]
  ) => void;
}

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

const StyledToggleButton = styled(ToggleButton)`
  padding: 24px 0;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  width: calc(100% / 7);
`;

const TimeOfDayFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeOfDayField = ({
  disabled = false,
  disabledTOD,
  handleTODClick,
  infoAlert,
  label,
  tOD,
}: TimeOfDayProps) => {
  const days = ['sun', 'mon', 'tues', 'wed', 'thurs', 'fri', 'sat'];

  return (
    <TimeOfDayFieldContainer>
      {label && <BlueLabel>{label}</BlueLabel>}
      {infoAlert && (
        <StyledAlert severity="info">
          <AlertTitle>Please Note:</AlertTitle>
          {infoAlert}
        </StyledAlert>
      )}
      <ScheduleContainer>
        {days.map(day => {
          return (
            <StyledToggleButtonGroup
              key={day}
              onChange={handleTODClick}
              orientation="vertical"
              value={tOD}
            >
              <StyledToggleButton
                value={`${day}-title`}
                aria-label={`${day}-title`}
                disabled
                sx={{
                  '&.Mui-disabled': {
                    color: 'action.active',
                  },
                }}
              >
                {day}
              </StyledToggleButton>
              <StyledToggleButton
                aria-label={`${day}-morning`}
                disabled={
                  disabled
                    ? disabled
                    : disabledTOD && !disabledTOD?.includes(`${day}-morning`)
                }
                value={`${day}-morning`}
              >
                <WbTwilight />
              </StyledToggleButton>
              <StyledToggleButton
                value={`${day}-afternoon`}
                aria-label={`${day}-afternoon`}
                disabled={
                  disabled
                    ? disabled
                    : disabledTOD && !disabledTOD?.includes(`${day}-afternoon`)
                }
              >
                <WbSunny />
              </StyledToggleButton>
              <StyledToggleButton
                value={`${day}-evening`}
                aria-label={`${day}-evening`}
                disabled={
                  disabled
                    ? disabled
                    : disabledTOD && !disabledTOD?.includes(`${day}-evening`)
                }
              >
                <Nightlight />
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          );
        })}
      </ScheduleContainer>
    </TimeOfDayFieldContainer>
  );
};

export default TimeOfDayField;
