import {
  EmailOutlined,
  PersonOutline,
  PhoneOutlined,
  SwapHoriz,
} from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import { FC, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FamilyData } from 'api/family.utils';
import { StudentData, saveStudentDocument } from 'api/student.utils';
import { CustomButton, InputFields } from 'components/shared';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

interface FamilyInfoProps {
  backToOverview: () => void;
  family: FamilyData;
  handleViewFamily: () => void;
  student: StudentData;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-left: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }: { maxWidth: string }) => maxWidth};
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 600px;
    max-width: 100%;
    padding: 8px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const FamilyInfo: FC<FamilyInfoProps> = ({
  backToOverview,
  family,
  handleViewFamily,
  student,
}) => {
  const { setUserStudents, userFamilies, userStudents } =
    useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { DropDown } = InputFields;

  const navigate = useNavigate();

  const [newFamilyId, setNewFamilyId] = useState<string>('');
  const [open, setOpen] = useState(false);

  const {
    family: { address, email, familyName, firstName, lastName, phone },
  } = family;

  const handleCancelChangeFamily = () => {
    setOpen(false);
    setNewFamilyId('');
  };

  const handleChangeFamily = () => {
    saveStudentDocument(
      currentUser,
      { ...student.student, familyId: newFamilyId },
      student.id
    ).then(() => {
      setUserStudents(prev =>
        prev.map(s => {
          if (s.id === student.id) {
            return { ...s, student: { ...s.student, familyId: newFamilyId } };
          }
          return s;
        })
      );
    });
    setOpen(false);
    setNewFamilyId('');
  };

  const handleOtherStudent = (id: string) => {
    navigate(`/students/details?s=${id}`);
    backToOverview();
  };

  const handleSelectFamily = (id: string) => {
    setNewFamilyId(id);
  };

  const familyStudents = useMemo(() => {
    return userStudents
      .filter(student => student.student.familyId === family.id)
      .filter(s => s.id !== student.id);
  }, [family.id, student.id, userStudents]);

  const familyOptions = userFamilies.map(family => ({
    name: `${family.family.familyName} (${family.family.lastName}, ${family.family.firstName})`,
    value: family.id,
  }));

  return (
    <>
      <Container maxWidth="800px">
        <Heading>
          <Typography variant="h4">Family Information</Typography>
        </Heading>
        <Container maxWidth="400px">
          <DataContainer>
            <Typography variant="body1">Family Name</Typography>
            <Typography variant="h6" sx={{ paddingLeft: 4 }}>
              {familyName}
            </Typography>
          </DataContainer>
          <DataContainer>
            <Typography variant="body1">Primary Contact</Typography>
            <ContactContainer>
              {<PersonOutline />}
              <Typography variant="h6">
                {firstName} {lastName}
              </Typography>
            </ContactContainer>
            {email && (
              <ContactContainer>
                {<EmailOutlined />}
                <Typography variant="h6">{email}</Typography>
              </ContactContainer>
            )}
            {phone && (
              <ContactContainer>
                {<PhoneOutlined />}
                <Typography variant="h6">{phone}</Typography>
              </ContactContainer>
            )}
          </DataContainer>
          {address && (
            <DataContainer>
              <Typography variant="body1">Address</Typography>
              <Typography variant="h6" sx={{ paddingLeft: 4 }}>
                {address}
              </Typography>
            </DataContainer>
          )}
          {familyStudents && (
            <DataContainer>
              <Typography variant="body1">Other Household Students</Typography>
              {familyStudents.map(student => (
                <Link
                  component={'button'}
                  key={student.id}
                  onClick={() => handleOtherStudent(student.id)}
                  sx={{ margin: 0, paddingLeft: 4, width: 'fit-content' }}
                  variant="h6"
                >
                  {student.student.firstName} {student.student.lastName}
                </Link>
              ))}
            </DataContainer>
          )}
        </Container>
        <ButtonContainer>
          <CustomButton
            buttonText="View Family Account"
            onClick={handleViewFamily}
            variant="outlined"
          />
          <CustomButton
            buttonText="Change Family"
            endIcon={<SwapHoriz />}
            onClick={() => setOpen(true)}
          />
        </ButtonContainer>
      </Container>
      <StyledDialog onClose={() => setOpen(prev => !prev)} open={open}>
        <DialogTitle>Update Associated Family</DialogTitle>
        <StyledDialogContent>
          <DialogContentText>
            Changing the family associated with this student will remove the
            current family and link a new family contact. This option will be
            disabled as soon as there are lessons invoiced and/or logged for
            this student.
          </DialogContentText>
          <DropDown
            options={familyOptions}
            onChange={event => handleSelectFamily(event.target.value)}
            value={newFamilyId}
            label="Select Existing Family"
          />
          <ButtonContainer>
            <CustomButton
              buttonText="Cancel"
              onClick={handleCancelChangeFamily}
              variant="outlined"
            />
            <CustomButton
              buttonText="Change Family"
              onClick={handleChangeFamily}
            />
          </ButtonContainer>
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
};

export default FamilyInfo;
