import {
  CakeOutlined,
  DescriptionOutlined,
  Edit,
  EmailOutlined,
  PersonOutlined,
  PhoneOutlined,
} from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, FC, useContext, useState } from 'react';
import styled from 'styled-components';

import {
  Student,
  StudentData,
  StudentStatus,
  StudentType,
  saveStudentDocument,
} from 'api/student.utils';
import {
  CustomButton,
  CustomToggleButtonGroup,
  InputFields,
  TooltipIconButton,
} from 'components/shared';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

interface StudentInfoProps {
  handleViewLessonLog: () => void;
  student: StudentData;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

const DataContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  padding: 1rem;
  max-width: 400px;
`;

const EditStudentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  max-width: 800px;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

const MainStudentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  align-items: center;
  max-width: 800px;
`;

const StudentHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const StyledChip = styled(Chip)`
  margin-left: 10px;
  width: fit-content;
`;

const TooltipButtonContainer = styled.div`
  position: absolute;
  right: 0;
`;

const StudentInfo: FC<StudentInfoProps> = ({
  handleViewLessonLog,
  student,
}) => {
  const { currentUser } = useContext(UserContext);
  const { setUserStudents, userStudents } = useContext(DataContext);
  const { TextArea, TextInput } = InputFields;

  const [edit, setEdit] = useState(false);
  const [emailError, setEmailError] = useState<string>('');
  const [updatedStudent, setUpdatedStudent] = useState<Student>(
    student.student
  );

  const {
    student: { dob, email, firstName, gender, lastName, notes, phone, status },
  } = student;

  const displayData = (icon: JSX.Element, data: string | number | null) =>
    data && (
      <DataContainer key={data}>
        {icon}
        <Typography variant="body1">{data}</Typography>
      </DataContainer>
    );

  const handleDateChange = (date: Dayjs | null) => {
    setUpdatedStudent(prev => ({ ...prev, dob: date }));
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    if (id === 'email') setEmailError('');
    setUpdatedStudent(prev => ({ ...prev, [id]: value }));
  };

  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    value: StudentStatus
  ) => {
    setUpdatedStudent(prev => ({ ...prev, status: value }));
  };

  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    value: StudentType
  ) => {
    setUpdatedStudent(prev => ({ ...prev, type: value }));
  };

  const handleUpdateStudent = () => {
    if (!currentUser) return;
    validateEmail();
    if (emailError) return;

    saveStudentDocument(currentUser, updatedStudent, student.id).then(res => {
      const { docId, success } = res;
      if (!success) return;

      if (docId) {
        const updatedStudents = userStudents.map(student =>
          student.id === docId
            ? { ...student, student: updatedStudent }
            : student
        );
        setUserStudents(updatedStudents);
      }
    });
    toggleEdit();
  };

  const toggleEdit = () => {
    setEdit(prev => !prev);
    setUpdatedStudent(student.student);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!updatedStudent.email || emailRegex.test(updatedStudent.email)) return;
    setEmailError('Invalid email address');
  };

  const disableSubmit =
    Object.keys(updatedStudent).every(
      key =>
        updatedStudent[key as keyof Student] ===
        student.student[key as keyof Student]
    ) || emailError !== '';

  const studentInformation = [
    { icon: <EmailOutlined />, data: email },
    { icon: <PhoneOutlined />, data: phone },
    { icon: <PersonOutlined />, data: gender },
    { icon: <CakeOutlined />, data: dob && dayjs(dob).format('MM/DD/YYYY') },
  ];

  return (
    <>
      {!edit ? (
        <MainStudentContainer>
          <TooltipButtonContainer>
            <TooltipIconButton
              action={toggleEdit}
              icon={<Edit />}
              tooltipText="Edit Student"
            />
          </TooltipButtonContainer>
          <StudentHeading>
            <Typography variant="h4">
              {lastName}, {firstName}
            </Typography>
            <StyledChip
              label={status.charAt(0).toUpperCase() + status.slice(1)}
              color={
                status === 'active'
                  ? 'primary'
                  : status === 'inactive'
                  ? 'default'
                  : 'secondary'
              }
            />
          </StudentHeading>
          <DetailsContainer>
            {studentInformation.map(({ icon, data }) =>
              displayData(icon, data)
            )}
          </DetailsContainer>
          <DetailsContainer>
            <Typography variant="h6">Private Notes</Typography>
            {displayData(<DescriptionOutlined />, notes)}
          </DetailsContainer>
          <DetailsContainer>
            <CustomButton
              buttonText="View Lesson Log"
              onClick={handleViewLessonLog}
              variant="outlined"
            />
          </DetailsContainer>
        </MainStudentContainer>
      ) : (
        <EditStudentContainer>
          <StudentHeading>
            <Typography variant="h4">Edit Student Information</Typography>
          </StudentHeading>
          <CustomToggleButtonGroup
            buttons={[
              { color: 'primary', name: 'Active', value: StudentStatus.ACTIVE },
              { color: 'secondary', name: 'Lead', value: StudentStatus.LEAD },
              {
                color: 'standard',
                name: 'Inactive',
                value: StudentStatus.INACTIVE,
              },
            ]}
            onChange={handleStatusChange}
            value={updatedStudent.status}
          />
          <CustomToggleButtonGroup
            buttons={[
              { name: 'Child', value: StudentType.CHILD },
              { name: 'Adult', value: StudentType.ADULT },
            ]}
            onChange={handleTypeChange}
            value={updatedStudent.type}
          />
          <InputContainer>
            <TextInput
              fullWidth
              id="firstName"
              label="First Name"
              onChange={handleInputChange}
              value={updatedStudent.firstName}
            />
            <TextInput
              fullWidth
              id="lastName"
              label="Last Name"
              onChange={handleInputChange}
              value={updatedStudent.lastName}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              error={emailError}
              fullWidth
              helperText={emailError || ''}
              id="email"
              label="Email"
              onChange={handleInputChange}
              onBlur={validateEmail}
              value={updatedStudent.email}
            />
            <TextInput
              fullWidth
              id="phone"
              label="Phone"
              onChange={handleInputChange}
              value={updatedStudent.phone}
            />
          </InputContainer>
          <InputContainer>
            <DateField
              fullWidth
              id="dob"
              label="Date of Birth"
              onChange={handleDateChange}
              value={updatedStudent.dob}
            />
            <TextInput
              fullWidth
              id="gender"
              label="Gender"
              onChange={handleInputChange}
              value={updatedStudent.gender}
            />
          </InputContainer>
          <TextArea
            fullWidth
            id="notes"
            label="Notes"
            onChange={handleInputChange}
            rows={5}
            value={updatedStudent.notes}
          />
          <ButtonContainer>
            <CustomButton
              buttonText="Cancel"
              onClick={toggleEdit}
              variant="text"
            />
            <CustomButton
              buttonText="Update"
              disabled={disableSubmit}
              onClick={handleUpdateStudent}
              variant="contained"
            />
          </ButtonContainer>
        </EditStudentContainer>
      )}
    </>
  );
};

export default StudentInfo;
