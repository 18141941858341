import { Typography, Link, Alert } from '@mui/material';
import { StandardContainer } from 'assets/styles/shared';
import styled from 'styled-components';

const AddressGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Data = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const DataItem = styled.div`
  margin-right: 4px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin: 32px 0;
`;

const SectionContainer = styled.div`
  max-width: 595px;
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SectionLabel = styled(Typography)`
  font-weight: 600;
  margin-bottom: 8px;
`;

const StyledAddressGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1rem;
`;

const StyledAlert = styled(Alert)`
  margin: 16px 0;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const StyledStandardContainer = styled(StandardContainer)`
  margin: 48px 96px;
`;

export {
  AddressGroup,
  Data,
  DataItem,
  FieldContainer,
  SectionContainer,
  SectionHeading,
  SectionLabel,
  StyledAddressGroup,
  StyledAlert,
  StyledLink,
  StyledStandardContainer,
};
