import React, { useContext, useEffect, useState } from 'react';

import UserContext from 'contexts/UserContext';
import { updateUserProfileDocument } from 'api/user.utils';
import SettingSection from './SettingSection';
import {
  FieldData,
  FieldTypes,
  SectionDataProps,
  SectionName,
  UserData,
} from './types';

import { StyledStandardContainer } from './styles';
import { PlaceType } from 'components/shared/InputFields/LocationSearch';
import SettingsView from '../SettingsView';
import DataContext from 'contexts/DataContext';

const PersonalInfo = () => {
  const { avatarUrl, currentUser } = useContext(UserContext);
  const { searchLocation } = useContext(DataContext);

  const [data, setData] = useState<UserData>();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);

  const fieldMap: FieldData = {
    address: 'Address',
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    imagePath: 'Avatar',
    phone: 'Phone Number',
  };

  useEffect(() => {
    if (currentUser?.id && !initialLoad) {
      setInitialLoad(true);
      setData({
        ...currentUser?.user,
      });
      return;
    }
    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleAddressChange = (address: PlaceType | null) => {
    if (address) {
      setData({
        ...data,
        address: address.description,
        placeId: address.place_id,
      });
    }
    setIsDirty(true);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const { id, value } = event.target;
    setData({ ...data, [id]: value });
    setIsDirty(true);
  };

  const handleSubmit = () => {
    currentUser?.id && updateUserProfileDocument(currentUser.id, data);
    setExpandedIndex(null);
  };

  const handleToggleClick = (index: number) => {
    if (expandedIndex === index) {
      setIsDirty(false);
      resetData();
      return setExpandedIndex(null);
    }
    setExpandedIndex(index);
  };

  const resetData = () => {
    if (currentUser?.user && currentUser.user.address) {
      const { address, firstName, lastName, email, imagePath, phone } =
        currentUser.user;
      setData({
        address: address || '',
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
        imagePath: imagePath || '',
        phone: phone || '',
      });
    }
  };

  const handleNewImageChange = (imagePath: string) => {
    setData({ ...data, imagePath });
    currentUser?.id &&
      updateUserProfileDocument(currentUser.id, {
        imagePath,
      });
    setExpandedIndex(null);
  };

  const storageRefLocation = `/avatars/${currentUser?.id}`;

  const settingSections: SectionDataProps[] = [
    {
      disabled: currentUser?.user.googleAuthenticated,
      disabledReason: 'Your name was provided by a third party (i.e Google)',
      editDescription:
        'This is the name that appears on your ID, which could be a license or a passport.',
      label: SectionName.LEGAL_NAME,
      sectionData: {
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
      },
    },
    {
      // TODO: convert this to a email verificaiton field so if a user changes their email addres
      // we need to validate it is real.  Also, determine if this needs to be disabled when creating
      // an account with Google.
      disabled: currentUser?.user.googleAuthenticated,
      disabledReason:
        'Your email address was provided by a third party (i.e Google)',
      editDescription:
        'Please provide a valid email address so can receive notifications and necessary communications.',
      label: SectionName.EMAIL,
      sectionData: {
        email: data?.email || '',
      },
    },
    {
      editDescription:
        'Please provide your phone number so you can receive text updates.',
      fieldType: FieldTypes.PHONE,
      label: SectionName.PHONE,
      sectionData: {
        phone: data?.phone || '',
      },
    },
    {
      fieldType: FieldTypes.ADDRESS,
      handleAddressChange: handleAddressChange,
      label: SectionName.ADDRESS,
      sectionData: {
        address: data?.address || 'Not provided',
      },
      options: [
        { name: 'US', value: 'US' },
        { name: 'CA', value: 'CA' },
      ],
    },
    {
      fieldType: FieldTypes.IMAGE,
      handleImageChange: handleNewImageChange,
      label: SectionName.IMAGE,
      sectionData: {
        imageUrl: avatarUrl || '',
      },
      showDivider: false,
    },
  ];

  return (
    <SettingsView
      rightContent={
        <StyledStandardContainer>
          {settingSections.map((s, index) => (
            <SettingSection
              active={expandedIndex === index}
              data={s.sectionData}
              disabled={s.disabled}
              disabledReason={s.disabledReason}
              expandedIndex={expandedIndex}
              editDescription={s.editDescription}
              fieldMap={fieldMap}
              fieldType={s.fieldType}
              handleAddressChange={s.handleAddressChange}
              handleChange={handleChange}
              handleImageChange={handleNewImageChange}
              handleSubmit={handleSubmit}
              handleToggleClick={() => handleToggleClick(index)}
              index={index}
              isDirty={isDirty}
              key={index}
              label={s.label}
              storageRefLocation={storageRefLocation}
              searchLocation={searchLocation}
              showDivider={s.showDivider}
              setExpandedIndex={setExpandedIndex}
            />
          ))}
        </StyledStandardContainer>
      }
    />
  );
};

export default PersonalInfo;
