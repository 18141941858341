import { Alert, Typography } from '@mui/material';
import RateSheet from 'components/Settings/Activities/RateSheet';
import SignupContext from 'contexts/SignupContext';
import { useContext } from 'react';

const Rates = () => {
  const { applicationData, updateActivityData } = useContext(SignupContext);
  return (
    <>
      <Typography variant="h3">Your Rates</Typography>
      <Alert severity="info" variant="outlined">
        Enter as many rates as you would like. You can add more later if you
        need to. You can set different rates for different lesson durations
        (e.g. 30 minutes, 45 minutes, 60 minutes, etc.) and for different types
        (e.g. private, semi-private, etc.).
      </Alert>
      <RateSheet
        rateData={applicationData.application.activity.rates}
        updateActivityData={updateActivityData}
      />
    </>
  );
};

export default Rates;
