import { Typography } from '@mui/material';
import { RequestData } from 'api/lesson.utils';
import { DetailsContainer, InformationContainer } from './styles';

interface GeneralInfoProps {
  requestData: RequestData;
}

const GeneralInfo = ({ requestData }: GeneralInfoProps) => {
  const { request } = requestData;

  return (
    <InformationContainer>
      <Typography variant="h5">Proposal Details</Typography>
      <DetailsContainer>
        <Typography variant="h6">
          Student: {request.student.student.firstName}{' '}
          {request.student.student.lastName}, age {request.student.student.age}
        </Typography>
        <Typography variant="h6">
          Instructor: {request.instructor.name} with{' '}
          {request.instructor.orgName}
        </Typography>
      </DetailsContainer>
    </InformationContainer>
  );
};

export default GeneralInfo;
