import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { StandardContainer } from 'assets/styles/shared';
import { CustomButton } from 'components/shared';

const SubjectsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
`;

const SubjectsSectionTitle = styled(Typography)`
  margin: 0 auto 40px;
`;

const SubjectsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubjectListItem = styled(Typography)`
  text-align: center;
  margin: 0 auto;
`;

const SubjectSectionDetails = styled(Typography)`
  text-align: center;
  color: #58abff;
  margin: 36px auto 0;
  width: 50%;
`;

const ButtonContainer = styled.div`
  margin: 36px auto 0;
`;

const SubjectSection = () => {
  const navigate = useNavigate();

  const subjectList = [
    'Swimming',
    'Surfing',
    'Piano',
    'Voice Acting',
    'Cello',
    'American Sign Language',
    'Drums',
    'Guitar',
    'Skateboarding',
    'Painting',
    'Singing',
    '...and 100s more',
  ];

  const handleClick = () => {
    navigate('/join');
  };

  return (
    <StandardContainer>
      <SubjectsSectionContainer>
        <SubjectsSectionTitle variant="h3">
          Activities you can teach
        </SubjectsSectionTitle>
        <SubjectsListContainer>
          {subjectList.map(subject => {
            return (
              <SubjectListItem key={subject} variant="h5">
                {subject}
              </SubjectListItem>
            );
          })}
        </SubjectsListContainer>
        <SubjectSectionDetails variant="h5">
          Teach your clients locally or online. With 100s of topics available,
          the possibilities are endless.
        </SubjectSectionDetails>
        <ButtonContainer>
          <CustomButton buttonText="Get Started" onClick={handleClick} />
        </ButtonContainer>
      </SubjectsSectionContainer>
    </StandardContainer>
  );
};

export default SubjectSection;
