import { Check } from '@mui/icons-material';
import { Card, CircularProgress, LinearProgress } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomButton } from 'components/shared';
import SignupContext from 'contexts/SignupContext';
import UserContext from 'contexts/UserContext';
import {
  validateAcknowledgePolicies,
  validateLandingPageDetails,
  validatePersonalInfo,
  validateTravelOptions,
} from './SignupValidation';
import {
  AcknowledgePolicies,
  ActivityAndDescription,
  Availability,
  LandingPageDetails,
  LocationAndTravel,
  OrganizationInfo,
  PaymentInfo,
  PersonalInfo,
  ProfileImage,
  Rates,
  ReviewAndConfirm,
  WelcomeMessage,
} from './Steps';

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0 auto;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SingleButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 16px;
  overflow: scroll;
  padding: 16px;
  width: 100%;
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 600px;
  margin: 32px auto;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  padding: 16px;
  width: 100%;
`;

const SignupProcess = () => {
  const {
    activeStep,
    applicationData,
    backToReview,
    finishDisabled,
    jumpToReview,
    handleComplete,
    handleStepChange,
    updateStepValidation,
  } = useContext(SignupContext);
  const { currentUser } = useContext(UserContext);

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const progress = useMemo(() => {
    return Math.round((activeStep / 11) * 100);
  }, [activeStep]);

  const finalizeApplication = () => {
    if (currentUser) {
      setSubmitting(true);
      setTimeout(() => {
        handleComplete(currentUser);
        localStorage.setItem('appMode', 'instructor');
        navigate('/settings?t=organization');
      }, 5000);
    }
  };

  const validateAndAdvance = (currentStep: number) => {
    let isValid = false;

    switch (currentStep) {
      case 1:
        if (currentUser === null) return;
        isValid = validatePersonalInfo(applicationData.application.instructor);
        break;
      case 2:
        if (currentUser === null) return;
        isValid = !!currentUser.user?.imagePath;
        break;
      case 3:
        isValid = !!applicationData.application.organization.orgName;
        break;
      case 4:
        isValid =
          !!applicationData.application.activity.details &&
          !!applicationData.application.activity.activity;
        break;
      case 5:
        isValid = applicationData.application.activity.timeOfDay.length > 0;
        break;
      case 6:
        isValid = applicationData.application.activity.rates.length > 0;
        break;
      case 7:
        isValid = validateTravelOptions(
          applicationData.application.organization
        );
        break;
      case 8:
        isValid = validateLandingPageDetails(
          applicationData.application.organization
        );
        break;
      case 9:
        isValid = true;
        break;
      case 10:
        if (currentUser === null) return;
        isValid = validateAcknowledgePolicies(
          applicationData.application.instructor
        );
        break;
      default:
        isValid = true;
    }

    if (isValid) {
      const stepKeys = Object.keys(
        applicationData.application.stepValidation ?? {}
      );
      const stepToUpdate = stepKeys[currentStep];
      if (stepToUpdate) {
        updateStepValidation(stepToUpdate, true);
      }
    }
    jumpToReview ? backToReview() : handleStepChange(currentStep + 1);
  };

  const RenderSteps = () => {
    switch (activeStep) {
      case 0:
        return <WelcomeMessage />;
      case 1:
        return <PersonalInfo />;
      case 2:
        return <ProfileImage />;
      case 3:
        return <OrganizationInfo />;
      case 4:
        return <ActivityAndDescription />;
      case 5:
        return <Availability />;
      case 6:
        return <Rates />;
      case 7:
        return <LocationAndTravel />;
      case 8:
        return <LandingPageDetails />;
      case 9:
        return <PaymentInfo />;
      case 10:
        return <AcknowledgePolicies />;
      case 11:
        return <ReviewAndConfirm />;
      default:
        return null;
    }
  };

  return (
    <StyledCard>
      <LinearProgress variant="determinate" value={progress} />
      <ContentWrapper>
        <MainContainer>
          <StepContainer>
            <StepContent>{RenderSteps()}</StepContent>
          </StepContainer>
        </MainContainer>
      </ContentWrapper>
      {jumpToReview ? (
        <SingleButtonContainer>
          <CustomButton
            buttonText="Back to Review"
            onClick={() => validateAndAdvance(activeStep)}
            variant="outlined"
          />
        </SingleButtonContainer>
      ) : (
        <NavigationContainer>
          <CustomButton
            buttonText="Back"
            disabled={activeStep === 0}
            onClick={() => handleStepChange(activeStep - 1)}
            variant="outlined"
          />
          {activeStep === 11 ? (
            <CustomButton
              buttonText={
                !submitting ? <Check /> : <CircularProgress size={25} />
              }
              disabled={finishDisabled}
              onClick={finalizeApplication}
              variant="contained"
              color="success"
            />
          ) : (
            <CustomButton
              buttonText="Next"
              onClick={() => validateAndAdvance(activeStep)}
              variant="contained"
            />
          )}
        </NavigationContainer>
      )}
    </StyledCard>
  );
};

export default SignupProcess;
