import { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

import { StandardContainer } from 'assets/styles/shared';

interface iCard {
  content: string;
  icon: JSX.Element;
  headline: JSX.Element;
}

interface WhyUseProps {
  cards: iCard[];
  title: JSX.Element;
}

const WhyUseSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
`;

const WhyUseTitle = styled(Typography)`
  margin: 0 auto 40px;
`;

const WhyUseCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const WhyUseCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  margin: 0 auto;
  padding: 24px 16px;
`;

const IconTitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const IconContainer = styled.div`
  color: #58abff;
  font-size: 1.5em;
  padding-right: 8px;
  width: fit-content;
`;

const CardTitle = styled(Typography)`
  color: #58abff;
  width: fit-content;
`;

const CardContent = styled(Typography)`
  flex: 1;
`;

const WhyUse: FC<WhyUseProps> = ({ cards, title }) => {
  return (
    <StandardContainer>
      <WhyUseSectionContainer>
        <WhyUseTitle variant="h3">{title}</WhyUseTitle>
        <WhyUseCardContainer>
          {cards.map(({ content, icon, headline }) => {
            return (
              <WhyUseCard key={content.length}>
                <IconTitleContainer>
                  <IconContainer>{icon}</IconContainer>
                  <CardTitle variant="h5">{headline}</CardTitle>
                </IconTitleContainer>
                <CardContent>{content}</CardContent>
              </WhyUseCard>
            );
          })}
        </WhyUseCardContainer>
      </WhyUseSectionContainer>
    </StandardContainer>
  );
};

export default WhyUse;
