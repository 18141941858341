import UserContext from 'contexts/UserContext';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }: any) => {
  const location = useLocation();
  const { currentUser } = useContext(UserContext);

  return currentUser ? (
    children
  ) : (
    <Navigate to={'/login'} replace state={{ path: location.pathname }} />
  );
};

export default RequireAuth;
