import { Add, ArrowBack, Remove } from '@mui/icons-material';
import { Card, SelectChangeEvent } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Family,
  initialFamilyData,
  saveFamilyDocument,
} from 'api/family.utils';
import {
  Student,
  StudentStatus,
  StudentType,
  initialStudentData,
  saveStudentDocument,
} from 'api/student.utils';
import {
  CustomButton,
  CustomDivider,
  CustomToggleButtonGroup,
  InputFields,
} from 'components/shared';
import { PlaceType } from 'components/shared/InputFields/AddressSearch';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';
import FamilyDetails from './FamilyDetails';
import LessonDefaults from './LessonDefaults';

interface AddStudentProps {
  toggleAddStudent: () => void;
}

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem 0;
  width: 100%;
  max-width: 800px;
`;

const AddStudentFormContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: 800px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
  overflow: scroll;
  padding: 1rem;
`;

const AddStudent: FC<AddStudentProps> = ({ toggleAddStudent }) => {
  const { userActivities, setUserStudents, setUserFamilies } =
    useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { LabeledCheckbox, TextArea, TextInput } = InputFields;

  const [newFamily, setNewFamily] = useState<Family>(initialFamilyData);
  const [familyType, setFamilyType] = useState<'new' | 'existing'>('new');
  const [formErrors, setFormErrors] = useState<Partial<Student>>({});
  const [newStudent, setNewStudent] = useState(initialStudentData);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [useStudentInfo, setUseStudentInfo] = useState<boolean>(false);

  const handleAddressChange = (address: PlaceType | null) => {
    if (address) {
      setNewFamily(prev => ({
        ...prev,
        address: address.description,
        placeId: address.place_id,
      }));
    }
  };

  const handleAddStudent = () => {
    if (!currentUser) return;

    if (familyType === 'existing') {
      saveStudentDocument(currentUser, newStudent).then(res => {
        const { docId, success } = res;
        if (!success) return;

        if (docId) {
          setUserStudents(prev => [
            ...prev,
            { id: res.docId || '', student: newStudent },
          ]);
          toggleAddStudent();
        }
      });
    } else {
      saveFamilyDocument(currentUser, newFamily, newStudent.familyId).then(
        res => {
          if (!res.success) return;

          if (res.docId) {
            const newStudentData = {
              ...newStudent,
              familyId: res.docId,
            };

            setUserFamilies(prev => [
              ...prev,
              { id: res.docId || '', family: newFamily },
            ]);

            saveStudentDocument(currentUser, newStudentData).then(res => {
              const { docId, success } = res;
              if (!success) return;

              if (docId) {
                setUserStudents(prev => [
                  ...prev,
                  { id: res.docId || '', student: newStudentData },
                ]);
                toggleAddStudent();
              }
            });
          }
        }
      );
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    setNewStudent(prev => ({ ...prev, dob: date }));
  };

  const handleDropDownChange = (
    event: SelectChangeEvent<string | number | readonly string[] | undefined>
  ) => {
    const { name, value } = event.target;
    setNewStudent(prev => ({ ...prev, [name]: value }));
  };

  const handleFamilyTypeChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: 'new' | 'existing'
  ) => {
    if (value === null) return;

    if (value === 'new') {
      setNewFamily(initialFamilyData);
      setNewStudent(prev => ({ ...prev, familyId: '' }));
      setUseStudentInfo(false);
    }

    setFamilyType(value);
  };

  const handleFamilyInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setNewFamily(prev => ({ ...prev, [id]: value }));
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;

    setFormErrors(prev => ({ ...prev, [id]: '' }));
    setNewStudent(prev => ({ ...prev, [id]: value }));
  };

  const handleSelectFamily = (id: string) => {
    setNewStudent(prev => ({ ...prev, familyId: id }));
  };

  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: StudentStatus
  ) => {
    setNewStudent(prev => ({ ...prev, status: value }));
  };

  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: StudentType
  ) => {
    setNewStudent(prev => ({ ...prev, type: value }));
  };

  const toggleUseStudentInfo = () => {
    setUseStudentInfo(prev => !prev);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!newStudent.email) return;

    if (!emailRegex.test(newStudent.email)) {
      setFormErrors(prev => ({ ...prev, email: 'Invalid email address' }));
    }
  };

  useEffect(() => {
    if (familyType === 'new' && useStudentInfo) {
      setNewFamily(prev => ({
        ...prev,
        familyName: newStudent.lastName,
        firstName: newStudent.firstName,
        lastName: newStudent.lastName,
        email: newStudent.email,
        phone: newStudent.phone,
      }));
    }
  }, [familyType, newStudent, useStudentInfo]);

  useEffect(() => {
    if (!useStudentInfo) {
      setNewFamily(initialFamilyData);
    }
  }, [useStudentInfo]);

  return (
    <StyledCard>
      <ActionBar>
        <CustomButton
          buttonText={'Go back to students'}
          onClick={toggleAddStudent}
          startIcon={<ArrowBack />}
          variant="text"
        />
      </ActionBar>
      <AddStudentFormContainer variant="outlined">
        <CustomToggleButtonGroup
          buttons={[
            { color: 'primary', name: 'Active', value: StudentStatus.ACTIVE },
            { color: 'secondary', name: 'Lead', value: StudentStatus.LEAD },
            {
              color: 'standard',
              name: 'Inactive',
              value: StudentStatus.INACTIVE,
            },
          ]}
          onChange={handleStatusChange}
          value={newStudent.status}
        />
        <CustomDivider text="Student Details" margin="large" />
        <CustomToggleButtonGroup
          buttons={[
            { name: 'Child', value: StudentType.CHILD },
            { name: 'Adult', value: StudentType.ADULT },
          ]}
          onChange={handleTypeChange}
          value={newStudent.type}
        />
        <InputContainer>
          <TextInput
            fullWidth
            id="firstName"
            label="First Name"
            onChange={handleInputChange}
            value={newStudent.firstName}
          />
          <TextInput
            fullWidth
            id="lastName"
            label="Last Name"
            onChange={handleInputChange}
            value={newStudent.lastName}
          />
        </InputContainer>
        <CustomButton
          buttonText={
            showOptionalFields ? 'Hide optional fields' : 'Show optional fields'
          }
          startIcon={showOptionalFields ? <Remove /> : <Add />}
          variant="text"
          onClick={() => setShowOptionalFields(prev => !prev)}
        />
        {showOptionalFields && (
          <>
            <InputContainer>
              <TextInput
                error={formErrors.email}
                fullWidth
                helperText={formErrors.email || ''}
                id="email"
                label="Email"
                onChange={handleInputChange}
                onBlur={validateEmail}
                value={newStudent.email}
              />
              <TextInput
                fullWidth
                id="phone"
                label="Phone"
                onChange={handleInputChange}
                value={newStudent.phone}
              />
            </InputContainer>
            <InputContainer>
              <DateField
                fullWidth
                id="dob"
                label="Date of Birth"
                onChange={handleDateChange}
                value={newStudent.dob}
              />
              <TextInput
                fullWidth
                id="gender"
                label="Gender"
                onChange={handleInputChange}
                value={newStudent.gender}
              />
            </InputContainer>
          </>
        )}
        <CustomDivider text="Family Details" margin="large" />
        <CustomToggleButtonGroup
          buttons={[
            { name: 'New Family', value: 'new' },
            { name: 'Existing Family', value: 'existing' },
          ]}
          onChange={handleFamilyTypeChange}
          value={familyType}
        />
        {familyType === 'new' && newStudent.type === StudentType.ADULT && (
          <LabeledCheckbox
            label="Use Student Info"
            onChange={toggleUseStudentInfo}
            checked={useStudentInfo}
          />
        )}
        <FamilyDetails
          family={newFamily}
          familyType={familyType}
          handleAddressChange={handleAddressChange}
          onChange={handleFamilyInput}
          selectFamily={handleSelectFamily}
          student={newStudent}
        />
        <CustomDivider text="Lesson Default Details" margin="large" />
        <LessonDefaults
          activities={userActivities}
          dropDownChange={handleDropDownChange}
          inputChange={handleInputChange}
          student={newStudent}
        />
        <CustomDivider text="Notes" margin="large" />
        <TextArea
          fullWidth
          id="notes"
          label="Notes"
          onChange={handleInputChange}
          rows={5}
          value={newStudent.notes}
        />
        <ButtonContainer>
          <CustomButton
            buttonText="Cancel"
            onClick={toggleAddStudent}
            variant="text"
          />
          <CustomButton
            buttonText="Add Student"
            onClick={handleAddStudent}
            variant="contained"
          />
        </ButtonContainer>
      </AddStudentFormContainer>
    </StyledCard>
  );
};

export default AddStudent;
