import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { RequestStatus } from 'api/lesson.utils';
import { FC, ReactElement, useState } from 'react';

interface DropDownProps {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
  input?: ReactElement<any, any> | undefined;
  label?: string;
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  options?: { name: string; value: string | number | RequestStatus }[];
  placeholder?: string;
  staticOption?: { name: string; value: string | number };
  value?: string;
  variant?: 'outlined' | 'standard' | 'filled';
}

const DropDown: FC<DropDownProps> = ({
  disabled = false,
  error,
  helperText,
  id,
  input,
  label,
  onChange,
  options,
  placeholder,
  staticOption,
  value,
  variant,
}) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const handleSelectToggle = () => {
    setSelectOpen(currentState => !currentState);
  };
  return (
    <FormControl disabled={disabled} fullWidth error={error}>
      <InputLabel id={id} error={error}>
        {label}
      </InputLabel>
      <Select
        error={error}
        id={id}
        input={input}
        label={label}
        labelId={label}
        name={id}
        onClose={handleSelectToggle}
        onOpen={handleSelectToggle}
        open={selectOpen}
        placeholder={placeholder}
        variant={variant}
        value={value}
        onChange={onChange}
      >
        {staticOption && (
          <MenuItem key={staticOption.value} value={staticOption.value}>
            {staticOption.name}
          </MenuItem>
        )}
        {options &&
          options.map(o => (
            <MenuItem key={o.value} value={o.value}>
              {o.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default DropDown;
