import { SelectChangeEvent } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { RequestStatus } from 'api/lesson.utils';
import ConversationListItem from 'components/Messages/ConversationListItem';
import { InputFields, Layouts } from 'components/shared';
import AppContext from 'contexts/AppContext';
import DataContext, { initialRequestData } from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

const ConversationList = () => {
  const { appMode } = useContext(AppContext);
  const { currentUser } = useContext(UserContext);
  const { incomingRequests, outgoingRequests, setSelectedRequest } =
    useContext(DataContext);

  const { DropDown } = InputFields;
  const { CardListLayout } = Layouts;

  const [params] = useSearchParams();
  const m = params.get('m');

  const [selectedFilter, setSelectedFilter] = useState('all');

  const allRequests =
    appMode === 'instructor' ? incomingRequests : outgoingRequests;

  const sortedRequests = allRequests.sort((a, b) => {
    const aDate = a.request.timestamp as Timestamp;
    const bDate = b.request.timestamp as Timestamp;

    if (!aDate || !bDate) return 0;

    return bDate.toMillis() - aDate.toMillis();
  });

  //TODO: add filters to conversation view
  const filterItems = [
    {
      name: 'All open conversations',
      value: 'all',
    },
    {
      name: 'Only unread conversations',
      value: 'open',
    },
    {
      name: 'Archived conversations',
      value: 'archived',
    },
  ];

  const handleSelectedFilterChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectedFilter(value);
  };

  const handleOpenConversation = (id: string) => {
    const conversationToOpen =
      sortedRequests.find(request => request.id === id) || initialRequestData;
    setSelectedRequest(conversationToOpen);
  };

  const filters = (
    <DropDown
      onChange={handleSelectedFilterChange}
      options={filterItems}
      value={selectedFilter}
    />
  );

  const cardList =
    sortedRequests &&
    sortedRequests.map(({ id, request }) => {
      const {
        activity,
        instructor,
        latestMessage,
        nextSession,
        requester,
        requestStatus,
      } = request;
      const displayName =
        currentUser && currentUser.id === instructor.id
          ? requester.name
          : instructor.name;
      return (
        <ConversationListItem
          key={id}
          activity={`${activity.name}`}
          handleCardClick={() => handleOpenConversation(id)}
          id={id}
          latestMessage={latestMessage ? latestMessage : null}
          name={displayName}
          nextSession={nextSession ? nextSession : null}
          requestStatus={requestStatus ? requestStatus : RequestStatus.NEW}
        />
      );
    });

  useEffect(() => {
    if (!sortedRequests.length) return;

    const conversationToOpen = m
      ? sortedRequests.find(request => request.id === m)
      : sortedRequests[0];

    setSelectedRequest(conversationToOpen || sortedRequests[0]);
  }, [sortedRequests, m, setSelectedRequest]);

  return (
    <CardListLayout
      alert={sortedRequests.length === 0}
      alertData={{
        message:
          'Once you make a request with an instructor, a conversation is opened and will appear in this list.',
        severity: 'info',
        title: 'Nothing to see here',
      }}
      filters={filters}
      cardList={cardList}
    />
  );
};

export default ConversationList;
