import { useState } from 'react';

import { Layouts } from 'components/shared';
import FamilyTable from 'components/Families/FamilyTable';
import { useNavigate } from 'react-router-dom';
import AddFamily from 'components/Families/AddFamily';

const Families = () => {
  const navigate = useNavigate();

  const { MainLayout } = Layouts;

  const [addNewFamily, setAddNewFamily] = useState(false);

  const toggleAddFamily = () => {
    setAddNewFamily(prev => !prev);
  };

  const handleViewFamily = (id: string) => {
    navigate(`/families/details?f=${id}`);
  };

  const renderFamiliesContent = () => {
    if (addNewFamily) {
      return <AddFamily toggleAddFamily={toggleAddFamily} />;
    }

    return (
      <FamilyTable
        handleViewFamily={handleViewFamily}
        toggleAddFamily={toggleAddFamily}
      />
    );
  };

  return <MainLayout content={renderFamiliesContent()} headerText="Families" />;
};

export default Families;
