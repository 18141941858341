import { BusinessCenter, ContentPaste, TouchApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { homepageHeroImg } from 'assets/images';
import { CallToAction, Hero, PhotoInfo, ThreeTile } from 'components/shared';

const HomePage = () => {
  const navigate = useNavigate();

  const howItWorksCards = [
    {
      details: `Allows us to help operate your Instructor business with tools that make sense`,
      icon: <ContentPaste sx={{ fontSize: 40 }} />,
      title: 'Focus on Instructing',
    },
    {
      details: `Instrekt developed tools, features and services to make your Instructor life easier.`,
      icon: <BusinessCenter sx={{ fontSize: 40 }} />,
      title: 'Manage your business',
    },
    {
      details: `Advertise or give your existing clients a customized link or use of a QR code to direct them to your versatile Instrekt homepage.`,
      icon: <TouchApp sx={{ fontSize: 40 }} />,
      title: 'Leverage technology',
    },
  ];
  const howItWorksTitle = (
    <>
      How <strong>Instrekt</strong> works
    </>
  );
  const easyToUseDesc = `Running a successful instructor business has its challenges. Searching for the right tools is simple and fast with Instrekt. The Instrekt platform offers tools; CRM, messaging, payments, scheduling, homepage to advertise and much more. `;
  const easyToUseTileData = [
    'Save time',
    'Organized administration',
    'Seamless communication and payment options',
  ];
  const safetyDesc = `Our customers are the instructors. Our innovative tools and services were designed by instructors for instructors. We are always developing and improving upon the features and services that we offer. Supporting our instructors is at the top of our ethos. With professional and attentive support, our instructors are happy instructors. With secure messages, hassle free payments, management and organizational tools were designed to make life easier with less worry.`;
  const safetyTileData = [
    'Excellent instructor support',
    'Innovative business tools for instructors',
    'Hassle free, secure payments between the students and the instructors',
  ];

  return (
    <>
      <Hero
        image={homepageHeroImg}
        title="Be Smarter - Earn more, Work less"
        callToAction="Become an Instructor"
        handleClick={() => navigate('/instructors')}
      />
      <ThreeTile title={howItWorksTitle} cards={howItWorksCards} />
      <PhotoInfo
        desc={easyToUseDesc}
        imageUrl="https://source.unsplash.com/random/800x400"
        tileData={easyToUseTileData}
        title={'Becoming a successful instructor is a breeze with Instrekt'}
      />
      <CallToAction
        title="Become an Instructor Today!"
        content="Do you have skills that you would like to share with your community and make a living doing it? Sign up to become an instructor and start your own business on Instrekt."
        handleClick={() => navigate('/instructors')}
        buttonText="Learn More"
      />
      <PhotoInfo
        desc={safetyDesc}
        imageUrl="https://source.unsplash.com/random/800x400"
        tileData={safetyTileData}
        title={'Innovation and Service at Instrekt'}
        photoAlignment={'right'}
      />
    </>
  );
};

export default HomePage;
