import { Card, Typography } from '@mui/material';
import styled from 'styled-components';

const AdjustmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin: 0 32px 32px;
`;

const ClearAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 32px;
`;

const Discount = styled(Typography)`
  margin-left: 16px;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 32px 0 0;
  width: 100%;
`;

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
  width: 250px;
`;

const LessonInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 32px;
`;

const StepNavButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`;

const SummaryInfoContainer = styled.div`
  margin-bottom: 24px;
`;

const TotalSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const TrackPanel = styled(Card)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow: scroll;
  padding: 16px;
  width: 100%;
`;

export {
  AdjustmentsContainer,
  ButtonContainer,
  ClearAllContainer,
  Discount,
  FlexColumnContainer,
  FlexRowContainer,
  InputContainer,
  LessonInformationContainer,
  StepNavButtons,
  SummaryInfoContainer,
  TotalSummaryContainer,
  TrackPanel,
};
