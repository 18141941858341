import { DarkMode, LightMode, Logout, Person } from '@mui/icons-material';
import { Avatar, Toolbar } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from 'api/firebaseConfig';
import { CustomButton, Logo, TooltipIconButton } from 'components/shared';
import AppContext from 'contexts/AppContext';
import UserContext from 'contexts/UserContext';

import {
  HeaderContent,
  LoggedInHeaderContainer,
  SearchAndMenuContainer,
  SignInButtonAndToggleContainer,
  StyledAppBar,
} from './styles';

const Header: FC = () => {
  const { isDarkMode, toggleDarkMode } = useContext(AppContext);
  const { avatarUrl, currentUser } = useContext(UserContext);

  const navigate = useNavigate();

  const handleLogoutClick = useCallback(() => {
    auth.signOut();
  }, []);

  const switchText = useMemo(() => {
    return isDarkMode ? 'Switch to light mode' : 'Switch to dark mode';
  }, [isDarkMode]);

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <LoggedInHeaderContainer darkMode={isDarkMode}>
          <HeaderContent>
            <Logo height={24} />
            <SearchAndMenuContainer>
              <SignInButtonAndToggleContainer>
                {currentUser ? (
                  <TooltipIconButton
                    action={() => navigate('/dashboard')}
                    icon={
                      <Avatar sx={{ width: 32, height: 32 }}>
                        {avatarUrl ? (
                          <img src={avatarUrl} alt="avatar" width="32" />
                        ) : (
                          <Person />
                        )}
                      </Avatar>
                    }
                    tooltipText={'To the dashboard'}
                  />
                ) : (
                  <>
                    <CustomButton
                      buttonText={'Join now'}
                      onClick={() => navigate('/signup')}
                      padding={{ x: 32, y: 8 }}
                    />
                    <CustomButton
                      buttonText={'Log in'}
                      onClick={() => navigate('/login')}
                      padding={{ x: 32, y: 8 }}
                      variant="outlined"
                    />
                  </>
                )}
                <TooltipIconButton
                  action={toggleDarkMode}
                  icon={isDarkMode ? <LightMode /> : <DarkMode />}
                  tooltipText={switchText}
                />

                {currentUser && (
                  <TooltipIconButton
                    action={handleLogoutClick}
                    icon={<Logout />}
                    tooltipText={'Log out'}
                  />
                )}
              </SignInButtonAndToggleContainer>
            </SearchAndMenuContainer>
          </HeaderContent>
        </LoggedInHeaderContainer>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
