import { Close, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  IconButton,
  SelectChangeEvent,
  Slide,
  Snackbar,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import styled from 'styled-components';

import {
  Lesson,
  LessonData,
  LessonStatus,
  saveLessonDocument,
} from 'api/lesson.utils';
import { CustomButton, InputFields } from 'components/shared';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

interface LessonLogProps {
  lessons: LessonData[];
}

const ActionContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }: { maxWidth: string }) => maxWidth};
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LessonLog = ({ lessons }: LessonLogProps) => {
  const { setUserLessons } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { DropDown, TextArea } = InputFields;

  const [disabled, setDisabled] = useState({ notes: true, status: true });
  const [expanded, setExpanded] = useState<string | false>(false);
  const [newInstructorNotes, setNewInstructorNotes] = useState('');
  const [newStatus, setNewStatus] = useState<LessonStatus | null>(null);
  const [open, setOpen] = useState(false);

  const handleCloseSnack = () => {
    setOpen(false);
  };

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      // This timeout is here to wait for the animation to finish before clearing the input
      setTimeout(() => {
        handleReset();
      }, 100);
    };

  const handleNotesChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
    instructorNotes?: string
  ) => {
    const newNotes = event.target.value;
    setNewInstructorNotes(newNotes);
    setDisabled(prev => ({ ...prev, notes: newNotes === instructorNotes }));
  };

  const handleReset = () => {
    setNewInstructorNotes('');
    setNewStatus(null);
    setDisabled({ notes: true, status: true });
  };

  const handleUpdateLesson = (lesson: Lesson, id: string) => {
    saveLessonDocument(
      currentUser,
      { ...lesson, instructorNotes: newInstructorNotes },
      id
    ).then(res => {
      if (!res.success) {
        setOpen(true);
        return;
      }

      setUserLessons(prevLessons =>
        prevLessons.map(lesson =>
          lesson.id === id
            ? {
                ...lesson,
                lesson: {
                  ...lesson.lesson,
                  instructorNotes: newInstructorNotes,
                },
              }
            : lesson
        )
      );
    });
  };

  const handleStatusChange = (
    event: SelectChangeEvent,
    status: LessonStatus
  ) => {
    const selectedStatus = event.target.value as LessonStatus;
    setNewStatus(selectedStatus);
    setDisabled(prev => ({ ...prev, status: selectedStatus === status }));
  };

  const actionDropdownOptions = [
    { name: 'Completed', value: LessonStatus.COMPLETED },
    { name: 'No Show', value: LessonStatus.NOSHOW },
    { name: 'Canceled', value: LessonStatus.CANCELED },
    { name: 'Rescheduled', value: LessonStatus.RESCHEDULED },
    { name: 'Scheduled', value: LessonStatus.SCHEDULED },
  ];

  return (
    <Container maxWidth="800px">
      <Heading>
        <Typography variant="h4">Lesson Log</Typography>
      </Heading>
      <CustomButton
        buttonText={'Add new lesson'}
        onClick={() => console.log('Add new lesson')}
        variant="outlined"
      />
      {lessons.length === 0 ? (
        <Alert severity="info">No lessons found</Alert>
      ) : null}
      <div>
        {lessons.map(lesson => {
          const {
            id,
            lesson: { activity, date, familyNotes, instructorNotes, status },
          } = lesson;
          return (
            <Accordion
              expanded={expanded === id}
              elevation={4}
              key={id}
              onChange={handleExpand(id)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h5">
                  {date &&
                    date.format('MMMM D, YYYY [at] h:mm A') +
                      ' - ' +
                      activity.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <NotesContainer>
                  <ActionContainer>
                    <Typography sx={{ width: 100, margin: 'auto 0 auto 16px' }}>
                      Mark as:
                    </Typography>
                    <DropDown
                      id="lessonStatus"
                      label="Lesson Status"
                      options={actionDropdownOptions}
                      value={newStatus ? newStatus : status}
                      onChange={e => handleStatusChange(e, status)}
                    />
                  </ActionContainer>
                  {familyNotes && (
                    <Typography variant="body1">{familyNotes}</Typography>
                  )}
                  <TextArea
                    id="instructorNotes"
                    label="Instructor Notes"
                    value={
                      newInstructorNotes ? newInstructorNotes : instructorNotes
                    }
                    fullWidth
                    onChange={e => handleNotesChange(e, instructorNotes)}
                  />
                  <ButtonContainer>
                    <CustomButton
                      buttonText={'Reset'}
                      onClick={handleReset}
                      variant="text"
                    />
                    <CustomButton
                      buttonText={'Update'}
                      disabled={disabled.notes && disabled.status}
                      onClick={() => handleUpdateLesson(lesson.lesson, id)}
                      variant="contained"
                    />
                  </ButtonContainer>
                </NotesContainer>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message="Note failed to save. Please try again."
        TransitionComponent={Slide}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default LessonLog;
