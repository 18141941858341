import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { CustomButton, InputFields } from 'components/shared';
import { FC, useState } from 'react';
import styled from 'styled-components';

interface ActivityRequestDialogProps {
  open: boolean;
  toggleDialog: () => void;
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 600px;
    max-width: 100%;
    padding: 8px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ActivityRequestDialog: FC<ActivityRequestDialogProps> = ({
  open,
  toggleDialog,
}) => {
  const { TextInput } = InputFields;

  const [requestedActivity, setRequestedActivity] = useState('');

  const handleInputChange = (event: any) => {
    setRequestedActivity(event.target.value);
  };

  const handleRequest = () => {
    // TODO: configure @sendgrid/mail and firebase-functions
    // Send email to admin with requested activity using deployed firebase function
    console.log(requestedActivity);
    toggleDialog();
  };

  return (
    <StyledDialog onClose={toggleDialog} open={open}>
      <DialogTitle>Need an activity added to the platform?</DialogTitle>
      <StyledDialogContent>
        <DialogContentText>
          If you didn't see your activity category and subcategory, let us know
          what you need added to the list. We'll review your request and add it
          to the platform under the appropriate category and subcategory.
        </DialogContentText>
        <TextInput
          fullWidth
          label="Activity Name"
          onChange={handleInputChange}
          value={requestedActivity}
        />
      </StyledDialogContent>
      <DialogActions>
        <CustomButton
          buttonText="Cancel"
          onClick={toggleDialog}
          variant="text"
        />
        <CustomButton
          buttonText="Request"
          disabled={!requestedActivity}
          onClick={handleRequest}
        />
      </DialogActions>
    </StyledDialog>
  );
};

export default ActivityRequestDialog;
