import { FC, createContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { completeApplication, saveApplicationDocument } from 'api/signup.utils';
import { UserData } from './UserContext';

enum LessonTypes {
  PRIVATE = 'Private',
  SEMIPRIVATE = 'Semi-Private',
  DROPIN = 'Drop-In',
}

interface Rate {
  adjustedCost: number;
  cost: number;
  duration: 15 | 20 | 30 | 45 | 60;
  id: string;
  type: LessonTypes;
}

interface Activity {
  active: boolean;
  activity: string;
  activityId: string | null;
  archived: boolean;
  details: string;
  rates: Rate[];
  timeOfDay: string[];
}

export interface Instructor {
  acknowledgement: {
    terms: boolean;
    privacy: boolean;
  };
  email: string;
  firstName: string;
  lastName: string;
}

interface TravelOptions {
  toInstructor: boolean;
  toStudent: boolean;
  toThirdParty: boolean;
}

export interface Organization {
  active?: boolean;
  bio?: string;
  buttonText?: string;
  heroImage?: string;
  heroMainText?: string;
  heroSubText?: string;
  travelOptions?: TravelOptions;
  ownerId?: string;
  ownerName?: string;
  orgName?: string;
  philosophy?: string;
  slug?: string;
}

interface StepValidation {
  welcomeMessage: boolean;
  personalInfo: boolean;
  profileImage: boolean;
  organizationInfo: boolean;
  activityAndDescription: boolean;
  availability: boolean;
  locationAndTravel: boolean;
  rates: boolean;
  landingPageDetails: boolean;
  paymentInfo: boolean;
  acknowledgePolicies: boolean;
}

export interface Application {
  activity: Activity;
  instructor: Instructor;
  organization: Organization;
  stepValidation: StepValidation;
}

export interface ApplicationData {
  id: string;
  application: Application;
}

interface ISignupContext {
  activeStep: number;
  applicationData: ApplicationData;
  finishDisabled: boolean;
  jumpToReview: boolean;

  backToReview: () => void;
  handleComplete: (user: UserData) => Promise<void>;
  handleStepChange: (step: number, jumpToStep?: boolean) => void;
  saveApplication: () => Promise<void>;
  setApplicationData: React.Dispatch<React.SetStateAction<ApplicationData>>;
  setInitialInstructorData: (userData: UserData) => void;
  updateActivityData: (
    key: string,
    value: string | number | Rate | Rate[] | string[] | number[]
  ) => void;
  updateInstructorData: (
    key: string,
    value: string | boolean | { [x: string]: boolean }
  ) => void;
  updateOrganizationData: (
    key: string,
    value:
      | string
      | string[]
      | TravelOptions
      | {
          [x: string]: string | boolean;
        }
  ) => void;
  updateStepValidation: (step: string, value: boolean) => void;
}

const initialApplication = {
  id: '',
  application: {
    activity: {
      active: true,
      activity: '',
      activityId: null,
      archived: false,
      details: '',
      rates: [],
      timeOfDay: [],
    },
    instructor: {
      acknowledgement: {
        terms: false,
        privacy: false,
      },
      email: '',
      firstName: '',
      lastName: '',
    },
    organization: {
      active: true,
      bio: '',
      buttonText: '',
      heroImage: '',
      heroMainText: '',
      heroSubText: '',
      travelOptions: {
        toInstructor: false,
        toStudent: false,
        toThirdParty: false,
      },
      ownerId: '',
      ownerName: '',
      orgName: '',
      philosophy: '',
      slug: '',
    },
    stepValidation: {
      welcomeMessage: false,
      personalInfo: false,
      profileImage: false,
      organizationInfo: false,
      activityAndDescription: false,
      availability: false,
      locationAndTravel: false,
      rates: false,
      landingPageDetails: false,
      paymentInfo: false,
      acknowledgePolicies: false,
    },
  },
};

const SignupContext = createContext<ISignupContext>({
  activeStep: 0,
  applicationData: initialApplication,
  finishDisabled: false,
  jumpToReview: false,

  backToReview: () => {},
  handleComplete: async () => {},
  handleStepChange: () => {},
  saveApplication: async () => {},
  setApplicationData: () => {},
  setInitialInstructorData: () => {},
  updateActivityData: () => {},
  updateInstructorData: () => {},
  updateOrganizationData: () => {},
  updateStepValidation: () => {},
});

export default SignupContext;

export const SignupProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [applicationData, setApplicationData] =
    useState<ApplicationData>(initialApplication);
  const [jumpToReview, setJumpToReview] = useState(false);

  const finishDisabled = useMemo(() => {
    return applicationData.application.stepValidation &&
      Object.values(applicationData.application.stepValidation).every(
        value => value === true
      )
      ? false
      : true;
  }, [applicationData.application.stepValidation]);

  const backToReview = () => {
    setJumpToReview(false);
    handleStepChange(11);
  };

  const handleComplete = async (user: UserData) => {
    completeApplication(applicationData, user);
    navigate('/settings?t=organization');
  };

  const handleStepChange = (step: number, jumpToStep?: boolean) => {
    if (jumpToStep) setJumpToReview(true);
    setActiveStep(step);
  };
  const saveApplication = async () => {
    saveApplicationDocument(applicationData);
    navigate('/dashboard');
  };

  const setInitialInstructorData = (userData: UserData) => {
    const { user } = userData;
    setApplicationData(prev => ({
      ...prev,
      application: {
        ...prev.application,
        instructor: {
          ...prev.application.instructor,
          acknowledgement: {
            ...prev.application.instructor.acknowledgement,
          },
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      },
    }));
  };

  const updateActivityData = (
    key: string,
    value: string | number | Rate | Rate[] | string[] | number[]
  ) => {
    setApplicationData(prev => ({
      ...prev,
      application: {
        ...prev.application,
        activity: {
          ...prev.application.activity,
          [key]: value,
        },
      },
    }));
  };

  const updateInstructorData = (
    key: string,
    value: string | boolean | { [x: string]: boolean }
  ) => {
    setApplicationData(prev => ({
      ...prev,
      application: {
        ...prev.application,
        instructor: {
          ...prev.application.instructor,
          [key]: value,
        },
      },
    }));
  };

  const updateOrganizationData = (
    key: string,
    value: string | string[] | { [x: string]: boolean | string } | TravelOptions
  ) => {
    setApplicationData(prev => ({
      ...prev,
      application: {
        ...prev.application,
        organization: {
          ...prev.application.organization,
          [key]: value,
        },
      },
    }));
  };

  const updateStepValidation = (step: string, value: boolean) => {
    setApplicationData(prev => ({
      ...prev,
      application: {
        ...prev.application,
        stepValidation: {
          ...prev.application.stepValidation,
          [step]: value,
        },
      },
    }));
  };

  const value = {
    activeStep,
    applicationData,
    finishDisabled,
    jumpToReview,

    backToReview,
    handleComplete,
    handleStepChange,
    saveApplication,
    setApplicationData,
    setInitialInstructorData,
    updateActivityData,
    updateInstructorData,
    updateOrganizationData,
    updateStepValidation,
  };

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  );
};
