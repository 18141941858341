import { useContext, useEffect } from 'react';

import ActivityCard from './ActivityCard';
import { AddNewButton, CustomSwitch } from 'components/shared';
import DataContext, { Activity, ActivityData } from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';
import NoActivities from './NoActivities';

import { OpenRequestsContainer, ShowArchivedCheckbox } from './styles';

const ActivityList = () => {
  const {
    addNewActivity,
    toggleArchiveActivity,
    editActivity,
    selectedActivity,
    setActivityData,
    setSelectedActivity,
    setShowArchived,
    showArchived,
    userActivities,
  } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const handleShowArchivedSwitch = () => {
    if (selectedActivity) {
      const selectedActivityData = userActivities.find(
        activity => activity.id === selectedActivity
      );
      if (selectedActivityData && selectedActivityData.activity.archived) {
        editActivity(nonArchivedActivities[0].id);
      }
    }

    setShowArchived(prev => !prev);
  };

  const determineStatus = (activity: Activity) => {
    if (activity.active) return 'active';
    if (activity.archived) return 'archived';
    return 'draft';
  };

  const renderActivityCards = (activityList: ActivityData[]) =>
    activityList.map(item => {
      const { activity, id } = item;
      const status = determineStatus(activity);

      const orgId = currentUser?.user.orgId;

      return (
        <ActivityCard
          key={id}
          activityId={id}
          archiveClick={() => orgId && toggleArchiveActivity(orgId, item)}
          direction="vertical"
          editClick={() => editActivity(id)}
          restoreClick={() => orgId && toggleArchiveActivity(orgId, item)}
          status={status}
          title={activity.activity}
        />
      );
    });

  const nonArchivedActivities = userActivities
    .filter(({ activity }) => !activity.archived)
    .sort((a, b) => {
      const { active: activeA, activity: activityA } = a.activity;
      const { active: activeB, activity: activityB } = b.activity;

      if (activeA && !activeB) return -1;
      if (!activeA && activeB) return 1;
      if (activityA < activityB) return -1;
      if (activityA > activityB) return 1;
      return 0;
    });

  const archivedActivities = userActivities.filter(
    ({ activity }) => activity.archived
  );

  useEffect(() => {
    if (!userActivities.length) return;

    if (!selectedActivity) {
      setActivityData(nonArchivedActivities[0]);
      setSelectedActivity(nonArchivedActivities[0].id);
    }
    if (selectedActivity) {
      const foundActivity = userActivities.find(
        act => act.id === selectedActivity
      );
      if (foundActivity) {
        setActivityData(foundActivity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActivities]);

  return (
    <OpenRequestsContainer>
      <ShowArchivedCheckbox>
        <CustomSwitch
          checked={showArchived}
          id="showArchived"
          mainLabel="Show Archived"
          onChange={handleShowArchivedSwitch}
        />
      </ShowArchivedCheckbox>
      {userActivities.length === 0 && <NoActivities />}
      {renderActivityCards(nonArchivedActivities)}
      {showArchived && renderActivityCards(archivedActivities)}
      <AddNewButton onClick={addNewActivity} />
    </OpenRequestsContainer>
  );
};

export default ActivityList;
