import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

interface CustomToggleButtonGroupProps {
  ariaLabel?: string;
  buttons?: {
    name: string;
    value: string | number;
    color?:
      | 'primary'
      | 'standard'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  }[];
  color?:
    | 'primary'
    | 'standard'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  disabled?: boolean;
  exclusive?: boolean;
  fullWidth?: boolean;
  numberOfButtons?: number;
  onChange?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void;
  value: string | number | null;
  width?: string;
}

interface ToggleButtonProps {
  num?: number;
}

const ButtonGroupWrapper = styled(({ width, ...otherProps }) => (
  <div {...otherProps} />
))`
  width: ${({ width }) => (width ? `${width}px` : `100%`)};
  margin-bottom: 8px;
`;

const StyledToggleButton = styled(ToggleButton)<ToggleButtonProps>`
  width: ${({ num }) => (num ? `calc(100%/${num})` : '100%')};
`;

const CustomToggleButtonGroup: FC<CustomToggleButtonGroupProps> = ({
  ariaLabel,
  buttons,
  color = 'primary',
  disabled = false,
  exclusive = true,
  fullWidth = true,
  numberOfButtons,
  onChange,
  value,
  width,
}) => {
  return (
    <ButtonGroupWrapper width={width}>
      <ToggleButtonGroup
        aria-label={ariaLabel}
        color={color}
        disabled={disabled}
        exclusive={exclusive}
        fullWidth={fullWidth}
        onChange={onChange}
        value={value}
      >
        {buttons?.map(button => {
          const { name, value, color = 'primary' } = button;
          return (
            <StyledToggleButton
              color={color}
              key={value}
              num={numberOfButtons}
              value={value}
            >
              {name}
            </StyledToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </ButtonGroupWrapper>
  );
};

export default CustomToggleButtonGroup;
