import { Close } from '@mui/icons-material';
import {
  Card,
  IconButton,
  Link,
  Paper,
  Slide,
  Snackbar,
  Table,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { FC, Fragment, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Family } from 'api/family.utils';
import { CustomButton } from 'components/shared';
import DataContext from 'contexts/DataContext';
import FamilyTableBody from './FamilyTableBody';
import FamilyTableHead from './FamilyTableHead';

type Order = 'asc' | 'desc';

interface IFamilyTable {
  handleViewFamily: (id: string) => void;
  toggleAddFamily: () => void;
}

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const TypeFilter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 16px;
`;

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: {
    id: string;
    family: { [key in Key]: number | string | null };
  },
  b: {
    id: string;
    family: { [key in Key]: number | string | null };
  }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a.family, b.family, orderBy)
    : (a, b) => -descendingComparator(a.family, b.family, orderBy);
}

const stableSort = <T,>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedArray = array.map((el, index) => [el, index] as [T, number]);
  stabilizedArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });
  return stabilizedArray.map(el => el[0]);
};

const StudentTable: FC<IFamilyTable> = ({
  handleViewFamily,
  toggleAddFamily,
}) => {
  const { userFamilies, userStudents } = useContext(DataContext);

  const [activeFilter, setActiveFilter] = useState<string>('active');
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Family>('familyName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) =>
    setPage(newPage);

  const handleCopyEmail = (email: string) => {
    navigator.clipboard.writeText(email);
    setOpen(true);
  };

  const handleCloseCopySnack = () => setOpen(false);

  const handleFilterChange = (status: string) => setActiveFilter(status);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Family
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderFilterLinks = () => {
    const filters = ['all', 'active', 'lead', 'inactive', 'no students'];
    const filterCounts = filters.reduce((acc, filter) => {
      if (filter === 'all') {
        acc[filter] = userFamilies.length;
      } else if (filter === 'active') {
        acc[filter] = userFamilies.filter(family =>
          userStudents.some(
            student =>
              student.student.familyId === family.id &&
              student.student.status === 'active'
          )
        ).length;
      } else if (filter === 'lead') {
        acc[filter] = userFamilies.filter(family =>
          userStudents.some(
            student =>
              student.student.familyId === family.id &&
              student.student.status === 'lead'
          )
        ).length;
      } else if (filter === 'inactive') {
        acc[filter] = userFamilies.filter(family =>
          userStudents.some(
            student =>
              student.student.familyId === family.id &&
              student.student.status === 'inactive'
          )
        ).length;
      } else if (filter === 'no students') {
        acc[filter] = userFamilies.filter(
          family =>
            !userStudents.some(
              student => student.student.familyId === family.id
            )
        ).length;
      }
      return acc;
    }, {} as Record<string, number>);

    return (
      <TypeFilter>
        {filters.map((filter, index) => {
          const count = filterCounts[filter];
          if (count === 0) return null;
          return (
            <Fragment key={filter}>
              {index > 0 && '|'}
              <Link
                component={'button'}
                onClick={() => handleFilterChange(filter)}
                style={{
                  fontWeight: activeFilter === filter ? 'bold' : 'normal',
                }}
              >
                {`${
                  filter.charAt(0).toUpperCase() + filter.slice(1)
                } (${count})`}
              </Link>
            </Fragment>
          );
        })}
      </TypeFilter>
    );
  };

  const filteredFamilies = useMemo(() => {
    if (activeFilter === 'all') {
      return userFamilies;
    } else if (activeFilter === 'no students') {
      return userFamilies.filter(
        family =>
          !userStudents.some(student => student.student.familyId === family.id)
      );
    } else {
      return userFamilies.filter(family =>
        userStudents.some(
          student =>
            student.student.familyId === family.id &&
            student.student.status === activeFilter
        )
      );
    }
  }, [activeFilter, userFamilies, userStudents]);

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredFamilies.length)
      : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(filteredFamilies, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredFamilies, order, orderBy, page, rowsPerPage]
  );

  return (
    <StyledCard>
      <ActionBar>
        <CustomButton
          buttonText={'Add New Family'}
          onClick={toggleAddFamily}
          variant="contained"
        />
      </ActionBar>
      {renderFilterLinks()}
      <TableContainer component={Paper}>
        <Table aria-label="families table">
          <FamilyTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <FamilyTableBody
            emptyRows={emptyRows}
            students={userStudents}
            families={visibleRows}
            handleCopyEmail={handleCopyEmail}
            handleViewFamily={handleViewFamily}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredFamilies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseCopySnack}
        message="Email copied to clipboard"
        TransitionComponent={Slide}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseCopySnack}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </StyledCard>
  );
};

export default StudentTable;
