import { Skeleton, Slider } from '@mui/material';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from 'firebase/storage';
import React, { FC, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';

import { storage } from 'api/firebaseConfig';
import { CustomButton } from 'components/shared';

interface ImageUploaderProps {
  currentImageUrl?: string;
  handleImageChange?: (imagePath: string) => void;
  height: number;
  imageName: string;
  storageRefLocation: string;
  width: number;
}

interface ImageUploaderState {
  image: File | null;
  uploading: boolean;
  scale: number | number[];
}

const ImageUploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledSlider = styled(Slider)`
  width: 250px;
  -webkit-appearance: slider-vertical;
`;

const ImageField: FC<ImageUploaderProps> = ({
  currentImageUrl,
  handleImageChange,
  height,
  imageName,
  storageRefLocation,
  width,
}) => {
  const [image, setImage] = useState<ImageUploaderState['image']>(null);
  const [uploading, setUploading] =
    useState<ImageUploaderState['uploading']>(false);
  const [scale, setScale] = useState<ImageUploaderState['scale']>(1);

  const editorRef = React.createRef<AvatarEditor>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImage(event.target.files && event.target.files[0]);
  };

  const handleScaleChange = (event: Event, newValue: number | number[]) => {
    setScale(newValue);
  };

  const handleUpload = () => {
    if (image) {
      setUploading(true);
      const canvas = editorRef.current?.getImageScaledToCanvas();
      if (!canvas) return;
      canvas.toBlob(blob => {
        if (blob) {
          const imageRef = storageRef(
            storage,
            `${storageRefLocation}/${imageName}.jpg`
          );

          uploadBytes(imageRef, blob)
            .then(() => {
              getDownloadURL(imageRef)
                .then((url: string) => {
                  setUploading(false);
                  setImage(null);
                  handleImageChange &&
                    handleImageChange(`${storageRefLocation}/${imageName}.jpg`);
                })
                .catch(error => {
                  console.error('Error getting download URL: ', error);
                  setUploading(false);
                });
            })
            .catch(error => {
              console.error('Error uploading image: ', error);
              setUploading(false);
            });
        }
      }, 'image/jpeg');
    }
    return;
  };

  return (
    <ImageUploaderContainer>
      <StyledInput
        id="profile-image-upload-button"
        onChange={handleFileChange}
        type="file"
      />
      <CustomButton
        buttonText="Browse..."
        htmlFor="profile-image-upload-button"
        variant="outlined"
      />
      {image ? (
        <>
          <AvatarEditor
            ref={editorRef}
            image={image}
            width={width}
            height={height}
            border={0}
            scale={typeof scale === 'number' ? scale : 1}
          />
          <StyledSlider
            value={typeof scale === 'number' ? scale : 1}
            onChange={handleScaleChange}
            aria-labelledby="profile-image"
            min={1}
            max={2}
            step={0.01}
          />
          <CustomButton
            buttonText={uploading ? 'Uploading...' : 'Upload Image'}
            disabled={uploading}
            onClick={handleUpload}
            variant="contained"
          />
        </>
      ) : (
        <div>
          {currentImageUrl ? (
            <img src={currentImageUrl} alt="hero" />
          ) : (
            <Skeleton
              height={400}
              width={595}
              variant="rectangular"
              animation={false}
            />
          )}
        </div>
      )}
    </ImageUploaderContainer>
  );
};

export default ImageField;
