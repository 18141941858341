import { Typography } from '@mui/material';
import styled from 'styled-components';

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WelcomeMessage = () => {
  return (
    <WelcomeContainer>
      <Typography variant="h3">Welcome to Instrekt!</Typography>
      <Typography variant="h6">
        You reached out Instructor Signup page.
      </Typography>
      <Typography variant="h6">
        We are excited to have you as an instructor. You made a great choice in
        partnering with Instrekt to help organize your business. Our tools allow
        you to do what you do best… teach!
      </Typography>
      <Typography variant="h6">
        We will walk you through a few steps to get your business set up and
        ready to go. Let’s get started!
      </Typography>
      <Typography variant="h6">
        At any point you have a question, we are here for you. Click the "I need
        help" button above to reach out.
      </Typography>
      <Typography variant="h6">
        We look forward to assisting with your continued success as an
        instructor with growing your business
      </Typography>
      <Typography variant="h6">Welcome aboard!</Typography>
      <Typography variant="h6">Nick and Jason</Typography>
    </WelcomeContainer>
  );
};

export default WelcomeMessage;
