import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import DataContext from 'contexts/DataContext';
import { Dayjs } from 'dayjs';
import { useContext } from 'react';
import styled from 'styled-components';

const LessonsPanel = styled.div`
  flex-grow: 1;
  flex-basis: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  height: 100%;
`;

const Lessons = () => {
  const { userLessons } = useContext(DataContext);

  const formattedDate = (date: Dayjs) =>
    date.format('MMMM D, YYYY [at] h:mm A');
  return (
    <LessonsPanel>
      {userLessons.length === 0 && <div>No lessons found</div>}
      {userLessons.map(({ id, lesson }) => (
        <Accordion key={id}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">
              {lesson.date && formattedDate(lesson.date)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{lesson.status}</AccordionDetails>
          <AccordionActions>test</AccordionActions>
        </Accordion>
      ))}
    </LessonsPanel>
  );
};

export default Lessons;
