import { Autocomplete, TextField } from '@mui/material';

interface AutocompleteDropdownProps {
  options: string[];
  value: string | null;
  onChange: (event: any, newValue: string | null) => void;
}

const AutocompleteDropdown = ({
  onChange,
  options,
  value,
}: AutocompleteDropdownProps) => {
  return (
    <Autocomplete
      disablePortal
      fullWidth
      id="available-activities"
      onChange={onChange}
      options={options}
      renderInput={params => (
        <TextField {...params} fullWidth label="Select an Activity" />
      )}
      value={value}
    />
  );
};

export default AutocompleteDropdown;
