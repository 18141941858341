import { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { initialFamilyData } from 'api/family.utils';
import { Layouts } from 'components/shared';
import DataContext from 'contexts/DataContext';
import FamilyDetails from 'components/ViewFamily/FamilyDetails';

const ViewFamily = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { userFamilies, userStudents } = useContext(DataContext);

  const familyId = params.get('f');

  const family = useMemo(() => {
    const selectedFamily = userFamilies.find(family => family.id === familyId);

    if (!selectedFamily) {
      return { id: '', family: initialFamilyData };
    }
    return selectedFamily;
  }, [familyId, userFamilies]);

  const students = useMemo(() => {
    const selectedStudents = userStudents.filter(
      student => student.student.familyId === familyId
    );

    if (!selectedStudents) {
      return;
    }

    return selectedStudents;
  }, [familyId, userStudents]);

  const { MainLayout } = Layouts;

  const handleBackToFamilies = () => {
    navigate('/families');
  };

  useEffect(() => {
    if (!familyId) {
      navigate('/families');
    }
  }, [familyId, navigate]);

  return (
    <MainLayout
      content={
        <FamilyDetails
          family={family}
          handleBackToFamilies={handleBackToFamilies}
          students={students}
        />
      }
      headerText={`Family Details`}
    />
  );
};

export default ViewFamily;
