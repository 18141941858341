import {
  DeleteOutline,
  GroupOutlined,
  MailOutline,
  MoreVert,
  PersonOutline,
  PhoneOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  IconButton,
  Link,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { FamilyData } from 'api/family.utils';
import { StudentData } from 'api/student.utils';

interface FamilyTableBodyProps {
  emptyRows: number;
  families: FamilyData[];
  students: StudentData[];
  handleCopyEmail: (email: string) => void;
  handleViewFamily: (id: string) => void;
}

const ContactCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DataCell = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const FamilyCell = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const MenuText = styled(Typography)`
  margin-left: 8px;
`;

const StudentCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FamilyTableBody: FC<FamilyTableBodyProps> = ({
  emptyRows,
  families,
  students,
  handleCopyEmail,
  handleViewFamily,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFamilyId, setSelectedFamilyId] = useState<string | null>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedFamilyId(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedFamilyId(id);
  };

  const handleMenuOptionClick = (option: string) => () => {
    handleMenuClose();
    if (!selectedFamilyId) return;
    switch (option) {
      case 'View':
        handleViewFamily(selectedFamilyId);
        break;
      case 'Delete':
        handleDelete(selectedFamilyId);
        break;
      default:
        break;
    }
  };

  const handleDelete = (id: string) => {
    console.log('Delete family with id: ', id);
  };

  const generateMenuOptions = () => [
    {
      name: 'View',
      icon: <VisibilityOutlined />,
    },
    { name: 'Delete', icon: <DeleteOutline /> },
  ];

  const familyNameCell = (familyData: FamilyData) => {
    const {
      family: { familyName },
      id,
    } = familyData;
    return (
      <FamilyCell>
        <Link
          component="button"
          onClick={() => handleViewFamily(id)}
        >{`${familyName}`}</Link>
      </FamilyCell>
    );
  };

  const familyContactCell = (familyData: FamilyData) => {
    const {
      family: { email, phone },
    } = familyData;
    return (
      <ContactCell>
        {!email && !phone && (
          <DataCell>
            <PersonOutline fontSize="small" />
            No contact info
          </DataCell>
        )}
        {email && (
          <DataCell onClick={() => handleCopyEmail(email)}>
            <MailOutline fontSize="small" />
            {email}
          </DataCell>
        )}
        {phone && (
          <DataCell>
            <PhoneOutlined fontSize="small" />
            {phone}
          </DataCell>
        )}
      </ContactCell>
    );
  };

  const studentCell = (familyId: string) => {
    const familyStudents = students
      .filter(student => student.student.familyId === familyId)
      .sort((a, b) => a.student.firstName.localeCompare(b.student.firstName));

    return (
      <StudentCell>
        {familyStudents.length ? (
          familyStudents.map(({ id, student: { firstName, status } }) => (
            <DataCell key={id}>
              {firstName}
              <Typography
                variant="body2"
                color={
                  status === 'active'
                    ? 'primary'
                    : status === 'inactive'
                    ? 'default'
                    : 'secondary'
                }
              >
                ({status.charAt(0).toUpperCase() + status.slice(1)})
              </Typography>
            </DataCell>
          ))
        ) : (
          <DataCell>
            <GroupOutlined fontSize="small" />
            No students
          </DataCell>
        )}
      </StudentCell>
    );
    // return (
    //   <DataCell>
    //     {familyStudents.map(student => student.student.firstName).join(', ') ||
    //       'No students'}
    //   </DataCell>
    // );
  };

  return (
    <>
      <TableBody>
        {families.map(family => (
          <TableRow key={family.id}>
            <TableCell>{familyNameCell(family)}</TableCell>
            <TableCell>
              <DataCell>
                {family.family.firstName + ' ' + family.family.lastName}
              </DataCell>
            </TableCell>
            <TableCell>{familyContactCell(family)}</TableCell>
            <TableCell>{studentCell(family.id)}</TableCell>
            <TableCell>
              <Tooltip title="Actions">
                <IconButton
                  aria-controls="menu"
                  aria-haspopup="true"
                  onClick={event => handleMenuOpen(event, family.id)}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {generateMenuOptions().map((option, index) => (
                  <MenuItem
                    key={index}
                    onClick={handleMenuOptionClick(option.name)}
                  >
                    {option.icon}
                    {<MenuText>{option.name}</MenuText>}
                  </MenuItem>
                ))}
              </Menu>
            </TableCell>
          </TableRow>
        ))}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: 53 * emptyRows,
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

export default FamilyTableBody;
