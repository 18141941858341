import { IconButton } from '@mui/material';
import styled from 'styled-components';

interface FooterProps {
  darkMode?: boolean;
  fixed?: boolean;
}

const BaseFooterDisplay = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bullet = styled.div`
  margin: 0 8px;
`;

const CopyrightNoticeContainer = styled.div<FooterProps>`
  background-color: ${({ darkMode }) => (darkMode ? '#121212' : '#F5F5F5')};
  padding: 0 48px;
  position: relative;
  width: 100%;
`;

const CopyrightAndNotices = styled(BaseFooterDisplay)<FooterProps>`
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
`;

const FooterContainer = styled.div<FooterProps>`
  background-color: ${({ darkMode }) => (darkMode ? '#121212' : '#F5F5F5')};
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  margin-top: auto;
  padding: 0 48px;
  width: 100%;
  z-index: 1300;

  ${({ fixed }) => fixed && 'position: fixed; bottom: 0;'}
`;

const FooterLinks = styled(BaseFooterDisplay)`
  flex-direction: row;
  justify-content: start;
  margin: 24px 0;
`;

const LeftSide = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 34px;
`;

const LinkList = styled(BaseFooterDisplay)``;

const LinkSection = styled(BaseFooterDisplay)`
  margin: 24px 0;
  max-width: 350px;
  width: 100%;
`;

const LogoSection = styled(BaseFooterDisplay)`
  margin: 24px 0;
  width: 100%;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  &:hover {
    border-radius: 100%;
  }
`;

export {
  Bullet,
  CopyrightNoticeContainer,
  CopyrightAndNotices,
  FooterContainer,
  FooterLinks,
  LeftSide,
  LinkList,
  LinkSection,
  LogoSection,
  StyledIconButton,
};
