import { EmojiPeople, NoteAlt, TouchApp } from '@mui/icons-material';
import { Card, Typography } from '@mui/material';
import styled from 'styled-components';

import { StandardContainer } from 'assets/styles/shared';
import { CustomButton } from 'components/shared';
import { FC } from 'react';

interface HowItWorksProps {
  handleClick?: () => void;
}

const CardContent = styled(Typography)`
  padding: 8px 16px;
  text-align: center;
`;

const CardTitle = styled(Typography)`
  color: #58abff;
  margin: 0 auto 8px;
  width: fit-content;
`;

const IconContainer = styled.div`
  color: #58abff;
  margin: 16px auto;
  width: fit-content;
`;

const ThreeTileCard = styled(Card)`
  width: 375px;
  margin: 0 24px;
  padding: 24px 16px;
`;

const ThreeTileCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  margin: 0 auto;
`;

const ThreeTileCTA = styled.div`
  margin: 48px auto 0;
  max-width: 300px;
`;

const ThreeTileSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
`;

const ThreeTileTitle = styled(Typography)`
  margin: 0 auto 40px;
`;

const HowItWorks: FC<HowItWorksProps> = ({ handleClick }) => {
  const cards = [
    {
      details: `Clicking any of the buttons on this page will take you to a form to request a lesson plan from me.`,
      icon: <TouchApp sx={{ fontSize: 40 }} />,
      title: 'Click to request my time',
    },
    {
      details: `Completing the request form will allow me to offer you the best lesson plan to meet your needs`,
      icon: <NoteAlt sx={{ fontSize: 40 }} />,
      title: 'Send me your details',
    },
    {
      details: `I will respond in the Instrekt app as soon as I am available to with a lesson plan`,
      icon: <EmojiPeople sx={{ fontSize: 40 }} />,
      title: 'Connect and learn something',
    },
  ];
  return (
    <StandardContainer>
      <ThreeTileSectionContainer>
        <ThreeTileTitle variant="h3">Let's Get Started</ThreeTileTitle>
        <ThreeTileCardContainer>
          {cards.map(({ details, icon, title }) => {
            return (
              <ThreeTileCard key={title} variant={'outlined'}>
                {icon && <IconContainer>{icon}</IconContainer>}
                <CardTitle variant="h5">{title}</CardTitle>
                <CardContent>{details}</CardContent>
              </ThreeTileCard>
            );
          })}
        </ThreeTileCardContainer>
        <ThreeTileCTA>
          <CustomButton
            buttonText={'Click to get started!'}
            onClick={handleClick}
          />
        </ThreeTileCTA>
      </ThreeTileSectionContainer>
    </StandardContainer>
  );
};

export default HowItWorks;
