import { createContext, FC, useState } from 'react';

export enum AppMode {
  STUDENT = 'student',
  INSTRUCTOR = 'instructor',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
  DEV = 'dev',
  TEST = 'test',
}

interface IAppContext {
  appMode?: AppMode;
  isDarkMode?: boolean;
  toggleDarkMode: () => void;
  toggleAppMode: (mode: AppMode) => void;
}

export interface DarkModeProps {
  darkMode: boolean | undefined;
}

const AppContext = createContext<IAppContext>({
  appMode: 'student' as AppMode,
  isDarkMode: false,

  toggleDarkMode: () => {},
  toggleAppMode: () => {},
});

export default AppContext;

export const AppProvider: FC = ({ children }) => {
  const [appMode, setAppMode] = useState<AppMode>(
    (localStorage.getItem('appMode') as AppMode) || AppMode.STUDENT
  );
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    localStorage.getItem('darkMode') !== 'false'
  );

  const toggleAppMode = (mode: AppMode) => {
    if (!Object.values(AppMode).includes(mode)) return;

    setAppMode(mode);
    localStorage.setItem('appMode', mode);
  };

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
  };

  const value = {
    appMode: appMode,
    isDarkMode: isDarkMode,

    toggleDarkMode: toggleDarkMode,
    toggleAppMode: toggleAppMode,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
