import styled from 'styled-components';

interface MainContentProps {
  displayHeader: boolean;
  displayPanelMenu: boolean;
}

const BlueLabel = styled.label`
  color: #58abff;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 8px 24px;
  width: fit-content;
`;

const MainContentArea = styled.div<MainContentProps>`
  ${({ displayHeader }) => displayHeader && 'padding-top: 70px;'}
  ${({ displayPanelMenu }) => displayPanelMenu && 'padding-left: 240px;'}
`;

const PageHeading = styled.div`
  font-size: 32px;
  margin: 24px 48px;
`;

const StandardContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export { BlueLabel, MainContentArea, PageHeading, StandardContainer };
