import { Drawer, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';
import CustomDivider from '../CustomDivider';

interface PanelViewProps {
  leftPanelContent: JSX.Element;
  leftPanelNavItem?: JSX.Element;
  leftPanelTitle: string;

  rightPanelContent: JSX.Element;
  rightPanelNavItem?: JSX.Element;
  rightPanelTitle: string;

  drawerContent?: JSX.Element;
  drawerNavItem?: JSX.Element;
  drawerTitle?: string;
  openDrawer?: boolean;
  toggleDrawer?: () => void;
  useDrawer?: boolean;
}

interface SectionBarProps {
  title?: string;
  navItem?: JSX.Element;
}

const PanelViewContainer = styled.div`
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  left: 240px;
`;

const PanelViewContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const PanelViewDiv = styled.div`
  height: 100%;
  opacity: 1;
  overflow: hidden;
`;

const PanelViewContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  position: relative;
`;

const PanelViewRightPanel = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  height: auto;
  overflow: hidden;
`;

const PanelViewLeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  overflow: hidden;
  min-width: 282px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  margin-bottom: 8px;
  max-height: 100%;
  overflow: scroll;
`;

const StyledToolbar = styled(Toolbar)`
  min-height: 71px;
  height: 71px;
`;

const DrawerDiv = styled.div`
  width: 500px;
`;

const PanelView = ({
  leftPanelContent,
  leftPanelNavItem,
  leftPanelTitle,

  rightPanelContent,
  rightPanelNavItem,
  rightPanelTitle,

  drawerContent,
  drawerNavItem,
  drawerTitle,
  openDrawer,
  toggleDrawer,
  useDrawer = false,
}: PanelViewProps) => {
  const SectionBar = ({ title, navItem }: SectionBarProps) => {
    return (
      <StyledToolbar>
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
        >
          {title}
        </Typography>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          {navItem && navItem}
        </Box>
      </StyledToolbar>
    );
  };

  return (
    <PanelViewContainer>
      <PanelViewContentContainer>
        <PanelViewLeftPanel>
          <PanelViewDiv>
            <PanelViewContent>
              <CustomDivider />
              <SectionBar navItem={leftPanelNavItem} title={leftPanelTitle} />
              <CustomDivider />
              {leftPanelContent}
            </PanelViewContent>
          </PanelViewDiv>
        </PanelViewLeftPanel>
        <CustomDivider orientation="vertical" flexItem />
        <PanelViewRightPanel>
          <PanelViewDiv>
            <PanelViewContent>
              <CustomDivider />
              <SectionBar navItem={rightPanelNavItem} title={rightPanelTitle} />
              <CustomDivider />
              <StyledBox>{rightPanelContent}</StyledBox>
            </PanelViewContent>
          </PanelViewDiv>
        </PanelViewRightPanel>
        <CustomDivider orientation="vertical" flexItem />
        {useDrawer && (
          <Drawer anchor="right" onClose={toggleDrawer} open={openDrawer}>
            <DrawerDiv>
              <PanelViewContent>
                <CustomDivider />
                <SectionBar title={drawerTitle} navItem={drawerNavItem} />
                <CustomDivider />
                {drawerContent}
              </PanelViewContent>
            </DrawerDiv>
          </Drawer>
        )}
      </PanelViewContentContainer>
    </PanelViewContainer>
  );
};

export default PanelView;
