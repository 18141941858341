import { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { getUserApplication } from 'api/signup.utils';
import SignupProcess from 'components/InstructorSignup/SignupProcess';
import { CustomButton } from 'components/shared';
import AppContext from 'contexts/AppContext';
import UserContext from 'contexts/UserContext';
import SignupContext from 'contexts/SignupContext';

interface InstructorSignupContainerProps {
  darkMode?: boolean;
}

const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding-bottom: 1px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 100%;
  margin: 0 16px 16px;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  padding: 16px;
`;

const InstructorSignupContainer = styled.div<InstructorSignupContainerProps>`
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  left: 0px;
  ${({ darkMode }) =>
    darkMode
      ? 'background-color: rgba(18, 18, 18, 0.96);'
      : 'background-color: rgba(0, 0, 0, 0.04);'}
`;

const InstructorSignupContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const InstructorSignup = () => {
  const { isDarkMode } = useContext(AppContext);

  const { saveApplication, setApplicationData, setInitialInstructorData } =
    useContext(SignupContext);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (!currentUser) return;

    getUserApplication(currentUser, setApplicationData).then(() => {
      setInitialInstructorData(currentUser);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <InstructorSignupContainer darkMode={isDarkMode}>
      <InstructorSignupContentContainer>
        <HeaderContainer>
          <CustomButton
            buttonText="I need help"
            onClick={() => console.log('help clicked')}
            variant="text"
          />
          <CustomButton
            buttonText="Save and Exit"
            onClick={saveApplication}
            variant="outlined"
          />
        </HeaderContainer>
        <ContentContainer>
          <Content>
            <SignupProcess />
          </Content>
        </ContentContainer>
      </InstructorSignupContentContainer>
    </InstructorSignupContainer>
  );
};

export default InstructorSignup;
