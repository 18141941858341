import { Alert } from '@mui/material';

import { StandardContainer } from 'assets/styles/shared';
import styled from 'styled-components';

const StyledStandardContainer = styled(StandardContainer)`
  margin: 16px 8px;
`;

const NoActivities = () => {
  return (
    <StyledStandardContainer>
      <Alert variant="outlined" severity="info">
        You have not added any activities yet.
      </Alert>
    </StyledStandardContainer>
  );
};

export default NoActivities;
