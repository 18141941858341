import { RequestData } from 'api/lesson.utils';
import { InformationContainer } from './styles';
import { Typography } from '@mui/material';
import UserContext from 'contexts/UserContext';
import { useCallback, useContext } from 'react';
import { CustomAlert, CustomButton } from 'components/shared';
import { openGoogleMaps } from 'hooks';

interface LocationInfoProps {
  requestData: RequestData;
}

const LocationInfo = ({ requestData }: LocationInfoProps) => {
  const { currentUser } = useContext(UserContext);

  const { request } = requestData;

  const handleLocationClick = useCallback(
    () => openGoogleMaps(request.lessonLocation?.place_id),
    [request.lessonLocation?.place_id]
  );

  const renderLocation = () => {
    if (!request.lessonLocation) {
      return <CustomAlert type="warning" title="No location provided" />;
    }

    if (request.travel === 'toStudent') {
      return currentUser?.user.address;
    } else {
      return request.lessonLocation?.description;
    }
  };

  return (
    <InformationContainer>
      <Typography variant="h5">Location Information</Typography>
      {renderLocation()}
      {request.lessonLocation && (
        <CustomButton
          buttonText={'View Location'}
          onClick={handleLocationClick}
          variant="outlined"
        />
      )}
    </InformationContainer>
  );
};

export default LocationInfo;
