import { Search } from '@mui/icons-material';
import { AppBar, Drawer } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface DarkModeProps {
  darkMode: boolean | undefined;
}

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px auto 0;
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  min-height: 72px;
`;

const HeaderContainer = styled.div<DarkModeProps>`
  flex: 0 1 auto;
  position: fixed;
  width: 100%;
  z-index: 10;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 24px;
`;

const HeaderSearch = styled.div`
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  margin: 8px auto;
  transition: all 0.2s;
  width: 500px;
`;

const HeaderSearchContainer = styled.div<DarkModeProps>`
  background-color: ${({ darkMode }) => (darkMode ? '#121212' : '#FFFFFF')};
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.05)
  );
  height: 70px;
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 10;
`;

const HeaderSpace = styled.div``;

const IconContainer = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  padding: 8px;
`;

const LinkContainer = styled.div``;

const LogoContainer = styled(Link)`
  height: 100%;
  width: 70px;
  padding: 25px 0;
`;

const LoggedInHeaderContainer = styled.div<DarkModeProps>`
  flex: 0 1 auto;
  width: 100%;
  z-index: 10;
`;

const MenuText = styled.div`
  margin-left: 12px;
`;

const NavigationMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const SearchAndMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SignInButtonAndToggleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  margin: 8px 0;
`;

const StyledAppBar = styled(AppBar)`
  z-index: 1300;
`;

const StyledDrawer = styled(Drawer)`
  width: 240px;
  flex-shrink: 0;
  white-space: 'nowrap';
  box-sizing: 'border-box';
  overflow: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1);
  width: 240px;
  .MuiDrawer-paper {
    overflow: hidden;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1);
    width: 240px;
  }
`;

const StyledSearchIcon = styled(Search)`
  color: #3399ff;
  vertical-align: middle;
`;

export {
  BottomContainer,
  ButtonContainer,
  DrawerHeader,
  HeaderContainer,
  HeaderContent,
  HeaderSearch,
  HeaderSearchContainer,
  HeaderSpace,
  IconContainer,
  LinkContainer,
  LoggedInHeaderContainer,
  LogoContainer,
  MenuText,
  NavigationMenuContainer,
  SearchAndMenuContainer,
  SignInButtonAndToggleContainer,
  StyledAppBar,
  StyledDrawer,
  StyledSearchIcon,
};
