import { Alert, Typography } from '@mui/material';
import { TravelOptions } from 'api/organization.utils';
import TravelSelector from 'components/Settings/Organization/TravelSelector';
import SignupContext from 'contexts/SignupContext';
import { useContext } from 'react';

const LocationAndTravel = () => {
  const { applicationData, updateOrganizationData } = useContext(SignupContext);

  const handleLocationChange = (travelData: TravelOptions) => {
    updateOrganizationData('travelOptions', travelData);
  };

  return (
    <>
      <Typography variant="h3">Location and Travel</Typography>
      <Alert severity="info" variant="outlined">
        This will give your prospective students an idea of where you are
        located and if you are willing to travel to teach.
      </Alert>
      <TravelSelector
        currentSelection={
          applicationData.application.organization.travelOptions
        }
        onChange={handleLocationChange}
      />
    </>
  );
};

export default LocationAndTravel;
