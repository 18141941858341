import { Alert, AlertTitle, Card } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

import { CustomDivider } from 'components/shared';

interface CardListLayoutProps {
  alert: boolean;
  alertData: {
    message: string;
    severity: 'error' | 'info' | 'success' | 'warning' | undefined;
    title: string;
  };
  filters?: JSX.Element;
  cardList?: JSX.Element | JSX.Element[];
}

const CardContainer = styled.div`
  overflow: scroll;
`;

const CardListContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding-bottom: 16px;
  width: 300px;
`;

const FilterContainer = styled.div`
  padding: 16px 16px 0;
`;

const StyledAlert = styled(Alert)`
  padding: 16px;
`;

const CardListLayout: FC<CardListLayoutProps> = ({
  alert,
  alertData,
  filters,
  cardList,
}) => {
  return (
    <CardListContainer>
      <FilterContainer>{filters}</FilterContainer>
      <CustomDivider margin="small" />
      {alert && (
        <StyledAlert severity={alertData.severity}>
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </StyledAlert>
      )}
      <CardContainer>{cardList}</CardContainer>
    </CardListContainer>
  );
};

export default CardListLayout;
