import { PageHeading, StandardContainer } from 'assets/styles/shared';
import styled from 'styled-components';

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 24px;
`;

const MainContainer = styled.div`
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  left: 240px;
`;

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const Insights = () => {
  return (
    <MainContainer>
      <MainContentContainer>
        <StandardContainer>
          <HeadingContainer>
            <PageHeading>Insights</PageHeading>
          </HeadingContainer>
        </StandardContainer>
      </MainContentContainer>
    </MainContainer>
  );
};

export default Insights;
