import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

interface CustomSwitchProps {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  id: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  mainLabel?: string | React.ReactNode;
  offLabel?: string | React.ReactNode;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onLabel?: string | React.ReactNode;
}

const StyledSwitch = styled(Switch)`
  margin: 0 16px;
`;

const CustomSwitch: FC<CustomSwitchProps> = ({
  checked,
  defaultChecked,
  disabled,
  id,
  inputProps,
  mainLabel,
  offLabel,
  onChange,
  onLabel,
}) => {
  const label = useMemo(() => {
    if (mainLabel) {
      return mainLabel;
    } else {
      return checked ? onLabel : offLabel;
    }
  }, [checked, mainLabel, offLabel, onLabel]);

  return (
    <FormGroup>
      <FormControlLabel
        disabled={disabled}
        control={
          <StyledSwitch
            checked={checked}
            defaultChecked={defaultChecked}
            id={id}
            inputProps={inputProps}
            onChange={onChange}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default CustomSwitch;
