import {
  DateTimeContainer,
  Day,
  FlexRowContainer,
  InformationContainer,
  Time,
} from './styles';
import { LessonData } from 'api/lesson.utils';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

interface LessonListProps {
  lessonData: LessonData[];
}

const LessonDate = ({ lesson, index }: any) => (
  <DateTimeContainer>
    <Typography variant="h6">{`${index + 1}`}</Typography>
    <FlexRowContainer>
      <Day variant="h6">
        {dayjs(lesson.lesson.date).format('dddd, MMMM D, YYYY')}
      </Day>
      <Typography variant="h6">at</Typography>
      <Time variant="h6">{dayjs(lesson.lesson.time).format('h:mm A')}</Time>
    </FlexRowContainer>
  </DateTimeContainer>
);

const LessonList = ({ lessonData }: LessonListProps) => {
  const sortedLessons = lessonData.sort((a, b) => {
    if (!a.lesson.date || !b.lesson.date) return 0;
    const dateA = a.lesson.date.valueOf();
    const dateB = b.lesson.date.valueOf();
    return dateA - dateB;
  });

  return (
    <InformationContainer>
      <Typography variant="h5">Date and Time</Typography>
      {sortedLessons.map((lesson, index) => (
        <LessonDate lesson={lesson} index={index} />
      ))}
    </InformationContainer>
  );
};

export default LessonList;
