import { Check, Close } from '@mui/icons-material';
import { Alert, Drawer, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import styled from 'styled-components';

import { CustomButton } from 'components/shared';
import { Privacy, Terms } from 'pages';
import SignupContext from 'contexts/SignupContext';

const AcknowledgementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;

const AcknowledgementLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 20px;
`;

const ReviewSetup = () => {
  const { applicationData, updateInstructorData } = useContext(SignupContext);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState('');

  const handleAcknowledge = () => {
    updateInstructorData('acknowledgement', {
      ...applicationData.application.instructor.acknowledgement,
      [drawerContent]: true,
    });
    setIsDrawerOpen(false);
    setDrawerContent('');
  };

  const handleViewPolicy = (policy: string) => {
    setIsDrawerOpen(true);
    setDrawerContent(policy);
  };

  const renderDrawerContent = () => {
    switch (drawerContent) {
      case 'privacy':
        return <Privacy acknowledgement={handleAcknowledge} />;
      case 'terms':
        return <Terms acknowledgement={handleAcknowledge} />;
      default:
        return <Typography>Content not found.</Typography>;
    }
  };

  return (
    <>
      <Typography variant="h3">Acknowledge Policies</Typography>
      <Alert severity="info" variant="outlined">
        Click each button below to view and acknowledge our Privacy Policy and
        Terms and Conditions. You must view both to continue.
      </Alert>
      <ButtonContainer>
        <CustomButton
          buttonText={'Privacy Policy'}
          onClick={() => handleViewPolicy('privacy')}
          variant="contained"
        />
        <CustomButton
          buttonText={'Terms and Conditions'}
          onClick={() => handleViewPolicy('terms')}
          variant="contained"
        />
      </ButtonContainer>
      <AcknowledgementContainer>
        <AcknowledgementLine>
          {applicationData.application.instructor.acknowledgement &&
          applicationData.application.instructor.acknowledgement.privacy ? (
            <Check color="success" />
          ) : (
            <Close color="error" />
          )}
          <Typography variant="body1">
            I have read the Privacy Policy
          </Typography>
        </AcknowledgementLine>
        <AcknowledgementLine>
          {applicationData.application.instructor.acknowledgement &&
          applicationData.application.instructor.acknowledgement.terms ? (
            <Check color="success" />
          ) : (
            <Close color="error" />
          )}
          <Typography variant="body1">
            I have read the Terms and Conditions
          </Typography>
        </AcknowledgementLine>
      </AcknowledgementContainer>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleAcknowledge}>
        {renderDrawerContent()}
      </Drawer>
    </>
  );
};

export default ReviewSetup;
