import {
  ManageAccounts,
  Forum,
  Dashboard,
  LightMode,
  DarkMode,
  Help,
  Logout,
  CalendarMonth,
  Insights,
  Groups2,
  AssignmentTurnedIn,
  LocalOffer,
  School,
  Contacts,
  FamilyRestroom,
} from '@mui/icons-material';
import {
  Box,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { auth } from 'api/firebaseConfig';
import {
  CustomButton,
  CustomDivider,
  Logo,
  TooltipIconButton,
} from 'components/shared';
import AppContext, { AppMode } from 'contexts/AppContext';
import UserContext, { RoleType } from 'contexts/UserContext';

import {
  BottomContainer,
  ButtonContainer,
  DrawerHeader,
  LinkContainer,
  NavigationMenuContainer,
  StyledDrawer,
} from './styles';

const Navigation = () => {
  const { appMode, isDarkMode, toggleAppMode, toggleDarkMode } =
    useContext(AppContext);
  const { currentUser } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname;

  const handleBecomeInstructor = useCallback(() => {
    navigate('/instructor-signup');
  }, [navigate]);

  const handleLogoutClick = useCallback(() => {
    auth.signOut();
  }, []);

  const handleModeSwitch = useCallback(() => {
    const newAppMode =
      appMode === AppMode.STUDENT ? AppMode.INSTRUCTOR : AppMode.STUDENT;
    toggleAppMode(newAppMode);
    navigate('/dashboard');
  }, [appMode, navigate, toggleAppMode]);

  const switchText = isDarkMode
    ? 'Switch to light mode'
    : 'Switch to dark mode';

  const menuItems = [
    {
      icon: <Dashboard />,
      onClick: () => navigate('/dashboard'),
      pathname: '/dashboard',
      text: 'Home',
    },
    ...(appMode === AppMode.INSTRUCTOR
      ? [
          {
            icon: <CalendarMonth />,
            onClick: () => navigate('/lesson-manager'),
            pathname: '/lessons-manager',
            text: 'Lessons',
          },
          {
            icon: <LocalOffer />,
            onClick: () => navigate('/track-manager'),
            pathname: '/track-manager',
            text: 'Tracks',
          },
          {
            icon: <FamilyRestroom />,
            onClick: () => navigate('/families'),
            pathname: '/families',
            text: 'Families',
          },
          {
            icon: <Groups2 />,
            onClick: () => navigate('/students'),
            pathname: '/students',
            text: 'Students',
          },
        ]
      : [
          {
            icon: <AssignmentTurnedIn />,
            onClick: () => navigate('/requests'),
            pathname: '/requests',
            text: 'My Requests',
          },
          {
            icon: <School />,
            onClick: () => navigate('/lessons'),
            pathname: '/lessons',
            text: 'My Lessons',
          },
        ]),
    {
      icon: <Contacts />,
      onClick: () => navigate('/contacts'),
      pathname: '/contacts',
      text: appMode === AppMode.INSTRUCTOR ? 'Clients' : 'My Instructors',
    },
    {
      icon: <Forum />,
      onClick: () => navigate('/messages'),
      pathname: '/messages',
      text: 'Messages',
    },
    ...(appMode === AppMode.INSTRUCTOR
      ? [
          {
            icon: <Insights />,
            onClick: () => navigate('/insights'),
            pathname: '/insights',
            text: 'Insights',
          },
        ]
      : []),
    {
      icon: <ManageAccounts />,
      onClick: () => navigate('/settings?t=personal'),
      pathname: '/settings',
      text: 'Settings',
    },
  ];

  const renderAppModeButton = () => {
    switch (currentUser?.user.role) {
      case RoleType.INSTRUCTOR:
        return (
          <CustomButton
            buttonText={appMode}
            onClick={handleModeSwitch}
            variant="text"
          />
        );
      case RoleType.STUDENT:
        return (
          <CustomButton
            buttonText="I am an instructor"
            onClick={handleBecomeInstructor}
            padding={{ x: 16, y: 16 }}
            variant="text"
          />
        );
      case RoleType.APPLICANT:
        return (
          <CustomButton
            buttonText="Finish Instructor setup"
            onClick={handleBecomeInstructor}
            padding={{ x: 16, y: 16 }}
            variant="text"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <StyledDrawer variant="permanent" anchor="left">
        <DrawerHeader>
          <Logo align="center" height={24} />
        </DrawerHeader>
        <CustomDivider />
        {renderAppModeButton()}
        <CustomDivider />
        <NavigationMenuContainer>
          <LinkContainer>
            <List>
              {menuItems.map(({ icon, onClick, pathname, text }) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={onClick}
                  sx={{ display: 'block' }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: 'initial',
                      px: 2.5,
                      backgroundColor:
                        pathname === path || path.includes(pathname)
                          ? isDarkMode
                            ? 'rgba(255, 255, 255, 0.08)'
                            : 'rgba(0, 0, 0, 0.04)'
                          : 'transparent',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: 'center',
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{ opacity: 1, whiteSpace: 'nowrap' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </LinkContainer>
          <BottomContainer>
            <CustomDivider />
            <ButtonContainer>
              <TooltipIconButton
                action={() => navigate('/')}
                icon={<Help />}
                tooltipText={'Need Help?'}
              />
              <TooltipIconButton
                action={toggleDarkMode}
                icon={isDarkMode ? <LightMode /> : <DarkMode />}
                tooltipText={switchText}
              />
              <TooltipIconButton
                action={handleLogoutClick}
                icon={<Logout />}
                tooltipText={'Log out'}
              />
            </ButtonContainer>
          </BottomContainer>
        </NavigationMenuContainer>
      </StyledDrawer>
    </Box>
  );
};

export default Navigation;
