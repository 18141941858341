import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { logoSrc } from 'assets/images';

interface LogoProps {
  align?: string;
  height?: number;
}

const LogoContainer = styled(Link)<LogoProps>`
  ${({ align }) =>
    align === 'center' ? 'margin: 20px auto;' : 'margin: 20px 0;'};
`;

const ImageContainer = styled.img<LogoProps>`
  ${({ height }) => `height: ${height}px`};
`;

const Logo: FC<LogoProps> = ({ align, height }) => {
  return (
    <LogoContainer to="/" align={align}>
      <ImageContainer src={logoSrc} alt={'instruct logo'} height={height} />
    </LogoContainer>
  );
};

export default Logo;
