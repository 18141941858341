import { Email, Google } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { ChangeEventHandler, KeyboardEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { signInWithGoogle } from 'api/user.utils';
import {
  CustomButton,
  CustomDivider,
  InputFields,
  Logo,
} from 'components/shared';
import { auth } from 'api/firebaseConfig';

const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding-bottom: 66px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  position: relative;
`;

const SignInAndSignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: space-between;
  margin: 30px auto;
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  padding: 4px;
  width: 360px;
`;

const Login = () => {
  const navigate = useNavigate();

  const [isAuthenticatingByEmail, setIsAuthenticatingByEmail] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const { TextInput } = InputFields;

  const redirectUrl = useMemo(() => {
    return localStorage.getItem('path') || '/dashboard';
  }, []);

  const handleContinueWithEmail = async () => {
    const domain = window.location.hostname;
    const url =
      domain === 'localhost'
        ? 'http://localhost:3000/confirm'
        : `https://${domain}/confirm`;

    try {
      await sendSignInLinkToEmail(auth, userEmail, {
        url,
        handleCodeInApp: true,
      });
      window.localStorage.setItem('emailForSignIn', userEmail);
      setIsAuthenticatingByEmail(true);
    } catch (error) {
      console.error('Error sending email link:', error);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { value } = event.target;
    setUserEmail(value);
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleContinueWithEmail();
    }
  };

  const handleGoogleAuth = async () => {
    try {
      await signInWithGoogle();
      navigate(redirectUrl);
    } catch (error) {
      console.error('Google sign-in error:', error);
    }
  };

  return (
    <LoginContainer>
      <SignInAndSignUpContainer>
        <Logo align={'center'} />
        <SignInContainer>
          <CustomButton
            buttonText="Continue with Google"
            onClick={handleGoogleAuth}
            startIcon={<Google />}
            variant="outlined"
          />
          <CustomDivider margin="large" text="or" textVariant="inherit" />
          {!isAuthenticatingByEmail ? (
            <>
              <TextInput
                fullWidth
                label="Enter Email Address"
                onChange={handleChange}
                onKeyDown={handleEnterPress}
                type="email"
                value={userEmail}
              />
              <ButtonContainer>
                <CustomButton
                  buttonText="Continue with Email"
                  startIcon={<Email />}
                  onClick={handleContinueWithEmail}
                />
              </ButtonContainer>
            </>
          ) : (
            <Alert onClose={() => setIsAuthenticatingByEmail(false)}>
              Check your email to continue
            </Alert>
          )}
        </SignInContainer>
      </SignInAndSignUpContainer>
    </LoginContainer>
  );
};

export default Login;
