import Lessons from 'components/MyLessons/Lessons';
import { Layouts } from 'components/shared';

const MyLessons = () => {
  const { MainLayout } = Layouts;
  return (
    <>
      <MainLayout headerText="My Lessons" content={<Lessons />} />
    </>
  );
};

export default MyLessons;
