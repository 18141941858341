import {
  FilledInputProps,
  InputBaseComponentProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from '@mui/material';
import { ChangeEvent, KeyboardEvent } from 'react';

interface TextInputProps {
  adornmentProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined;
  disabled?: boolean;
  error?: string | JSX.Element;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  inputProps?: InputBaseComponentProps;
  label?: string;
  maxLength?: number;
  onBlur?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value?: number | string;
  variant?: 'standard' | 'filled' | 'outlined';
}

const TextInput = ({
  adornmentProps,
  disabled,
  error,
  fullWidth = false,
  helperText,
  id,
  inputProps,
  label,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  required = false,
  type = 'text',
  value,
  variant = 'outlined',
}: TextInputProps) => {
  return (
    <TextField
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      inputProps={inputProps}
      InputProps={adornmentProps}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
      variant={variant}
    />
  );
};

export default TextInput;
