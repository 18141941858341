import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import {
  Timestamp,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';

import { auth, db, functions, storage } from './firebaseConfig';
import dayjs, { Dayjs } from 'dayjs';

interface AdditionalDataObject {
  [key: string]: string;
}

interface UserAuthObject {
  displayName: string | null;
  email: string | null;
  emailVerified: boolean;
  providerData: any;
  uid: string;
}

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account', scope: 'name' });

export const createUserProfileDocument = async (
  userAuth: UserAuthObject,
  additionalData?: AdditionalDataObject
) => {
  if (!userAuth) return;
  const userRef = doc(db, `users/${userAuth.uid}`);

  const snapShot = await getDoc(userRef);

  if (!snapShot.exists) {
    const { displayName, email, emailVerified, providerData } = userAuth;
    const [firstName, lastName] = displayName
      ? displayName.split(' ')
      : ['', ''];
    const providerId = providerData[0].providerId === 'google.com' && true;
    const createdAt = new Date();

    try {
      const mergedData = {
        createdAt,
        email,
        emailVerified,
        firstName,
        lastName,
        googleAuthenticated: providerId,
        ...additionalData,
      };

      await setDoc(userRef, mergedData);
    } catch (error: any) {
      console.log('error creating user', error.message);
    }
  }
  return userRef;
};

export const updateUserProfileDocument = async (
  userId: string,
  newData: any
) => {
  await setDoc(doc(db, 'users', userId), newData, { merge: true });
};

export const getImageURL = async (
  imageLoc: string,
  setUrl: (url: string) => void
) => {
  const imageRef = ref(storage, imageLoc);

  try {
    const imageUrl = await getDownloadURL(imageRef);
    setUrl(imageUrl);
  } catch (error) {
    console.log(error);
  }
};

export const signInWithGoogle = async () =>
  signInWithPopup(auth, googleProvider)
    .then(result => {
      const user = result.user;
      const { displayName } = user;

      let [firstName, lastName] = ['', ''];

      if (displayName) {
        [firstName, lastName] = displayName.split(' ');
      }

      const newData = {
        firstName: firstName,
        lastName: lastName,
        googleAuthenticated: true,
      };

      updateUserProfileDocument(user.uid, newData);
    })
    .catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(errorCode, errorMessage, email, credential);
    });

export const createPortalLink = httpsCallable(
  functions,
  'ext-firestore-stripe-payments-createPortalLink'
);

export const getServerTimestamp = () => {
  return serverTimestamp();
};

export const convertDayjsToFirestoreTimestamp = (dayjsObject: Dayjs) => {
  if (!dayjsObject || !dayjs.isDayjs(dayjsObject)) {
    throw new Error('Invalid Day.js object');
  }

  const date = dayjsObject.toDate();

  return Timestamp.fromDate(date);
};

export const convertFirestoreTimestampToDayjs = (timestamp: Timestamp) => {
  if (!timestamp || !(timestamp instanceof Timestamp)) {
    throw new Error('Invalid Firestore timestamp');
  }

  return dayjs(timestamp.toDate());
};
