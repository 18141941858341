import { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { initialFamilyData } from 'api/family.utils';
import { initialStudentData } from 'api/student.utils';
import { Layouts } from 'components/shared';
import StudentDetails from 'components/ViewStudent/StudentDetails';
import DataContext from 'contexts/DataContext';

const ViewStudent = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { userFamilies, userStudents } = useContext(DataContext);

  const studentId = params.get('s');

  const student = useMemo(() => {
    const selectedStudent = userStudents.find(
      student => student.id === studentId
    );

    if (!selectedStudent) {
      return { id: '', student: initialStudentData };
    }
    return selectedStudent;
  }, [studentId, userStudents]);

  const family = useMemo(() => {
    const selectedFamily = userFamilies.find(
      family => family.id === student.student.familyId
    );

    if (!selectedFamily) {
      return { id: '', family: initialFamilyData };
    }

    return selectedFamily;
  }, [student.student.familyId, userFamilies]);

  const { MainLayout } = Layouts;

  const handleBackToStudents = () => {
    navigate('/students');
  };

  useEffect(() => {
    if (!studentId) {
      navigate('/students');
    }
  }, [studentId, navigate]);

  return (
    <MainLayout
      content={
        <StudentDetails
          family={family}
          handleBackToStudents={handleBackToStudents}
          student={student}
        />
      }
      headerText={`Student Details`}
    />
  );
};

export default ViewStudent;
