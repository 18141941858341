import {
  DescriptionOutlined,
  Edit,
  EmailOutlined,
  LocationOnOutlined,
  PersonOutline,
  PhoneOutlined,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

import { Family, FamilyData, saveFamilyDocument } from 'api/family.utils';
import {
  CustomButton,
  CustomDivider,
  InputFields,
  TooltipIconButton,
} from 'components/shared';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';
import { openGoogleMaps } from 'hooks';
import { PlaceType } from 'components/shared/InputFields/LocationSearch';

interface FamilyInfoProps {
  family: FamilyData;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

const DataContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  padding: 1rem;
  max-width: 400px;
`;

const EditFamilyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  max-width: 800px;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

const MainFamilyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  align-items: center;
  max-width: 800px;
`;

const FamilyHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const TooltipButtonContainer = styled.div`
  position: absolute;
  right: 0;
`;

const FamilyInfo: FC<FamilyInfoProps> = ({ family }) => {
  const { setUserFamilies, userFamilies } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { AddressSearch, TextArea, TextInput } = InputFields;

  const [edit, setEdit] = useState(false);
  const [emailError, setEmailError] = useState<string>('');
  const [updatedFamily, setUpdatedFamily] = useState<Family>(family.family);
  const {
    family: {
      address,
      email,
      familyName,
      firstName,
      lastName,
      notes,
      phone,
      placeId,
    },
  } = family;

  const displayData = (icon: JSX.Element, data: string | number | null) =>
    data && (
      <DataContainer key={data}>
        {icon}
        <Typography variant="body1">{data}</Typography>
      </DataContainer>
    );

  const handleAddressChange = (address: PlaceType | null) => {
    if (address) {
      setUpdatedFamily(prev => ({
        ...prev,
        address: address.description,
        placeId: address.place_id,
      }));
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    if (id === 'email') setEmailError('');
    setUpdatedFamily(prev => ({ ...prev, [id]: value }));
  };

  const handleLocationClick = useCallback(
    () => openGoogleMaps(placeId),
    [placeId]
  );

  const handleUpdateFamily = () => {
    if (!currentUser) return;
    validateEmail();
    if (emailError) return;

    saveFamilyDocument(currentUser, updatedFamily, family.id).then(res => {
      const { docId, success } = res;
      if (!success) return;

      if (docId) {
        const updatedFamilies = userFamilies.map(family =>
          family.id === docId ? { ...family, family: updatedFamily } : family
        );
        setUserFamilies(updatedFamilies);
      }
    });
    toggleEdit();
  };

  const toggleEdit = () => {
    setEdit(prev => !prev);
    setUpdatedFamily(family.family);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!updatedFamily.email || emailRegex.test(updatedFamily.email)) return;
    setEmailError('Invalid email address');
  };

  const disableSubmit =
    Object.keys(updatedFamily).every(
      key =>
        updatedFamily[key as keyof Family] ===
        family.family[key as keyof Family]
    ) || emailError !== '';

  const familyInformation = [
    { icon: <PersonOutline />, data: firstName + ' ' + lastName },
    { icon: <EmailOutlined />, data: email },
    { icon: <PhoneOutlined />, data: phone },
  ];

  const formattedAddress = useMemo(() => {
    if (!address) return null;
    const addressArray = address.split(', ');
    addressArray.pop();
    return addressArray.join(', ');
  }, [address]);

  return (
    <>
      {!edit ? (
        <MainFamilyContainer>
          <TooltipButtonContainer>
            <TooltipIconButton
              action={toggleEdit}
              icon={<Edit />}
              tooltipText="Edit Family"
            />
          </TooltipButtonContainer>
          <FamilyHeading>
            <Typography variant="h4">{familyName}</Typography>
          </FamilyHeading>
          <CustomDivider text={'Primary Contact'} margin={'none'} />
          <DetailsContainer>
            {familyInformation.map(({ icon, data }) => displayData(icon, data))}
          </DetailsContainer>
          <CustomDivider text={'Address'} margin={'none'} />
          <DetailsContainer>
            {displayData(<LocationOnOutlined />, formattedAddress)}
            <CustomButton
              buttonText={'View Location'}
              onClick={handleLocationClick}
              variant="outlined"
            />
          </DetailsContainer>
          {notes && (
            <>
              <CustomDivider text={'Private Notes'} margin={'none'} />
              <DetailsContainer>
                {displayData(<DescriptionOutlined />, notes)}
              </DetailsContainer>
            </>
          )}
        </MainFamilyContainer>
      ) : (
        <EditFamilyContainer>
          <FamilyHeading>
            <Typography variant="h4">Edit Family Information</Typography>
          </FamilyHeading>
          <InputContainer>
            <TextInput
              fullWidth
              id="familyName"
              label="Family Name"
              onChange={handleInputChange}
              value={updatedFamily.familyName}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              fullWidth
              id="firstName"
              label="First Name"
              onChange={handleInputChange}
              value={updatedFamily.firstName}
            />
            <TextInput
              fullWidth
              id="lastName"
              label="Last Name"
              onChange={handleInputChange}
              value={updatedFamily.lastName}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              error={emailError}
              fullWidth
              helperText={emailError || ''}
              id="email"
              label="Email"
              onChange={handleInputChange}
              onBlur={validateEmail}
              value={updatedFamily.email}
            />
            <TextInput
              fullWidth
              id="phone"
              label="Phone"
              onChange={handleInputChange}
              value={updatedFamily.phone}
            />
          </InputContainer>
          <AddressSearch handleAddressChange={handleAddressChange} />
          <TextArea
            fullWidth
            id="notes"
            label="Notes"
            onChange={handleInputChange}
            rows={5}
            value={updatedFamily.notes}
          />
          <ButtonContainer>
            <CustomButton
              buttonText="Cancel"
              onClick={toggleEdit}
              variant="text"
            />
            <CustomButton
              buttonText="Update"
              disabled={disableSubmit}
              onClick={handleUpdateFamily}
              variant="contained"
            />
          </ButtonContainer>
        </EditFamilyContainer>
      )}
    </>
  );
};

export default FamilyInfo;
