import { Card, CardContent, CardHeader } from '@mui/material';
import styled from 'styled-components';

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
`;

const DisplayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const MainDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledCardHeader = styled(CardHeader)`
  color: #58abff;
`;

export {
  CardDetails,
  DisplayContainer,
  MainDisplayContainer,
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
};
