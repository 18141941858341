import UserContext from 'contexts/UserContext';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }: any) => {
  const { currentUser } = useContext(UserContext);

  return currentUser ? <Navigate to={'/dashboard'} replace /> : children;
};

export default AuthGuard;
