import {
  Alert,
  AlertTitle,
  ListItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { SpringValues, animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import {
  AddNewButton,
  CustomButton,
  CustomToggleButtonGroup,
  InputFields,
} from 'components/shared';
import { Rate } from 'contexts/DataContext';
import RateCard from './RateCard';

import { StandardContainer } from 'assets/styles/shared';
import { LessonTypes } from 'api/lesson.utils';

interface RateSheetProps {
  rateData: Rate[];
  updateActivityData: (
    key: string,
    value: string | string[] | number | number[] | Rate | Rate[],
    dirty?: boolean
  ) => void;
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
`;

const RateFeedbackContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;

const RateFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  width: 100%;
`;

const StyledListAddItem = styled(animated(ListItem))`
  border: 1px solid;
  border-radius: 8px;
  flex-direction: column;
  gap: 16px;
  padding: 16px 32px;
  transition: 200ms all;
`;

const StyledStandardContainer = styled(StandardContainer)`
  gap: 16px;
`;

const initialRate: Rate = {
  id: '',
  adjustedCost: 56,
  cost: 50,
  type: LessonTypes.PRIVATE,
  duration: 15,
};

const RateSheet: FC<RateSheetProps> = ({ rateData, updateActivityData }) => {
  const [addNew, setAddNew] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [newRate, setNewRate] = useState<Rate>(initialRate);

  const { DropDown, SliderTextField } = InputFields;

  const props = useSpring<SpringValues>({
    from: {
      display: addNew ? 'none' : 'flex',
      opacity: addNew ? 0 : 1,
    },
    to: {
      display: addNew ? 'flex' : 'none',
      opacity: addNew ? 1 : 0,
    },
  });

  const adjustedRate = useMemo(() => {
    return newRate.cost ? Math.floor(Number(newRate.cost) * 1.12) : 0;
  }, [newRate.cost]);

  const sessionLengthOption = [
    { name: '15 - Fifteen minutes', value: 15 },
    { name: '20 - Twenty minutes', value: 20 },
    { name: '30 - Thirty minutes', value: 30 },
    { name: '45 - Forty-Five minutes', value: 45 },
    { name: '60 - Sixty minutes', value: 60 },
  ];

  const updateNewRate = (
    key: string,
    value: string | string[] | number | number[],
    dirty?: boolean
  ) => {
    setNewRate(prevData => ({ ...prevData, [key]: value }));
    dirty && setIsDirty(dirty);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    updateNewRate('cost', newValue, true);
    updateNewRate('adjustedCost', Math.floor(Number(newValue) * 1.12), true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      event.target.value === typeof 'string' ? '' : Number(event.target.value);
    updateNewRate('cost', newValue, true);
    newValue &&
      updateNewRate('adjustedCost', Math.floor(newValue * 1.12), true);
  };

  const handleBlur = () => {
    if (newRate.cost && typeof newRate.cost === 'number' && newRate.cost < 0) {
      updateNewRate('cost', 0, true);
    } else if (
      newRate.cost &&
      typeof newRate.cost === 'number' &&
      newRate.cost > 250
    ) {
      updateNewRate('cost', 250, true);
    }
  };

  const handleSessionLengthChange = (event: SelectChangeEvent) => {
    updateNewRate('duration', Number(event.target.value), true);
  };

  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    if (value === null) return;

    updateNewRate('type', value, true);
  };

  const handleAdd = () => {
    const updatedRates = [...(rateData || [])];
    updatedRates.push({ ...newRate, id: uuidv4() });
    updateActivityData('rates', updatedRates, true);
    handleCancel();
  };

  const handleCancel = () => {
    setNewRate(initialRate);
    setAddNew(false);
  };

  const handleDelete = (id: string) => {
    const updatedRates = rateData.filter(rate => rate.id !== id);
    updateActivityData('rates', updatedRates, true);
  };

  const typeButtons = [
    { name: 'Private', value: LessonTypes.PRIVATE },
    { name: 'Semi-private', value: LessonTypes.SEMIPRIVATE },
  ];

  const rateFields = (
    <RateFieldContainer>
      <CustomToggleButtonGroup
        ariaLabel={'lesson-type'}
        buttons={typeButtons}
        onChange={handleTypeChange}
        value={newRate.type}
      />
      <DropDown
        id="session-length-select"
        label="Session Length"
        onChange={handleSessionLengthChange}
        options={sessionLengthOption}
        value={newRate.duration.toString()}
      />
      <SliderTextField
        ariaLabel="price-slider"
        onBlur={handleBlur}
        onSliderChange={handleSliderChange}
        onTextChange={handleInputChange}
        sliderRange={{ min: 15, max: 250 }}
        value={newRate.cost}
      />
      <RateFeedbackContainer>
        <Typography variant="h6">
          You will receive{' '}
          <span style={{ fontWeight: 'bold' }}>${newRate.cost}</span>
        </Typography>
        <Typography variant="h6">
          and your student will pay{' '}
          <span style={{ fontWeight: 'bold' }}>${adjustedRate}</span>
        </Typography>
      </RateFeedbackContainer>
    </RateFieldContainer>
  );

  const privRates = rateData
    ?.filter(rate => rate.type === LessonTypes.PRIVATE)
    .sort((a, b) => a.duration - b.duration);
  const semiRates = rateData
    ?.filter(rate => rate.type === LessonTypes.SEMIPRIVATE)
    .sort((a, b) => a.duration - b.duration);

  return (
    <StyledStandardContainer>
      {privRates && privRates.length ? (
        <>
          <Typography>Private Lesson Rates</Typography>
          {privRates.map(rate => {
            return (
              <RateCard
                handleDelete={() => handleDelete(rate.id)}
                key={rate.id}
                rate={rate}
              />
            );
          })}
        </>
      ) : (
        <Alert severity="info" variant="outlined">
          <AlertTitle>{`You haven't added any private rates yet`}</AlertTitle>
          {`If you offer private lessons, add additional information about your private rates`}
        </Alert>
      )}

      {semiRates && semiRates.length ? (
        <>
          <Typography>Semi-private Lesson Rates</Typography>
          {semiRates.map(rate => {
            return (
              <RateCard
                handleDelete={() => handleDelete(rate.id)}
                key={rate.id}
                rate={rate}
              />
            );
          })}
        </>
      ) : (
        <Alert severity="info" variant="outlined">
          <AlertTitle>{`You haven't added any semi-private rates yet`}</AlertTitle>
          {`If you offer semi-private lessons, add additional information about your semi-private rates`}
        </Alert>
      )}
      {addNew ? (
        <StyledListAddItem style={props}>
          {rateFields}
          <ButtonContainer>
            <CustomButton
              buttonText="Cancel"
              onClick={handleCancel}
              variant="outlined"
            />
            <CustomButton
              buttonText="Add"
              disabled={!isDirty}
              onClick={handleAdd}
            />
          </ButtonContainer>
        </StyledListAddItem>
      ) : (
        <AddNewButton onClick={() => setAddNew(prev => !prev)} />
      )}
    </StyledStandardContainer>
  );
};

export default RateSheet;
