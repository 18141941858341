import { Card } from '@mui/material';
import { FC, useContext } from 'react';
import styled from 'styled-components';

import {
  Contact,
  ContactData,
  ContactType,
  saveContact,
} from 'api/contact.utils';
import { CustomAlert } from 'components/shared';
import ContactRecord from './ContactRecord';
import AppContext, { AppMode } from 'contexts/AppContext';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

interface ContactsProps {
  contactData: ContactData[];
}

const ContactsPanel = styled(Card)`
  display: flex;
  flex-grow: 1;
  flex-basis: 10px;
  flex-wrap: wrap;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  width: 100%;
`;

const Contacts: FC<ContactsProps> = ({ contactData }) => {
  const { setUserContacts } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);
  const { appMode } = useContext(AppContext);

  const filteredAndSortedContacts = contactData
    .filter(({ contact }) =>
      appMode === AppMode.INSTRUCTOR
        ? contact.contactType === ContactType.CLIENT
        : contact.contactType === ContactType.INSTRUCTOR
    )
    .sort((a, b) => {
      const nameA = a.contact.contactName?.toUpperCase();
      const nameB = b.contact.contactName?.toUpperCase();
      if (nameA && nameB) {
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      }
      return 0;
    });

  const handleRemoveContact = (contacttoDelete: Contact) => {
    saveContact(currentUser, contacttoDelete).then(res => {
      if (res.success) {
        setUserContacts(
          contactData.filter(
            contact => contact.contact.contactId !== contacttoDelete.contactId
          )
        );
      }
    });
  };

  return (
    <ContactsPanel>
      {filteredAndSortedContacts.length ? (
        filteredAndSortedContacts.map(({ id, contact }) => (
          <ContactRecord
            key={id}
            contact={contact}
            handleRemoveContact={() => handleRemoveContact(contact)}
          />
        ))
      ) : (
        <CustomAlert
          title="You have not favorited any instructors yet"
          body="Click the star icon on an instructor's profile
          to add them to your My Instructor's page."
        />
      )}
    </ContactsPanel>
  );
};

export default Contacts;
