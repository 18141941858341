import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { FC } from 'react';
import styled from 'styled-components';

import { Family } from 'api/family.utils';

interface TableHeadCell {
  id: any;
  label: string;
  disableSort?: boolean;
  width: string;
}

interface FamilyTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Family
  ) => void;
  order: Order;
  orderBy: string;
}

type Order = 'asc' | 'desc';

const StyledTableCell = styled(TableCell)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FamilyTableHead: FC<FamilyTableHeadProps> = ({
  onRequestSort,
  order,
  orderBy,
}) => {
  const createSortHandler =
    (property: keyof Family) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const tableHeadCells: TableHeadCell[] = [
    {
      id: 'familyName',
      label: 'Family Name',
      width: '20%',
    },
    {
      id: 'lastName',
      label: 'Primary Contact',
      width: '25%',
    },
    {
      disableSort: true,
      id: 'contact',
      label: 'Contact Info',
      width: '25%',
    },
    {
      disableSort: true,
      id: 'students',
      label: 'Student(s)',
      width: '25%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {tableHeadCells.map(cell => (
          <StyledTableCell
            key={cell.id}
            sortDirection={orderBy === cell.id ? order : false}
            style={{ width: cell.width }}
          >
            {cell.disableSort ? (
              <span>{cell.label}</span>
            ) : (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                {cell.label}
                {orderBy === cell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </StyledTableCell>
        ))}
        <TableCell style={{ width: '5%' }}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FamilyTableHead;
