import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { Dispatch, SetStateAction } from 'react';

import { UserData } from 'contexts/UserContext';
import { db } from './firebaseConfig';

export interface TravelOptions {
  toInstructor: boolean;
  toStudent: boolean;
  toThirdParty: boolean;
}

export interface Organization {
  active?: boolean;
  bio?: string;
  buttonText?: string;
  heroImage?: string;
  heroMainText?: string;
  heroSubText?: string;
  travelOptions?: TravelOptions;
  ownerId?: string;
  ownerName?: string;
  orgName?: string;
  philosophy?: string;
  slug?: string;
}

export interface OrganizationData {
  id: string;
  organization: Organization;
}

export const getOrganizationByOrgId = async (
  orgId: string,
  setOrgData: (organizationData: OrganizationData) => void
) => {
  const userOrganizationDocument = doc(db, 'organizations', orgId);
  const querySnapshot = await getDoc(userOrganizationDocument);

  if (querySnapshot.exists()) {
    setOrgData({
      id: querySnapshot.id,
      organization: querySnapshot.data() as Organization,
    });
  }
};

export const getOrganizationBySlug = (
  setOrgData: (organizationData: OrganizationData) => void,
  slug: string
) => {
  const userOrganizationDocument = query(
    collection(db, 'organizations'),
    where('slug', '==', slug)
  );

  const fetchOrganization = async () => {
    const querySnapshot = await getDocs(userOrganizationDocument);

    if (querySnapshot) {
      querySnapshot.forEach(doc => {
        setOrgData({
          id: doc.id,
          organization: doc.data() as Organization,
        });
      });
    }
  };

  fetchOrganization();
};

export const getSlugByName = async (slug: string) => {
  let results = { slug: '', ownerId: '' };
  const querySnapshot = await getDocs(
    query(collection(db, 'organizations'), where('slug', '==', slug))
  );
  querySnapshot.forEach(doc => {
    results = { slug: doc.data().slug, ownerId: doc.data().ownerId };
  });
  return results;
};

export const saveOrganizationDocument = async (
  currentUser: UserData | null,
  organizationData: OrganizationData,
  setOrgData?: Dispatch<SetStateAction<OrganizationData>>
) => {
  if (!currentUser) return;

  const { id, organization } = organizationData;

  if (id) {
    await updateExistingOrganization(id, organization);
    setOrgData &&
      setOrgData({
        id,
        organization: organization,
      });
    return id;
  } else {
    const docRef = await addNewOrganization(currentUser, organization);
    setOrgData &&
      setOrgData({
        id: docRef,
        organization: organization,
      });
    return docRef;
  }
};

const updateExistingOrganization = async (
  id: string,
  organization: Organization
) => {
  await setDoc(doc(db, 'organizations', id), organization, {
    merge: true,
  });
};

const addNewOrganization = async (
  currentUser: UserData,
  organization: Organization
) => {
  const { user } = currentUser;
  const newOrgRef = await addDoc(collection(db, 'organizations'), {
    ...organization,
    ownerId: currentUser.id,
    ownerName: user.firstName + ' ' + user.lastName,
  });

  await setDoc(
    doc(db, 'users', currentUser.id),
    { orgId: newOrgRef.id },
    { merge: true }
  );
  return newOrgRef.id;
};
