import {
  AccountBox,
  FamilyRestroom,
  Payment,
  School,
  ToggleOn,
  Web,
} from '@mui/icons-material';
import { Card, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ActivityInfo,
  OrganizationInfo,
  PaymentInfo,
  PersonalInfo,
  Preferences,
  StudentInfo,
} from 'components/Settings';
import AppContext, { AppMode } from 'contexts/AppContext';

import { CustomDivider, Layouts } from 'components/shared';

export interface FamilyMember {
  age: string;
  name: string;
}

export interface FamilyData {
  id: string;
  familyMember: FamilyMember;
}

interface TabProps {
  icon: JSX.Element;
  index: string;
  title: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  tab: string;
}

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  height: 100%;
  width: 100%;
`;

const StyledCard = styled(Card)`
  padding: 8px;
  height: 100%;
  overflow: scroll;
  border-radius: 0 0 16px 16px;
`;

const StyledTabCard = styled(Card)`
  padding: 16px;
  border-radius: 16px 16px 0 0;
`;

const StyledTab = styled(Tab)`
  width: 200px;
`;

const StyledTabs = styled(Tabs)`
  margin: 0 16px;
`;

const AccountSettings = () => {
  const { appMode } = useContext(AppContext);

  const [tabParams] = useSearchParams();
  const navigate = useNavigate();

  const { MainLayout } = Layouts;

  const t = tabParams.get('t');

  const [tab, setTab] = useState('personal');

  const tabList: TabProps[] = [
    {
      icon: <AccountBox />,
      index: 'personal',
      title: 'Personal',
    },
    ...(appMode === AppMode.STUDENT
      ? [
          {
            icon: <FamilyRestroom />,
            index: 'students',
            title: 'Students',
          },
        ]
      : []),
    ...(appMode === AppMode.INSTRUCTOR
      ? [
          {
            icon: <Web />,
            index: 'organization',
            title: 'Organization',
          },
          {
            icon: <School />,
            index: 'activities',
            title: 'Activities',
          },
        ]
      : []),
    { icon: <Payment />, index: 'payments', title: 'Payments' },
    {
      icon: <ToggleOn />,
      index: 'preferences',
      title: 'Preferences',
    },
  ];

  const a11yProps = (index: string) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  const TabPanel = ({ children, index, tab }: TabPanelProps) => {
    return (
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tabpanel-${index}`}
      >
        {tab === index && children}
      </div>
    );
  };

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newTab: string
  ) => {
    setTab(newTab);
    navigate(`/settings?t=${newTab}`);
  };

  useEffect(() => {
    if (t && tabList.some(item => item.index === t)) {
      setTab(t);
    } else {
      navigate(`/settings?t=personal`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <MainLayout
      headerText="Account Settings"
      content={
        <SettingsContainer>
          <StyledTabCard>
            <StyledTabs
              value={tab}
              onChange={handleTabChange}
              aria-label="account settings tabs"
            >
              {tabList.map(({ icon, index, title }) => {
                return (
                  <StyledTab
                    disableRipple
                    key={title}
                    icon={icon}
                    iconPosition="start"
                    label={title}
                    value={index}
                    {...a11yProps(index)}
                  />
                );
              })}
            </StyledTabs>
          </StyledTabCard>
          <StyledCard>
            <CustomDivider />
            <TabPanel tab={tab} index={'personal'}>
              <PersonalInfo />
            </TabPanel>
            {appMode === AppMode.STUDENT && (
              <TabPanel tab={tab} index={'students'}>
                <StudentInfo />
              </TabPanel>
            )}
            {appMode === AppMode.INSTRUCTOR && (
              <>
                <TabPanel tab={tab} index={'organization'}>
                  <OrganizationInfo />
                </TabPanel>
                <TabPanel tab={tab} index={'activities'}>
                  <ActivityInfo />
                </TabPanel>
              </>
            )}
            <TabPanel tab={tab} index={'payments'}>
              <PaymentInfo />
            </TabPanel>
            <TabPanel tab={tab} index={'preferences'}>
              <Preferences />
            </TabPanel>
          </StyledCard>
        </SettingsContainer>
      }
    />
  );
};

export default AccountSettings;
