import { Box, TextField, Autocomplete, Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

interface AddressSearchProps {
  disabled?: boolean;
  handleAddressChange?: (address: PlaceType | null) => void;
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function loadScript(
  src: string,
  position: HTMLElement | null,
  id: string,
  callback: string
) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = `${src}&callback=${callback}`;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const AddressSearch: FC<AddressSearchProps> = ({
  disabled,
  handleAddressChange,
}) => {
  const [searchLocation, setSearchLocation] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [placeId, setPlaceId] = useState<string | null>();
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      const callbackName = 'googleMapsLoaded';
      (window as any)[callbackName] = () => {
        console.log('Google Maps API loaded');
      };
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
        callbackName
      );
    }

    loaded.current = true;
  }

  const fetchPredictions = useMemo(
    () =>
      throttle(
        (
          request: {
            componentRestrictions: { country: string[] };
            input: string;
            types: string[];
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  useEffect(() => {
    let active = true;
    const google = (window as any).google;

    if (!autocompleteService.current && google) {
      autocompleteService.current =
        new google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(searchLocation ? [searchLocation] : []);
      return undefined;
    }

    fetchPredictions(
      {
        componentRestrictions: { country: ['ca', 'us'] },
        input: inputValue,
        types: [],
      },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (searchLocation) {
            newOptions = [searchLocation];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [fetchPredictions, inputValue, placeId, searchLocation]);

  return (
    <Autocomplete
      id={'address'}
      disabled={disabled}
      fullWidth
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={searchLocation}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setSearchLocation(newValue);
        setPlaceId(newValue?.place_id || '');
        handleAddressChange && handleAddressChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          disabled={disabled}
          label={
            disabled
              ? 'Toggle the switch above to enter an address'
              : 'Begin typing your address'
          }
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default AddressSearch;
