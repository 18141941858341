import { Typography } from '@mui/material';
import { StandardContainer } from 'assets/styles/shared';
import { CustomCard } from 'components/shared';
import { ActivityData } from 'contexts/DataContext';
import { FC } from 'react';
import styled from 'styled-components';

interface OfferingProps {
  offeredActivities: ActivityData[];
}

const OfferingContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  margin: 0 auto;
`;

const OfferingSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
`;

const OfferingSectionTitle = styled(Typography)`
  margin: 0 auto 40px;
`;

const Offering: FC<OfferingProps> = ({ offeredActivities }) => {
  return (
    <StandardContainer>
      <OfferingSectionContainer>
        <OfferingSectionTitle variant="h3">
          I can teach you...
        </OfferingSectionTitle>
        <OfferingContainer>
          {offeredActivities &&
            offeredActivities.map(({ activity, id }) => (
              <CustomCard
                description={activity.details}
                handleCardClick={() => console.log('clicked')}
                icon={<div />}
                key={id}
                title={activity.activity}
              />
            ))}
        </OfferingContainer>
      </OfferingSectionContainer>
    </StandardContainer>
  );
};

export default Offering;
