import {
  Business,
  LocalAtm,
  PersonAdd,
  Security,
  SupportAgent,
  Web,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Subjects } from 'components/InstructorsInfo';
import { Hero, ThreeTile, WhyUse } from 'components/shared';

const Instructors = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/join');
  };

  const howItWorksTitle = (
    <>
      How <strong>Instrekt</strong> works
    </>
  );

  const howItWorksCards = [
    {
      details: `Create your personalized profile, choose the activities you wish to
      teach, and customize your landing page.`,
      icon: <Web sx={{ fontSize: 40 }} />,
      title: 'Create your profile',
    },
    {
      details: `Send a link to your personalized landing page to your network online,
      include it on business cards, or print it on flyers. Also, Let Instrekt send you
      fully paid students.`,
      icon: <PersonAdd sx={{ fontSize: 40 }} />,
      title: 'Attract new students',
    },
    {
      details: `Watch as your business expands.  Using the tools provided by Instrekt,
      manage your time, mileage, and customer communication with ease.`,
      icon: <Business sx={{ fontSize: 40 }} />,
      title: 'Watch your business grow',
    },
  ];

  const whyUseTitle = (
    <>
      Why use <strong>Instrekt</strong>?
    </>
  );

  const whyUseCards = [
    {
      icon: <LocalAtm />,
      headline: <>Receive paid students</>,
      content: `Let us help with the heavy lifting. Our search
      algorithm will direct local students to you and, with a
      unique URL you can include your booking page on social
      media, business cards, and more.`,
    },
    {
      icon: <Business />,
      headline: <>Be the CEO with a team behind you</>,
      content: `Let the Instrekt team be your team.  Eliminate
      the busy work of running the day to day so you can focus
      on your clients.`,
    },
    {
      icon: <SupportAgent />,
      headline: <>Support every step of the way</>,
      content: `When problems arise, let our team help solve
      client, payment, and scheduling issues.  We have a team
      waiting and available to help you and your clients.`,
    },
    {
      icon: <Security />,
      headline: <>Worry-free transactions</>,
      content: `Our secure payment portal makes collecting
      payments from clients, dealing with refunds, and ensuring
      you get your money easy and secure.`,
    },
  ];

  return (
    <>
      <Hero
        title="Spread your passion with Instrekt"
        subText="Earn a living while being independent"
        callToAction="Get Started"
        handleClick={handleClick}
      />
      <WhyUse title={whyUseTitle} cards={whyUseCards} />
      <ThreeTile title={howItWorksTitle} cards={howItWorksCards} />
      <Subjects />
    </>
  );
};

export default Instructors;
