import { Skeleton, Typography } from '@mui/material';
import React, { FC } from 'react';

import DataLine from './DataLine';
import { CustomButton, CustomDivider, InputFields } from 'components/shared';
import PhoneVerification from 'components/Settings/Personal/PhoneNumber';
import { FieldTypes, SettingSectionProps, UserData } from './types';

import {
  Data,
  FieldContainer,
  SectionContainer,
  SectionHeading,
  SectionLabel,
  StyledAlert,
  StyledLink,
} from './styles';

const SettingSection: FC<SettingSectionProps> = ({
  active,
  data,
  disabled,
  disabledReason,
  expandedIndex,
  editDescription,
  fieldType = 'LEGAL_NAME',
  fieldMap,
  handleAddressChange,
  handleChange,
  handleImageChange,
  handleSubmit,
  handleToggleClick,
  index,
  isDirty,
  label,
  storageRefLocation,
  toggleText = 'Edit',
  showDivider = true,
  setExpandedIndex,
}) => {
  const { AddressSearch, AvatarUploader, TextInput } = InputFields;
  const renderDataLine = (key: string) => {
    switch (fieldType) {
      case FieldTypes.IMAGE:
        return data.imageUrl ? (
          <img src={data.imageUrl} alt="profile" />
        ) : (
          <div>no image</div>
        );
      case FieldTypes.PHONE:
        return data.phone ? (
          <DataLine key={key} label={String(key)} value={data[key]} />
        ) : (
          <div>Not Provided</div>
        );
      default:
        return data[key] ? (
          <DataLine key={key} label={String(key)} value={data[key]} />
        ) : (
          <Skeleton animation="wave" variant="text" width={100} />
        );
    }
  };

  const renderField = (key: string) => {
    switch (fieldType) {
      case FieldTypes.ADDRESS:
        return <AddressSearch handleAddressChange={handleAddressChange} />;
      case FieldTypes.EMAIL:
        return (
          <TextInput
            id={key}
            disabled={disabled}
            label={fieldMap[key]}
            onChange={handleChange}
            value={data.email}
          />
        );
      case FieldTypes.IMAGE:
        return (
          <AvatarUploader
            currentImageUrl={data.imageUrl}
            handleImageChange={handleImageChange}
            imageName="avatar"
            storageRefLocation={storageRefLocation}
          />
        );
      case FieldTypes.LEGAL_NAME:
        return (
          <TextInput
            fullWidth
            id={key}
            disabled={disabled}
            label={fieldMap[key]}
            onChange={handleChange}
            value={data[key as keyof UserData]}
          />
        );
      case FieldTypes.PHONE:
        return (
          <PhoneVerification
            currentNumber={data.phone}
            setExpandedIndex={setExpandedIndex}
          />
        );
      default:
        return null;
    }
  };

  return (
    <SectionContainer>
      <SectionHeading>
        <SectionLabel>{label}</SectionLabel>
        {expandedIndex === index || expandedIndex === null ? (
          <StyledLink onClick={handleToggleClick}>
            {active ? 'Cancel' : toggleText}
          </StyledLink>
        ) : (
          <Typography color="text.disabled">{toggleText}</Typography>
        )}
      </SectionHeading>
      {!active ? (
        <Data>
          {Object.keys(data).map(key => (
            <React.Fragment key={key}>{renderDataLine(key)}</React.Fragment>
          ))}
        </Data>
      ) : (
        <>
          <div>{editDescription && editDescription}</div>
          {disabled && (
            <StyledAlert severity="info">{disabledReason}</StyledAlert>
          )}
          <form onSubmit={handleSubmit}>
            <FieldContainer>
              {Object.keys(data).map(key => (
                <React.Fragment key={key}>{renderField(key)}</React.Fragment>
              ))}
            </FieldContainer>
            {fieldType !== 'IMAGE' && fieldType !== 'PHONE' && (
              <CustomButton
                buttonText="Save"
                disabled={!isDirty}
                type="submit"
                variant="outlined"
              />
            )}
          </form>
        </>
      )}
      {showDivider && <CustomDivider margin="large" />}
    </SectionContainer>
  );
};

export default SettingSection;
