import {
  Card,
  CardContent,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { FC } from 'react';

import {
  CustomAlert,
  CustomDivider,
  CustomToggleButtonGroup,
  InputFields,
} from 'components/shared';

import {
  AdjustmentsContainer,
  InputContainer,
  LessonInformationContainer,
} from './styles';
import { RequestStatus } from 'api/lesson.utils';

interface RateAndDiscountsProps {
  discountAmount: number | null;
  discountType: 'fixed' | 'percentage';
  overrideDuration: string;
  overrideRate: number | null;
  requestStatus: RequestStatus;
  handleDiscountAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDiscountType: (
    event: React.MouseEvent<HTMLElement>,
    newDiscountType: 'fixed' | 'percentage'
  ) => void;
  handleDurationOverride: (e: SelectChangeEvent<string>) => void;
  handleRateOverride: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RateAndDiscounts: FC<RateAndDiscountsProps> = ({
  discountAmount,
  discountType,
  overrideDuration,
  overrideRate,
  requestStatus,
  handleDiscountAmount,
  handleDiscountType,
  handleDurationOverride,
  handleRateOverride,
}) => {
  const { DropDown, TextInput } = InputFields;

  const discountButtons = [
    {
      value: 'fixed',
      name: 'Fixed',
    },
    {
      value: 'percentage',
      name: 'Percentage',
    },
  ];

  const lessonLengthOptions = [
    {
      name: '15 minutes',
      value: '15',
    },
    {
      name: '20 minutes',
      value: '20',
    },
    {
      name: '30 minutes',
      value: '30',
    },
    {
      name: '45 minutes',
      value: '45',
    },
    {
      name: '60 minutes',
      value: '60',
    },
  ];

  return (
    <LessonInformationContainer>
      {requestStatus === RequestStatus.NEW && (
        <CustomAlert
          body={`If you discussed any pricing changes with your client, provide discounts
        for lesson packages, or are charging a different rate for this lesson, you can
        adjust the rate and apply any discounts here. Please note: all discounts are applied to
        the total lesson track cost.`}
          title="Adjust your rate and apply any discounts"
        />
      )}
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5">Update Rate</Typography>
          <AdjustmentsContainer>
            <InputContainer>
              <TextInput
                adornmentProps={{
                  startAdornment: `$`,
                }}
                label="Rate"
                onChange={handleRateOverride}
                value={overrideRate !== null ? overrideRate : ''}
                variant="standard"
              />
              <DropDown
                label="Length"
                onChange={handleDurationOverride}
                options={lessonLengthOptions}
                value={overrideDuration}
              />
            </InputContainer>
            <CustomDivider margin="small" />
            <Typography variant="h5">Apply Discount</Typography>
            <AdjustmentsContainer></AdjustmentsContainer>
            <CustomToggleButtonGroup
              aria-label="discount type"
              buttons={discountButtons}
              exclusive
              onChange={handleDiscountType}
              value={discountType}
              width={'300'}
            />
            <InputContainer>
              {discountType === 'fixed' ? (
                <TextInput
                  adornmentProps={{
                    startAdornment: `$`,
                  }}
                  label="Fixed"
                  onChange={handleDiscountAmount}
                  value={discountAmount !== null ? discountAmount : ''}
                  variant="standard"
                />
              ) : (
                <TextInput
                  adornmentProps={{
                    endAdornment: `%`,
                  }}
                  label="Percentage"
                  onChange={handleDiscountAmount}
                  value={discountAmount !== null ? discountAmount : ''}
                  variant="standard"
                />
              )}
            </InputContainer>
          </AdjustmentsContainer>
        </CardContent>
      </Card>
    </LessonInformationContainer>
  );
};

export default RateAndDiscounts;
