import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import { db } from './firebaseConfig';
import {
  ActivityQueryData,
  ActivityQuery,
} from 'components/Settings/Organization/types';
import { Activity, ActivityData } from 'contexts/DataContext';

export interface ActivityListObject {
  id: string;
  activity: string;
}

export const getUserActivities = (
  orgId: string,
  setActivities: (activityData: ActivityData[]) => void
) => {
  if (!orgId) return;
  console.log('activity query ran after mount');

  const userActivitiesCollection = collection(
    db,
    'organizations',
    orgId,
    'activities'
  );

  const fetchActivities = async () => {
    const snapshot = await getDocs(query(userActivitiesCollection));
    setActivities(
      snapshot.docs.map(doc => ({
        id: doc.id,
        activity: doc.data() as Activity,
      }))
    );
  };

  fetchActivities();
};

export const getPublishedActivities = async (userId: string) => {
  let results: ActivityQueryData[] = [];
  const querySnapshot = await getDocs(
    query(
      collection(db, 'users', userId, 'activities'),
      where('publishedId', '!=', '')
    )
  );
  querySnapshot.forEach(doc => {
    results.push({ id: doc.id, activity: doc.data() as ActivityQuery });
  });
  return results;
};

export const getAvailableOrganizationActivities = async (
  orgId: string,
  setActivities: (activityData: ActivityData[]) => void
) => {
  const availableActivitiesCollection = query(
    collection(db, 'organizations', orgId, 'activities'),
    where('active', '==', true)
  );

  const fetchActivities = async () => {
    const snapshot = await getDocs(availableActivitiesCollection);
    setActivities(
      snapshot.docs.map(
        doc =>
          ({
            id: doc.id,
            activity: doc.data(),
          } as ActivityData)
      )
    );
  };

  fetchActivities();
};

export const saveActivityDocument = async (
  orgId: string,
  activityData: ActivityData
) => {
  let idRef = '';
  if (!orgId) return;

  const { id, activity } = activityData;

  if (id) {
    await updateExistingActivity(id, activity, orgId);
  } else {
    idRef = await addNewActivity(orgId, activity);
  }

  return idRef;
};

const updateExistingActivity = async (
  id: string,
  activity: Activity,
  orgId: string
) => {
  await setDoc(doc(db, 'organizations', orgId, 'activities', id), activity, {
    merge: true,
  });
};

const addNewActivity = async (orgId: string, activity: Activity) => {
  const newActivityRef = await addDoc(
    collection(db, 'organizations', orgId, 'activities'),
    activity
  );

  return newActivityRef.id;
};

export const getAvailableActivities = async () => {
  let results: ActivityListObject[] = [];
  const querySnapshot = await getDocs(
    query(collection(db, 'activities'), orderBy('name'))
  );
  querySnapshot.forEach(doc => {
    results.push({ id: doc.id, activity: doc.data().name });
  });
  return results;
};
