import { Alert, Typography } from '@mui/material';
import { updateUserProfileDocument } from 'api/user.utils';
import { InputFields } from 'components/shared';
import SignupContext from 'contexts/SignupContext';
import UserContext from 'contexts/UserContext';
import { useContext } from 'react';

const ProfilelImage = () => {
  const { updateInstructorData } = useContext(SignupContext);
  const { avatarUrl, currentUser } = useContext(UserContext);

  const { AvatarUploader } = InputFields;

  const storageRefLocation = `/avatars/${currentUser?.id}`;

  const handleNewImageChange = (imagePath: string) => {
    updateInstructorData('imagePath', imagePath);
    currentUser?.id &&
      updateUserProfileDocument(currentUser.id, {
        imagePath,
      });
  };

  return (
    <>
      <Typography variant="h3">Set Profile Image</Typography>
      <Alert severity="info" variant="outlined">
        Please ensure your profile image is a clear, professional headshot or a
        logo for your organization. This will be used on your landing page and
        to build trust and let your clients know who you are.
      </Alert>
      <AvatarUploader
        currentImageUrl={avatarUrl || ''}
        handleImageChange={handleNewImageChange}
        imageName="avatar"
        storageRefLocation={storageRefLocation}
      />
    </>
  );
};

export default ProfilelImage;
