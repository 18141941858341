import { useState, useEffect, useMemo } from 'react';
import { Dayjs } from 'dayjs';

import {
  LessonData,
  LessonStatus,
  RequestData,
  RequestStatus,
  addLessonsToRequest,
  getLessonsFromRequest,
} from 'api/lesson.utils';
import { SelectChangeEvent } from '@mui/material';

const useTracks = (selectedTrack: RequestData) => {
  const [activeStep, setActiveStep] = useState(0);
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [discountType, setDiscountType] = useState<'fixed' | 'percentage'>(
    'fixed'
  );
  const [isDirty, setIsDirty] = useState(false);
  const [lessons, setLessons] = useState<LessonData[]>([]);
  const [overrideDuration, setOverrideDuration] = useState<string>(
    selectedTrack.request.lessonDuration
  );
  const [overrideRate, setOverrideRate] = useState<number | null>(
    selectedTrack.request.lessonRate
  );
  const [previousLessons, setPreviousLessons] = useState<LessonData[]>([]);
  const [response, setResponse] = useState<string | Error>('');
  const [sameTime, setSameTime] = useState<boolean>(false);

  const { id, request } = selectedTrack;

  const validateNumberOrEmptyInput = (input: string) => {
    if (input.trim() === '') {
      return null;
    }
    const number = parseFloat(input);
    return isNaN(number) ? null : number;
  };

  const handleAddNewLesson = () => {
    const newLessonsArray = [...lessons];
    const newEmptyLesson = {
      id: '',
      lesson: {
        activity: request.activity,
        familyId: request.requester.id,
        completed: false,
        date: null,
        instructorId: request.instructor.id,
        requestId: id,
        status: LessonStatus.SCHEDULED,
        studentId: request.requester.id,
      },
    };
    newLessonsArray.push(newEmptyLesson);

    setLessons(newLessonsArray);
  };

  const handleCloseAlert = () => {
    setResponse('');
  };

  const handleDateChange = (index: number, newDate: Dayjs | null) => {
    const newLessonsArray = [...lessons];
    newLessonsArray[index].lesson.date = newDate;
    setLessons(newLessonsArray);
  };

  const handleDeleteLesson = (index: number) => {
    const newLessonsArray = [...lessons];
    newLessonsArray.splice(index, 1);
    setLessons(newLessonsArray);
  };

  const handleDiscountAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = validateNumberOrEmptyInput(event.target.value);
    setDiscountAmount(newAmount);
  };

  const handleDiscountType = (
    event: React.MouseEvent<HTMLElement>,
    newType: 'fixed' | 'percentage'
  ) => {
    setDiscountType(newType);
    setDiscountAmount(null);
  };

  const handleDurationOverride = (event: SelectChangeEvent) => {
    setOverrideDuration(event.target.value);
  };

  const handleRateOverride = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRate = validateNumberOrEmptyInput(event.target.value);
    setOverrideRate(newRate);
  };

  const handleReset = () => {
    setLessons(previousLessons);
    setIsDirty(false);
  };

  const handleUpdateProposal = () => {
    const updatedRequestData = {
      ...request,
      lessonCount: validLessons.length,
      lessonDuration: overrideDuration
        ? overrideDuration
        : request.lessonDuration,
      lessonRate: overrideRate ? overrideRate : request.lessonRate,
      discount: {
        amount: discountAmount,
        type: discountType,
      },
      requestStatus: RequestStatus.SENT,
    };

    addLessonsToRequest(id, validLessons, updatedRequestData, setResponse);
    setIsDirty(false);
  };

  const validLessons = lessons.filter(
    lesson => lesson.lesson.date && lesson.lesson.date.isValid()
  );

  const generateTotalLessonCost = useMemo(() => {
    if (!overrideRate || !overrideDuration) {
      return;
    }
    let totalCost = lessons.reduce((acc, lesson) => {
      if (!lesson.lesson.date) return acc;
      return acc + (overrideRate ? overrideRate : request.lessonRate);
    }, 0);

    if (discountAmount && discountType === 'percentage') {
      totalCost -= (totalCost * discountAmount) / 100;
    }

    if (discountAmount && discountType === 'fixed') {
      totalCost -= discountAmount;
    }

    return totalCost > 0 ? totalCost.toFixed(2) : 0;
  }, [
    overrideRate,
    overrideDuration,
    lessons,
    discountAmount,
    discountType,
    request.lessonRate,
  ]);

  useEffect(() => {
    if (
      !request.lessonCount ||
      !id ||
      request.requestStatus === RequestStatus.NEW
    )
      return;

    getLessonsFromRequest(id, setLessons, setPreviousLessons);
  }, [id, request.lessonCount, request.requestStatus]);

  useEffect(() => {
    if (!selectedTrack) return;

    setOverrideDuration(selectedTrack.request.lessonDuration);
    setOverrideRate(selectedTrack.request.lessonRate);
    setDiscountAmount(selectedTrack.request.discount.amount);
    setDiscountType(selectedTrack.request.discount.type);
    setSameTime(selectedTrack.request.sameTime);
  }, [selectedTrack]);

  return {
    activeStep,
    discountAmount,
    discountType,
    isDirty,
    lessons,
    overrideDuration,
    overrideRate,
    previousLessons,
    response,
    sameTime,
    setActiveStep,
    setDiscountAmount,
    setDiscountType,
    setIsDirty,
    setLessons,
    setOverrideDuration,
    setOverrideRate,
    setPreviousLessons,
    setResponse,
    setSameTime,
    handleAddNewLesson,
    handleCloseAlert,
    handleDateChange,
    handleDeleteLesson,
    handleDiscountAmount,
    handleDiscountType,
    handleDurationOverride,
    handleRateOverride,
    handleReset,
    handleUpdateProposal,
    generateTotalLessonCost,
    validLessons,
  };
};

export default useTracks;
