import { FC, createContext, useState } from 'react';

import { saveActivityDocument } from 'api/activity.utils';
import { StudentData, initialStudentData } from 'api/student.utils';
import { OrganizationData } from 'api/organization.utils';
import {
  Frequency,
  LessonData,
  LessonTypes,
  RequestData,
  RequestStatus,
  Travel,
} from 'api/lesson.utils';
import { Contact, ContactData } from 'api/contact.utils';
import { FamilyData } from 'api/family.utils';

export enum ActivityType {
  NONE = 'None',
  ASL = 'American Sign Language',
  BASS = 'Bass Guitar',
  BONSAI = 'Bonsai Training',
  CELLO = 'Cello',
  DRUMS = 'Drums',
  GUITAR = 'Guitar',
  PIANO = 'Piano',
  SKATEBOARDING = 'Skateboarding',
  SURFING = 'Surfing',
  SWIMMING = 'Swimming',
  VIOLIN = 'Violin',
  VOICE = 'Voice Acting',
  YOGA = 'Yoga',
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

export interface Rate {
  adjustedCost: number;
  cost: number;
  duration: 15 | 20 | 30 | 45 | 60;
  id: string;
  type: LessonTypes;
}

export interface Activity {
  active: boolean;
  activity: string;
  activityId: string | null;
  archived: boolean;
  details: string;
  rates: Rate[];
  timeOfDay: string[];
}

export interface ActivityData {
  id: string;
  activity: Activity;
}

interface IDataContext {
  activityData: ActivityData;
  activityList: { id: string; name: ActivityType }[];
  incomingRequests: RequestData[];
  outgoingRequests: RequestData[];
  searchLocation: PlaceType | null;
  selectedActivity: string | null;
  selectedRequest: RequestData | null;
  selectedTrack: RequestData | null;
  showArchived: boolean;
  userActivities: ActivityData[];
  userContacts: ContactData[];
  userFamilies: FamilyData[];
  userLessons: LessonData[];
  userOrganization: OrganizationData;
  userStudents: StudentData[];

  addNewActivity: () => void;
  clearMatchedActivityData: (activityId: string, activity: string) => void;
  editActivity: (activityId: string) => void;
  saveActivity: (orgId: string, updatedActivityData: ActivityData) => void;
  setActivityData: React.Dispatch<React.SetStateAction<ActivityData>>;
  setIncomingRequests: React.Dispatch<React.SetStateAction<RequestData[]>>;
  setOutgoingRequests: React.Dispatch<React.SetStateAction<RequestData[]>>;
  setSearchLocation: React.Dispatch<React.SetStateAction<PlaceType | null>>;
  setSelectedActivity: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedRequest: React.Dispatch<React.SetStateAction<RequestData | null>>;
  setSelectedTrack: React.Dispatch<React.SetStateAction<RequestData | null>>;
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
  setUserActivities: React.Dispatch<React.SetStateAction<ActivityData[]>>;
  setUserContacts: React.Dispatch<React.SetStateAction<ContactData[]>>;
  setUserFamilies: React.Dispatch<React.SetStateAction<FamilyData[]>>;
  setUserLessons: React.Dispatch<React.SetStateAction<LessonData[]>>;
  setUserOrganization: React.Dispatch<React.SetStateAction<OrganizationData>>;
  setUserStudents: React.Dispatch<React.SetStateAction<StudentData[]>>;
  toggleArchiveActivity: (
    orgId: string,
    activityToArchive: ActivityData
  ) => void;
  togglePublishActivity: (
    orgId: string,
    updatedActivityData: ActivityData
  ) => void;
  updateUserContacts: (contact: Contact) => void;
}

const initialActivity = {
  id: '',
  activity: {
    active: false,
    activity: '',
    activityId: '',
    archived: false,
    details: '',
    rates: [],
    timeOfDay: [],
  },
};

export const initialOrganizationData = {
  id: '',
  organization: {
    active: false,
    bio: '',
    buttonText: '',
    heroImage: '',
    heroMainText: '',
    heroSubText: '',
    orgName: '',
    ownerId: '',
    ownerName: '',
    philosophy: '',
    slug: '',
    travelOptions: {
      toInstructor: true,
      toStudent: false,
      toThirdParty: false,
    },
  },
};

export const initialRequestData: RequestData = {
  id: '',
  request: {
    activity: { name: '', id: '' },
    discount: {
      amount: null,
      type: 'fixed',
    },
    haveLocation: false,
    instructor: { id: '', name: '', orgName: '' },
    latestMessage: null,
    lessonCount: 0,
    lessonDuration: '30',
    lessonLocation: null,
    lessonRate: 50,
    lessonType: 'Private' as LessonTypes,
    frequency: 'WEEKLY' as Frequency,
    nextSession: null,
    note: '',
    originalTimeStamp: null,
    preferredTOD: [],
    proposalFeedback: '',
    requester: { name: '', id: '' },
    requestStatus: 'New' as RequestStatus,
    sameTime: false,
    startASAP: true,
    startDate: null,
    student: { id: '', student: initialStudentData },
    timestamp: null,
    travel: 'toInstructor' as Travel,

    cancelReason: '',
    declineReason: '',
  },
};

const DataContext = createContext<IDataContext>({
  activityData: initialActivity,
  activityList: [],
  incomingRequests: [],
  outgoingRequests: [],
  searchLocation: null,
  selectedActivity: null,
  selectedRequest: initialRequestData,
  selectedTrack: initialRequestData,
  showArchived: false,
  userActivities: [initialActivity],
  userContacts: [],
  userFamilies: [],
  userLessons: [],
  userOrganization: initialOrganizationData,
  userStudents: [],

  addNewActivity: () => {},
  clearMatchedActivityData: () => {},
  editActivity: () => {},
  saveActivity: () => {},
  setActivityData: () => {},
  setIncomingRequests: () => {},
  setOutgoingRequests: () => {},
  setSearchLocation: () => {},
  setSelectedActivity: () => {},
  setSelectedRequest: () => {},
  setSelectedTrack: () => {},
  setShowArchived: () => {},
  setUserActivities: () => {},
  setUserContacts: () => {},
  setUserFamilies: () => {},
  setUserLessons: () => {},
  setUserOrganization: () => {},
  setUserStudents: () => {},
  toggleArchiveActivity: () => {},
  togglePublishActivity: () => {},
  updateUserContacts: () => {},
});

export default DataContext;

export const DataProvider: FC = ({ children }) => {
  const [activityData, setActivityData] =
    useState<ActivityData>(initialActivity);
  const [incomingRequests, setIncomingRequests] = useState<RequestData[]>([]);
  const [outgoingRequests, setOutgoingRequests] = useState<RequestData[]>([]);
  const [searchLocation, setSearchLocation] = useState<PlaceType | null>(null);
  const [selectedActivity, setSelectedActivity] = useState<string | null>(null);
  const [selectedRequest, setSelectedRequest] = useState<RequestData | null>(
    null
  );
  const [selectedTrack, setSelectedTrack] = useState<RequestData | null>(null);
  const [showArchived, setShowArchived] = useState(false);
  const [userActivities, setUserActivities] = useState<ActivityData[]>([]);
  const [userContacts, setUserContacts] = useState<ContactData[]>([]);
  const [userFamilies, setUserFamilies] = useState<FamilyData[]>([]);
  const [userLessons, setUserLessons] = useState<LessonData[]>([]);
  const [userOrganization, setUserOrganization] = useState<OrganizationData>(
    initialOrganizationData
  );
  const [userStudents, setUserStudents] = useState<StudentData[]>([]);

  const activityList = Object.entries(ActivityType).map(([key, value]) => ({
    id: key,
    name: value,
  }));

  const addNewActivity = () => {
    setSelectedActivity('new');
    setActivityData(initialActivity);
  };

  const clearMatchedActivityData = (activityId: string, activity: string) => {
    setSelectedActivity('new');
    setActivityData({
      id: '',
      activity: {
        ...initialActivity.activity,
        activity: activity,
        activityId: activityId,
      },
    });
  };

  const editActivity = (activityId: string) => {
    setSelectedActivity(activityId);
  };

  const updateActivityState = (
    activity: ActivityData,
    changes: Partial<ActivityData['activity']>
  ): ActivityData => ({
    ...activity,
    activity: {
      ...activity.activity,
      ...changes,
    },
  });

  const updateActivitiesList = (
    updatedActivity: ActivityData,
    activities: ActivityData[]
  ) =>
    activities.map(activity =>
      activity.id === updatedActivity.id ? updatedActivity : activity
    );

  const saveActivity = async (
    orgId: string,
    updatedActivityData: ActivityData
  ) => {
    const idRef = await saveActivityDocument(orgId, updatedActivityData);

    if (idRef) {
      setUserActivities(prevActivities => [
        ...prevActivities,
        { id: idRef, activity: updatedActivityData.activity },
      ]);
    }

    setUserActivities(prevActivities =>
      prevActivities.map(activity =>
        activity.id === updatedActivityData.id ? updatedActivityData : activity
      )
    );
  };

  const toggleArchiveActivity = async (
    orgId: string,
    activityToArchive: ActivityData
  ) => {
    const updatedActivity = updateActivityState(activityToArchive, {
      active: false,
      archived: !activityToArchive.activity.archived,
    });

    await saveActivityDocument(orgId, updatedActivity);

    setUserActivities(prevActivities =>
      updateActivitiesList(updatedActivity, prevActivities)
    );

    if (userActivities) {
      const remainingActivities = userActivities.find(
        item => item.id !== activityToArchive.id && !item.activity.archived
      );
      if (remainingActivities) {
        editActivity(remainingActivities.id);
      } else {
        addNewActivity();
      }
    }
  };

  const togglePublishActivity = async (
    orgId: string,
    updatedActivityData: ActivityData
  ) => {
    const updatedActivity = updateActivityState(updatedActivityData, {
      active: !updatedActivityData.activity.active,
    });

    await saveActivityDocument(orgId, updatedActivity);

    setUserActivities(prevActivities =>
      updateActivitiesList(updatedActivity, prevActivities)
    );
  };

  const updateUserContacts = (contact: Contact) => {
    const contactIndex = userContacts.findIndex(
      c => c.contact.contactId === contact.contactId
    );

    if (contactIndex !== -1) {
      userContacts.splice(contactIndex, 1);
    } else {
      userContacts.push({ id: '', contact: contact });
    }
  };

  const value = {
    activityData: activityData,
    activityList: activityList,
    incomingRequests: incomingRequests,
    outgoingRequests: outgoingRequests,
    searchLocation: searchLocation,
    selectedActivity: selectedActivity,
    selectedRequest: selectedRequest,
    selectedTrack: selectedTrack,
    showArchived: showArchived,
    userActivities: userActivities,
    userContacts: userContacts,
    userFamilies: userFamilies,
    userLessons: userLessons,
    userOrganization: userOrganization,
    userStudents: userStudents,

    addNewActivity: addNewActivity,
    clearMatchedActivityData: clearMatchedActivityData,
    editActivity: editActivity,
    saveActivity: saveActivity,
    setActivityData: setActivityData,
    setIncomingRequests: setIncomingRequests,
    setOutgoingRequests: setOutgoingRequests,
    setSearchLocation: setSearchLocation,
    setSelectedActivity: setSelectedActivity,
    setSelectedRequest: setSelectedRequest,
    setSelectedTrack: setSelectedTrack,
    setShowArchived: setShowArchived,
    setUserActivities: setUserActivities,
    setUserContacts: setUserContacts,
    setUserFamilies: setUserFamilies,
    setUserLessons: setUserLessons,
    setUserOrganization: setUserOrganization,
    setUserStudents: setUserStudents,
    toggleArchiveActivity: toggleArchiveActivity,
    togglePublishActivity: togglePublishActivity,
    updateUserContacts: updateUserContacts,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
