import { Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';
import CustomButton from './CustomButton';

interface HeroProps {
  callToAction?: string;
  handleClick?: () => void;
  image?: string;
  link?: string;
  subText?: string;
  title?: string;
}

interface HeroContainerProps {
  image: string;
}

const HeroContainer = styled.div<HeroContainerProps>`
  background-image: linear-gradient(
      to bottom,
      rgba(11, 10, 10, 0.6),
      rgba(11, 10, 10, 0.6)
    ),
    url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  @media only screen and (max-width: 1600px) {
    height: 700px;
  }
`;

const HeroContent = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fffefe;
  @media only screen and (max-width: 375px) {
    text-align: start;
    height: 80%;
  }
`;

const HeroContentText = styled.div`
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    width: 80%;
  }
  @media only screen and (max-width: 375px) {
    position: absolute;
    align-items: flex-start;
  }
`;

const HeroTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 24px;
`;

const HeroSubText = styled(Typography)`
  margin-bottom: 24px;
  padding: 4px 8px;
`;

const HeroSection: FC<HeroProps> = ({
  callToAction,
  handleClick,
  image,
  subText,
  title,
}) => {
  return (
    <>
      <HeroContainer image={image || ''}>
        <HeroContent>
          <HeroContentText>
            <HeroTitle variant="h1">{title}</HeroTitle>
            {subText && <HeroSubText variant="h5">{subText}</HeroSubText>}
            {callToAction && (
              <CustomButton
                buttonText={callToAction}
                variant="contained"
                onClick={handleClick}
              />
            )}
          </HeroContentText>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
