import { Typography } from '@mui/material';
import { useContext } from 'react';
import styled from 'styled-components';

import { Check } from '@mui/icons-material';
import { StandardContainer } from 'assets/styles/shared';
import AppContext from 'contexts/AppContext';

interface DarkModeProps {
  darkMode: boolean | undefined;
}

interface DirectionProps {
  direction: 'left' | 'right';
}

interface PhotoInfoProps {
  desc: string;
  photoAlignment?: 'left' | 'right';
  tileData: string[];
  title: string;
  imageUrl: string;
}

const PhotoInfoContainer = styled.div<DirectionProps>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'right' ? 'row' : 'row-reverse'};
  min-height: 425px;
`;

const PhotoInfoContent = styled(Typography)`
  font-weight: 600;
  padding-top: 6px;
`;

const PhotoInfoDesc = styled(Typography)`
  font-weight: 300;
  line-height: 1.4;
  padding-bottom: 24px;
`;

const PhotoInfoImageSection = styled.img`
  background: #000000;
  flex: 1;
  width: 50%;
`;

const PhotoInfoListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const PhotoInfoListSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 80px 48px;
`;

const PhotoInfoTitle = styled(Typography)`
  font-weight: 600;
  padding-bottom: 24px;
`;

const StyledCheck = styled(Check)`
  color: #58abff;
  padding-right: 8px;
`;

const StyledContainer = styled(StandardContainer)<DarkModeProps>`
  background: ${({ darkMode }) =>
    darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'};
  max-width: 100%;
  margin-top: 0px;
`;

const PhotoInfo = ({
  desc,
  photoAlignment = 'left',
  tileData,
  title,
  imageUrl,
}: PhotoInfoProps) => {
  const { isDarkMode } = useContext(AppContext);

  return (
    <StyledContainer darkMode={isDarkMode}>
      <PhotoInfoContainer direction={photoAlignment}>
        <PhotoInfoImageSection src={imageUrl} alt="placeholder" />
        <PhotoInfoListSection>
          <PhotoInfoTitle variant="h5">{title}</PhotoInfoTitle>
          <PhotoInfoDesc>{desc}</PhotoInfoDesc>
          {tileData.map(item => {
            return (
              <PhotoInfoListItem key={item}>
                <StyledCheck sx={{ fontSize: 32 }} />
                <PhotoInfoContent>{item}</PhotoInfoContent>
              </PhotoInfoListItem>
            );
          })}
        </PhotoInfoListSection>
      </PhotoInfoContainer>
    </StyledContainer>
  );
};

export default PhotoInfo;
