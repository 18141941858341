import { Alert, AlertTitle, Typography } from '@mui/material';
import { InputFields } from 'components/shared';
import SignupContext from 'contexts/SignupContext';
import UserContext from 'contexts/UserContext';
import { useContext } from 'react';
import styled from 'styled-components';

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const PersonalInfo = () => {
  const { applicationData, updateInstructorData } = useContext(SignupContext);
  const { currentUser } = useContext(UserContext);

  const { TextInput } = InputFields;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    updateInstructorData(id, value);
  };

  return (
    <>
      <Typography variant="h3">Verify Personal Information</Typography>
      <Alert severity="info" variant="outlined">
        Please verify your personal information before continuing. This
        information will be used on your landing page and to build trust with
        your clients so they know you are who you say you are.
      </Alert>
      {currentUser?.user.googleAuthenticated && (
        <Alert severity="warning" variant="outlined">
          <AlertTitle>Google Authentication</AlertTitle>
          You have authenticated your account with Google. If you need to change
          your name or email, please contact support.
        </Alert>
      )}
      <NameContainer>
        <TextInput
          disabled={currentUser?.user.googleAuthenticated}
          fullWidth
          id="firstName"
          label="First Name"
          onChange={handleInputChange}
          value={applicationData.application.instructor.firstName}
        />
        <TextInput
          disabled={currentUser?.user.googleAuthenticated}
          fullWidth
          id="lastName"
          label="Last Name"
          onChange={handleInputChange}
          value={applicationData.application.instructor.lastName}
        />
      </NameContainer>
      <TextInput
        disabled={currentUser?.user.googleAuthenticated}
        fullWidth
        id="email"
        label="Email Address"
        onChange={handleInputChange}
        value={applicationData.application.instructor.email}
      />
    </>
  );
};

export default PersonalInfo;
