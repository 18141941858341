import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';

import { Student } from 'api/student.utils';
import { CustomButton, InputFields } from 'components/shared';

interface AddStudentDialogProps {
  cancel: () => void;
  isDirty: boolean;
  onClose: () => void;
  open: boolean;
  save: () => void;
  student: Student;
  updateStudent: (key: string, value: string) => void;
}

const StyledAlert = styled(Alert)`
  margin: 0 auto;
  width: fit-content;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AddStudentDialog: FC<AddStudentDialogProps> = ({
  cancel,
  isDirty,
  onClose,
  open,
  save,
  student,
  updateStudent,
}) => {
  const { LabeledCheckbox, TextInput } = InputFields;

  const [checked, setChecked] = useState(false);
  const [ageError, setAgeError] = useState('');

  const handleCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSave = () => {
    setAgeError('');
    if (isNaN(Number(student.age))) {
      setAgeError('Age must be a number');
      return;
    }
    save();
  };

  const handleUpdateStudent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    updateStudent(id, value);
  };

  const disabled = student.ownerId
    ? !student.age || !student.firstName || !isDirty || !checked
    : !student.age || !student.firstName || !isDirty;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        {student.ownerId ? 'Update Existing Student' : 'Add New Student'}
      </DialogTitle>
      <StyledDialogContent>
        {student.ownerId ? (
          <StyledAlert severity="warning">
            Modifying the student name can be confusing for an instructor. Make
            sure to notify your instructor of any changes. If you are attempting
            to change the student connected with an ongoing lesson, create a new
            student and contact your instructor.
          </StyledAlert>
        ) : (
          <StyledAlert severity="info">
            Add a student to your account by entering their name and age. When
            requesting a lesson, you can select the student you want to add to
            the lesson.
          </StyledAlert>
        )}
        <TextInput
          fullWidth
          id="firstName"
          label="Name"
          onChange={handleUpdateStudent}
          value={student.firstName}
        />
        <TextInput
          error={ageError}
          fullWidth
          helperText={ageError}
          id="age"
          label="Age"
          onChange={handleUpdateStudent}
          value={student.age}
        />
        {student.ownerId && (
          <LabeledCheckbox
            onChange={handleCheckBox}
            checked={student.ownerId ? checked : true}
            label="I have read and understand the warning above."
          />
        )}
      </StyledDialogContent>
      <DialogActions>
        <CustomButton buttonText="Cancel" onClick={cancel} variant="outlined" />
        <CustomButton
          buttonText={student.ownerId ? 'Update' : 'Add'}
          disabled={disabled}
          onClick={handleSave}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddStudentDialog;
