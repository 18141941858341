import SendIcon from '@mui/icons-material/Send';
import { Card, TextField } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  MessageData,
  sendMessage,
  useSubscribeToMessages,
} from 'api/lesson.utils';
import { CustomButton, CustomDivider } from 'components/shared';
import UserContext from 'contexts/UserContext';
import Message from './Message';
import DataContext, { initialRequestData } from 'contexts/DataContext';
import { Timestamp } from 'firebase/firestore';

const ConversationPanel = styled(Card)`
  flex-grow: 1;
  flex-basis: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  padding-top: 16px;
  width: 100%;
  height: 100%;
`;

const GroupedMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MessagesDiv = styled.div`
  flex: 1 1 auto;
  position: relative;
  padding-top: 24px;
`;

const MessagesContainer = styled.div`
  position: absolute;
  inset: 0px;
  overflow: hidden auto;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin: 0;
  padding: 8px 24px 0;
  width: 100%;
`;

const Conversation = () => {
  const { selectedRequest: conversationData } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const [newMessage, setNewMessage] = useState('');
  const [allMessages, setAllMessages] = useState<MessageData[]>([]);

  const { id: conversationId, request } =
    conversationData || initialRequestData;
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useSubscribeToMessages(currentUser, conversationId, setAllMessages);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [allMessages]);

  const receiverId = useMemo(() => {
    const { instructor, requester } = request;
    return currentUser?.id === instructor.id ? requester.id : instructor.id;
  }, [currentUser?.id, request]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      sendMessage(currentUser, newMessage, conversationId, receiverId);
      setNewMessage('');
    }
  };

  const getDateString = (timestamp: Timestamp) => {
    return timestamp.toDate().toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      handleSendMessage();
    }
  };

  const groupedMessages = useMemo(() => {
    const groups: MessageData[][] = [];
    allMessages.forEach((message, index) => {
      const timestamp = message.message.timestamp;
      const prevTimestamp =
        index === 0
          ? message.message.timestamp
          : allMessages[index - 1].message.timestamp;

      const date = timestamp && getDateString(timestamp);
      const prevDate = prevTimestamp && getDateString(prevTimestamp);

      if (index === 0 || prevDate !== date) {
        groups.push([message]);
      } else {
        groups[groups.length - 1].push(message);
      }
    });
    return groups;
  }, [allMessages]);

  const renderGroupedMessages = () => {
    if (!groupedMessages.length) return null;

    return groupedMessages.map((group, index) => {
      const firstMessage = group[0];
      const timestamp = firstMessage.message.timestamp;
      const date = timestamp && getDateString(timestamp);

      return (
        <GroupedMessageContainer key={index}>
          <CustomDivider margin="large" text={date} />
          {group.map(({ id, message }) => (
            <Message key={id} id={id} message={message} />
          ))}
        </GroupedMessageContainer>
      );
    });
  };

  return (
    <ConversationPanel>
      <MessagesDiv>
        <MessagesContainer id="chat-feed">
          {renderGroupedMessages()}
          <div ref={messagesEndRef}></div>
        </MessagesContainer>
      </MessagesDiv>
      <InputContainer>
        <TextField
          disabled={!conversationId}
          value={newMessage}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          type="text"
          placeholder="Type message..."
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px 0 0 4px',
            },
            bottom: '0',
            width: '100%',
          }}
        />
        <CustomButton
          buttonText={<SendIcon />}
          disabled={!conversationId || !newMessage.trim()}
          onClick={handleSendMessage}
          variant="contained"
          sx={{
            borderRadius: '0 4px 4px 0',
          }}
        />
      </InputContainer>
    </ConversationPanel>
  );
};

export default Conversation;
