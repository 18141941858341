import { Alert, Typography } from '@mui/material';
import { getImageURL } from 'api/user.utils';
import { CustomDivider, InputFields } from 'components/shared';
import SignupContext from 'contexts/SignupContext';
import UserContext from 'contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LandingPageDetails = () => {
  const { applicationData, updateOrganizationData } = useContext(SignupContext);
  const { currentUser } = useContext(UserContext);

  const { TextInput, ImageField, TextArea } = InputFields;

  const [imageUrl, setImageUrl] = useState('');

  const handleImageChange = (imagePath: string) => {
    updateOrganizationData('heroImage', imagePath);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    updateOrganizationData(id, value);
  };

  useEffect(() => {
    let isMounted = true;

    if (applicationData.application.organization.heroImage) {
      getImageURL(applicationData.application.organization.heroImage, url => {
        isMounted && setImageUrl(url);
      });
    }

    return () => {
      isMounted = false;
    };
  }, [applicationData.application.organization.heroImage]);

  return (
    <>
      <Typography variant="h3">Landing Page Details</Typography>
      <Alert severity="info" variant="outlined">
        These information provided here will be on your custom landing page. You
        should include as much detail as possible so your customers can get a
        good understanding of who you are and what you offer.
      </Alert>
      <SectionContainer>
        <CustomDivider margin="large" text="Hero Section" />
        <TextInput
          id="heroMainText"
          label="Main Text"
          inputProps={{ maxLength: 40 }}
          onChange={handleInputChange}
          value={applicationData.application.organization.heroMainText}
        />
        <TextInput
          id="heroSubText"
          label="Sub Text"
          inputProps={{ maxLength: 90 }}
          onChange={handleInputChange}
          value={applicationData.application.organization.heroSubText}
        />
        <TextInput
          id="buttonText"
          label="CTA Button Text"
          inputProps={{ maxLength: 40 }}
          onChange={handleInputChange}
          value={applicationData.application.organization.buttonText}
        />
        <ImageField
          currentImageUrl={imageUrl}
          height={400}
          imageName="hero"
          storageRefLocation={`/lps/${currentUser?.id}`}
          handleImageChange={handleImageChange}
          width={595}
        />
      </SectionContainer>
      <SectionContainer>
        <CustomDivider margin="large" text="About" />
        <TextArea
          id="bio"
          inputProps={{ maxLength: 510 }}
          label="Say a little bit about yourself"
          onChange={handleInputChange}
          rows={4}
          value={applicationData.application.organization.bio}
        />
        <TextArea
          id="philosophy"
          inputProps={{ maxLength: 510 }}
          label="What is your teaching philosophy"
          onChange={handleInputChange}
          rows={4}
          value={applicationData.application.organization.philosophy}
        />
      </SectionContainer>
    </>
  );
};

export default LandingPageDetails;
