import { Edit } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useContext, useState } from 'react';
import styled from 'styled-components';

import {
  BillingType,
  Student,
  StudentData,
  saveStudentDocument,
} from 'api/student.utils';
import {
  CustomButton,
  InputFields,
  TooltipIconButton,
} from 'components/shared';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }: { maxWidth: string }) => maxWidth};
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

const TooltipButtonContainer = styled.div`
  position: absolute;
  right: 0;
`;

interface LessonDefaultInfoProps {
  student: StudentData;
}

const LessonDefaultInfo: FC<LessonDefaultInfoProps> = ({ student }) => {
  const { setUserStudents, userActivities, userStudents } =
    useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { DropDown, TextInput } = InputFields;

  const [edit, setEdit] = useState(false);
  const [updatedDefaults, setUpdatedDefaults] = useState<Student>(
    student.student
  );

  const {
    student: {
      defaultActivity,
      defaultBillingType,
      defaultLessonLength,
      defaultLessonRate,
    },
  } = student;

  const getBillingDescription = (type: string) => {
    switch (type) {
      case 'hourly':
        return 'Pays an hourly rate';
      case 'monthly':
        return 'Pays monthly for a set number of lessons';
      case 'lesson':
        return 'Pays a per lesson amount';
      default:
        return 'No default billing plan';
    }
  };

  const getBillingRateType = (type: string) => {
    switch (type) {
      case 'hourly':
        return 'per hour';
      case 'lesson':
        return 'per lesson';
      case 'monthly':
        return 'per month';
      default:
        return null;
    }
  };

  const EndAdornment = () => {
    switch (updatedDefaults.defaultBillingType) {
      case 'hourly':
        return <Typography>per hour</Typography>;
      case 'lesson':
        return <Typography>per lesson</Typography>;
      case 'monthly':
        return <Typography>per month</Typography>;
      default:
        return null;
    }
  };

  const handleDropDownChange = (
    event: SelectChangeEvent<string | number | readonly string[] | undefined>
  ) => {
    const { name, value } = event.target;
    setUpdatedDefaults(prev => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setUpdatedDefaults(prev => ({ ...prev, [id]: value }));
  };

  const handleUpdateDefaults = () => {
    if (!currentUser) return;

    saveStudentDocument(currentUser, updatedDefaults, student.id).then(res => {
      const { docId, success } = res;
      if (!success) return;

      if (docId) {
        const updatedStudents = userStudents.map(s =>
          s.id === docId ? { ...s, student: updatedDefaults } : s
        );
        setUserStudents(updatedStudents);
      }
    });
    toggleEdit();
  };

  const toggleEdit = () => {
    setEdit(prev => !prev);
    setUpdatedDefaults(student.student);
  };

  const activityOptions = userActivities
    .filter(activity => activity.activity.active)
    .map(activity => ({
      name: activity.activity.activity,
      value: activity.id,
    }));

  const billingTypeOptions = [
    { label: 'No billing plan.', value: BillingType.NONE },
    { label: 'Pays an hourly rate', value: BillingType.HOURLY },
    {
      label: 'Pays monthly for a set number of lessons',
      value: BillingType.MONTHLY,
    },
    { label: 'Pays a specific amount per lesson', value: BillingType.LESSON },
  ];

  const defaultInformation = [
    {
      title: 'Default Activity',
      value: userActivities.find(activity => activity.id === defaultActivity)
        ?.activity.activity,
    },
    {
      title: 'Default Billing Type',
      value: getBillingDescription(defaultBillingType),
    },
    {
      title: 'Default Lesson Length',
      value: `${defaultLessonLength} minutes`,
    },
    {
      title: 'Default Lesson Rate',
      value:
        defaultBillingType !== BillingType.NONE
          ? `$ ${defaultLessonRate} / ${getBillingRateType(defaultBillingType)}`
          : 'Select default billing type to set rate',
    },
  ];

  const defaultLessonLengthOptions = [
    { name: '30 minutes', value: '30' },
    { name: '45 minutes', value: '45' },
    { name: '60 minutes', value: '60' },
  ];

  const disableSubmit = Object.keys(updatedDefaults).every(
    key =>
      updatedDefaults[key as keyof Student] ===
      student.student[key as keyof Student]
  );

  return (
    <>
      {!edit ? (
        <Container maxWidth="800px">
          <TooltipButtonContainer>
            <TooltipIconButton
              action={toggleEdit}
              icon={<Edit />}
              tooltipText="Edit Student"
            />
          </TooltipButtonContainer>
          <Heading>
            <Typography variant="h4">Lesson Defaults</Typography>
          </Heading>
          <Container maxWidth="400px">
            {defaultInformation.map(info => (
              <DataContainer key={info.title}>
                <Typography variant="body1">{info.title}:</Typography>
                <Typography variant="h6" sx={{ paddingLeft: 4 }}>
                  {info.value}
                </Typography>
              </DataContainer>
            ))}
          </Container>
        </Container>
      ) : (
        <Container maxWidth="800px">
          <Heading>
            <Typography variant="h4">Edit Lesson Defaults</Typography>
          </Heading>
          <InputContainer>
            <DropDown
              id="defaultActivity"
              label="Default Activity"
              options={activityOptions}
              onChange={handleDropDownChange}
              placeholder="Select an activity"
              value={updatedDefaults.defaultActivity || ''}
            />
            <DropDown
              label="Default Lesson Length"
              id="defaultLessonLength"
              options={defaultLessonLengthOptions}
              onChange={handleDropDownChange}
              value={updatedDefaults.defaultLessonLength}
            />
          </InputContainer>
          <FormControl component="fieldset">
            <FormLabel component="legend">Default Billing Type</FormLabel>
            <RadioGroup
              aria-label="defaultBillingType"
              name="defaultBillingType"
              value={updatedDefaults.defaultBillingType}
              onChange={handleInputChange}
            >
              {billingTypeOptions.map(option => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio id="defaultBillingType" />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <InputContainer>
            {updatedDefaults.defaultBillingType !== BillingType.NONE && (
              <TextInput
                fullWidth
                id="defaultLessonRate"
                label="Default Lesson Rate"
                onChange={handleInputChange}
                value={updatedDefaults.defaultLessonRate}
                adornmentProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {<EndAdornment />}
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">$ </InputAdornment>
                  ),
                }}
              />
            )}
          </InputContainer>
          <ButtonContainer>
            <CustomButton
              buttonText="Cancel"
              onClick={toggleEdit}
              variant="text"
            />
            <CustomButton
              buttonText="Update"
              disabled={disableSubmit}
              onClick={handleUpdateDefaults}
              variant="contained"
            />
          </ButtonContainer>
        </Container>
      )}
    </>
  );
};

export default LessonDefaultInfo;
