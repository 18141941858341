import {
  Alert,
  AlertTitle,
  CircularProgress,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Frequency,
  LessonTypes,
  Request,
  Travel,
  saveInitialRequestDocument,
} from 'api/lesson.utils';
import {
  OrganizationData,
  getOrganizationBySlug,
} from 'api/organization.utils';
import { StudentData } from 'api/student.utils';
import StudentSelector from 'components/RequestInstructor/StudentSelector';
import {
  CustomButton,
  CustomDivider,
  CustomToggleButtonGroup,
  InputFields,
} from 'components/shared';
import { PlaceType } from 'components/shared/InputFields/LocationSearch';
import UserContext from 'contexts/UserContext';

import { StandardContainer, PageHeading } from 'assets/styles/shared';
import DataContext, {
  ActivityData,
  initialOrganizationData,
  initialRequestData,
} from 'contexts/DataContext';
import { getAvailableOrganizationActivities } from 'api/activity.utils';
import { Dayjs } from 'dayjs';

const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin: 24px auto 48px;
  width: 540px;
`;

const StartDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RequestInstructor = () => {
  const navigate = useNavigate();
  const { orgName } = useParams();
  const { userStudents } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const [availableActivities, setAvailableActivities] = useState<
    ActivityData[]
  >([]);
  const [formActive, setFormActive] = useState(false);
  const [orgData, setOrgData] = useState<OrganizationData>(
    initialOrganizationData
  );
  const [requestData, setRequestData] = useState<Request>(
    initialRequestData.request
  );
  const [sendingRequest, setSendingRequest] = useState(false);

  const {
    AddressSearch,
    DropDown,
    LabeledCheckbox,
    TextInput,
    TimeOfDayField,
  } = InputFields;
  const { organization } = orgData;

  const getAvailableTypesForActivity = (activityName: string) => {
    const activity = availableActivities.find(
      act => act.activity.activity === activityName
    );
    if (!activity) return [];

    const typesSet = new Set<LessonTypes>();
    activity.activity.rates.forEach(rate => {
      if (rate.type) {
        typesSet.add(rate.type);
      }
    });

    if ([...typesSet].length === 1) {
      updateRequestData('lessonType', [...typesSet][0]);
    }

    return [...typesSet];
  };

  const updateRequestData = (
    key: string,
    value:
      | boolean
      | number
      | number[]
      | PlaceType
      | string
      | string[]
      | StudentData
      | { [x: string]: boolean | string }
      | { name: string | undefined; id: string | null }
      | { cost: number; adjustedCost: number }
      | Dayjs
      | null
  ) => {
    setRequestData(prevData => ({ ...prevData, [key]: value }));
  };

  const handleAddStudent = (studentData: StudentData) => {
    updateRequestData('student', studentData);
  };

  const handleActivityChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (value === '') {
      setFormActive(false);
    } else {
      setFormActive(true);
    }
    const activityName = availableActivities?.find(
      act => act.activity.activityId === value
    )?.activity.activity;
    updateRequestData('activity', { name: activityName, id: value });
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    updateRequestData('startASAP', isChecked);
    if (isChecked) {
      updateRequestData('startDate', null);
    }
  };

  const handleDurationChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    duration: number | number[] | null
  ) => {
    if (duration !== null) {
      console.log(duration);
      updateRequestData('lessonDuration', duration);
      updateCostsBasedOnDuration(duration);
    }
  };

  const handleFrequencyChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    updateRequestData('frequency', value);
  };

  const handleTravelChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    updateRequestData('travel', value);
  };

  const handleLocationChange = (address: PlaceType | null) => {
    address && updateRequestData('lessonLocation', address);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    updateRequestData(id, value);
  };

  const handleSendRequest = () => {
    setSendingRequest(true);
    // set timeout to simulate sending request.  needed?
    setTimeout(() => {
      if (!orgData || !currentUser) return;
      saveInitialRequestDocument(orgData, requestData, currentUser).then(
        ref => {
          ref && navigate('/requests');
        }
      );
    }, 2000);
  };

  const handleTODClick = (
    event: React.MouseEvent<HTMLElement>,
    timeDay: string[]
  ) => {
    updateRequestData('preferredTOD', timeDay);
  };

  const handleTypeOfLessonToggle = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    type: string | string[] | null
  ) => {
    if (type !== null) {
      updateRequestData('lessonType', type);

      const activity = availableActivities.find(
        act => act.activity.activityId === requestData.activity.id
      );
      if (activity) {
        const rates = activity.activity.rates.filter(
          rate => rate.type === type
        );
        const durations = rates.map(rate => rate.duration).filter(Boolean);
        if (durations.length > 0) {
          updateRequestData('lessonDuration', durations[0]);
          updateCostsBasedOnDuration(durations[0]);
        }
      }
    }
  };

  const renderValue = (value: string) => {
    switch (value) {
      case 'toInstructor':
        return 'I am willing to travel to the instructor';
      case 'toStudent':
        return 'I would like the instructor to travel to me';
      case 'toThirdParty':
        return 'I will travel to a third party location specified by the instructor';
    }
  };

  const updateCostsBasedOnDuration = (duration: number | number[]) => {
    const activity = availableActivities.find(
      act => act.activity.activityId === requestData.activity.id
    );

    if (activity) {
      const matchingRate = activity.activity.rates.find(
        rate =>
          rate.duration === duration && rate.type === requestData.lessonType
      );

      if (matchingRate) {
        // Assuming matchingRate has 'cost' and 'adjustedCost' properties
        const { cost, adjustedCost } = matchingRate;

        const newRate = {
          cost,
          adjustedCost,
        };

        // Update your state or context with the new cost information
        // This is an example, you might need to adjust it based on how your state is managed
        updateRequestData('rate', newRate);
      }
    }
  };

  const activityOptions = availableActivities?.map(act => {
    return {
      name: `${act.activity.activity}`,
      value: `${act.activity.activityId}`,
    };
  });

  const freqencyOptions = Object.entries(Frequency).map(([key, value]) => ({
    name: value as string,
    value: key,
  }));

  const travelOptions = useMemo(() => {
    if (!orgData.organization.travelOptions) return [];

    return Object.entries(orgData.organization.travelOptions)
      .filter(([, value]) => value)
      .map(([key]) => {
        switch (key) {
          case 'toInstructor':
            return {
              name: 'I am willing to travel to the instructor',
              value: key,
            };
          case 'toStudent':
            return {
              name: 'I would like the instructor to travel to me',
              value: key,
            };
          case 'toThirdParty':
            return {
              name: 'I will travel to a third party location specified by the instructor',
              value: key,
            };
        }
        return {
          name: 'a',
          value: 'a',
        };
      });
  }, [orgData.organization.travelOptions]);

  const availableLessonTypes = useMemo(() => {
    return getAvailableTypesForActivity(requestData.activity.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData.activity.name]);

  const typeButtons = useMemo(() => {
    const baseTypes = [
      { name: 'Private', value: LessonTypes.PRIVATE },
      { name: 'Semi-private', value: LessonTypes.SEMIPRIVATE },
      { name: 'Drop-in', value: LessonTypes.DROPIN },
    ];
    return baseTypes.filter(button =>
      availableLessonTypes.includes(button.value)
    );
  }, [availableLessonTypes]);

  const durationButtons = useMemo(() => {
    if (!requestData.lessonType || !requestData.activity.id) return [];

    const activity = availableActivities.find(
      act => act.activity.activityId === requestData.activity.id
    );
    if (!activity) return [];

    const rates = activity.activity.rates.filter(
      rate => rate.type === requestData.lessonType
    );
    const durationSet = new Set<number>();
    rates.forEach(rate => {
      if (rate.duration) {
        durationSet.add(rate.duration);
      }
    });
    return [...durationSet].map(duration => ({
      name: `${duration} min`,
      value: duration,
    }));
  }, [availableActivities, requestData.lessonType, requestData.activity.id]);

  const disabledTOD = useMemo(() => {
    if (!availableActivities || availableActivities.length === 0) return;
    const { activity } = requestData;
    const activityData = availableActivities.find(
      act => act.activity.activityId === activity.id
    );

    if (activityData) {
      return activityData.activity.timeOfDay;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData.activity]);

  const disabledRequestButton = useMemo(() => {
    return (
      !requestData.activity ||
      !requestData.lessonType ||
      !requestData.lessonDuration ||
      requestData.preferredTOD.length === 0 ||
      (!requestData.startASAP && !requestData.startDate) ||
      !requestData.student.id ||
      (requestData.travel === Travel.toStudent && !requestData.lessonLocation)
    );
  }, [requestData]);

  const userIsOrgOwner = useMemo(() => {
    if (!currentUser || !orgData) return false;
    return currentUser.id === orgData.organization.ownerId;
  }, [currentUser, orgData]);

  useEffect(() => {
    if (!currentUser || !orgData.organization.ownerId) return;

    updateRequestData('requester', {
      id: currentUser.id,
      name: currentUser.user.firstName + ' ' + currentUser.user.lastName,
    });
    updateRequestData('instructor', {
      id: orgData.organization.ownerId,
      name: orgData.organization.ownerName,
    });
  }, [
    currentUser,
    orgData.organization.ownerId,
    orgData.organization.ownerName,
  ]);

  useEffect(() => {
    if (availableActivities && availableActivities.length === 1) {
      updateRequestData('activity', {
        name: availableActivities[0].activity.activity,
        id: availableActivities[0].activity.activityId,
      });
      setFormActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableActivities]);

  useEffect(() => {
    if (orgName) {
      getOrganizationBySlug(setOrgData, orgName);
    }
  }, [orgName]);

  useEffect(() => {
    if (!orgData.id) return;

    getAvailableOrganizationActivities(orgData.id, setAvailableActivities);
  }, [orgData]);

  useEffect(
    () => {
      if (!orgData.organization.travelOptions) return;

      if (travelOptions.length === 1) {
        updateRequestData('travel', travelOptions[0].value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgData.organization.travelOptions]
  );

  useEffect(() => {
    if (!requestData.lessonType) return;

    const activity = availableActivities.find(
      act => act.activity.activityId === requestData.activity.id
    );
    if (activity) {
      const rates = activity.activity.rates.filter(
        rate => rate.type === requestData.lessonType
      );
      const durations = rates.map(rate => rate.duration).filter(Boolean);

      if (durations.length > 0) {
        updateRequestData('lessonDuration', durations[0]);
        updateCostsBasedOnDuration(durations[0]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData.activity, requestData.lessonType]);

  return (
    <StandardContainer>
      <PageHeading>
        Send request to {organization.ownerName} at {organization.orgName}
      </PageHeading>
      <RequestContainer>
        {userIsOrgOwner ? (
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            You are the owner of this organization. You cannot request a
            proposal from yourself.
          </Alert>
        ) : (
          <Alert severity="info">
            <AlertTitle>Important</AlertTitle>
            Please note that this is a request for a proposal. You will be
            contacted by the instructor to discuss the details of your request
            and to provide you with a proposal.
          </Alert>
        )}
        <CustomDivider margin="small" text="Pick your activity" />
        {availableActivities && availableActivities.length === 1 ? (
          <TextInput
            disabled
            helperText={
              'This field is not editable since it is the only available activity'
            }
            label={'Activity'}
            value={requestData.activity.name}
          />
        ) : (
          <DropDown
            id={'activity'}
            label={'Pick the activity you would like to learn'}
            onChange={handleActivityChange}
            options={activityOptions}
            value={requestData.activity.id}
          />
        )}
        {!requestData.activity.name && (
          <Alert severity="warning">
            <AlertTitle>Important</AlertTitle>
            You must select an activity before you can continue. The rest of
            this page cannot be interacted with until the activity you would
            like to learn is selected.
          </Alert>
        )}
        <CustomDivider margin="small" text="Lesson Preferences" />
        {typeButtons.length === 0 ? (
          <CustomToggleButtonGroup
            buttons={[
              { name: 'Private', value: LessonTypes.PRIVATE },
              { name: 'Semi-Private', value: LessonTypes.SEMIPRIVATE },
              { name: 'Drop-in', value: LessonTypes.DROPIN },
            ]}
            disabled={true}
            onChange={handleTypeOfLessonToggle}
            value={requestData.lessonType}
          />
        ) : (
          <CustomToggleButtonGroup
            buttons={typeButtons}
            disabled={!formActive}
            onChange={handleTypeOfLessonToggle}
            value={requestData.lessonType}
          />
        )}
        {durationButtons.length === 0 ? (
          <CustomToggleButtonGroup
            buttons={[
              { name: '15 min', value: 15 },
              { name: '20 min', value: 20 },
              { name: '30 min', value: 30 },
              { name: '45 min', value: 45 },
              { name: '60 min', value: 60 },
            ]}
            disabled={true}
            onChange={handleDurationChange}
            value={requestData.lessonDuration}
          />
        ) : (
          <CustomToggleButtonGroup
            buttons={durationButtons}
            disabled={!formActive}
            onChange={handleDurationChange}
            value={requestData.lessonDuration}
          />
        )}
        <DropDown
          disabled={!formActive}
          id={'frequency'}
          label={'How often would you like to have lessons?'}
          onChange={handleFrequencyChange}
          options={freqencyOptions}
          value={requestData.frequency}
        />
        <StartDateContainer>
          <LabeledCheckbox
            disabled={!formActive}
            label={'I would like to start as soon as possible'}
            onChange={handleCheckboxChange}
            checked={requestData.startASAP}
          />
          {requestData.startASAP ? (
            <Alert severity="info">
              No need to pick a specific start date. Your instructor will offer
              a start date based on their availability and starting as soon as
              possible.
            </Alert>
          ) : (
            <DatePicker
              disabled={requestData.startASAP}
              label="Start Date"
              onChange={date => updateRequestData('startDate', date)}
              value={requestData.startDate}
            />
          )}
        </StartDateContainer>
        <TimeOfDayField
          disabled={!formActive}
          disabledTOD={disabledTOD}
          handleTODClick={handleTODClick}
          infoAlert="Select the available times you would like the instructor to consider. If you are flexible, select all times that would work for you."
          label="Preferred Time of Day"
          tOD={requestData.preferredTOD}
        />
        <CustomDivider margin="small" text="Student Info" />
        <StudentSelector
          disabled={!formActive}
          handleAddStudent={handleAddStudent}
          students={userStudents || []}
        />
        <CustomDivider margin="small" text="Location Info" />
        {travelOptions.length === 1 ? (
          <TextInput
            disabled
            helperText={
              'This field is not editable since it is the only available travel option'
            }
            label={'Lesson Location'}
            value={renderValue(requestData.travel)}
          />
        ) : (
          <DropDown
            disabled={!formActive}
            id={'travel'}
            label={'Pick where you would like to have lessons'}
            onChange={handleTravelChange}
            options={travelOptions}
            value={requestData.travel}
          />
        )}
        {requestData.travel === Travel.toStudent && (
          <AddressSearch handleAddressChange={handleLocationChange} />
        )}
        <CustomDivider margin="small" text="Additional Notes" />
        <TextField
          disabled={!formActive}
          id={'note'}
          label="Notes"
          minRows={5}
          multiline
          onChange={handleInputChange}
          placeholder="Enter any information that would be helpful for your instructor to know to create your proposal"
          value={requestData.note}
        />
        <CustomButton
          buttonText={
            sendingRequest ? 'Sending Request...' : 'Send Request to Instructor'
          }
          disabled={disabledRequestButton || sendingRequest || userIsOrgOwner}
          startIcon={
            sendingRequest ? <CircularProgress size={24} /> : undefined
          }
          onClick={handleSendRequest}
        />
      </RequestContainer>
    </StandardContainer>
  );
};

export default RequestInstructor;
