import { ChevronRight } from '@mui/icons-material';
import { Alert, Button, Typography } from '@mui/material';
import { LessonTypes } from 'api/lesson.utils';
import { ActivityData } from 'contexts/DataContext';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface OfferingProps {
  activities: ActivityData[];
}

const ActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const OfferingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const RatesContainer = styled(Typography)`
  padding-left: 16px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 48px;
`;

const Offering: FC<OfferingProps> = ({ activities }) => {
  const navigate = useNavigate();
  return (
    <OfferingContainer>
      <StyledAlert
        action={
          <Button
            color="inherit"
            onClick={() => navigate('/settings?t=activities')}
            size="small"
          >
            Go
          </Button>
        }
        severity="info"
      >
        Rate sheets for offered activities are managed in the Activities
        Settings.
      </StyledAlert>
      {activities.map(act => {
        const { activity, id } = act;
        const privRates = activity.rates
          ?.filter(rate => rate.type === LessonTypes.PRIVATE)
          .sort((a, b) => a.duration - b.duration);
        const semiRates = activity.rates
          ?.filter(rate => rate.type === LessonTypes.SEMIPRIVATE)
          .sort((a, b) => a.duration - b.duration);
        const dropInRates = activity.rates
          ?.filter(rate => rate.type === LessonTypes.DROPIN)
          .sort((a, b) => a.duration - b.duration);
        return (
          activity.active && (
            <ActivityContainer key={id}>
              <HeadingContainer>
                <ChevronRight />
                {activity.activity}
              </HeadingContainer>
              {privRates.length > 0 && (
                <TypeContainer>
                  Private Sessions
                  {privRates.map(rate => (
                    <RatesContainer key={rate.id}>
                      &bull; {rate.duration} min sessions at ${rate.cost}
                    </RatesContainer>
                  ))}
                </TypeContainer>
              )}
              {semiRates.length > 0 && (
                <TypeContainer>
                  Semi-Private Sessions
                  {semiRates.map(rate => (
                    <RatesContainer key={rate.id}>
                      &bull; {rate.duration} min sessions at ${rate.cost}
                    </RatesContainer>
                  ))}
                </TypeContainer>
              )}
              {dropInRates.length > 0 && (
                <TypeContainer>
                  Drop-in Sessions
                  {dropInRates.map(rate => (
                    <RatesContainer key={rate.id}>
                      &bull; {rate.duration} min sessions at ${rate.cost}
                    </RatesContainer>
                  ))}
                </TypeContainer>
              )}
            </ActivityContainer>
          )
        );
      })}
    </OfferingContainer>
  );
};

export default Offering;
