import React, { useContext } from 'react';

import { RequestStatus } from 'api/lesson.utils';
import DataContext, { initialRequestData } from 'contexts/DataContext';
import { TrackPanel } from './styles';
import useTracks from 'hooks/useTracks';
import LessonTrack from './LessonTrack';
import RateAndDiscounts from './RateAndDiscounts';
import ProposalReview from './ProposalReview';
import NewTrack from './NewTrack';
import { CustomAlert } from 'components/shared';

const Track = () => {
  const { selectedTrack } = useContext(DataContext);
  const {
    activeStep,
    lessons,
    sameTime,
    discountAmount,
    discountType,
    overrideDuration,
    overrideRate,
    response,
    validLessons,
    generateTotalLessonCost,
    isDirty,

    setActiveStep,
    setLessons,
    setResponse,

    handleAddNewLesson,
    handleCloseAlert,
    handleDateChange,
    handleDeleteLesson,
    handleDiscountAmount,
    handleReset,
    handleUpdateProposal,
    handleDiscountType,
    handleDurationOverride,
    handleRateOverride,
  } = useTracks(selectedTrack || initialRequestData);

  const { request } = selectedTrack || initialRequestData;

  const steps = [
    {
      content: (
        <LessonTrack
          lessons={lessons}
          requestStatus={request.requestStatus}
          sameTime={sameTime}
          handleAddNewLesson={handleAddNewLesson}
          handleClearAllLessons={() => setLessons([])}
          handleDateChange={handleDateChange}
          handleDeleteLesson={handleDeleteLesson}
        />
      ),
      disabled: !validLessons.length,
      label: 'Create lesson track',
      stepAction: () => setActiveStep(prev => prev + 1),
    },
    {
      content: (
        <RateAndDiscounts
          discountAmount={discountAmount}
          discountType={discountType}
          overrideDuration={overrideDuration}
          overrideRate={overrideRate}
          requestStatus={request.requestStatus}
          handleRateOverride={handleRateOverride}
          handleDurationOverride={handleDurationOverride}
          handleDiscountAmount={handleDiscountAmount}
          handleDiscountType={handleDiscountType}
        />
      ),
      disabled: overrideRate === null || overrideRate === 0,
      label: 'Update rate and apply discounts',
      stepAction: () => setActiveStep(prev => prev + 1),
    },
    {
      content: (
        <ProposalReview
          discountAmount={discountAmount}
          discountType={discountType}
          handleCloseAlert={() => setResponse('')}
          handleReset={handleReset}
          handleUpdateProposal={handleUpdateProposal}
          isDirty={isDirty}
          lessonCount={validLessons.length}
          lessonRate={overrideRate ? overrideRate : request.lessonRate}
          lessonTotal={generateTotalLessonCost}
          request={request}
          response={response}
        />
      ),
      disabled: !generateTotalLessonCost,
      label: 'Review and send proposal',
      stepAction: handleUpdateProposal,
    },
  ];

  const renderStepContent = () => {
    return steps[activeStep].content;
  };

  return (
    <TrackPanel>
      {selectedTrack ? (
        selectedTrack.request.requestStatus === RequestStatus.NEW ? (
          <NewTrack
            activeStep={activeStep}
            backStep={() => setActiveStep(prev => prev - 1)}
            renderStepContent={renderStepContent}
            steps={steps}
          />
        ) : (
          <>
            <ProposalReview
              {...{
                lessonCount: validLessons.length,
                lessonRate: overrideRate ? overrideRate : request.lessonRate,
                lessonTotal: generateTotalLessonCost,
                discountAmount,
                discountType,
                response,
                isDirty,
                validLessons,
                overrideRate,
                generateTotalLessonCost,
                request: selectedTrack.request,
                handleReset,
                handleUpdateProposal,
                handleCloseAlert,
              }}
            />
            <RateAndDiscounts
              {...{
                discountAmount,
                discountType,
                overrideDuration,
                overrideRate,
                requestStatus: selectedTrack.request.requestStatus,
                handleRateOverride,
                handleDurationOverride,
                handleDiscountAmount,
                handleDiscountType,
              }}
            />
            <LessonTrack
              {...{
                lessons,
                requestStatus: selectedTrack.request.requestStatus,
                sameTime,
                handleAddNewLesson,
                handleClearAllLessons: () => setLessons([]),
                handleDateChange,
                handleDeleteLesson,
              }}
            />
          </>
        )
      ) : (
        <CustomAlert
          body="If the list to the left is empty, you have no pending requests to create lessons tracks."
          title="Looks like you're all caught up!"
        />
      )}
    </TrackPanel>
  );
};

export default Track;
