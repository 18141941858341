import { Delete, RestartAlt } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { FC } from 'react';

import { LessonData, RequestStatus } from 'api/lesson.utils';
import {
  AddNewButton,
  CustomAlert,
  TooltipIconButton,
} from 'components/shared';

import {
  ClearAllContainer,
  FlexRowContainer,
  LessonInformationContainer,
} from './styles';

interface LessonsTrackProps {
  requestStatus: RequestStatus;
  handleAddNewLesson: () => void;
  handleClearAllLessons?: () => void;
  handleDateChange: (index: number, date: Dayjs | null) => void;
  handleDeleteLesson: (index: number) => void;
  lessons: LessonData[];
  sameTime: boolean;
}

const LessonDate = ({
  lesson,
  index,
  handleDateChange,
  handleDeleteLesson,
  handleTimeChange,
  removeDeleteIcon,
  sameTime,
}: any) => (
  <FlexRowContainer>
    <Typography variant="h6">{`${index + 1}`}</Typography>
    <FlexRowContainer>
      <DatePicker
        label="Lesson date"
        onChange={date => handleDateChange(index, date)}
        sx={{
          width: '100%',
          '& .MuiInputBase-root': {
            width: '100%',
          },
        }}
        value={lesson.lesson.date}
      />
      <TimePicker
        disabled={sameTime && index !== 0}
        label="Lesson time"
        onChange={time => handleTimeChange(index, time)}
        sx={{
          width: '100%',
          '& .MuiInputBase-root': {
            width: '100%',
          },
        }}
        value={lesson.lesson.time}
      />
    </FlexRowContainer>
    {removeDeleteIcon ? (
      <TooltipIconButton
        icon={<Delete />}
        tooltipText="Delete lesson"
        action={() => handleDeleteLesson(index)}
      />
    ) : (
      <IconButton disabled>
        <Delete />
      </IconButton>
    )}
  </FlexRowContainer>
);

const LessonTrack: FC<LessonsTrackProps> = ({
  lessons,
  requestStatus,
  sameTime,
  handleAddNewLesson,
  handleClearAllLessons,
  handleDateChange,
  handleDeleteLesson,
}) => {
  const hasInvalidDate = lessons.some(
    lesson => !lesson.lesson.date || !lesson.lesson.date.isValid()
  );

  console.log('lessons', lessons);

  const sortedLessons = lessons.sort((a, b) => {
    if (!a.lesson.date || !b.lesson.date) return 0;
    const dateA = a.lesson.date.valueOf();
    const dateB = b.lesson.date.valueOf();
    return dateA - dateB;
  });

  const validLessons = lessons.filter(
    lesson => lesson.lesson.date && lesson.lesson.date.isValid()
  );

  return (
    <LessonInformationContainer>
      {requestStatus === RequestStatus.NEW && (
        <CustomAlert
          body={`You can add as many lessons as needed. If the client accepts
          your proposal, they will be charged for each lesson you add. The
          total cost of the lesson track will be available for review on the
          last step. You will be able to duplicate this proposal and send a
          payment request again for future months.`}
          title="Add each lesson date below"
        />
      )}
      <ClearAllContainer>
        {handleClearAllLessons && (
          <TooltipIconButton
            action={handleClearAllLessons}
            disabled={validLessons.length === 0}
            icon={<RestartAlt />}
            tooltipText="Clear all lessons"
          />
        )}
      </ClearAllContainer>
      {sortedLessons.map((lesson, index) => (
        <LessonDate
          key={index}
          lesson={lesson}
          index={index}
          handleDateChange={handleDateChange}
          handleDeleteLesson={handleDeleteLesson}
          removeDeleteIcon={lesson.lesson.date && lesson.lesson.date.isValid()}
          sameTime={sameTime}
        />
      ))}
      {!hasInvalidDate && (
        <AddNewButton
          onClick={handleAddNewLesson}
          shouldPulsate={!validLessons.length}
        />
      )}
    </LessonInformationContainer>
  );
};

export default LessonTrack;
