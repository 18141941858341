import { FC } from 'react';
import styled from 'styled-components';

interface DataLineProps {
  icon?: JSX.Element;
  label: string;
  value?: string | number | JSX.Element | null;
}

const DataContent = styled.div`
  margin-left: 24px;
`;

const DataLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const DataLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DataLine: FC<DataLineProps> = ({ icon, label, value }) => {
  return (
    <DataLineContainer>
      <DataLabel>
        {icon && icon}
        <strong>{label.toUpperCase()}</strong>
      </DataLabel>
      {value && <DataContent>{value}</DataContent>}
    </DataLineContainer>
  );
};

export default DataLine;
