import { Route, Routes } from 'react-router-dom';
import {
  AccountSettings,
  Calendar,
  ConfirmEmailLogin,
  Dashboard,
  Families,
  HomePage,
  Insights,
  Instructors,
  InstructorSignup,
  LandingPage,
  LessonManager,
  Login,
  Messages,
  MyContacts,
  MyLessons,
  MyRequests,
  Privacy,
  RequestInstructor,
  Students,
  Terms,
  TrackManager,
  ViewFamily,
  ViewStudent,
} from 'pages';
import AuthGuard from './AuthGuard';
import RequireAuth from './RequireAuth';
import ScrollToTop from './ScrollToTop';
import Title from './Title';
import { SignupProvider } from 'contexts/SignupContext';

const routeConfig = [
  {
    element: (
      <Title>
        <HomePage />
      </Title>
    ),
    path: '/',
  },
  {
    element: (
      <Title title="Privacy">
        <ScrollToTop>
          <Privacy />
        </ScrollToTop>
      </Title>
    ),
    path: '/privacy',
  },
  {
    element: (
      <Title title="Terms">
        <ScrollToTop>
          <Terms />
        </ScrollToTop>
      </Title>
    ),
    path: '/terms',
  },
  {
    element: (
      <Title title="About">
        <Privacy />
      </Title>
    ),
    path: '/about',
  },
  {
    element: <ConfirmEmailLogin />,
    path: '/confirm',
  },
  {
    element: (
      <Title title="Login">
        <AuthGuard>
          <Login />
        </AuthGuard>
      </Title>
    ),
    path: '/login',
  },
  {
    element: (
      <Title title="Account Settings">
        <RequireAuth>
          <AccountSettings />
        </RequireAuth>
      </Title>
    ),
    path: '/settings',
  },
  {
    element: (
      <Title title="Instructors">
        <ScrollToTop>
          <Instructors />
        </ScrollToTop>
      </Title>
    ),
    path: '/instructors',
  },
  {
    element: (
      <ScrollToTop>
        <LandingPage />
      </ScrollToTop>
    ),
    path: '/i/:orgName',
  },
  {
    element: (
      <Title title="Request Instructor">
        <RequireAuth>
          <RequestInstructor />
        </RequireAuth>
      </Title>
    ),
    path: '/i/:orgName/request',
  },
  {
    element: (
      <Title title="Messages">
        <RequireAuth>
          <Messages />
        </RequireAuth>
      </Title>
    ),
    path: '/messages/',
  },
  {
    element: (
      <Title title="Dashboard">
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      </Title>
    ),
    path: '/dashboard/',
  },
  {
    element: (
      <Title title="Lesson Manager">
        <RequireAuth>
          <LessonManager />
        </RequireAuth>
      </Title>
    ),
    path: '/lesson-manager/',
  },
  {
    element: (
      <Title title="Track Manager">
        <RequireAuth>
          <TrackManager />
        </RequireAuth>
      </Title>
    ),
    path: '/track-manager/',
  },
  {
    element: (
      <Title title="Families">
        <RequireAuth>
          <Families />
        </RequireAuth>
      </Title>
    ),
    path: '/families/',
  },
  {
    element: (
      <Title title="Families">
        <RequireAuth>
          <ViewFamily />
        </RequireAuth>
      </Title>
    ),
    path: '/families/details',
  },
  {
    element: (
      <Title title="Students">
        <RequireAuth>
          <Students />
        </RequireAuth>
      </Title>
    ),
    path: '/students/',
  },
  {
    element: (
      <Title title="Students">
        <RequireAuth>
          <ViewStudent />
        </RequireAuth>
      </Title>
    ),
    path: '/students/details',
  },
  {
    element: (
      <Title title="Insights">
        <RequireAuth>
          <Insights />
        </RequireAuth>
      </Title>
    ),
    path: '/insights/',
  },
  {
    element: (
      <Title title="My Requests">
        <RequireAuth>
          <MyRequests />
        </RequireAuth>
      </Title>
    ),
    path: '/requests/',
  },
  {
    element: (
      <Title title="My Lessons">
        <RequireAuth>
          <MyLessons />
        </RequireAuth>
      </Title>
    ),
    path: '/lessons/',
  },
  {
    element: (
      <Title title="Contacts">
        <RequireAuth>
          <MyContacts />
        </RequireAuth>
      </Title>
    ),
    path: '/contacts/',
  },
  {
    element: (
      <Title title="Instructor Signup">
        <RequireAuth>
          <SignupProvider>
            <InstructorSignup />
          </SignupProvider>
        </RequireAuth>
      </Title>
    ),
    path: '/instructor-signup/',
  },
  {
    element: (
      <Title title="Calendar">
        <RequireAuth>
          <Calendar />
        </RequireAuth>
      </Title>
    ),
    path: '/calendar/',
  },
];

const RenderRoutes = () => (
  <Routes>
    {routeConfig.map(({ element, path }) => (
      <Route key={path} path={path} element={element} />
    ))}
    <Route element={<h1>Not Found!</h1>} />
  </Routes>
);

export default RenderRoutes;
