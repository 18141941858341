import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { RequestStatus, updateRequestDocument } from 'api/lesson.utils';
import { CustomButton, InputFields } from 'components/shared';
import { ChangeEvent, FC } from 'react';
import styled from 'styled-components';

interface ReasonForActionDialogProps {
  buttonText: string;
  handleReason: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  newStatus: RequestStatus;
  open: boolean;
  reason: string;
  reasonContent: string;
  reasonLabel: string;
  reasonTitle: string;
  reasonType: string;
  requestId: string;
  setOpen: () => void;
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 600px;
    max-width: 100%;
    padding: 8px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ReasonForActionDialog: FC<ReasonForActionDialogProps> = ({
  buttonText,
  handleReason,
  newStatus,
  open,
  reason,
  reasonContent,
  reasonLabel,
  reasonTitle,
  reasonType,
  requestId,
  setOpen,
}) => {
  const { TextArea } = InputFields;

  const handleAction = () => {
    updateRequestDocument(requestId, {
      [reasonType]: reason,
      requestStatus: newStatus,
    });
    setOpen();
  };

  return (
    <StyledDialog onClose={setOpen} open={open}>
      <DialogTitle>{reasonTitle}</DialogTitle>
      <StyledDialogContent>
        <DialogContentText>{reasonContent}</DialogContentText>
        <TextArea
          fullWidth
          label={reasonLabel}
          onChange={handleReason}
          rows={8}
          value={reason}
        />
      </StyledDialogContent>
      <DialogActions>
        <CustomButton
          buttonText={'Nevermind'}
          onClick={setOpen}
          variant="text"
        />
        <CustomButton
          buttonText={buttonText}
          color={'error'}
          onClick={handleAction}
        />
      </DialogActions>
    </StyledDialog>
  );
};

export default ReasonForActionDialog;
