import { Event, Home, Note, People, Schedule } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { RequestData } from 'api/lesson.utils';
import { CustomAlert } from 'components/shared';
import styled from 'styled-components';

const CardTitle = styled(CardHeader)`
  color: #58abff;
  margin: 0 auto 8px;
  width: fit-content;
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: fit-content;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const DetailsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 3 1 auto;
  margin: 0 24px;
  padding: 16px;
`;

const InstructorDetails = styled(Typography)`
  margin: 0 auto 16px;
  width: fit-content;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Details = ({ request }: RequestData) => {
  const {
    activity,
    instructor,
    lessonType,
    frequency,
    note,
    haveLocation,
    preferredTOD,
    student,
  } = request;

  const location = haveLocation
    ? 'I have a location and provided the address'
    : 'I will need a location provided';

  const time = (timeOfDay: string) => {
    if (timeOfDay.includes('morning')) {
      return 'Morning (8am - 12pm)';
    } else if (timeOfDay.includes('afternoon')) {
      return 'Afternoon (12pm - 4pm)';
    } else if (timeOfDay.includes('evening')) {
      return 'Evening (4pm - 8pm)';
    } else {
      return '';
    }
  };

  const day = (timeOfDay: string) => {
    if (timeOfDay.includes('mon')) {
      return 'Monday';
    } else if (timeOfDay.includes('tues')) {
      return 'Tuesday';
    } else if (timeOfDay.includes('wed')) {
      return 'Wednesday';
    } else if (timeOfDay.includes('thurs')) {
      return 'Thursday';
    } else if (timeOfDay.includes('fri')) {
      return 'Friday';
    } else if (timeOfDay.includes('sat')) {
      return 'Saturday';
    } else if (timeOfDay.includes('sun')) {
      return 'Sunday';
    } else {
      return '';
    }
  };

  return (
    <DetailsContainer>
      <CustomAlert
        title="This is your original request"
        body="Once a lesson request is accepted and a proposal is created, the information on this tab will update to reflect the proposed plan."
        type="info"
      />
      <DetailsCard variant="outlined">
        <CardTitle title={`${lessonType} ${activity} lessons`} />
        <InstructorDetails variant="h6">
          Instructor {instructor.name}
        </InstructorDetails>
        <CardContentContainer>
          <List>
            <ListItem>
              <ListItemIcon>
                <Event />
              </ListItemIcon>
              <ListItemText primary={frequency} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary={location} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Schedule />
              </ListItemIcon>
              <ListContainer>
                {preferredTOD.length
                  ? preferredTOD.map(timeOfDay => (
                      <ListItemText
                        key={timeOfDay}
                        primary={`${day(timeOfDay)} ${time(timeOfDay)}`}
                      />
                    ))
                  : null}
              </ListContainer>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListContainer>
                {student ? (
                  <Tooltip
                    key={student.id}
                    placement="bottom"
                    title={student.student.notes}
                  >
                    <ListItemText
                      primary={`${student.student.firstName} ${student.student.lastName} (age: ${student.student.age})`}
                    />
                  </Tooltip>
                ) : (
                  <ListItemText primary="No students added as part of this request." />
                )}
              </ListContainer>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Note />
              </ListItemIcon>
              <ListItemText primary={note} />
            </ListItem>
          </List>
        </CardContentContainer>
      </DetailsCard>
    </DetailsContainer>
  );
};

export default Details;
