import { useContext } from 'react';

import Contacts from 'components/Contacts/Contacts';
import { Layouts } from 'components/shared';
import AppContext, { AppMode } from 'contexts/AppContext';
import DataContext from 'contexts/DataContext';

const MyContacts = () => {
  const { appMode } = useContext(AppContext);
  const { userContacts } = useContext(DataContext);

  const { MainLayout } = Layouts;

  return (
    <MainLayout
      headerText={
        appMode === AppMode.INSTRUCTOR ? 'My Clients' : 'My Instructors'
      }
      content={<Contacts contactData={userContacts} />}
    />
  );
};

export default MyContacts;
