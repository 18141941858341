import { Close } from '@mui/icons-material';
import { Alert, Typography } from '@mui/material';
import { useContext } from 'react';
import styled from 'styled-components';

import SignupContext from 'contexts/SignupContext';

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

const ReviewAndConfirm = () => {
  const { applicationData, finishDisabled, handleStepChange } =
    useContext(SignupContext);

  const instructorSteps = applicationData.application.stepValidation;

  const stepsOrder = [
    'welcomeMessage',
    'personalInfo',
    'profileImage',
    'organizationInfo',
    'activityAndDescription',
    'availability',
    'rates',
    'locationAndTravel',
    'landingPageDetails',
    'paymentInfo',
    'acknowledgePolicies',
  ] as const;

  const stepsTitles: Record<(typeof stepsOrder)[number], string> = {
    welcomeMessage: 'Welcome to Instrekt',
    personalInfo: 'Verify Personal Information',
    profileImage: 'Set Profile Image',
    organizationInfo: 'Create Organization Name',
    activityAndDescription: 'Set Activity to Instruct',
    availability: 'Set Your Availability',
    rates: 'Set Rate Information',
    locationAndTravel: 'List Location and travel Preferences',
    landingPageDetails: 'Add Landing Page Details',
    paymentInfo: ' Setup Payment Information',
    acknowledgePolicies: 'Acknowledge Privacy and Terms',
  };

  const handleJumpToStep = (stepKey: keyof typeof stepsTitles) => {
    const StepIndex = stepsOrder.findIndex(key => key === stepKey);
    handleStepChange(StepIndex, true);
  };

  const renderStepLink = (key: keyof typeof stepsTitles) => {
    return (
      <LinkContainer key={key}>
        <Close color="error" />
        <Typography
          onClick={() => handleJumpToStep(key)}
          style={{ cursor: 'pointer' }}
        >
          {stepsTitles[key]}
        </Typography>
      </LinkContainer>
    );
  };

  return (
    <>
      <Typography variant="h3">Review and Confirm</Typography>
      {finishDisabled ? (
        <Alert severity="info" variant="outlined">
          Click the links below to address any information that is not
          completed. Once all information is complete, you will be able to
          finish the registration process.
        </Alert>
      ) : (
        <Alert severity="success" variant="outlined">
          You did it! You have completed all the steps required to successfully
          use the platform to manage your private instruction business. Click
          the finish button to complete the registration process. Remember: all
          of the information you have provided can be updated at any time in the
          settings section of your profile.
        </Alert>
      )}
      {instructorSteps && (
        <>
          {Object.entries(instructorSteps)
            .filter(([, value]) => value === false)
            .map(([key]) => {
              const stepKey = key as keyof typeof stepsTitles;
              return renderStepLink(stepKey);
            })}
        </>
      )}
    </>
  );
};

export default ReviewAndConfirm;
