import { TextSnippet } from '@mui/icons-material';
import { FC } from 'react';

import { StudentData } from 'api/student.utils';
import { DataLine } from 'components/shared';

import {
  CardDetails,
  DisplayContainer,
  MainDisplayContainer,
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
} from './styles';

interface StudentInfoProps {
  studentData: StudentData;
  variant: 'outlined' | 'elevation';
}

const StudentInfo: FC<StudentInfoProps> = ({
  studentData,
  variant = 'elevation',
}) => {
  const { id, student } = studentData;
  return (
    <MainDisplayContainer>
      <DisplayContainer>
        <StyledCard key={id} variant={variant}>
          <StyledCardContent>
            <StyledCardHeader
              subheader={`Age: ${student.age}`}
              title={`Student Details for ${student.firstName} ${student.lastName}`}
            />
            <CardDetails>
              <DataLine
                icon={<TextSnippet />}
                label={'Notes'}
                value={student.notes}
              />
            </CardDetails>
          </StyledCardContent>
        </StyledCard>
      </DisplayContainer>
    </MainDisplayContainer>
  );
};

export default StudentInfo;
