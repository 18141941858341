import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import {
  ButtonContainer,
  LessonInformationContainer,
  SummaryInfoContainer,
} from './styles';
import { CustomAlert, CustomButton, CustomDivider } from 'components/shared';
import { FC } from 'react';
import { Request, RequestStatus } from 'api/lesson.utils';

interface ProposalReviewProps {
  discountAmount: number | null;
  discountType: 'percentage' | 'fixed';
  isDirty: boolean;
  lessonCount: number;
  lessonRate: number | null;
  lessonTotal?: string | number;
  request: Request;
  response: string | Error;

  handleCloseAlert: () => void;
  handleReset: () => void;
  handleUpdateProposal: () => void;
}

const ProposalReview: FC<ProposalReviewProps> = ({
  discountAmount,
  discountType,
  isDirty,
  lessonCount,
  lessonRate,
  lessonTotal,
  request,
  response,

  handleCloseAlert,
  handleReset,
  handleUpdateProposal,
}) => {
  const { requestStatus } = request;
  return (
    <LessonInformationContainer>
      {requestStatus === RequestStatus.NEW && (
        <CustomAlert
          body={`Once you click send proposal, your client will receive a notification
        that they have been sent a proposal. They will have 48 hours to accept or decline
        the proposal. If they accept, you will be notified and the lessons will be added
        your lesson view.  You can resend the proposal if its been declined or expired.
        In the event the proposal is declined, you will be able to make adjustments and
        resend the proposal.`}
          title="Review your lesson track and total cost"
        />
      )}
      <Card variant="outlined">
        <CardHeader
          title={
            request.requestStatus === RequestStatus.NEW
              ? `${request.requestStatus} Proposal`
              : request.requestStatus
          }
          subheader={`for ${request.requester.name}'s ${request.activity.name} Lessons`}
        />
        <CardContent>
          <SummaryInfoContainer>
            <Typography variant="h6">Track summary</Typography>
            <Typography>
              &bull; {lessonCount} lessons at ${lessonRate} each
            </Typography>
            <Typography>
              &bull;{' '}
              {discountType === 'percentage' && discountAmount
                ? `$${
                    lessonRate &&
                    ((lessonCount * lessonRate * discountAmount) / 100).toFixed(
                      2
                    )
                  } discount applied`
                : discountAmount
                ? `$${discountAmount} discount applied`
                : 'no discounts applied'}
            </Typography>
          </SummaryInfoContainer>
          <Typography variant="h6">
            Subtotal: ${lessonCount * Number(lessonRate)}
          </Typography>
          <CustomDivider margin="small" />
          <Typography variant="h4" color="primary">
            Total: ${lessonTotal}
          </Typography>
          <CustomAlert
            title={`You will receive $${(Number(lessonTotal) / 1.12).toFixed(
              2
            )} after the service and processing fees.`}
          />
          {request.proposalFeedback && (
            <CustomAlert
              body={request.proposalFeedback}
              title="Proposal feedback"
              type="error"
            />
          )}
          {response && (
            <CustomAlert
              onClose={handleCloseAlert}
              title={response instanceof Error ? response.message : response}
              type={response instanceof Error ? 'error' : 'success'}
            />
          )}
        </CardContent>
        {request.requestStatus !== RequestStatus.NEW && (
          <ButtonContainer>
            <CustomButton
              buttonText="Reset"
              disabled={!isDirty}
              onClick={handleReset}
              variant="text"
            />
            <CustomButton
              buttonText="Update Proposal"
              disabled={!isDirty}
              onClick={handleUpdateProposal}
            />
          </ButtonContainer>
        )}
      </Card>
    </LessonInformationContainer>
  );
};

export default ProposalReview;
