import { ArrowBack } from '@mui/icons-material';
import { Card } from '@mui/material';
import { ChangeEvent, FC, useContext, useState } from 'react';
import styled from 'styled-components';

import {
  Family,
  initialFamilyData,
  saveFamilyDocument,
} from 'api/family.utils';
import { CustomButton, CustomDivider, InputFields } from 'components/shared';
import { PlaceType } from 'components/shared/InputFields/AddressSearch';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

interface AddFamilytProps {
  toggleAddFamily: () => void;
}

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem 0;
  width: 100%;
  max-width: 800px;
`;

const AddFamilyFormContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: 800px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
  overflow: scroll;
  padding: 1rem;
`;

const AddFamily: FC<AddFamilytProps> = ({ toggleAddFamily }) => {
  const { setUserFamilies } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { AddressSearch, TextArea, TextInput } = InputFields;

  const [newFamily, setNewFamily] = useState<Family>(initialFamilyData);
  const [formErrors, setFormErrors] = useState<Partial<Family>>({});

  const handleAddressChange = (address: PlaceType | null) => {
    if (address) {
      setNewFamily(prev => ({
        ...prev,
        address: address.description,
        placeId: address.place_id,
      }));
    }
  };

  const handleAddFamily = () => {
    if (!currentUser) return;

    saveFamilyDocument(currentUser, newFamily).then(res => {
      if (!res.success) return;

      if (res.docId) {
        setUserFamilies(prev => [
          ...prev,
          { id: res.docId || '', family: newFamily },
        ]);
      }
    });
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;

    setFormErrors(prev => ({ ...prev, [id]: '' }));
    setNewFamily(prev => ({ ...prev, [id]: value }));
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!newFamily.email) return;

    if (!emailRegex.test(newFamily.email)) {
      setFormErrors(prev => ({ ...prev, email: 'Invalid email address' }));
    }
  };

  return (
    <StyledCard>
      <ActionBar>
        <CustomButton
          buttonText={'Go back to families'}
          onClick={toggleAddFamily}
          startIcon={<ArrowBack />}
          variant="text"
        />
      </ActionBar>
      <AddFamilyFormContainer variant="outlined">
        <InputContainer>
          <TextInput
            fullWidth
            id="familyName"
            label="Family Name"
            onChange={handleInputChange}
            value={newFamily.familyName}
          />
        </InputContainer>
        <CustomDivider text="Primary Contact Details" margin="large" />
        <InputContainer>
          <TextInput
            fullWidth
            id="firstName"
            label="First Name"
            onChange={handleInputChange}
            value={newFamily.firstName}
          />
          <TextInput
            fullWidth
            id="lastName"
            label="Last Name"
            onChange={handleInputChange}
            value={newFamily.lastName}
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            error={formErrors.email}
            fullWidth
            helperText={formErrors.email || ''}
            id="email"
            label="Email"
            onChange={handleInputChange}
            onBlur={validateEmail}
            value={newFamily.email}
          />
          <TextInput
            fullWidth
            id="phone"
            label="Phone"
            onChange={handleInputChange}
            value={newFamily.phone}
          />
        </InputContainer>
        <AddressSearch handleAddressChange={handleAddressChange} />
        <CustomDivider text="Notes" margin="large" />
        <TextArea
          fullWidth
          id="notes"
          label="Notes"
          onChange={handleInputChange}
          rows={5}
          value={newFamily.notes}
        />
        <ButtonContainer>
          <CustomButton
            buttonText="Cancel"
            onClick={toggleAddFamily}
            variant="text"
          />
          <CustomButton
            buttonText="Add Family"
            onClick={handleAddFamily}
            variant="contained"
          />
        </ButtonContainer>
      </AddFamilyFormContainer>
    </StyledCard>
  );
};

export default AddFamily;
