import { Close } from '@mui/icons-material';
import {
  Card,
  IconButton,
  Link,
  Paper,
  Slide,
  Snackbar,
  Table,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { FC, Fragment, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Student, StudentStatus, changeStudentStatus } from 'api/student.utils';
import { CustomButton } from 'components/shared';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';
import StudentTableBody from './StudentTableBody';
import StudentTableHead from './StudentTableHead';

type Order = 'asc' | 'desc';

interface IStudentTable {
  handleViewFamily: (id: string) => void;
  handleViewStudent: (id: string) => void;
  toggleAddStudent: () => void;
}

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const TypeFilter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 16px;
`;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: {
    id: string;
    student: { [key in Key]: number | string | Dayjs | null };
  },
  b: {
    id: string;
    student: { [key in Key]: number | string | Dayjs | null };
  }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a.student, b.student, orderBy)
    : (a, b) => -descendingComparator(a.student, b.student, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const StudentTable: FC<IStudentTable> = ({
  handleViewFamily,
  handleViewStudent,
  toggleAddStudent,
}) => {
  const { setUserStudents, userFamilies, userStudents } =
    useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const [activeFilter, setActiveFilter] = useState<string>('active');
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Student>('lastName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleCopyEmail = (email: string) => {
    navigator.clipboard.writeText(email);
    setOpen(true);
  };

  const handleCloseCopySnack = () => {
    setOpen(false);
  };

  const handleFilterChange = (status: string) => {
    setActiveFilter(status);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Student
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleStatusChange = (option: StudentStatus, id: string) => {
    changeStudentStatus(currentUser, id, option).then(success => {
      if (!success) return;

      const updatedStudents = userStudents.map(student => {
        if (student.id === id) {
          return {
            ...student,
            student: { ...student.student, status: option },
          };
        }
        return student;
      });
      setUserStudents(updatedStudents);
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderFilterLinks = () => {
    const filters = ['all', 'active', 'lead', 'inactive'];

    const filterCounts = filters.reduce((acc, filter) => {
      if (filter === 'all') {
        acc[filter] = userStudents.length;
      } else {
        acc[filter] = userStudents.filter(
          student => student.student.status === filter
        ).length;
      }
      return acc;
    }, {} as Record<string, number>);

    return (
      <TypeFilter>
        {filters.map((filter, index) => {
          const count = filterCounts[filter];
          if (count === 0) return null;
          return (
            <Fragment key={filter}>
              {index > 0 && '|'}
              <Link
                component={'button'}
                onClick={() => handleFilterChange(filter)}
                style={{
                  fontWeight: activeFilter === filter ? 'bold' : 'normal',
                }}
              >
                {`${
                  filter.charAt(0).toUpperCase() + filter.slice(1)
                } (${count})`}
              </Link>
            </Fragment>
          );
        })}
      </TypeFilter>
    );
  };

  const filteredStudents =
    activeFilter === 'all'
      ? userStudents
      : userStudents.filter(student => student.student.status === activeFilter);

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredStudents.length)
      : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(filteredStudents, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredStudents, order, orderBy, page, rowsPerPage]
  );

  return (
    <StyledCard>
      <ActionBar>
        <CustomButton
          buttonText={'Add New Student'}
          onClick={toggleAddStudent}
          variant="contained"
        />
      </ActionBar>
      {renderFilterLinks()}
      <TableContainer component={Paper}>
        <Table aria-label="students table">
          <StudentTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <StudentTableBody
            emptyRows={emptyRows}
            students={visibleRows}
            families={userFamilies}
            handleCopyEmail={handleCopyEmail}
            handleStatusChange={handleStatusChange}
            handleViewFamily={handleViewFamily}
            handleViewStudent={handleViewStudent}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredStudents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseCopySnack}
        message="Email copied to clipboard"
        TransitionComponent={Slide}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseCopySnack}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </StyledCard>
  );
};

export default StudentTable;
