import { useContext, useState } from 'react';

import { Conversation, ConversationList } from 'components/Messages';
import { CustomButton, Layouts } from 'components/shared';
import DataContext from 'contexts/DataContext';
import RequestDrawer from 'components/RequestDrawer/RequestDrawer';

const Messages = () => {
  const { MainLayout } = Layouts;
  const { selectedRequest } = useContext(DataContext);

  const [openDrawer, setOpenDrawer] = useState(false);

  const drawerNav = (
    <CustomButton
      buttonText="Show details"
      onClick={() => setOpenDrawer(prev => !prev)}
      variant="text"
    />
  );

  return (
    <MainLayout
      content={<Conversation />}
      headerText="Messages"
      navigation={<ConversationList />}
      drawer={
        selectedRequest ? (
          <RequestDrawer
            requestData={selectedRequest}
            open={openDrawer}
            onClose={() => setOpenDrawer(prev => !prev)}
          />
        ) : undefined
      }
      drawerButton={selectedRequest && drawerNav}
      useDrawer={selectedRequest ? true : false}
    />
  );
};

export default Messages;
