import { FC } from 'react';
import { DataLineProps } from './types';

import { DataItem } from './styles';

const DataLine: FC<DataLineProps> = ({ label, value }) => {
  return <DataItem key={label}>{value}</DataItem>;
};

export default DataLine;
