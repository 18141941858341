import { FC, useContext } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
} from '@mui/material';

import AppContext from 'contexts/AppContext';
import { Frequency, Request } from 'api/lesson.utils';
import DataContext from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';

interface StyledCardProps {
  darkmode?: string | undefined;
  selected?: boolean;
}

interface RequestCardProps {
  handleClick: () => void;
  id: string;
  request: Request;
}

const StyledCard = styled(Card)<StyledCardProps>`
  ${({ darkmode, selected }) =>
    selected
      ? 'border: 1px solid rgba(51, 153, 255);'
      : darkmode === 'true'
      ? 'border: 1px solid #FFFFFF;'
      : 'border: 1px solid#121212;'}
  box-shadow: none;
  min-height: 100px;
  margin: 0 16px;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const TrackListItem: FC<RequestCardProps> = ({ handleClick, id, request }) => {
  const { isDarkMode } = useContext(AppContext);
  const { selectedTrack } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { activity, instructor, requester } = request;

  const displayName =
    currentUser && currentUser.id === instructor.id
      ? requester.name
      : instructor.name;

  return (
    <StyledCard
      darkmode={isDarkMode ? isDarkMode.toString() : ''}
      selected={selectedTrack ? id === selectedTrack.id : false}
      onClick={handleClick}
    >
      <CardActionArea
        disabled={selectedTrack ? id === selectedTrack.id : false}
        sx={{ minHeight: 100 }}
      >
        <CardHeader
          title={displayName}
          subheader={`${activity.name} Lessons`}
        />
        <StyledCardContent>
          <Chip
            label={
              Frequency[request.frequency as unknown as keyof typeof Frequency]
            }
            color="primary"
          />
          <Chip label={request.lessonType} color="primary" />
        </StyledCardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default TrackListItem;
