import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  List,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import styled from 'styled-components';

import {
  initialStudentData,
  saveStudentDocument,
  Student,
  StudentData,
  StudentInfoProps,
} from 'api/student.utils';
import {
  CustomButton,
  InputFields,
  TooltipIconButton,
} from 'components/shared';
import UserContext from 'contexts/UserContext';

import { StandardContainer } from 'assets/styles/shared';
import AddStudentDialog from 'components/Settings/Students/AddStudentDialog';
import DataContext from 'contexts/DataContext';

const CardActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const StudentSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 24px;
`;

const StyledTypography = styled(Typography)`
  margin: auto 0;
`;

const StudentSelector = ({
  disabled,
  handleAddStudent,
  students,
}: StudentInfoProps) => {
  const { currentUser } = useContext(UserContext);
  const { setUserStudents, userStudents } = useContext(DataContext);

  const [isDirty, setIsDirty] = useState(false);
  const [newStudentData, setNewStudentData] =
    useState<Student>(initialStudentData);
  const [selectedStudent, setSelectedStudent] = useState<StudentData | null>(
    null
  );
  const [showStudentForm, setShowStudentForm] = useState<string | null>(null);

  const { DropDown } = InputFields;

  const updateNewStudentData = (key: string, value: string) => {
    setNewStudentData(prevData => ({
      ...prevData,
      [key]: value,
    }));
    setIsDirty(true);
  };

  const handleAddOrUpdateStudent = () => {
    if (!currentUser) return;

    let studentId = showStudentForm === 'new' ? '' : showStudentForm;

    saveStudentDocument(currentUser, newStudentData, studentId).then(res => {
      const { docId, success } = res;
      if (!success) return;

      if (docId) {
        const existingStudentIndex = userStudents.findIndex(
          s => s.id === docId
        );

        if (existingStudentIndex > -1) {
          const updatedStudents = [...userStudents];
          updatedStudents[existingStudentIndex] = {
            id: docId,
            student: newStudentData,
          };
          setUserStudents(updatedStudents);
        } else {
          setUserStudents([
            ...userStudents,
            {
              id: docId,
              student: { ...newStudentData, ownerId: currentUser.id },
            },
          ]);
        }

        setSelectedStudent({ id: docId, student: newStudentData });
        handleAddStudent({
          id: docId,
          student: { ...newStudentData, ownerId: currentUser.id },
        });
        setShowStudentForm(null);
      }
    });
  };

  // const handleAddOrUpdateStudent = async () => {
  //   if (!currentUser) return;

  //   try {
  //     saveStudentDocument(currentUser, newStudentData, showStudentForm);
  //     let returnedStudentId: { success: boolean; docId?: string };;

  //     if (showStudentForm === 'new') {
  //       returnedStudentId = await saveStudentDocument(
  //         currentUser,
  //         newStudentData,
  //       );
  //     } else {
  //       await saveStudentDocument(currentUser, newStudentData, showStudentForm);
  //     }

  //     const studentId =
  //       returnedStudentId ||
  //       (showStudentForm !== 'new' && showStudentForm) ||
  //       '';

  //     const existingStudentIndex = userStudents.findIndex(
  //       s => s.id === studentId
  //     );

  //     if (existingStudentIndex > -1) {
  //       const updatedStudents = [...userStudents];
  //       updatedStudents[existingStudentIndex] = {
  //         id: studentId,
  //         student: newStudentData,
  //       };
  //       setUserStudents(updatedStudents);
  //     } else {
  //       setUserStudents([
  //         ...userStudents,
  //         {
  //           id: studentId,
  //           student: { ...newStudentData, ownerId: currentUser.id },
  //         },
  //       ]);
  //     }
  //     setSelectedStudent({ id: studentId, student: newStudentData });
  //     handleAddStudent({
  //       id: studentId,
  //       student: { ...newStudentData, ownerId: currentUser.id },
  //     });
  //     setShowStudentForm(null);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleReset = () => {
    setNewStudentData(initialStudentData);
    setShowStudentForm(null);
  };

  const handleStudentPickerChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    if (value === 'new') {
      setShowStudentForm('new');
      return;
    }

    const matchedStudent =
      userStudents && userStudents.find(student => student.id === value);

    if (!matchedStudent) return;

    setSelectedStudent(matchedStudent);
    handleAddStudent(matchedStudent);
  };

  const handleDelete = () => {
    setSelectedStudent(null);
    setNewStudentData(initialStudentData);
    handleAddStudent({ id: '', student: initialStudentData });
  };

  const handleEdit = (id: string) => {
    if (!selectedStudent) return;

    setNewStudentData(selectedStudent.student);
    setShowStudentForm(id);
  };

  const options = userStudents
    .filter(student => student.student.status === 'active')
    .map(student => ({
      name: student.student.firstName + ' ' + student.student.lastName,
      value: student.id,
    }));

  return (
    <StandardContainer>
      <List>
        {selectedStudent ? (
          <Card key={selectedStudent.id}>
            <CardContent>
              <CardHeader
                action={
                  <CardActionButtons>
                    <TooltipIconButton
                      action={() => handleEdit(selectedStudent.id)}
                      icon={<Edit />}
                      tooltipText="Edit Student Info"
                    />
                    <TooltipIconButton
                      action={() => handleDelete()}
                      icon={<Delete />}
                      tooltipText="Remove student from request"
                    />
                  </CardActionButtons>
                }
                subheader={`Age: ${selectedStudent.student.age}`}
                title={
                  selectedStudent.student.firstName +
                  ' ' +
                  selectedStudent.student.lastName
                }
              />
            </CardContent>
          </Card>
        ) : (
          <Alert severity="info" variant="outlined">
            <AlertTitle>You haven't added a student yet</AlertTitle>
            Please select an existing student or add a new student.
          </Alert>
        )}
      </List>
      {!selectedStudent && (
        <StudentSelectorContainer>
          <DropDown
            disabled={disabled}
            id={'student'}
            label={'Choose existing student'}
            onChange={handleStudentPickerChange}
            options={options}
            staticOption={{ name: 'Add a new student', value: 'new' }}
            value={
              showStudentForm && showStudentForm !== 'new'
                ? showStudentForm
                : ''
            }
          />
          <StyledTypography variant="body1">or</StyledTypography>
          <CustomButton
            buttonText={'Add'}
            disabled={disabled}
            onClick={() => setShowStudentForm('new')}
            startIcon={<Add />}
          />
        </StudentSelectorContainer>
      )}
      <AddStudentDialog
        cancel={handleReset}
        isDirty={isDirty}
        onClose={handleReset}
        open={!!showStudentForm}
        save={handleAddOrUpdateStudent}
        student={newStudentData}
        updateStudent={updateNewStudentData}
      />
    </StandardContainer>
  );
};

export default StudentSelector;
