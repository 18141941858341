import { Paper, Typography } from '@mui/material';
import { getImageURL } from 'api/user.utils';
import { StandardContainer } from 'assets/styles/shared';
import AppContext, { DarkModeProps } from 'contexts/AppContext';
import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

interface AboutMeProps {
  details?: string;
  instructorId?: string;
  instructorName?: string;
}

const AboutMeContent = styled.div`
  height: 400px;
  margin: 0 auto;
  position: relative;
  width: 625px;
`;

const ProfileImageContainer = styled.img`
  border-radius: 16px;
  left: 0;
  position: absolute;
  top: 50px;
`;

const InstructorBioContainer = styled(Paper)`
  border-radius: 16px;
  padding: 16px;
  position: absolute;
  right: 0;
  width: 400px;
  z-index: 1;
`;

const AboutMeContainer = styled(StandardContainer)<DarkModeProps>`
  background: ${({ darkMode }) =>
    darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'};
  max-width: 100%;
  margin-top: 0px;
  padding-top: 36px;
`;

const AboutMe: FC<AboutMeProps> = ({
  details,
  instructorId,
  instructorName,
}) => {
  const { isDarkMode } = useContext(AppContext);

  const [profileImage, setProfileImage] = useState('');

  const handleImageUrl = (url: string) => {
    setProfileImage(url);
  };

  useEffect(() => {
    if (!instructorId) return;

    console.log('get profile image url query ran');
    getImageURL(`/avatars/${instructorId}/avatar.jpg`, handleImageUrl);
  }, [instructorId]);

  return (
    <AboutMeContainer darkMode={isDarkMode}>
      <AboutMeContent>
        <ProfileImageContainer src={profileImage} alt="instructor profile" />
        <InstructorBioContainer elevation={10}>
          <Typography>{details}</Typography>
        </InstructorBioContainer>
      </AboutMeContent>
    </AboutMeContainer>
  );
};

export default AboutMe;
