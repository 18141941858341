import { useState } from 'react';

import { Layouts } from 'components/shared';
import StudentTable from 'components/Students/StudentTable';
import AddStudent from 'components/Students/AddStudent';
import { useNavigate } from 'react-router-dom';

const Students = () => {
  const navigate = useNavigate();

  const { MainLayout } = Layouts;

  const [addNewStudent, setAddNewStudent] = useState(false);

  const toggleAddStudent = () => {
    setAddNewStudent(prev => !prev);
  };

  const handleViewFamily = (id: string) => {
    navigate(`/families/details?s=${id}`);
  };

  const handleViewStudent = (id: string) => {
    navigate(`/students/details?s=${id}`);
  };

  const renderStudentsContent = () => {
    if (addNewStudent) {
      return <AddStudent toggleAddStudent={toggleAddStudent} />;
    }

    return (
      <StudentTable
        handleViewFamily={handleViewFamily}
        handleViewStudent={handleViewStudent}
        toggleAddStudent={toggleAddStudent}
      />
    );
  };

  return <MainLayout content={renderStudentsContent()} headerText="Students" />;
};

export default Students;
