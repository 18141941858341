import { Typography } from '@mui/material';
import styled from 'styled-components';

import { StandardContainer } from 'assets/styles/shared';
import CustomButton from './CustomButton';

interface CallToActionProps {
  buttonText: string;
  content?: string;
  disabled?: boolean;
  handleClick?: () => void;
  title: string;
}

const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
  width: 100%;
`;

const CallToActionTitle = styled(Typography)`
  margin: 0 auto 40px;
`;

const CallToActionText = styled(Typography)`
  margin: 0 auto;
  max-width: 400px;
`;

const ButtonContainer = styled.div`
  width: 250px;
  margin: 48px auto 0;
`;

const CallToAction = ({
  buttonText,
  content,
  disabled,
  handleClick,
  title,
}: CallToActionProps) => {
  return (
    <StandardContainer>
      <CallToActionContainer>
        <CallToActionTitle variant="h3">{title}</CallToActionTitle>
        <CallToActionText>{content}</CallToActionText>
        <ButtonContainer>
          <CustomButton
            buttonText={buttonText}
            disabled={disabled}
            onClick={handleClick}
            variant="contained"
          />
        </ButtonContainer>
      </CallToActionContainer>
    </StandardContainer>
  );
};

export default CallToAction;
