import { Star, StarOutline } from '@mui/icons-material';
import { TooltipIconButton } from 'components/shared';
import { FC } from 'react';
import styled from 'styled-components';

interface FavoriteInstructorProps {
  disabled?: boolean;
  handleClick: () => void;
  favorite: boolean;
}

const FavoriteContainer = styled.div`
  padding: 16px;
  position: fixed;
  right: 10px;
  z-index: 1;
`;

const FavoriteButton = styled.div`
  position: relative;
`;

const GoldStar = styled(Star)`
  color: gold;
`;

const GoldStarOutline = styled(StarOutline)`
  color: inherit;
  &:hover {
    color: gold;
  }
`;

const FavoriteInstructor: FC<FavoriteInstructorProps> = ({
  disabled = false,
  handleClick,
  favorite,
}) => {
  return (
    <FavoriteContainer>
      <FavoriteButton>
        <TooltipIconButton
          action={handleClick}
          disabled={disabled}
          icon={favorite ? <GoldStar /> : <GoldStarOutline />}
          tooltipText={favorite ? 'Remove from favorites' : 'Add to favorites'}
        />
      </FavoriteButton>
    </FavoriteContainer>
  );
};

export default FavoriteInstructor;
