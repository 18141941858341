import { Slider, TextField } from '@mui/material';
import styled from 'styled-components';

interface InputProps {
  step: number;
  min: number;
  max: number;
}

interface SliderTextFieldProps {
  ariaLabel: string;
  inputProps?: InputProps;
  onBlur: () => void;
  onSliderChange: (event: Event, newValue: number | number[]) => void;
  onTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sliderRange: { min: number; max: number };
  value: string | number | (string | number)[];
}

const SliderInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`;

const StyledSlider = styled(Slider)`
  margin: 16px 0;
`;

const StyledTextField = styled(TextField)`
  width: 100px;
`;

const SliderTextField = ({
  ariaLabel,
  inputProps = {
    step: 5,
    min: 0,
    max: 250,
  },
  onBlur,
  onSliderChange,
  onTextChange,
  sliderRange,
  value,
}: SliderTextFieldProps) => {
  return (
    <SliderInputContainer>
      <StyledSlider
        value={typeof value === 'number' ? value : 0}
        onChange={onSliderChange}
        aria-labelledby={ariaLabel}
        min={sliderRange.min}
        max={sliderRange.max}
      />
      <StyledTextField
        value={value}
        onBlur={onBlur}
        onChange={onTextChange}
        inputProps={{
          step: inputProps.step,
          min: inputProps.min,
          max: inputProps.max,
          type: 'number',
          'aria-labelledby': ariaLabel,
        }}
      />
    </SliderInputContainer>
  );
};

export default SliderTextField;
