import { ChangeEvent, useState } from 'react';

import { RequestStatus } from 'api/lesson.utils';
import Requests from 'components/MyRequests/Requests';
import { Layouts, ReasonDialog } from 'components/shared/';

const MyRequests = () => {
  const { MainLayout } = Layouts;

  const [cancelId, setCancelId] = useState('');
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const handleCancelModalChange = () => {
    setCancelModalOpen(prev => !prev);
  };

  const handleCancelReasonChange = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCancelReason(event.target.value);
  };

  const toggleCancelDialog = (id: string) => {
    setCancelId(id);
    setCancelModalOpen(prev => !prev);
  };

  return (
    <>
      <MainLayout
        headerText="My Requests"
        content={<Requests toggleCancelDialog={toggleCancelDialog} />}
      />
      <ReasonDialog
        buttonText="Cancel Request"
        handleReason={handleCancelReasonChange}
        newStatus={RequestStatus.CANCELED}
        reason={cancelReason}
        reasonTitle="Cancel Request"
        reasonContent="Please enter a reason for cancelling this request."
        reasonLabel="Reason for Cancelling"
        reasonType="cancelReason"
        open={cancelModalOpen}
        requestId={cancelId || ''}
        setOpen={handleCancelModalChange}
      />
    </>
  );
};

export default MyRequests;
