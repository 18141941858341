import { CircularProgress, Typography } from '@mui/material';
import { StandardContainer } from 'assets/styles/shared';
import { QRCodeCanvas } from 'qrcode.react';
import styled from 'styled-components';

const QrCodeContentContainer = styled.div`
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 24px;
  left: 50%;
  padding: 4px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const SaveAndPreviewButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  padding: 8px;
`;

const StyledQRCodeCanvas = styled(QRCodeCanvas)`
  border-radius: 0;
`;

const StyledStandardContainer = styled(StandardContainer)`
  margin: 0 96px 48px;
  max-width: 595px;
`;

const StyledTypography = styled(Typography)`
  margin: auto 0;
`;

const UrlContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UrlIconButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const UrlNameAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
`;

export {
  QrCodeContentContainer,
  SaveAndPreviewButtonContainer,
  SectionContainer,
  StyledCircularProgress,
  StyledQRCodeCanvas,
  StyledStandardContainer,
  StyledTypography,
  UrlContainer,
  UrlIconButtonContainer,
  UrlNameAndButtonContainer,
};
