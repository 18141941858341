import RequestDrawer from 'components/RequestDrawer/RequestDrawer';
import Track from 'components/TrackManager/Track';
import TrackList from 'components/TrackManager/TrackList';
import { CustomButton, Layouts } from 'components/shared';
import DataContext from 'contexts/DataContext';
import { useContext, useState } from 'react';

const TrackManager = () => {
  const { selectedTrack } = useContext(DataContext);
  const { MainLayout } = Layouts;

  const [openDrawer, setOpenDrawer] = useState(false);

  const drawerNav = (
    <CustomButton
      buttonText="Show details"
      onClick={() => setOpenDrawer(prev => !prev)}
      variant="text"
    />
  );

  return (
    <MainLayout
      headerText="Track Manager"
      content={<Track />}
      navigation={<TrackList />}
      drawer={
        selectedTrack ? (
          <RequestDrawer
            requestData={selectedTrack}
            open={openDrawer}
            onClose={() => setOpenDrawer(prev => !prev)}
          />
        ) : undefined
      }
      drawerButton={selectedTrack ? drawerNav : undefined}
      useDrawer={selectedTrack ? true : false}
    />
  );
};

export default TrackManager;
