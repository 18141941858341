import { FC } from 'react';
import styled from 'styled-components';
import { Card, Typography } from '@mui/material';

import { StandardContainer } from 'assets/styles/shared';

interface iCard {
  details: string;
  icon?: JSX.Element;
  title: string;
}

interface ThreeTileProps {
  cards: iCard[];
  title: JSX.Element;
}

const CardContent = styled(Typography)`
  padding: 8px 16px;
  text-align: center;
`;

const CardTitle = styled(Typography)`
  color: #58abff;
  margin: 0 auto 8px;
  width: fit-content;
`;

const IconContainer = styled.div`
  color: #58abff;
  margin: 16px auto;
  width: fit-content;
`;

const ThreeTileCard = styled(Card)`
  width: 375px;
  margin: 0 24px;
  padding: 24px 16px;
`;

const ThreeTileCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  margin: 0 auto;
`;

const ThreeTileSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
`;

const ThreeTileTitle = styled(Typography)`
  margin: 0 auto 40px;
`;

const ThreeTile: FC<ThreeTileProps> = ({ cards, title }) => {
  return (
    <StandardContainer>
      <ThreeTileSectionContainer>
        <ThreeTileTitle variant="h3">{title}</ThreeTileTitle>
        <ThreeTileCardContainer>
          {cards.map(({ details, icon, title }) => {
            return (
              <ThreeTileCard key={title} variant={'outlined'}>
                {icon && <IconContainer>{icon}</IconContainer>}
                <CardTitle variant="h5">{title}</CardTitle>
                <CardContent>{details}</CardContent>
              </ThreeTileCard>
            );
          })}
        </ThreeTileCardContainer>
      </ThreeTileSectionContainer>
    </StandardContainer>
  );
};

export default ThreeTile;
