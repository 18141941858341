import { Archive, Check, Close, Edit, Undo } from '@mui/icons-material';
import { CardHeader, Chip, IconButton, Tooltip } from '@mui/material';
import { FC, useContext, useState } from 'react';

import AppContext from 'contexts/AppContext';
import { CardProps } from './types';

import {
  ConfirmationButtons,
  ConfirmationContainer,
  ConfirmationText,
  LeftActions,
  StyledCard,
  StyledCardActions,
} from './styles';
import DataContext from 'contexts/DataContext';
import ArchiveActivityDialog from './ArchiveActivityDialog';

const ActivityCard: FC<CardProps> = ({
  activityId,
  archiveClick,
  direction,
  editClick,
  restoreClick,
  status,
  title,
}) => {
  const { isDarkMode } = useContext(AppContext);
  const { selectedActivity, userActivities } = useContext(DataContext);

  const [confirmArchive, setConfirmArchive] = useState(false);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const activeActivities = userActivities.filter(
    ({ activity }) => activity.active
  );

  const archiveConfirmation = () => {
    if (activeActivities.length === 1) {
      setOpen(true);
    } else {
      setConfirmArchive(prev => !prev);
    }
  };

  const handleArchive = () => {
    if (!archiveClick) return;

    archiveClick();
    setProcessing(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProcessing(false);
  };

  const chip = () => {
    switch (status) {
      case 'active':
        return <Chip label="Active" color="primary" />;
      case 'archived':
        return <Chip label="Archived" color="error" variant="outlined" />;
      case 'draft':
        return <Chip label="Draft" color="primary" variant="outlined" />;
      default:
        return;
    }
  };

  return (
    <StyledCard
      darkmode={isDarkMode ? isDarkMode.toString() : ''}
      direction={direction}
      selected={activityId === selectedActivity}
    >
      {confirmArchive ? (
        processing ? (
          <ConfirmationContainer>
            <ConfirmationText variant="h5">Archiving...</ConfirmationText>
          </ConfirmationContainer>
        ) : (
          <ConfirmationContainer>
            <ConfirmationText variant="h5">Confirm archive?</ConfirmationText>
            <ConfirmationButtons>
              <IconButton onClick={handleArchive} size="large">
                <Check />
              </IconButton>
              <IconButton onClick={archiveConfirmation} size="large">
                <Close />
              </IconButton>
            </ConfirmationButtons>
          </ConfirmationContainer>
        )
      ) : (
        <>
          <CardHeader title={title} />
          <StyledCardActions>
            {chip()}
            <LeftActions>
              <Tooltip placement="top" title="Edit">
                <IconButton onClick={editClick} aria-label="edit">
                  <Edit />
                </IconButton>
              </Tooltip>
              {status === 'archived' ? (
                <Tooltip placement="top" title="Restore">
                  <IconButton onClick={restoreClick} aria-label="restore">
                    <Undo />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Archive">
                  <IconButton
                    aria-label="archive"
                    onClick={archiveConfirmation}
                  >
                    <Archive />
                  </IconButton>
                </Tooltip>
              )}
            </LeftActions>
          </StyledCardActions>
        </>
      )}
      <ArchiveActivityDialog open={open} onClose={handleClose} />
    </StyledCard>
  );
};

export default ActivityCard;
