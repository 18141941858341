import { FC, useContext } from 'react';
import styled from 'styled-components';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';

import AppContext from 'contexts/AppContext';

interface CardProps {
  description?: string;
  handleCardClick: () => void;
  icon?: JSX.Element;
  title: string | null;
}

interface StyledCardProps {
  darkmode?: string | undefined;
}

const StyledCard = styled(Card)<StyledCardProps>`
  border: 1px solid
    ${({ darkmode }) => (darkmode === 'true' ? '#FFFFFF' : '#121212')};
  box-shadow: none;
  margin: 8px;
  max-width: 360px;
  min-height: 150px;
  width: -webkit-fill-available;
`;

const CustomCard: FC<CardProps> = ({
  description,
  handleCardClick,
  icon,
  title,
}) => {
  const { isDarkMode } = useContext(AppContext);

  return (
    <StyledCard
      darkmode={isDarkMode ? isDarkMode.toString() : ''}
      onClick={handleCardClick}
    >
      <CardActionArea sx={{ minHeight: 150, minWidth: 250 }}>
        <CardContent>
          {icon}
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default CustomCard;
