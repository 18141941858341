import { Alert } from '@mui/material';
import { useCallback, useContext, useState } from 'react';

import { CustomButton, CustomDivider, InputFields } from 'components/shared';
import ActivityRequestDialog from 'components/shared/ActivityRequestDialog';
import DataContext, { Rate } from 'contexts/DataContext';
import UserContext from 'contexts/UserContext';
import RateSheet from './RateSheet';

import {
  ActivityDetailsFormContainer,
  ButtonContainer,
  PublishSaveContainer,
} from './styles';

const ActivityDetails = () => {
  const {
    activityData,
    activityList,
    clearMatchedActivityData,
    saveActivity,
    setSelectedActivity,
    togglePublishActivity,
    userActivities,
  } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { AutocompleteDropdown, TextArea, TimeOfDayField } = InputFields;

  const [isDirty, setIsDirty] = useState(false);
  const [showActivityRequest, setShowActivityRequest] = useState(false);
  const [updatedActivityData, setUpdatedActivityData] = useState(activityData);

  const activeActivities = userActivities.filter(
    ({ activity }) => activity.active
  );

  const updateActivity = (
    key: string,
    value: string | number | Rate | Rate[] | string[] | number[],
    dirty?: boolean
  ) => {
    setUpdatedActivityData(prev => ({
      ...prev,
      activity: { ...prev.activity, [key]: value },
    }));
    setIsDirty(dirty || true);
  };

  const resetToInitialData = () => {
    setUpdatedActivityData(activityData);
    setIsDirty(false);
  };

  const handleDropdownChange = (event: any, newValue: string | null) => {
    const matchingActivity = activityList.find(({ name }) => name === newValue);
    if (!matchingActivity) return resetToInitialData();

    updateActivity('activity', newValue || '');
    updateActivity('activityId', matchingActivity.id);

    const userActivityMatch = userActivities.find(
      userActivity => userActivity.activity.activityId === matchingActivity.id
    );

    userActivityMatch
      ? setSelectedActivity(userActivityMatch.id)
      : clearMatchedActivityData(matchingActivity.id, newValue || '');
  };

  const setInitialData = useCallback(() => {
    if (activityData) resetToInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityData]);

  const validateForm = () => {
    const { activity } = updatedActivityData;
    return (
      activity.activity &&
      activity.details &&
      activity.timeOfDay?.length &&
      activity.rates?.length
    );
  };

  const orgId = currentUser?.user.orgId;
  const currentUserExists = Boolean(orgId);
  const isFormValid = validateForm();

  if (!activityData) return null;

  return (
    <ActivityDetailsFormContainer>
      <AutocompleteDropdown
        onChange={handleDropdownChange}
        options={activityList.map(item => item.name)}
        value={updatedActivityData.activity.activity || null}
      />
      <CustomButton
        onClick={() => setShowActivityRequest(prev => !prev)}
        buttonText={"Don't see your activity? Request it here"}
        variant="text"
      />
      <TextArea
        fullWidth
        label="Activity Details"
        id="details"
        onChange={e => updateActivity(e.target.id, e.target.value)}
        rows={5}
        value={updatedActivityData.activity.details}
      />
      <CustomDivider margin="small" text="Availability" />
      <TimeOfDayField
        handleTODClick={(event, timeDay) => {
          timeDay.length && updateActivity('timeOfDay', timeDay);
        }}
        tOD={updatedActivityData.activity.timeOfDay}
      />
      <CustomDivider margin="small" text="Rate Sheet" />
      <RateSheet
        rateData={updatedActivityData.activity.rates}
        updateActivityData={updateActivity}
      />
      <CustomDivider margin="small" />
      <Alert severity="warning" variant="outlined">
        You need to have al least one activity "Active" at all times. Once you
        add a new activity, you can disable or archive the one you no longer
        want to use. With no active Activity, your clients would no be able to
        send you a request.
      </Alert>
      <ButtonContainer>
        <CustomButton
          buttonText="Reset"
          onClick={setInitialData}
          variant="outlined"
        />
        <PublishSaveContainer>
          <CustomButton
            buttonText={
              updatedActivityData.activity.active ? 'Disable' : 'Activate'
            }
            disabled={
              !currentUserExists ||
              !isFormValid ||
              activeActivities.length === 1
            }
            onClick={() =>
              orgId && togglePublishActivity(orgId, updatedActivityData)
            }
            variant="outlined"
          />
          <CustomButton
            buttonText="Save"
            disabled={!isDirty}
            onClick={() => orgId && saveActivity(orgId, updatedActivityData)}
          />
        </PublishSaveContainer>
      </ButtonContainer>
      <ActivityRequestDialog
        open={showActivityRequest}
        toggleDialog={() => setShowActivityRequest(prev => !prev)}
      />
    </ActivityDetailsFormContainer>
  );
};

export default ActivityDetails;
