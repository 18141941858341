import { CalendarMonth } from '@mui/icons-material';
import { Timestamp } from 'firebase/firestore';
import { FC } from 'react';

import { Frequency, RequestData } from 'api/lesson.utils';
import { DataLine } from 'components/shared';

import {
  CardDetails,
  DisplayContainer,
  MainDisplayContainer,
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
} from './styles';

interface ActivityInfoProps {
  requestData: RequestData;
}

const ActivityInfo: FC<ActivityInfoProps> = ({ requestData }) => {
  const { request } = requestData;

  const formatDate = (date: Timestamp | null) => {
    return date?.toDate().toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <MainDisplayContainer>
      <DisplayContainer>
        <StyledCard variant="outlined">
          <StyledCardContent>
            <StyledCardHeader
              title={`${
                Frequency[
                  request.frequency as unknown as keyof typeof Frequency
                ]
              } ${request.lessonType} ${request.activity.name} Lessons`}
            />
            <CardDetails>
              <DataLine
                icon={<CalendarMonth />}
                label="Original Request Date"
                value={formatDate(request.timestamp)}
              />
            </CardDetails>
          </StyledCardContent>
        </StyledCard>
      </DisplayContainer>
    </MainDisplayContainer>
  );
};

export default ActivityInfo;
