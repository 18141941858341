import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';

interface SettingsViewProps {
  leftContent?: JSX.Element;
  rightContent: JSX.Element;
}

const SettingsViewContainer = styled.div`
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 225px;
  left: 240px;
`;

const SettingsViewContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const SettingsViewDiv = styled.div`
  height: 100%;
  opacity: 1;
  overflow: hidden;
`;

const SettingsViewContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  position: relative;
`;

const SettingsViewRightSettings = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  height: auto;
  overflow: hidden;
`;

const SettingsViewLeftSettings = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  overflow: hidden;
  min-width: 282px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  margin-bottom: 36px;
  max-height: 100%;
  overflow: scroll;
`;

const SettingsView = ({ leftContent, rightContent }: SettingsViewProps) => {
  return (
    <SettingsViewContainer>
      <SettingsViewContentContainer>
        {leftContent && (
          <>
            <SettingsViewLeftSettings>
              <SettingsViewDiv>
                <SettingsViewContent>{leftContent}</SettingsViewContent>
              </SettingsViewDiv>
            </SettingsViewLeftSettings>
            <Divider orientation="vertical" flexItem sx={{ mb: 4 }} />
          </>
        )}
        <SettingsViewRightSettings>
          <SettingsViewDiv>
            <SettingsViewContent>
              <StyledBox>{rightContent}</StyledBox>
            </SettingsViewContent>
          </SettingsViewDiv>
        </SettingsViewRightSettings>
        <Divider orientation="vertical" flexItem />
      </SettingsViewContentContainer>
    </SettingsViewContainer>
  );
};

export default SettingsView;
