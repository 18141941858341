import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowBack } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import { RequestData, RequestStatus } from 'api/lesson.utils';
import { CustomButton, TabPanel } from 'components/shared';
import { Proposal } from 'components/Proposal';
import { ActivityInfo, RequestInfo, StudentInfo } from 'components/Request';

interface ReviewRequestProps {
  handleAcceptRequest: () => void;
  handleCancelRequest: () => void;
  handleDeclineRequest: () => void;
  handleGoBack: () => void;
  requestToReview: RequestData;
}

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const RequestReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledTab = styled(Tab)`
  width: 200px;
`;

const StyledTabs = styled(Tabs)`
  margin: 0 16px;
`;

const ReviewRequest: React.FC<ReviewRequestProps> = ({
  handleAcceptRequest,
  handleCancelRequest,
  handleDeclineRequest,
  handleGoBack,
  requestToReview,
}) => {
  const { request } = requestToReview;

  const [activeTab, setActiveTab] = useState('request');

  const hasProposal = request.requestStatus === RequestStatus.SENT; // Assuming proposal is a property of requestToReview
  const tabList = hasProposal
    ? [
        { index: 'proposal', title: 'Proposal' },
        { index: 'request', title: 'Request' },
      ]
    : [{ index: 'request', title: 'Request' }];

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <RequestReviewContainer>
      <ButtonContainer>
        <CustomButton
          onClick={handleGoBack}
          buttonText="Back to All Requests"
          startIcon={<ArrowBack />}
          variant="text"
        />
        {request.requestStatus === RequestStatus.NEW && (
          <CustomButton
            onClick={handleCancelRequest}
            buttonText="Cancel this request"
            color="error"
            variant="outlined"
          />
        )}
      </ButtonContainer>
      {tabList.length > 1 && (
        <StyledTabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Request Tabs"
        >
          {tabList.map(tab => (
            <StyledTab key={tab.title} label={tab.title} value={tab.index} />
          ))}
        </StyledTabs>
      )}
      {tabList.map(tab => (
        <TabPanel key={tab.index} isActive={activeTab === tab.index}>
          {tab.index === 'proposal' ? (
            <Proposal
              handleAccept={handleAcceptRequest}
              handleDecline={handleDeclineRequest}
              requestData={requestToReview}
            />
          ) : (
            <ReviewContainer>
              <ActivityInfo requestData={requestToReview} />
              <StudentInfo
                studentData={requestToReview.request.student}
                variant="outlined"
              />
              <RequestInfo requestData={requestToReview} />
            </ReviewContainer>
          )}
        </TabPanel>
      ))}
    </RequestReviewContainer>
  );
};

export default ReviewRequest;
