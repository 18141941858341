import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  isActive: boolean;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, isActive }) => (
  <div role="tabpanel" hidden={!isActive}>
    {isActive && children}
  </div>
);

export default TabPanel;
