import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';

import { RequestStatus } from 'api/lesson.utils';
import { LessonManagerNav, LessonTable } from 'components/LessonManager';
import { Layouts, ReasonDialog } from 'components/shared';
import DataContext from 'contexts/DataContext';

import UserContext from 'contexts/UserContext';
import { SelectChangeEvent } from '@mui/material';
import styled from 'styled-components';
import RequestDrawer from 'components/RequestDrawer/RequestDrawer';

const LessonTableContainter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
`;

const LessonManager = () => {
  const {
    incomingRequests,
    outgoingRequests,
    selectedRequest,
    setSelectedRequest,
    userActivities,
  } = useContext(DataContext);
  const { currentUser } = useContext(UserContext);

  const { MainLayout } = Layouts;

  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [status, setStatus] = useState<string>(RequestStatus.NEW);
  const [selectedActivities, setSelectedActivities] = useState<
    Record<string, boolean>
  >({});

  const allRequests =
    currentUser && currentUser.user && currentUser.user.instructor
      ? incomingRequests
      : outgoingRequests;

  const filteredRequests = useMemo(() => {
    if (!allRequests.length) return [];

    return allRequests.filter(({ request }) => {
      const statusMatches = request.requestStatus === status;
      const activityMatches = selectedActivities[request.activity.name];

      return statusMatches && activityMatches;
    });
  }, [allRequests, selectedActivities, status]);

  const sortedRequests = filteredRequests.sort((a, b) => {
    const aDate = a.request.timestamp;
    const bDate = b.request.timestamp;

    if (!aDate || !bDate) return 0;

    return bDate.toMillis() - aDate.toMillis();
  });

  const handleDeclineModalChange = () => {
    setDeclineModalOpen(prev => !prev);
    setDeclineReason('');
  };

  const handleDeclineReasonChange = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDeclineReason(event.target.value);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value);
  };

  const handleActivityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedActivities({
      ...selectedActivities,
      [value]: event.target.checked,
    });
  };

  const handleRowClick = (id: string) => {
    const requestToManage =
      allRequests.find(request => request.id === id) || null;
    requestToManage && setSelectedRequest(requestToManage);
    setOpenDrawer(prev => !prev);
  };

  useEffect(() => {
    if (userActivities) {
      const initialActivities = userActivities.reduce(
        (acc, activity) => ({
          ...acc,
          [activity.activity.activity]: true,
        }),
        {}
      );
      setSelectedActivities(initialActivities);
    }
  }, [userActivities]);

  return (
    <>
      <MainLayout
        content={
          <LessonTableContainter>
            <LessonManagerNav
              changeActivity={handleActivityChange}
              changeStatus={handleFilterChange}
              selectedActivities={selectedActivities}
              status={status}
            />
            <LessonTable
              handleRowClick={handleRowClick}
              requestData={sortedRequests}
              toggleDeclineDialog={handleDeclineModalChange}
            />
          </LessonTableContainter>
        }
        headerText="Lesson Manager"
      />
      {selectedRequest && (
        <RequestDrawer
          accept={() => {}}
          decline={handleDeclineModalChange}
          onClose={() => setOpenDrawer(prev => !prev)}
          open={openDrawer}
          requestData={selectedRequest}
        />
      )}
      <ReasonDialog
        buttonText="Decline Request"
        handleReason={handleDeclineReasonChange}
        newStatus={RequestStatus.DECLINED}
        reason={declineReason}
        reasonTitle="Decline Request"
        reasonContent="Please enter a reason for declining this request."
        reasonLabel="Reason for Declining"
        reasonType="declineReason"
        open={declineModalOpen}
        requestId={selectedRequest?.id || ''}
        setOpen={handleDeclineModalChange}
      />
    </>
  );
};

export default LessonManager;
