import { createGlobalStyle } from 'styled-components';

interface GlobalStyleProps {
  darkMode: boolean | undefined;
}

export const GlobalStyles = createGlobalStyle<GlobalStyleProps>`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    ${({ darkMode }) =>
      darkMode
        ? 'background-color: rgba(18, 18, 18, 0.96);'
        : 'background-color: rgba(0, 0, 0, 0.04);'}
    background-image: linear-gradient(rgba(255,255,255,0.05),rgba(255,255,255,0.05));
    color: ${({ darkMode }) => (darkMode ? '#F0F0F0;' : '#121212;')}
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica', 'Arial',
      'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @keyframes enter {
      0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: translateY(-250px);
      }

      38% {
        animation-timing-function: ease-out;
        opacity: 1;
        transform: translateY(0);
      }

      55% {
        animation-timing-function: ease-in;
        transform: translateY(-65px);
      }

      72% {
        animation-timing-function: ease-out;
        transform: translateY(0);
      }

      81% {
        animation-timing-function: ease-in;
        transform: translateY(-28px);
      }

      90% {
        animation-timing-function: ease-out;
        transform: translateY(0);
      }

      95% {
        animation-timing-function: ease-in;
        transform: translateY(-8px);
      }

      100% {
        animation-timing-function: ease-out;
        transform: translateY(0);
      }
    }
  }
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
};

canvas {
  border-radius: 20px;
}
  `;
