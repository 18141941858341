import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import AppContext from 'contexts/AppContext';
import { useContext } from 'react';
import styled from 'styled-components';

interface AddNewButtonProps {
  disabled?: boolean;
  onClick: () => void;
  shouldPulsate?: boolean;
}

const AddButton = styled(({ shouldPulsate, ...otherProps }) => (
  <IconButton {...otherProps} />
))`
  height: 80px;
  width: 80px;
  margin: 16px auto;

  ${({ shouldPulsate }) =>
    shouldPulsate &&
    `
    animation: enter 2s ease 0s 1 normal forwards;
  `}
`;

const AddNewButton = ({
  disabled = false,
  onClick,
  shouldPulsate = false,
}: AddNewButtonProps) => {
  const { isDarkMode } = useContext(AppContext);
  return (
    <AddButton
      disabled={disabled}
      onClick={onClick}
      shouldPulsate={shouldPulsate}
      sx={{
        backgroundColor: isDarkMode
          ? 'rgba(255, 255, 255, 0.08)'
          : 'rgba(0, 0, 0, 0.08)',
        '&:hover': { backgroundColor: '#39F' },
      }}
    >
      <Add />
    </AddButton>
  );
};

export default AddNewButton;
