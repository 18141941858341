import {
  CalendarViewWeek,
  Place,
  Schedule,
  TaskAlt,
  TextSnippet,
} from '@mui/icons-material';
import { useCallback } from 'react';

import { Frequency, RequestData, Travel } from 'api/lesson.utils';
import { CustomButton, InputFields } from 'components/shared';
import { DataLine } from 'components/shared';
import { openGoogleMaps } from 'hooks';

import {
  CardDetails,
  DisplayContainer,
  MainDisplayContainer,
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
} from './styles';

interface RequestInfoProps {
  requestData: RequestData;
}

const RequestInfo = ({ requestData }: RequestInfoProps) => {
  const { request } = requestData;

  const { TimeOfDayField } = InputFields;

  const handleLocationClick = useCallback(
    () => openGoogleMaps(request.lessonLocation?.place_id),
    [request.lessonLocation?.place_id]
  );

  return (
    <MainDisplayContainer>
      <DisplayContainer>
        <StyledCard variant="outlined">
          <StyledCardContent>
            <StyledCardHeader title={'Request Details'} />
            <CardDetails>
              <DataLine
                icon={<TaskAlt />}
                label="Lesson Type"
                value={`${request.lessonType}`}
              />
              <DataLine
                icon={<CalendarViewWeek />}
                label="Frequency"
                value={
                  Frequency[
                    request.frequency as unknown as keyof typeof Frequency
                  ]
                }
              />
              <DataLine
                icon={<Schedule />}
                label="Preferred Time of Day"
                value={<TimeOfDayField disabled tOD={request.preferredTOD} />}
              />
              <DataLine
                icon={<TextSnippet />}
                label="Notes"
                value={request.note}
              />
              {request.travel === Travel.toStudent && (
                <>
                  <DataLine
                    icon={<Place />}
                    label="Lesson Location"
                    value={request.lessonLocation?.description}
                  />
                  <CustomButton
                    buttonText={'View Location'}
                    onClick={handleLocationClick}
                    variant="outlined"
                  />
                </>
              )}
            </CardDetails>
          </StyledCardContent>
        </StyledCard>
      </DisplayContainer>
    </MainDisplayContainer>
  );
};

export default RequestInfo;
