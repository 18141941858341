import { useContext } from 'react';

import ActivityDetails from './ActivityDetails';
import ActivityList from './ActivityList';
import MissingOrganiztion from './MissingOrganization';
import SettingsView from '../SettingsView';

import UserContext from 'contexts/UserContext';

const ActivityInfo = () => {
  const { currentUser } = useContext(UserContext);

  const hasOrganization = currentUser && !!currentUser.user.orgId;

  return hasOrganization ? (
    <SettingsView
      leftContent={<ActivityList />}
      rightContent={<ActivityDetails />}
    />
  ) : (
    <SettingsView rightContent={<MissingOrganiztion />} />
  );
};

export default ActivityInfo;
