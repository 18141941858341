import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import { UserData } from 'contexts/UserContext';
import { auth, db } from './firebaseConfig';
import { ActivityType } from 'contexts/DataContext';
import { Dayjs, isDayjs } from 'dayjs';
import {
  convertDayjsToFirestoreTimestamp,
  convertFirestoreTimestampToDayjs,
} from './user.utils';

export enum BillingType {
  HOURLY = 'hourly',
  MONTHLY = 'monthly',
  LESSON = 'lesson',
  NONE = 'none',
}

export enum StudentStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  LEAD = 'lead',
}

export enum StudentType {
  CHILD = 'child',
  ADULT = 'adult',
}

export interface Student {
  age: string;
  dob: Dayjs | null;
  defaultActivity: ActivityType | '';
  defaultLessonLength: string;
  defaultLessonRate: number;
  defaultBillingType: BillingType;
  email: string;
  firstName: string;
  lastName: string;
  familyId: string;
  gender: string;
  notes: string;
  ownerId: string;
  phone: string;
  status: StudentStatus;
  type: StudentType;
}

export interface StudentData {
  id: string;
  student: Student;
}

export interface StudentInfoProps {
  disabled?: boolean;
  handleAddStudent: (studentData: StudentData) => void;
  students: StudentData[];
}

export const initialStudentData: Student = {
  age: '',
  dob: null,
  defaultActivity: '',
  defaultBillingType: BillingType.NONE,
  defaultLessonLength: '',
  defaultLessonRate: 50,
  email: '',
  firstName: '',
  lastName: '',
  familyId: '',
  gender: '',
  notes: '',
  ownerId: '',
  phone: '',
  status: StudentStatus.ACTIVE,
  type: StudentType.CHILD,
};

export const calculateAge = (dob: Dayjs | null) => {
  if (!dob) return '';
  const ageDiffMs = Date.now() - dob.toDate().getTime();
  const ageDate = new Date(ageDiffMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getUserStudents = async (
  setStudents: (studentData: StudentData[]) => void
) => {
  const userId = auth.currentUser?.uid;

  if (!userId) return;

  console.log('student query ran after mount');

  const studentsSnapshot = await getDocs(
    query(collection(db, 'students'), where('ownerId', '==', userId))
  );

  const students: StudentData[] = [];

  studentsSnapshot.forEach(doc => {
    const studentData = doc.data() as Student;
    students.push({
      id: doc.id,
      student: {
        ...studentData,
        dob: studentData.dob
          ? convertFirestoreTimestampToDayjs(
              studentData.dob as unknown as Timestamp
            )
          : null,
      },
    });
  });

  setStudents(students);
};

export const saveStudentDocument = async (
  currentUser: UserData | null,
  studentData: Student,
  studentId?: string | null
): Promise<{ success: boolean; docId?: string }> => {
  const authId = auth.currentUser?.uid;
  if (!currentUser || !authId) return { success: false };

  if (authId !== currentUser.id) return { success: false };

  try {
    let docId;
    let firestoreDate: Timestamp | null;

    if (isDayjs(studentData.dob)) {
      firestoreDate = convertDayjsToFirestoreTimestamp(studentData.dob);
    } else {
      firestoreDate = studentData.dob;
    }

    if (studentId) {
      await setDoc(
        doc(db, 'students', studentId),
        {
          ...studentData,
          dob: firestoreDate,
          ownerId: studentData.ownerId || authId,
          status: studentData.status || 'active',
        },
        {
          merge: true,
        }
      );
      docId = studentId;
    } else {
      const newStudentId = await addDoc(collection(db, 'students'), {
        ...studentData,
        dob: firestoreDate,
        ownerId: studentData.ownerId || authId,
        status: studentData.status || 'active',
      });
      docId = newStudentId.id;
    }
    return { success: true, docId };
  } catch (error) {
    return { success: false };
  }
};

export const deleteStudent = async (
  currentUser: UserData | null,
  studentId: string
) => {
  const authId = auth.currentUser?.uid;
  if (!currentUser || !authId) return;

  if (authId !== currentUser.id) return;

  // TODO: add a check to see if the student has any associated lesson data
  // if so, do not delete and instead set status as inactive with returned
  // message to user that student has associated lesson data

  if (studentId) {
    await deleteDoc(doc(db, 'students', studentId));
  }
};

export const changeStudentStatus = async (
  currentUser: UserData | null,
  studentId: string,
  newStatus: 'active' | 'inactive' | 'lead'
) => {
  let success = false;
  try {
    const authId = auth.currentUser?.uid;
    if (!currentUser || !authId) return;

    if (authId !== currentUser.id) return;

    if (studentId && newStatus) {
      await setDoc(
        doc(db, 'students', studentId),
        { status: newStatus },
        {
          merge: true,
        }
      );
    }
    success = true;
  } catch (error) {
    console.error(error);
  }
  return success;
};
