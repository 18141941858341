import { InputBaseComponentProps, TextField } from '@mui/material';

interface TextAreaProps {
  disabled?: boolean;
  fullWidth?: boolean;
  id?: string;
  inputProps?: InputBaseComponentProps;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  value?: string;
  variant?: 'standard' | 'filled' | 'outlined';
}

const TextArea = ({
  disabled = false,
  fullWidth = false,
  id,
  inputProps,
  label,
  onChange,
  rows,
  value,
  variant = 'outlined',
}: TextAreaProps) => {
  return (
    <TextField
      disabled={disabled}
      fullWidth={fullWidth}
      id={id}
      inputProps={inputProps}
      label={label}
      minRows={rows}
      multiline
      onChange={onChange}
      value={value}
      variant={variant}
    />
  );
};

export default TextArea;
