export enum FieldTypes {
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
  IMAGE = 'IMAGE',
  LEGAL_NAME = 'LEGAL_NAME',
  PHONE = 'PHONE',
}

export enum SectionName {
  ADDRESS = 'Address',
  EMAIL = 'Email',
  IMAGE = 'Profile Image',
  LEGAL_NAME = 'Legal name',
  PHONE = 'Phone',
}

export interface UserData {
  address?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  imagePath?: string;
  phone?: string;
  placeId?: string;
}

export interface DataLineProps {
  label: string;
  value?: string;
}

export interface FieldData {
  [x: string]: string | undefined;
}

export interface FieldProps {
  data?: FieldData;
  handleChange?: (address: PlaceType | null) => void;
  id?: string;
}

export interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

export interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

export interface UserSectionData {
  firstName?: string;
  lastName?: string;
  email?: string;
  imageUrl?: string;
}

export interface SectionDataProps {
  handleAddressChange?: (address: PlaceType | null) => void;
  handleChange?: React.ChangeEventHandler<HTMLInputElement>;
  handleImageChange?: (imageUrl: string, imageName?: string) => void;
  handleSubmit?: () => void;
  label: SectionName;
  sectionData: FieldData;

  editDescription?: string;
  disabled?: boolean;
  disabledReason?: string;
  fieldType?: FieldTypes;
  options?: { name: string; value: string }[];
  toggleText?: string;
  showDivider?: boolean;
}

export interface SettingSectionProps {
  active: boolean;
  data: FieldData;
  disabled?: boolean;
  disabledReason?: string;
  expandedIndex: number | null;
  fieldMap: FieldData;
  handleAddressChange?: (address: PlaceType | null) => void;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageChange?: (imageUrl: string, imageName?: string) => void;
  handleSubmit: () => void;
  handleToggleClick: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
  index: number;
  isDirty: boolean;
  label: SectionName;
  searchLocation: PlaceType | null;
  showDivider?: boolean;
  editDescription?: string;
  fieldType?: FieldTypes;
  storageRefLocation: string;
  toggleText?: string;
  setExpandedIndex?: React.Dispatch<React.SetStateAction<number | null>>;
}

export interface PhoneVerificationProps {
  currentNumber?: string;
  setExpandedIndex?: React.Dispatch<React.SetStateAction<number | null>>;
}
