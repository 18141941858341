import { Card, CardActions, FormGroup, Typography } from '@mui/material';
import styled from 'styled-components';

import { StyledCardProps } from './types';

const ActivityDetailsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 40px;
  max-width: 680px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
`;

const ConfirmationButtons = styled.div`
  display: flex;
  gap: 48px;
  justify-content: center;
`;

const ConfirmationContainer = styled.div`
  margin: 20px auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  height: 80px;
`;

const ConfirmationText = styled(Typography)`
  margin: 0 auto;
`;

const LeftActions = styled.div``;

const OpenRequestsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  overflow: scroll;
  margin-bottom: 36px;
`;

const PublishSaveContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const ShowArchivedCheckbox = styled(FormGroup)`
  margin-left: 24px;
`;

const StyledCard = styled(Card)<StyledCardProps>`
  ${({ darkmode, selected }) =>
    selected
      ? 'border: 1px solid rgba(51, 153, 255);'
      : darkmode === 'true'
      ? 'border: 1px solid #FFFFFF;'
      : 'border: 1px solid #121212;'}
  box-shadow: none;
  margin: ${({ direction }) =>
    direction === 'horizontal' ? '8px' : '16px 16px 0 16px'};
  min-width: 250px;
  width: -webkit-fill-available;
  overflow: unset;
`;

const StyledCardActions = styled(CardActions)`
  justify-content: space-between;
  margin: 0 8px;
`;

export {
  ActivityDetailsFormContainer,
  ButtonContainer,
  ConfirmationButtons,
  ConfirmationContainer,
  ConfirmationText,
  LeftActions,
  OpenRequestsContainer,
  PublishSaveContainer,
  ShowArchivedCheckbox,
  StyledCard,
  StyledCardActions,
};
