import { createPortalLink } from 'api/user.utils';
import { CustomButton } from 'components/shared';
import UserContext from 'contexts/UserContext';
import { FC, useContext } from 'react';

interface PortalLinkResponse {
  url: string;
}

const PaymentInfo: FC = () => {
  const { currentUser } = useContext(UserContext);

  console.log('id => ', currentUser?.id);
  const handlePaymentPortalClick = () => {
    if (!currentUser) {
      console.error('Current user is not available.');
      return;
    }

    if (!currentUser.id) {
      console.error('Current user ID is not available.');
      return;
    }

    createPortalLink({
      returnUrl: 'http://localhost:3000/dashboard',
    })
      .then(result => {
        const data = result.data as PortalLinkResponse;
        window.location.assign(data.url);
      })
      .catch(error => {
        console.error('Error generating portal link: ', error);
      });
  };

  return (
    <div>
      <CustomButton
        buttonText={'Go to Payment Portal'}
        onClick={handlePaymentPortalClick}
      />
    </div>
  );
};

export default PaymentInfo;
